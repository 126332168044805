.popular-container {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: #f5f5f5;
  z-index: 1;
}
.popular-container[agencypage="yes"]{
  /* background-color:#fff; */
}
.popular-product-gradient {
  position: absolute;
  width: calc(100% - 55px);
  height: 590px;
  z-index: -1;
}
.agencyback{
  margin-top:40px !important;
}
.popular-container-heading {
  padding-top: 20px;
  padding-left: 2.5vw;
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 700;
  margin-bottom: -10px;
  font-size: 30px;
  line-height: 46px;
  /* identical to box height */

  letter-spacing: 0.02em;

  color: #03989e;
}

.popular-container-sub-heading {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: -51px;
  padding-left: 36px;
  font-weight: 600;
  font-size: 27px;
  color: #fff;
}

.popular-product-container {
  display: flex;
  gap: 2vw;
  align-items: center;
  /* background-color: #F5F5F5; */
}
.popular-product-container-info-heading {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.popular-product-container-info-heading > .more-products {
  padding-right: 36px;
  color: #03989e;
  cursor: pointer;
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.02em;
  font-size: 22px;
}
.popular-products-per-category-head {
  width: 95% !important;
  display: flex;
  height: 270px;
  background-color: #fdfcff;
  scroll-behavior: smooth;
  position: relative;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
}

.popular-products-per-category-head.hovered .carousel-scroll button,
.service-provide-head.hovered .carousel-scroll button {
  visibility: visible;
  transition: visibility 0.4s ease;
}

.popular-products-per-category {
  width: 100%;
  display: flex;
  height: 270px;
  overflow-x: auto;
  overflow-y: hidden;
  background-color: #fdfcff;
  scroll-behavior: smooth;
  position: relative;
}
.popular-products-per-category > a {
  text-decoration: none;
}
.popular-product-scroll {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  visibility: hidden;
}

.popular-product-scroll-left {
  width: 49px;
  height: 108px;
  background: #ffffff;
  box-shadow: 9px 9px 4px rgba(0, 0, 0, 0.5);
  z-index: 18;
  visibility: visible;
}

.popular-products-scroll-right {
  width: 49px;
  height: 108px;
  background: #ffffff;
  box-shadow: 9px 9px 4px rgba(0, 0, 0, 0.5);
  z-index: 18;
  visibility: visible;
}

.popular-product-category {
  cursor: pointer;
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #03989e;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 36px;
}
.popularproductcard-container {
  width: 200px;
  height: 270px;
  background-color: #ffffff;
}
.popularproduct-image-container {
  padding: 0.6vw;
  width: 200px;
  height: 188px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popularproduct-image {
  width: 100%;
  height: 100%;
}
.popularproduct-details {
  height: 82px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.popularproduct-name {
  width: 201px;
  text-align: center;
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  height: 50%;
  color: #000000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.3vw;
}
.popularproduct-price {
  width: 100%;
  text-align: center;
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  height: 50%;
  color: #14a233;
  display: flex;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.popularproduct-price > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* mobile css */
.popular-container-mobile-heading {
  border: 2px solid black;
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 20px;
  /* identical to box height, or 91% */

  letter-spacing: -0.2px;

  color: #000000;
  padding: 1vw;
  padding-bottom: 40px;
}

.popular-container .popular-noproducts-available {
  display: flex;
  justify-content: center;
  font-weight: 600;
  color: #03989e;
  font-size: 20px;
}

.popular-product-gradient {
  position: absolute;
  width: calc(100% - 55px);
  min-height: 100%;
  z-index: -1;
}

.popular-product-container {
  display: flex;
  gap: 0.6rem;
  flex-direction: column;
  /* align-items: center; */
  /* background-color: #F5F5F5; */
}
.popular-products-per-category-head {
  width: 85%;
  display: flex;
  height: fit-content;
  background-color: #fdfcff;
  scroll-behavior: smooth;
  position: relative;
}
.popular-products-per-category {
  width: 100%;
  display: flex;
  height: fit-content;
  gap: 1rem;
  padding: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  background-color: #fdfcff;
  scroll-behavior: smooth;
  position: relative;
}
.my-bills-profile-page-view-main-right:has(.agency-section-container){
  background-color:#f5f5f5;
}

@media screen and (max-width: 720px) {
  .agencyback{
    margin-top:0px !important;
  }
  .popular-container {
    padding-left: 0;
  }
  .popular-product-category {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    text-align: left;
    padding-left: 10px;
  }
  .popular-product-container-info-heading > .more-products {
    font-size: 16px;
    padding-right: 20px;
  }
  .pcontainer-mobile {
    gap: 10px;
  }
}
