.service-provider-advertisement
{
 width: 100% ;
 height: 143px;
 background-color: #00838F;
 display:flex ;
 padding: 1.8vw;
}
.service-provider-advertisement-mobileview{
display: none;
}

/* image */
.service-provider-advertisement-image-container
{
 display: flex;
 flex-direction: column;
 align-items: center;
 width: 8%;
}
.service-provider-advertisement-image
{
    width: 74px;
height: 74px;
margin-bottom: 5px;
}
.service-provider-advertisement-image>img
{
    width: 74px;
height: 74px;
border-radius: 100px;

}

.service-provider-advertisement-rating-container
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.service-view-card-star
{
width: 10px;
height: 10px;
}
.service-provider-advertisement-rating-text
{
    padding-left: 5px;
 font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
/* identical to box height */

text-align: right;

color: #FFFFFF;
}
.service-provider-advertisement-number-rating
{
 font-family: 'Titillium Web';
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 15px;

color: rgba(255, 255, 255, 0.7);
}


/* info */
.service-provider-advertisement-info-container
{
    display: flex;
    justify-content: space-around;
    width: 100%;
    background-color: #00838F;
}


/* name */
.service-provider-advertisement-name-container
{


}
.service-provider-advertisement-name
{
font-family: 'Titillium Web';
font-style: normal;
font-weight: 600;
max-width: 360px;
font-size: 24px;
line-height: 37px;

color: #FFFFFF;
}
.service-provider-advertisement-time
{
 font-family: 'Titillium Web';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;

color: #F8F8F8;

opacity: 0.8;
}



/* follow button */

.service-provider-advertisement-follow-container
{

}
.service-provider-advertisement-follow
{
 outline: none;
 border: none;
 width: 145px;
height: 41px;
left: 557px;
top: 102px;

background: #FFFFFF;
border-radius: 50px;
}
.service-provider-advertisement-follow-text{
 font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
/* identical to box height */


color: #1E1E1E;
}



/* popularity */

.service-provider-advertisement-popularity-container
{
 display: flex;
 width: 17%;
 justify-content: space-between;
}
.service-provider-advertisement-follower-container
{

}
.service-provider-advertisement-follower-number
{
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 26px;
/* identical to box height */


color: #F8F8F8;
}
.service-provider-advertisement-follower-text
{
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
/* identical to box height */

text-align: center;

color: #F8F8F8;

opacity: 0.8;
}
.service-provider-advertisement-break-container
{
width: 1px;
height: 40px;
background-color: #00838F;
border: 0.1px solid #F8F8F8;
opacity: 0.2;
}
.service-provider-advertisement-following-container
{

}
.service-provider-advertisement-item-container
{

}



/* details */ 

.service-provider-advertisement-details-container
{
 display: flex;
 flex-direction: column;
gap: 0.4vw;
width: 20%;
}
.service-provider-advertisement-location-container , .service-provider-advertisement-delivery-container ,.service-provider-advertisement-pickup-container
{
 display: flex;
 height: 25px;
 align-items: baseline;
     white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.service-provider-advertisement-detail-image-container
{
    width: 20px;
    height: 20px;
}
.service-provider-advertisement-detail-image
{
width: 20px;
    height: 20px;
}
.service-provider-advertisement-detail-text
{
 font-family: 'Titillium Web';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;

color: #CDE4E6;
padding-left: 11px;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}


/* other */
.service-provider-advertisement-other-container
{
    display: grid;
    width: 12%;
    grid-template-columns: 1fr 1fr 1fr;

    padding-top: 10px;
}

.service-provider-advertisement-chat
{
    width: 29px;
    height: 29px;
}

.service-provider-advertisement-chat>img
{
    width: 29px;
    height: 29px;
    cursor: pointer;
}

.service-provider-advertisement-phone
{
    width: 23px;
    height: 23px;
}

.service-provider-advertisement-phone>img
{
    width: 23px;
    height: 23px;
    cursor: pointer;

}


.service-provider-modal {
    position: fixed;
    top: 0;
    left: 0;
    
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  
  .service-provider-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    max-width: 400px;
    min-height: 200px;
    max-height: 500px;
    height: fit-content;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .service-provider-modal-content .rating-container .star-rating{
    display: flex;
    justify-content: center;
    gap: 20px;
margin-bottom: 20px;

  }
  .service-provider-modal-content .rating-container .star-rating >span{
    font-size: 2rem;
    color:#d4d5d9;
  }
  .service-provider-modal-content .rating-container .star-rating  .star {
    cursor: pointer;
    color:#d4d5d9;
    transition: color 0.2s;
  }
  
  .service-provider-modal-content .rating-container .star-rating .star.filled,  .service-provider-modal-content .rating-container .star-rating .star.hovered {
    color: #ffc107; /* Change this color for the filled star effect */
  }
  .service-provider-modal-content .rating-container .star-rating .star.half-filled {
    color:#ffc107;
    position: relative;
  }


  
 
  
  
  
  
  
  
  
  
  
  
  .service-provider-modal-content .rating-container h1{
    font-size: 1.4rem;
    color: #00838F;
    margin-top: -30px;

  }
  .service-provider-modal-content h1{
    font-size: 1.4rem;
    color: #00838F;
  }
  .service-provider-modal-content-button{
    text-align: end;
  } 
  .service-provider-modal-close-button {
    color: #00838f;
    font-size: 1.5rem;
    opacity: .5;
    font-weight: 700;
    border: none;
    background: none;

    cursor: pointer;
  }
  .service-provider-modal-content .service-provider-contact-details{
display: flex;
flex-direction: column;
align-items: center;
gap: 30px;
  }
  .service-provider-contact-details  .buttons-container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
  }
  .service-provider-contact-details .buttons-container button{

 color: #fff;
    font-size: 1.5rem;
padding: 5px 10px;
border-radius: 5px;
    font-weight: 700;
    border: none;
    background: #00838f;

    cursor: pointer;
  }
  .service-provider-modal-content .service-provider-contact-details >div{
    font-size: 1.5rem;
    font-weight: 700;
    color: #00838f;

  }
  .service-provider-modal-content .service-provider-contact-details >p{
    color: #00838F;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: -30px;
  }
  .service-provider-modal-close-button:hover{
    color: #000;
    opacity: .75;
  }

  .service-provider-addressforminput{
    height: 140px;
    width: 100%;
    font-size: 12px;
    padding: 12px;
    border: 2px solid #d4d5d9;
    background-color: transparent;
    font-weight: 500;
    border-radius: 4px;
    line-height: 14px;
    margin-bottom: 20px;
    resize: none;
  }
  .service-provider-addressforminput:focus,.service-provider-addressforminput:active{
    outline: none;
  }
  .service-provider-addressr-Button{
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 5px;
    background: #00838f;
    cursor: pointer;
    text-align: center;
    border: none;
    border-radius: 2px;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: #00838f ;
    font-size: 14px ;
    border-radius: 4px ;
    
  }

  @media screen and (max-width: 720px) {
    .service-provider-advertisement{
      display: none;
    }
    .service-provider-advertisement-mobileview {
      display: flex;
      height: fit-content;
      flex-wrap: wrap;
      gap: 15px;
    }
    .service-provider-advertisement-image-container {
      width: 30%;
      margin-top: 20px;
    }
    .service-provider-advertisement-info-container {
      flex-direction: column;
      width: 60%;
    }
    .service-provider-advertisement-details-container {
      width: 100%;
    }
    .service-provider-advertisement-popularity-container {
      display: flex;
      width: 50%;
    }
    .service-provider-advertisement-other-container {
      width: 100%;
      grid-template-columns: repeat(6, 1fr);
    }
  }