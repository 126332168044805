.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  width: 70%;
  max-width: 600px;
  min-height: 50%;
  border-radius: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.close-button {
  width: 30px;
  height: 30px;
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #333;
  font-weight: 700;
  position: absolute;
  top: 10px;
  right: 15px;
}

.modal-body {
  flex: 1;
  overflow-y: auto;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .modal-content {
    width: 90%; /* Reduce width on smaller screens */
    min-height: auto; /* Allow height to adjust naturally */
  }
}

@media (max-width: 480px) {
  .modal-content {
    width: 95%; /* Further reduce width on very small screens */
    padding: 10px; /* Reduce padding for smaller screens */
  }
}
