.my-profile-refer-and-earn-main-page
{
  width: 100%;
 height: 100%;
 padding: 27px;
}
.my-profile-refer-and-earn-banner
{
 margin-top: 30px;
 /* padding: 1vw; */
 background-color: #f4f7ff;
 display: flex;
 align-items: center;
 justify-content: space-between;
}
.my-profile-refer-and-earn-banner-col1
{
 height: 200px;
 width: 60%;
 padding: 2vw;
}
.my-profile-refer-and-earn-banner-col1>div
{
     font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 21px;
    color: #474747;
    margin-bottom: 20px;

}
.my-profile-refer-and-earn-banner-col1>section>div
{
 font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    color: #757575;
}
.my-profile-refer-and-earn-banner-col1>section>section
{
 font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    color: #474747;
}

.my-profile-refer-and-earn-banner-col2
{
 height: 200px;
 width: 40%;
}
.my-profile-refer-and-earn-banner-col2>img
{
 height: 100%;
 width: 100%;
}



.my-profile-refer-and-earn-code
{
 display: flex;
 align-items: center;
 justify-content: space-around;
 margin-top: 30px;
 height: fit-content;
}
.my-profile-refer-and-earn-copycode
{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 30px;
height: 100%;

font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    color: #474747;
}
.my-profile-refer-and-earn-copycode>div
{
 font-size: 16px;

}
.my-profile-refer-and-earn-copycode>section
{
    border: 1px dashed #42a2a2;
    padding: 12px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
}

.my-profile-refer-and-earn-code-seperator
{
 width: 1px;
 height: 110px;
 display: flex;
 align-items: center;
 background-color: rgba(0, 0, 0, 0.116);;
}
.my-profile-refer-and-earn-code-seperator>span
{
 margin-left: -10px;
 font-weight: 700;
 color: #474747b6;
}





.my-profile-refer-and-earn-sharecode
{
 display: flex;
 align-items: center;
 flex-direction: column;
 justify-content: center;
 gap: 25px;
}
.my-profile-refer-and-earn-sharecode>div
{
 font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #474747;
}
.my-profile-refer-and-earn-sharecode>section
{
 display: flex;
 align-items: center;
 justify-content:space-around;
 gap: 20px;
}
.my-profile-refer-and-earn-sharecode>section>div
{
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
 font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    color: #474747;
    cursor: pointer;
}
.my-profile-refer-and-earn-sharecode>section>div>div
{

}
.my-profile-refer-and-earn-sharecode>section>div>img
{

}



.my-profile-refer-and-earn-working
{
 margin-top: 50px;
 display: flex;
 align-items: center;
 justify-content: space-around;
 font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #474747;
}
.my-profile-refer-and-earn-working>div>ul
{
 list-style: none;
}
.my-profile-refer-and-earn-working>div>ul>li
{
 font-size: 13px;
 display: flex;
 align-items: center;
 gap: 5px;
}
.my-profile-refer-and-earn-working-Bullet
{
 width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00838F;
    color: #fff;
    font-size: 9px;
    border-radius: 50%;
}
@media screen and (max-width: 720px){
    .my-profile-refer-and-earn-main-page {
        padding: 10px 27px;
    }
    .profile-refer-and-earn-main-page-heading
    {
        font-size: 23px;
        text-align: center;
        padding-left: 0;
    }
    .profile-refer-and-earn-main-page-heading>span
    {
        padding-left: 15px;
        padding-right: 15px;
    }
    .my-profile-refer-and-earn-banner-col1>div
    {
        font-size: 17px;
    }
    .my-profile-refer-and-earn-code
    {
        flex-direction: column;
    }
    .my-profile-refer-and-earn-copycode
    {
        gap: 20px;
    }
    .my-profile-refer-and-earn-sharecode
    {
        gap: 20px;
    }
    .my-profile-refer-and-earn-code-seperator
    {
        height: 55px;
    }
}