.admin-container{

height: 100vh;

}
.eyeopen{
    cursor: pointer;
    height: 19px;
    width: 22px;
}
.paassword{
    background: #F2F2F2;
    border: 0.2px solid #228FA6;
    box-shadow: 0px 0px 10px rgba(34, 143, 166, 0.15);
    border-radius: 3px;
    height: 40px;
    padding-left: 3px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    padding-right: 20px;
    justify-content: space-between;
}
.admin-container main,.admin-container main form{
    width: 100%;
    height: 100%;
    display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
.admin-container main .admin-form-floating{
    width: 280px;
}
.admin-container main .user input {
    background: #F2F2F2;
    border: 0.2px solid #228FA6;
    box-shadow: 0px 0px 10px rgba(34, 143, 166, 0.15);
    border-radius: 3px;
    height: 40px;
    width: 100%;
    padding-left: 3px;
    margin-bottom: 20px;
}
.admin-container main .admin-container-main-heading{
    padding-top: 20px;
    padding-left: 2.5vw;
    font-family: "Titillium Web", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 37px;
    line-height: 46px;
    margin-top: -30px;
    margin-bottom: 30px;
    letter-spacing: 0.02em;
    color: #03989e;
}
.admin-container main form .admin-checkbox-rememberme label{
    width: 100%;
display: flex;
gap: 10px;
justify-content: center;
align-items: center;
}
.admin-container main button {
    width: 159px;
    height: 48px;
    border: none;
    background: #228FA6;
    box-shadow: 0px 15px 30px rgba(34, 143, 166, 0.5);
    border-radius: 3px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
}
.adminnav{
        align-items: center;
        background-color: #00838f;
        display: flex;
        flex-wrap: nowrap;
        height: 85px;
        justify-content: space-between;
        padding: 0.4vw 3vw;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 999;
}
.login-details-headeradmin{
    align-items: center;
    display: flex;
    gap:20px;
    position: relative;
}
.login-details-headeradminright{
    position: relative;
    white-space:nowrap;
    cursor: pointer;
    font-size:18px;
    font-weight:500;
}
.paassword input{
    margin-bottom:0px !important;
    flex:1;
    background-color:#F2F2F2;
    border:0px;
    outline: none;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px){
.putatoe-logo-image {
    max-height: 50px;
}
.login-details-headeradminright{
    font-size:15px;
}
}