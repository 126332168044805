.carousal-container-desktop {
  height: 18vw;
  width: 100vw;
}
.carousel-image > img {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
}

.carousel-image.active {
  opacity: 1;
  visibility: visible;
}
.carousel-loader {
  color: #888;
  font-size: 48px;
  font-weight: bold;
  font-family: "Titillium Web";
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
@media only screen and (max-width: 583px) {
  .carousal-container-desktop {
    padding: 2.99vw;
    height: 37vw !important;
  }
  .carousel-loader {
    font-size: 20px;
  }
}
.carousel-head {
  transition: transform 0.5s ease-in-out;
  width: 100%;
  height: 100%;
  position: relative;
}
.carousel-head.hovered .carousel-scroll button {
  visibility: visible;
  transition: visibility 0.4s ease;
}
.carousel-image {
  width: 100%;
  height: 18vw;
  transition: all 2s linear;
}
.carousel-image.active {
  opacity: 1;
  width: 100%;
  height: 18vw;
  /* transform: translateX(-100%); */
}
/* .carousel-image-active:hover
{
  transform: translateX(-100%);
  transition: all 1s; 
  transform: translateX(0%);
  transition: all 1s; 

} */

/* @keyframes MARY-GO-ROUND{
   0%, 100% {transform: translate(0);}
   12% {transform: translate(25px);}
   24% {transform: translate(100%);}
   36% {transform: translate(-230px);}
   48% {transform: translate(-442px);}
   60% {transform: translate(-485px);}
   72% {transform: translate(100%);}
   84% {transform: translate(100%);}
}
.carousel-image-active{
   animation: MARY-GO-ROUND 10s infinite;
} */

.carousel-image.inactive {
  width: 100%;
  height: 18vw;
  opacity: 0;
  transform: translateX(-100%);
}

.carousel-scroll {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  visibility: hidden;
}

.carousel-scroll-left {
  border: none;
  outline: none;
  width: 49px;
  height: 108px;
  box-shadow: 9px 9px 4px rgba(0, 0, 0, 0.5);
  z-index: 18;

  background: #eeeeee;
  border: 1px solid #f7f7f7;
  border-radius: 4px;
}
.carousel-scroll-left > img {
  width: 40px;
  height: 40px;
  filter: invert(30%) sepia(82%) saturate(2289%) hue-rotate(164deg)
    brightness(97%) contrast(98%);
}
.carousel-scroll-right > img {
  width: 40px;
  height: 40px;
  filter: invert(30%) sepia(82%) saturate(2289%) hue-rotate(164deg)
    brightness(97%) contrast(98%);
}
.carousel-scroll-right {
  border: none;
  outline: none;

  width: 49px;
  height: 108px;
  box-shadow: 9px 9px 4px rgba(0, 0, 0, 0.5);
  z-index: 18;
  background: #eeeeee;
  border: 1px solid #f7f7f7;
  border-radius: 4px;
}
.carousel-scroll-right-mobile {
  display: none;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .carousel-image {
    height: 120px;
  }
  .carousel-image > img {
    height: 100%;
  }
  .carousel-scroll > button {
    display: none;
  }
}
