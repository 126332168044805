.profile-account-setting-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
}

.login-sidebar-new {
  top: 0;
  left: 0;
  position: fixed;
  height: 100vh;
  /* display: block; */
  width: 399px;
  z-index: 100000;
}

.profile-account-setting-container-left {
  width: 29.79%;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.37);
  padding-top: 74px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
.profile-account-setting-container-left-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height */
  /* width: 100%;
text-align: center; */
  border-bottom: 3px solid #1d8b97;
  color: #2b2b2b;
  margin-bottom: 60px;
}
.profile-account-setting-container-left-1 {
  width: 100%;
  height: 74px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  cursor: pointer;
}
.profile-account-setting-container-left-1-selected {
  width: 5px;
  height: 100%;
  background: #1d8b97;
}

.profile-account-setting-container-left-1-unselected {
  width: 5px;
  height: 100%;
  background: #1d8b97;
  visibility: hidden;
}
.profile-account-setting-container-left-1:hover {
  background-color: #e9e9ea;
}
.profile-account-setting-container-left-1:hover > div {
  /* visibility: visible; */
}

.profile-account-setting-container-left-1 > span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2b2b2b;
  width: 100%;
  text-align: start;
  margin-left: 10px;
}

.profile-account-setting-right-container {
  height: 100%;
  width: calc(100% - 29.79%);
  display: flex;
  flex-direction: column;
  padding: 75px 20px;
}

.profile-account-setting-right-container-2 {
  height: 950px;
  width: calc(100%);
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 30px;
}
.profile-account-setting-profile-main {
  margin-left: 19px;
  margin-top: 50px;
  display: flex;
  gap: 20px;
  margin-bottom: 60px;
}
.profile-account-setting-profile-main-image {
  width: 50px;
  height: 50px;
  border-radius: 100px;
}
.profile-account-setting-profile-main-image > img {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  object-fit: cover;
}
.profile-account-setting-profile-main-names {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.profile-account-setting-profile-main-names > div {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #25282c;
}
.profile-account-setting-profile-main-names > a {
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  /* identical to box height */

  color: #0066ff;
}
.profile-account-setting-profile-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-left: 7.373vw;
}
.profile-account-setting-profile-detials-box {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  justify-content: space-between;
  gap: 65px;
}
.profile-account-setting-profile-details-box-field {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #25282c;
  width: 30%;
}
.profile-account-setting-profile-detail-box-field-value {
  width: 25.4vw;
  position: relative;
  height: 40px;
  background: rgba(217, 217, 217, 0.5);
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 5.596vw;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  
  color: #757575;
}
.profile-account-setting-profile-detials-box .availability-message{
  position: absolute;
  right: 122px;
  color: #00838f;
  margin-top: -20px;
}
.profile-account-setting-profile-detail-box-field-value > input,.profile-account-setting-profile-detail-box-field-value > select {
  width: 100%;
  height: 100%;
  background-color: transparent;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  padding-left: 37px;
  border: none;
  outline: none;
  color: #757575;
}

.profile-account-setting-profile-save {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-account-setting-profile-save > button {
  margin-top: 90px;
  width: 180px;
  height: 45px;
  left: 8px;
  background: #00838f;
  border-radius: 10px;
  
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  border: none;

  color: #ffffff;
}
.profile-account-setting-profile-detail-box-field-value > select{
  width:95%;
  outline:none;
  border:none;
  color: #757575;
  padding-left: 34px;
}

/* help section */
.profile-account-setting-right-container-help-heading {
  margin-top: 70px;
  margin-left: 84px;
  margin-bottom: 46px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height */

  color: #666666;
}
.profile-account-setting-right-container-help-heading-sub {
  margin-bottom: 25px;
  margin-left: 84px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  cursor: pointer;
  color: #666666;
}

/* block list */
.profile-account-setting-right-container-Block-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height */

  color: #666666;
  margin-top: 70px;
  margin-left: 84px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  gap: 24px;
}
.profile-account-setting-right-container-Block-heading > span {
  padding: 3px 10px;
  width: 29px;
  height: 29px;
  background: #c2bfbf;
  border-radius: 100px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  color: #ffffff;
}

.profile-account-setting-right-container-Block-list-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.profile-account-setting-right-container-Block-list-container .follow-list-wrapper-btns{
  width: 100%;
  margin-top: -40px;
  margin-bottom: 10px;
}
.profile-account-setting-right-container-Block-list-item {
  width: 90%;

  display: flex;
  justify-content: space-between;

  align-items: center;
  box-shadow: 2px 2px 2px rgba(128, 128, 128, 0.475);
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #027d8b09;
}
.profile-account-setting-right-container-Block-list-item > div {
  margin-left: 5.727vw;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;

  line-height: 22px;
  /* identical to box height */

  color: #666666;
}
.profile-account-setting-right-container-Block-list-item > button {
  margin-right: 14.1vw;
  padding: 4px 8px;
  width: 73px;
  height: 29px;
  background: #666666;
  border-radius: 10px;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #ffffff;
}

/* change password  */
.profile-account-setting-profile-main-password {
  margin-top: 65px;
  margin-left: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 27px;
  margin-bottom: 60px;
}
.profile-account-setting-profile-main-image-password {
  width: 50px;
  height: 50px;
  border-radius: 100px;
}
.profile-account-setting-profile-main-image-password > img {
  width: 50px;
  height: 50px;
  border-radius: 100px;
}
.profile-account-setting-profile-main-names-password > div {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #25282c;
}

.profile-account-setting-password-change-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}
.profile-account-setting-password-change-box-list {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.profile-account-setting-password-change-box-list > div {
  margin-left: 88px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #25282c;
}
.profile-account-setting-password-change-box-list > input {
  margin-right: 109px;
  padding-left: 37px;
  /* border: none; */
  outline: none;
  width: 387px;
  height: 40px;
  background: rgba(217, 217, 217, 0.5);
  border: 1px solid #bab9b9;
  border-radius: 10px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #757575;
}

.profile-account-setting-password-change-box-list > input:focus {
  /* border: none; */
  outline: none;
}
.profile-account-setting-password-change-button {
  margin-top: 95px;
  margin-bottom: 15px;
}
.profile-account-setting-password-change-button > button {
  width: 216px;
  height: 40px;
  outline: none;
  border: none;
  background: #00838f;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #ffffff;
}
.profile-account-setting-password-change-forgot {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #3385ff;
}

/* manage address */
.profile-account-setting-right-container-address-heading {
  /* margin-top: 50px;
  margin-left: 50px; */
  margin-bottom: 20px;
    font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #25282c;
}

.profile-account-setting-right-container-address-new {
  /* margin-left: 12px; */
  width: 100%;
  height: 60px;
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 34px;
  cursor: pointer;
}
.profile-account-setting-right-container-address-new > div {
  color: #0066ff;
  margin-top: -6px;
  font-size: 40px;
  margin-left: 60px;
}
.profile-account-setting-right-container-address-new > span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #0066ff;
}
.profile-account-setting-right-container-address-list {
  margin-top: 32px;
  /* height: 100%; */
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  gap: 6px;
}
.profile-account-setting-right-container-address-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  width: 43vw;
  height: 107px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 0 20px;
}
.profile-account-setting-right-container-address-list-item-dot {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 14px;
  z-index: 999;
  right: 1.712vw;
  cursor: pointer;
  height: 16px;
  width: 4px;
  gap: 2px;
}
.profile-account-setting-right-container-address-list-item-dot > div {
  background: #787a7c;
  width: 4px;
  height: 4px;
  border-radius: 100px;
}
.profile-account-setting-right-container-address-list-item-modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 30px;
  right: 40px;
  cursor: pointer;
  /* gap: 23px; */
  width: 170px;
  height: 150px;
  background: #ffffff;
  visibility: hidden;
  z-index: 100;
}
.profile-account-setting-right-container-address-list-item-modal-2 {
  visibility: visible !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  flex-direction: column;
}

.profile-account-setting-right-container-address-list-item-modal-2 > div {
  width: 100%;
  height: calc(100% / 3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-account-setting-right-container-address-list-item-modal > div {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  letter-spacing: -0.2px;

  color: rgba(0, 0, 0, 0.7);
}
.profile-account-setting-right-container-address-list-item-top {
  /* width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 26px;
  padding-bottom: 12px;

  position: relative; */
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: space-around;
  padding-top: 26px;
  padding-bottom: 12px;
  position: relative;
  justify-content: start;
  gap: 10px;
  grid-template-columns: 0.2fr 1fr 1fr;
  padding-right: 20px;
}
.default-address-icon {
  position: absolute;

  top: 17px;
  left: -20px;
  transform: rotate(-45deg);
  text-transform: uppercase;
  font-size: 8px;
  background-color: #fff;
  font-weight: 500;
  color: #00838f;
  border-radius: 5px;
}

.profile-account-setting-right-container-address-list-item-top > span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #000000;
}
.profile-account-setting-right-container-address-list-item-top > div {
  /* position: absolute; */
  width: 59px;
  height: 28px;
  left: 2.107vw;
  top: 14px;

  background: rgba(54, 54, 54, 0.15);
  border-radius: 10px;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #787a7c;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-account-setting-right-container-address-list-item-top > section {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  justify-self: end;
  /* identical to box height */

  color: #000000;
}
.profile-account-setting-right-container-address-list-item-bottom {
  width: 100%;
  padding-left: 37px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px;
}
.profile-account-setting-container-left-parent-heading,
.backButton {
  display: none;
}
.LoadingContainer{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

/*********************** style for FieldChange and EnterOTP ******************************/
.overlayTheModal {
  position:fixed;
  top: 0;
  left: 0;
  right:0;
  bottom: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9978;
}

.mycontainer {
  position: relative;
  padding: 10px 20px;
  background-color: white;
  /* transform: translate(0, 50%); */
  min-height: 50vh;
  width: 70vh;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  border-radius: 5px;
}

.MycontainerEnterOTP {
  height: 70%;
}

.headingOfFieldChange {
  font-weight: 700;
  font-size: 2rem;
  width: 100%;
  text-align: center;
  font-size: 24px;
  margin-top: 50px;
  color: #00838f;
}

.buttonsty {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  background-color: #00838f;
  color: white;
  margin-top: auto;
  margin-bottom: 30px;
  padding: 10px 30px;
  border-radius: 20px;
  border: 0px;
}

.inputsty {
  border-radius: 30px;
  margin-top: auto;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  border: 0px;
}

.crossIcon{
  color: #00838f;
  font-size: xx-large;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  align-self: right;
}

.message{
  font-weight: 500;
  display: flex;
  flex-direction: column;
  color: black;
  justify-content: center;
  gap: 10px;
  margin-top: auto;
  margin-bottom: "auto"
  }

  .messageItem{
    align-self:"center",
  }

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

  .mycontainer{
    margin: 20px 20px;
  }

  .hideSiblingHeading {
    display: none;
  }
  .profile-account-setting-container-left-parent-heading {
    display: block;
    margin: 10px 0px 10px 0px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    border-bottom: 3px solid #1d8b97;
    color: #2b2b2b;
  }
  .profile-account-setting-container-left-1:hover > div {
    /* visibility: visible; */
  }
  .profile-account-setting-container {
    flex-direction: column;
    align-items: center;
  }
  .profile-account-setting-profile-detials-box .availability-message{
    right: 25px;
    font-size: 12px;
  }
  .profile-account-setting-container-left {
    width: 100%;
    height: fit-content;
    /* border: 2px solid; */
    display: flex;
    flex-direction: row;
    gap: 20px;
    overflow-x: scroll;
    border-right: none;
    padding-top: unset;
    margin: 0px 0px 20px 0px;
    padding: 10px 0;
  }
  .profile-account-setting-container-left-heading {
    margin-bottom: 20px;
    border-bottom: unset;
    text-align: center;
    display: none;
  }
  .profile-account-setting-container-left-1 {
    flex-direction: column-reverse;
    justify-content: space-between;
    height: 35px;
    width: 100px;
    min-height: fit-content;
    margin-bottom: unset;
  }
  .profile-account-setting-container-left-1 > span {
    font-size: 13px;
    line-height: unset;
    width: 100%;
    text-align: center;
    margin-left: unset;
  }
  .profile-account-setting-container-left-1-unselected{
    width:100%;
    height: 3px;
  }
  .profile-account-setting-right-container{
    width: 100%;
    padding: unset;
    align-items: center;
  }
  .profile-account-setting-profile-main{
    margin: 10px 0 20px ;
    width: 100%;
    justify-content: center;
  }
  .profile-account-setting-profile-details{
    padding: 0 20px;
  }
  .profile-account-setting-profile-detials-box{
    justify-content: space-between;
    gap: 20px;
  }
  .profile-account-setting-profile-detail-box-field-value{
    flex-grow: 1;
    margin-right: unset;
  }
  .profile-account-setting-profile-save > button{
    margin: 40px 0px;
  }

  /* manage address */
  .profile-account-setting-right-container-address-heading{
    margin: 10px 0 20px 20px;
  }
  .profile-account-setting-right-container-address-list {
    margin-top: 20px;
    padding: 10px 20px;
  }
  .profile-account-setting-right-container-address-list-item{
    min-height: fit-content;
    height: 120px;
    width: 100%;
  }
  .profile-account-setting-right-container .profile-account-setting-right-container-address-new{
    margin: 0 auto;
    width: 88%;
  }
  .profile-account-setting-right-container .profile-account-setting-right-container-address-new > div {
    margin-left: 20px;
  }
  .profile-account-setting-right-container-address-list-item .profile-account-setting-right-container-address-list-item-top {
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .profile-account-setting-right-container-address-list-item-dot {
    right: 10px;
  }
  .profile-account-setting-right-container-address-list-item-top > section {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .profile-account-setting-right-container-address-list-item-bottom {
    padding-left: 3px;
  }
  /* chanage password */
  .profile-account-setting-profile-main-password{
    margin: 10px 0 20px 20px;
  }
  .profile-account-setting-password-change-box-list {
    padding: 10px 20px;
    gap: 40px;
  }
  .profile-account-setting-password-change-box-list > div{
    margin-left: unset;
  }
  .profile-account-setting-password-change-box-list > input{
    margin-right: unset;
    width: 100px;
    flex-grow: 1;
  }
  .profile-account-setting-password-change-button{
    margin: 40px 0;
    margin-top: 10px;
  }
  .profile-account-setting-right-container  .profile-account-setting-password-change-box {
gap: 20px;
  }
/* block list */
.profile-account-setting-right-container-Block-heading{
margin-top: -10px;
margin-left: 20px;


}
.profile-account-setting-right-container-Block-list-item {
  margin-top: 10px;
}
.profile-account-setting-right-container-Block-list-container{
  min-height: 100px;
}
/* help center */
.profile-account-setting-right-container-help-heading {
  margin: 0px 0 20px 20px;
}
.profile-account-setting-right-container-help-heading-sub{
  margin: 20px 50px;
}
.profile-account-setting-container-leftanother{
  width:100vw;
  overflow-x: auto;
  gap:0.5vw;
}
.profile-account-setting-container-left-secondclass{
  width: 50vw;
  margin: 0px 10px 0px 10px;
  white-space:nowrap;

}
/* .profile-account-setting-container-leftanother::-webkit-scrollbar{
  height:2px;
  margin-top:10px; */
  /* margin-right:20px; */
  /* display:flex;
  width:2px;
  position: absolute;
  left: 0;
}
.profile-account-setting-container-leftanother::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 5px;
}
.profile-account-setting-container-leftanother::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
} */
}
