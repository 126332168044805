.pagenotfound-main {
    width: 100%;
    height: 100vh;
}
.pagenotfound-main .pagenotfound-inner-container{
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 2rem;

}
.pagenotfound-main .pagenotfound-inner-container .pagenotfound-content{
    display: flex;
    width: 100%;
    padding-top: 2rem;
    flex-direction: column;
    align-items: center;

}
.pagenotfound-main .pagenotfound-inner-container .pagenotfound-content >h1 {
    margin-bottom: 1.5rem;
    font-weight: 700;
    font-size: 2.2rem;
}
.pagenotfound-main .pagenotfound-inner-container .pagenotfound-content >p {
    font-weight: 500;
    font-size: 1.1rem;
}
.pagenotfound-main .pagenotfound-inner-container .pagenotfound-content >p a {
    color: #00838f;
    font-weight: 700;
}

@media only screen and (max-width: 975px) {
    .pagenotfound-main {
        height: 60vh;
    }
    .pagenotfound-main .pagenotfound-inner-container .pagenotfound-content >h1 {
        font-size: 1.2rem;

    }
    .pagenotfound-main .pagenotfound-inner-container .pagenotfound-content >p {
        font-size: 0.9rem;
        text-align: center;
        padding: 0 4px;
    }
}