.about-us-page-cont {
  height: 100%;
  width: 100%;
  margin-bottom: 100px;
}
.about-us-page-heading {
  font-family: "Open Sans", sans-serif;
  text-align: center;
  font-size: 44px;
  text-transform: uppercase;
  color: #00838f;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 26px;
  letter-spacing: 1px;
  word-spacing: 2px;
  transition: word-spacing 0.5s;
}

.about-us-page-intro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 176px;
}
.about-us-page-intro-head {
  width: 30%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #00838f;
  height: 150px;
  color: #fff;
  font-size: 30px;
  border-radius: 0 100px 100px 0;
  transform-origin: 0 0;
  transition: all 0.9s ease;
}
.about-us-page-intro-head > div {
  width: 56px;
  height: 56px;
  background-color: #70b7be;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-us-page-intro-head:hover {
  background-color: #01646d;
  cursor: pointer;
  transform: scaleX(1.05);
}
.about-us-page-intro-head > div > img {
  width: 40px;
  height: 40px;
  background-color: #70b7be;
  border-radius: 50%;
}
.about-us-page-intro-text {
  width: 70%;
  /* height:max(  176px , fit-content ) ; */
  font-weight: lighter;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  transition: color 0.9s;
  text-align: justify;
  padding: 40px;
  font-size: 16px;
}

.about-us-page-vision,
.about-us-offer-intro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 176px;
}
.about-us-page-vision-head,
.about-us-page-offer-head {
  width: 30%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #00838f;
  height: 150px;
  color: #fff;
  font-size: 30px;
  border-radius: 0 100px 100px 0;
  transform-origin: 0 0;
  transition: all 0.9s ease;
}
.about-us-page-vision-head:hover,
.about-us-page-offer-head:hover {
  background-color: #01646d;
  cursor: pointer;
  transform: scaleX(1.05);
}
.about-us-page-vision-head > div,
.about-us-page-offer-head > div {
  width: 56px;
  height: 56px;
  background-color: #70b7be;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-us-page-vision-head > div > img,
.about-us-page-offer-head > div > img {
  width: 40px;
  height: 40px;
  background-color: #70b7be;
  border-radius: 50%;
}
.about-us-page-intro-text,
.about-us-page-offer-text {
  width: 70%;
  /* height:max(  176px , fit-content ) ; */
  font-weight: lighter;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  transition: color 0.9s;
  text-align: justify;
  padding: 40px;
  font-size: 16px;
}

.about-us-page-conclusion,
.about-us-page-mission,
.about-us-page-work {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 176px;
}
.about-us-page-conclusion-text,
.about-us-page-mission-text,
.about-us-page-work-text {
  width: 70%;
  /* height:max(  176px , fit-content ) ; */
  font-weight: lighter;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  transition: color 0.9s;
  text-align: justify;
  padding: 40px;
  font-size: 16px;
}
.about-us-page-conclusion-head,
.about-us-page-mission-head,
.about-us-page-work-head {
  width: 30%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #00838f;
  height: 150px;
  color: #fff;
  font-size: 30px;
  border-radius: 100px 0 0 100px;
  transform-origin: 100% 0;
  transition: all 0.9s ease;
}
.about-us-page-conclusion-head:hover,
.about-us-page-mission-head:hover,
.about-us-page-work-head:hover {
  background-color: #01646d;
  cursor: pointer;
  transform: scaleX(1.05);
}
.about-us-page-conclusion-head > div,
.about-us-page-mission-head > div,
.about-us-page-work-head > div {
  width: 56px;
  height: 56px;
  background-color: #70b7be;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-us-page-conclusion-head > div > img,
.about-us-page-mission-head > div > img,
.about-us-page-work-head > div > img {
  width: 40px;
  height: 40px;
  background-color: #70b7be;
  border-radius: 50%;
}

.about-us-page-work-secondary-text {
  width: 100%;
  font-weight: lighter;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  justify-content: center;

  transition: color 0.9s;
  text-align: justify;
  padding: 40px;
  font-size: 16px;
}
.about-us-page-work-secondary-text > div {
  width: 100%;
  font-weight: lighter;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  justify-content: center;

  transition: color 0.9s;
  text-align: justify;
  padding: 37px;
  font-size: 16px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .about-us-page-cont {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }
  .about-us-page-heading {
    font-size: 28px;
    margin: 20px 0px;
  }
  .about-us-page-intro {
    flex-direction: column;
    align-items: start;
    height: fit-content;
    margin-bottom: 10px;
  }
  .about-us-page-intro-head,
  .about-us-page-work-head,
  .about-us-page-vision-head,
  .about-us-page-mission-head,
  .about-us-page-offer-head,
  .about-us-page-conclusion-head {
    width: 90%;
    height: fit-content;
    font-size: 20px;
    padding: 10px;
    justify-content: start;
    gap: 10px;
  }

  .about-us-page-intro-text,
  .about-us-page-offer-text,
  .about-us-page-work-text,
  .about-us-page-work-secondary-text > div,
  .about-us-page-mission-text,
  .about-us-page-offer-text,
  .about-us-page-conclusion-text {
    width: 100%;
    padding: 20px;
    font-size: 16px;
    align-self: center;
  }
  .about-us-page-work-secondary-text {
    padding: unset;
  }
  .about-us-page-work {
    flex-direction: column-reverse;
    height: fit-content;
  }
  .about-us-page-work-head,
  .about-us-page-mission-head,
  .about-us-page-conclusion-head {
    align-self: flex-end;
  }
  .about-us-page-vision,
  .about-us-page-mission,
  .about-us-offer-intro,
  .about-us-page-conclusion {
    flex-direction: column;
    align-items: unset;
    height: fit-content;
  }
  .about-us-page-mission,
  .about-us-page-conclusion {
    flex-direction: column-reverse;
  }
}
