.Address-modal-container-background {
  position: fixed;
  width: 100%;
  height: 100%;
  /* do something to stop scroll */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}
.Address-modal-container {
  margin: 1.75rem auto;
  background: #fff;
  width: 100%;
  max-width: 500px;
  overflow-y: scroll;
  /* padding: 35px; */
  height: fit-content;
  /* right: -400px; */
  transition: all 0.6s ease;
}
/* .Address-modal-container-intro-in
{
 right: 0 !important;
 transition: all 0.6s ease;
} */
.Address-modal-cross {
  padding: 1.5rem;
  display: block;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.5;
}
.Address-modal-cross > div {
  font-weight: 700;
  font-size: 18px;
  color: #343a40;
}
.Address-modal-cross > span {
  font-size: 25px;
  text-align: left;
  color: #00838f;
  cursor: pointer;
  font-weight: 800;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.Address-modal-cross > span:hover {
  opacity: 1;
}

.Address-modal-coupon-enter {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  max-height: 200px;
  height: min(fit-content, 170px);
  overflow: scroll;
}
.Address-modal-coupon-enter-row {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.Address-modal-coupon-enter-row > input {
  margin-right: 10px;
}
.Address-modal-coupon-enter-row > span {
  font-size: 12px;
  line-height: 1.2em;
}
.Address-modal-coupon-enter-row-buttons {
  float: right !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  margin-right: 10px;
}
.Address-modal-coupon-enter-row-buttons > span {
  line-height: 1.4em;
  font-weight: 700;
  font-size: 12px;
  color: #00838f;
  cursor: pointer;
}
.Address-modal-coupon-enter-row-buttons > p {
  line-height: 1.4em;
  font-weight: 700;
  font-size: 12px;
  color: #00838f;
  cursor: pointer;
}

.Address-modal-coupon-enter-button-last {
  padding: 8px;
  box-shadow: 0 -1px 4px 0 #0000004d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.Address-modal-coupon-enter-button-last > button:nth-child(1) {
  width: 50%;
  height: 45px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  border-radius: 4px;
  text-align: center;
  background-color: transparent;
  outline: none;
  border: none;
  color: #00838f;
}
.Address-modal-coupon-enter-button-last > button:nth-child(2) {
  width: 50%;
  height: 45px;
  text-align: center;
  background: #00838f;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-weight: 700;
}
.Address-modal-coupon-enter-row-input {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  font-size: 12.5px;

  overflow: hidden;
  text-overflow: ellipsis; 
}
@media screen and (max-width: 720px) {
  .Address-modal-container {
    width: 80%;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .Address-modal-coupon-enter-row {
    display: flex;

    justify-content: center;
  }
  .Address-modal-coupon-enter-row > span {
    width: 50%;
  }

  .Address-modal-coupon-enter-row-buttons {
    padding-left: 10px;
  }
  .Address-modal-coupon-enter-button-last > button:nth-child(1),
  .Address-modal-coupon-enter-button-last > button:nth-child(2) {
    font-size: 10px;
  }
  .Address-modal-coupon-enter {
    padding: 10px;
    max-height: 250px;
  }
  .addressrowforr{
    justify-content: unset !important;
  }
}
