.follow-list-navbar
{
  background-color: #027e8b;
  overflow: hidden;
  height: 60px;
  padding: 0px 20px;
  position: sticky;
  display: flex;
  align-items: center;
  top: 0;
  width: 100vw;
}
.follow-list-navbar-left-arrow
{
    color: white;
    font-size: 20px;
    margin-right: 20px;
}
.follow-list-navbar-heading
{
    color: white;
    font-size: 22px;
    text-align: center;
}
.follow-list-wrapper-btns
{
  height: 40px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 20px;
  margin-bottom: 40px;
}
.follow-list-wrapper-btns button
{
    border: none;
    background-color: rgba(255, 255, 255, 0);
    /* height: 60px; */
    font-size: 18px;
    color: #027e8b;
    border-right: 2px solid #027e8b;
    width: 50%;
}
.follow-list-btn-text-border-bottom:hover
{
  border-bottom: 3px solid #1D8B97;
}
.follow-list-wrapper-btns .follow-list-following-btn
{
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: center;
  padding: 5px 0;
}
.wrapper-btns .follow-list-my-followers-btn
{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.follow-list-my-followers-btn
{
  /* margin-top: 30px; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50vw;
}
.follow-list-all-cards-container
{
  overflow-y: auto;
    height: 90%;
    width: 97%;
    margin: auto;
}
.follow-list-following-card-container
{

  display: flex;
  align-items: center;
  flex-direction: column;
}
.follow-list-following-card
{
  padding: 5px 10px;
  background-color: #027d8b09;
  width: 50%;
  margin-left: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  height: 120px;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  box-shadow: 2px 2px 2px rgba(128, 128, 128, 0.475);
}
.follow-list-following-card-image-container
{
  margin-right: 20px;

}
.follow-list-following-card-image-container >img{
  width: 50px;
  height: 50px;
  border-radius: 25px;
}
.follow-list-following-card-content
{
  display: flex;
  flex-direction: column;
  
}
.follow-list-following-card-no-following
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #027e8b;
  background-color: #027d8b3f;
  width: 500px;
  box-shadow: none;
}
.follow-list-all-cards-container::-webkit-scrollbar{
  height:7px;
  margin-top:10px;
  display:flex;
  width:4px;
}
.follow-list-all-cards-container::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 5px;
}
.follow-list-all-cards-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}
@media screen and (max-width: 720px) {
  .follow-list-following-card-container
  {
    width: 99%;
    margin-top: 10px;
  }
  .follow-list-following-card
  {
    width: 90%;
    min-height: 150px;
  }
  /* .follow-list-wrapper-btns .follow-list-following-btn,.follow-list-my-followers-btn
  {
    margin: 10px;
    width: 100%;
  } */
  .follow-list-following-card-content  p:nth-child(2),.follow-list-following-card-content  p:nth-child(3){
font-size: 13px;
  }
 
  .follow-list-wrapper-btns{
    margin-top: 40px;
  }
  .follow-list-all-cards-container {
    width:94%;
    margin:auto;
    height: 500px;
  }
  .follow-list-following-card-container{
    width:97%;
  }
}