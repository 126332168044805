.cart-main-view-page-payment-page-mobile
{
 width: 100vw;
 height: 100vh;
 background-color: #ffffff;
}
.cart-main-view-page-payment-page-mobile-pay-box
{
     margin: 10px auto;
    padding: 3vw 2vw;
    background-color: #FFFFFF;
    width: calc(100% - 4vw );
    min-height: 220px;
    border-radius: 10px;
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    background-color: #d6f0f1fb;
}
.cart-main-view-page-payment-page-mobile-pay-box>section
{
 font-weight: 600;
 font-size: 23px;
}
.cart-main-view-page-payment-page-mobile-pay-box>form
{
 margin: 2vw;
font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 16px;
    color: #8A8A8A;
    word-spacing: 2px;
    letter-spacing: 0.5px;

}
.cart-main-view-page-payment-page-mobile-pay-box>form>input
{
 margin-right: 2vw;
 margin-bottom: 20px;
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
  background-color: transparent;
}
.cart-main-view-page-payment-page-mobile-pay-box>form>div
{
 font-size: 13px;
 font-weight: 400;
 padding-left: 9vw;
 color: rgb(0, 131, 143);
}










.cart-main-page-mobile-view-bottom-fixed-2
{
 position: fixed;
 z-index: 1000;
 bottom: 0;
}

.cart-main-page-mobile-view-bottom-fixed-2
{
 width: 100%;
 height: 80px;
 display: flex;
 align-items: center;
 justify-content: center;
}
.cart-main-page-mobile-view-bottom-fixed-col1-2
{
 width: 50%;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 background-color: #FFFFFF;
 height: 100%;

   /* font-family: 'lato'; */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    /* line-height: 20px; */
    letter-spacing: -0.2px;
    color: #0000007a;
}
.cart-main-page-mobile-view-bottom-fixed-col1-2>span
{
 color: #000000;
}

.cart-main-page-mobile-view-bottom-fixed-col2-2
{
  width: 50%;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 background-color: #FFFFFF;
 height: 100%;
}
.cart-main-page-mobile-view-bottom-fixed-col2-2>button
{

 height: 70%;
 background-color: rgba(0, 131, 143, 0.88);
 padding: 2vw 6vw;
 border-radius: 7px;
 box-shadow: none;
 border: none;


  font-family: 'Lato';
  /* letter-spacing: -0.2px; */
 font-style: normal;
    font-weight: 600;
    font-size: 17px;
    /* line-height: 15px; */
    color: #FFFFFF;
}