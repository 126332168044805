p {
  margin: 0;
}

.myprofileMainDiv {
  width: 100%;
  display: flex;
  gap: 25px;
  position: relative;
  padding: 20px;
  background-color: rgb(232, 227, 227);
}
.myprofileleftsideProfilesection {
  width: 100%;
  position: sticky;
  top: 85px;
  padding: 20px 0 10px 0;
  z-index: 2;
  background-color: #ffffff;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.myprofileMainDivFirst {
  min-width: 20%;
  /* height: 981px; */
  background: #ffffff;
  border-radius: 20px;
  flex: 0 0 20%; /* 20% width for the first child */
}
.myprofileMainDivFirstRecharge {
  min-width: 20%;
  height: 981px;
  background: #ffffff;
  border-radius: 20px;
  flex: 0 0 20%;
}
.myprofileMaindivSecondRecharge {
  width: 70%;
  position: relative;
  background: #ffffff;
  border-radius: 20px;
  flex: 1 1;
}
.myprofileprofileimg {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  position: relative;
}
.myprofileprofileimg > span {
  position: absolute;
  right: -20px;
  top: -20px;
  font-size: 24px;
  cursor: pointer;
}
.myprofileprofileimg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.myprofilusernamesection {
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.myprofilusernamesection h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #25282c;
}
.myprofilusernamesection p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  color: #77797c;
}

.profilenavitem:hover{
  background-color: #e2e0e0;
  background-color: #c2c1c1;
  cursor: pointer;
}
.myprofileprofleleftsideNav .profilenavitem{
  height:53px;
  margin:2.5px auto 2.5px auto;
}
.profilenavitemactive{ 
  cursor: pointer;
  background-color: #c2c1c1;
}
.profileNavItemNav p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #2e2d2d;
}
.myprofileprofleleftsideNav {
  position: relative;
  margin-top: 20px;
  z-index: 1;
  /* height: 100vh; */
  height:auto;
}
.myprofileprofleleftsideNav1{
  margin-bottom: 20px;
}
.profilenavitem {
  width: 80%;
  height: 58px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 1px 20px;
  border-radius: 8px;
  /* gap:40px */
}
.profileNavImg {
  width: 30px;
  height: 30px;
}
.profileNavImg img {
  width: 100%;
  height: 100%;
}
.profileNavImg .bill-img {
  transform: scale(1.5);
}
.profileNavItemNav {
  margin-left: 18px;
  /* margin-top: -11px; */
}
.profileNavItemNav p {
  margin: 0;
}
.no-service-started {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.no-service-started .no-service-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  align-items: center;
  color: #027e8b;
  background-color: #027d8b3f;
  width: 500px;
  box-shadow: none;
}
.no-service-started .no-service-empty > p {
  font-size: 22px;
  font-weight: bold;
}
#accountSetting {
  position: relative;
}
#accountSetting img {
  position: absolute;
  top: 0;
}
#accountSetting img:nth-child(2) {
  width: 20px;
  left: 5px;
}
/* #accountSetting img:nth-child(1){
   top: 15px;
} */
.myprofileMaindivSecond {
  width: 100%;
  position: relative;
  background: #ffffff;
  border-radius: 20px;
  flex: 1;
}
.expand-icon {
  display: none;
}
.backIcon {
  display: none;
  cursor: pointer;
  font-size: 24px;
  margin: 20px 0 0 20px;
}
.hideText {
  display: none;
}
.myprofileMaindivSecond:has(.landbill){
  width:71% !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .myprofileMainDiv {
    padding: unset;
  }
  .myprofileleftsideProfilesection {
    padding: 20px 0 10px 30px;
    justify-content: start;
  }
  .myprofileMainDivFirst {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-width: unset;
    flex: unset;
  }
  .closeSidebar {
    display: none;
  }
  .hideMyprofileMaindivSecond {
    display: none;
  }
  .expandShowText {
    display: block;
  }
  /* .myprofilusernamesection,.profileNavItemNav{
        display: none;
    } */
  .showText {
    display: block;
  }
  .expand-icon {
    display: block;
    width: 40px;
    height: 40px;
    left: 10px;
    top: 135px;

    cursor: pointer;
    position: fixed;
  }
  .myprofileprofleleftsideNav {
    width: 100%;
    padding: 0px 20px;
  }
  .profilenavitem {
    width: 100%;
    cursor: pointer;
  }
  .profilenavitem:hover {
    background-color: #e2e0e0;
  }
  .myprofileMaindivSecond {
    width: 100%;
  }
  .myprofileExpandDiv {
    width: 100vw;
    height: fit-content;
    position: absolute;
    z-index: 9;
  }
  .backIcon {
    display: block;
    position: absolute;
    top: -5px;
    left: 10px;
    z-index: 1;
  }
  
   .backIcon:has(+.profile-section-container2 .myblog-blog-container){
    position: relative;
  }
  .myprofileMainDivFirstRecharge{
    min-width: unset;
    width: 100%;
    flex: unset;
  }
}
@media screen and (max-width: 720px) {
  /* .myprofileleftsideProfilesection {
    top: 65px;
    justify-content: start;
    padding-left: 40px;
  } */
  /* .myprofileMaindivSecond{
    padding-bottom: 400px;
  } */
  .no-service-started {
    height: 50vh;
  }
  .no-service-started .no-service-empty {
    width: 90%;
  }
}
