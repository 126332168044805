/* css for header */

.header-icons {
  height: 7vh;
  width: 10vw;
  cursor: pointer;
}

.putatoe-logo {
  display: flex;
}

.putatoe-logo-image {
  height: 70px;
  width: 70px;
}

.putatoe-location .putatoe-user-location {
  color: white;
  font-size: 12px;
  display: flex;
}

.putatoe-location .putatoe-user-location .user-address {
  cursor: pointer;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 84%;
}

.putatoe-location .putatoe-user-location span > i {
  cursor: pointer;
}

/* .nav-search-bar-desktop
{
  width: 21vw;
  display: flex;
    position: relative;
} */
/* .navbar-short
{
  padding-left: 60px;
    position: sticky;
  top: 85px;
  padding: 0.1vw 3vw;
  height: 8vh;
    background-color: #00838f;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: space-around;
    flex-wrap: nowrap;
    z-index: 19;
    display: none;
} */

.notification-header {
  margin: 0%;
  padding: 0%;
  display: block;
  cursor: pointer;
  position: relative;
}

.login-otp-modal {
  position: fixed;
  background-color: transparent;
  z-index: -100;
  height: 350px;
  width: 350px;
  top: 0;
  right: calc(50% - 150px);
}

.otp-modal-show {
  padding: 1vw;
  background-color: white;
  z-index: -1;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.otp-logo {
  width: 80%;
  /* background-color: #000000; */
}

.otp-logo > img {
  width: 100%;
}

.verify-otp-button {
  border: none;
  padding: 0.5vw;
  width: fit-content;
  padding: 10px 20px;
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  background-color: #00838f;
  text-decoration: none;
  border-radius: 12px;
}

.verify-otp-text {
  color: #00838f;
  font-weight: 600;
  padding: 1vw;
  padding-bottom: 0;
}

.verify-otp-number {
  padding: 1vw;
  color: #000000;
  font-size: 12px;
  font-weight: 600;
}

.resend-otp {
  color: #94969f;
  font-size: 13px;
}

.otp-input {
  display: flex;
  gap: 1vw;
  padding: 0.5vw;
}

.otp-input > input {
  width: 3vw;
  height: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp-input > input::-webkit-scrollbar {
  display: none;
}

.search-modal {
  position: absolute;
  background-color: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: 30vw;
  border-radius: 10px;
  padding: 1vw;
  height: fit-content;
  top: 40px;
  max-height: 250px;
  overflow: auto;
  z-index: 200;
}

.nav-search-bar-desktop-mobile {
  display: none;
  width: 100%;
}
.loading[stylechange="yes"],
.mobile-loader[stylechange="yes"] {
  background-color: #f5f5f5 !important;
  padding: 50px !important;
  margin: 0px 0px;
}
@media only screen and (max-width: 975px) {
  .nav-search-bar-desktop {
    display: none;
  }

  .navbar-short {
    display: flex;
    padding: 15px 24px;
    top: 65px;
    /* padding-top: 15px; */
    height: auto;
    gap: 10px;
  }

  .nav-search-bar-desktop-mobile {
    display: flex;
    align-items: center;
    border-radius: 12px;
    overflow: hidden;
    background-color: white;
    padding-right: 10px;
  }
  .navbarshort-chat-icon > img {
    display: flex;
  }
  .nav-search-bar-desktop-mobile .search-bar-desktop {
    width: 100%;
  }
  .sendIcon {
    cursor: pointer;
  }
}

.search-bar-desktop {
  height: 35px;
  border: none;
  width: 100%;
  background-color: white;
  padding-left: 0.5vw;
  padding-bottom: 3px;
  position: relative;
}

.search-bar-desktop::placeholder {
  font-weight: 400;
  height: 100%;
  color: #5e6160;
  opacity: 0.8;
  font-size: 14px;
  display: flex;
  padding-left: 0px;
  align-items: center;
}

.search-bar-desktop:focus {
  outline: none;
}

.search-icon-desktop {
  background-color: white;
  display: flex;
  padding-left: 10px;
  align-items: center;
}

.search-icon-image {
  height: 24px;
  width: 24px;
}

.bell-icon {
  height: 24px;
  width: 24px;
}

.cart-icon {
  height: 24px;
  width: 24px;
}

.login-button > span {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}

.search {
  display: none;
  margin-top: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 1vw 2vw;
  background-color: #2f8676;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* align-content: space-around; */
  flex-wrap: nowrap;
  z-index: 100;
}

.search-bar {
  display: none;
  caret-color: #105644;
  border: none;
  border-radius: 10px;
  width: 85vw;
  height: 5vh;
  font-size: 2vw;
  font-weight: 200;
  color: #5e6160;
  display: flex;
  text-align: center;
  align-items: center;
  padding: 2vw;
}

.search-bar::placeholder {
  display: none;
  font-size: larger;
  font-weight: 200;
  color: #5e6160;
  display: flex;
  text-align: center;
  align-items: center;
  padding: 2vw;
}

:focus::placeholder {
  opacity: 0.4;
  transition: color 0.5s;
}

.mic {
}

/* carousel */
.carousal-container-desktop {
  height: 18vw;
  width: 100vw;
}

@media only screen and (max-width: 583px) {
}

.carousel-head {
  width: 100%;
  height: 100%;
  position: relative;
}

/* .carousel-image-active:hover
{
  transform: translateX(-100%);
  transition: all 1s; 
  transform: translateX(0%);
  transition: all 1s; 

} */

/* @keyframes MARY-GO-ROUND{
   0%, 100% {transform: translate(0);}
   12% {transform: translate(25px);}
   24% {transform: translate(100%);}
   36% {transform: translate(-230px);}
   48% {transform: translate(-442px);}
   60% {transform: translate(-485px);}
   72% {transform: translate(100%);}
   84% {transform: translate(100%);}
}
.carousel-image-active{
   animation: MARY-GO-ROUND 10s infinite;
} */

.carousel-image-inactive {
  width: 100%;
  height: 18vw;
  opacity: 0;
  transform: translateX(-100%);
}

.carousel-scroll {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  visibility: hidden;
}

/* side bar container */
.sidebar-container {
  position: fixed;
  width: 60px;
  /* height: 652px; */
  height: 100vh;
  background: #027e8b;
  top: 0;
  z-index: 10;
}

@media screen and (max-width: 720px) {
  .sidebar-container {
    display: none;
  }
}

.sidebar-noHover {
  padding-top: 69px;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.sidebar-noHover-image {
  height: 30px;
  width: 30px;
  border-radius: 100%;
}

.sidebar-decision {
  padding-top: 69px;
  overflow: hidden;
  width: 100%;
  flex-direction: column;
  position: absolute;
  width: 0px;
  height: 100%;
  opacity: 0;
  background: #027e8b;
  z-index: 20;
  transition: all 0.6s ease;
  transition: width 0.4s;
}

.sidebar-container:hover {
  z-index: 20;
}

.sidebar-container:hover .sidebar-decision {
  opacity: 1;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 284px;
  height: 100%;
  background: #027e8b;
  transition: all 1s ease;
}

.sidebar-container:hover .sidebar-noHover {
  display: none;
}

.tab-container {
  display: flex;
  background-color: #027e8b;
  height: 11.111%;
  width: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.tab-highlighter {
  display: flex;
  width: 100%;
  height: 80%;
  flex-wrap: nowrap;
  align-items: center;
}

.tab-highlighter:hover {
  background-color: white;
  border-radius: 100px 0 0 100px;
}

.image-container {
  border-radius: 100%;
  padding-left: 33px;
  padding-right: 28px;
}

.tab-image {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.tab-text-container {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
}

.tab-highlighter:hover .tab-text-container {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #03989e;
  transition: all 0.5s ease;
}

.error-message {
  color: #cc0033;
  display: inline-block;
  width: 100%;
  font-size: 14px;
  line-height: 15px;
  margin: 5px 0 0;
}

@media screen and (max-width: 720px) {
  .popular-container {
    padding-left: 0;
    padding-left: 0;
    padding-top: 0;
  }

  .popular-products-per-category-head {
    height: fit-content;
  }

  .popular-products-per-category {
    height: 245px;
  }

  .popular-products-per-category-for-mobile-view {
    height: 400px;
  }

  .popular-container-heading {
    font-size: 23px;
    padding-top: 5px;
    margin-bottom: unset;
  }

  .popular-container-heading-mobile-view {
    margin-bottom: -5px;
  }
}

/* .popular-product-category
{
  

  font-family: 'Titillium Web', sans-serif;;
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 33px;
letter-spacing: 0.02em;
text-align: center;
color: #000000;
display: flex;
align-items: center;
justify-content: center;
} */
.popularproductcard-container {
  width: 200px;
  height: 270px;
  background-color: #ffffff;
}

.popularproduct-image-container {
  padding: 0.6vw;
  width: 200px;
  height: 188px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popularproduct-image {
  width: 100%;
  height: 100%;
}

.popularproduct-details {
  height: 82px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.popularproduct-name {
  width: 201px;
  text-align: center;
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  height: 50%;
  color: #000000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.3vw;
}

.popularproduct-price {
  width: 100%;
  text-align: center;
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  height: 50%;
  color: #14a233;
  display: flex;
  justify-content: center;
}

.bestseller-scroll {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  visibility: hidden;
  margin-top: 50px;
}

.bestseller-scroll-container > button > span {
  display: flex;
  align-items: center;
  text-align: center;
  color: #07484a;
  font-size: 30px;
}

.bestseller-scroll-dots {
  width: 12px;
  height: 12px;
  background: #878787;
  visibility: visible;
  border-radius: 100px;
}

.bestseller-scroll-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1vw;
}

.bestseller-scroll-left {
  z-index: 19;
  visibility: visible;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 100px 0px; */
  gap: 4px;
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  border: none;
  outline: none;
}

.bestseller-scroll-right {
  z-index: 19;
  visibility: visible;
  border: none;
  outline: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 100px 0px; */
  gap: 4px;
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
}

@media screen and (max-width: 720px) {
  .bestseller-scroll-container {
    display: none;
  }
}

.service-card-contact-container {
  width: 200px;
  height: 30px;
  display: flex;
  align-items: center;

  background: #03989e;
  border-radius: 10px;
  transition: all 0.2s ease-in;
}

.service-card-contact {
  width: 100%;
  text-align: center;
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 95.6%;
  /* or 17px */

  color: #ffffff;
}

.service-card-hover {
  position: absolute;
  width: 352px;
  height: 112px;
  top: 188px;

  background: rgba(68, 68, 68, 0.8);
  border-radius: 0px 0px 10px 10px;
  opacity: 0;
  transition: all 0.3s ease-in;
  padding: 1vw;
  display: flex;
  flex-direction: column;
}

.service-hover-top-row {
  /* width: 100%;
  height: 50%; */
  /* display: flex; */
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.service-hover-star-heart {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  gap: 1vw;
}

.service-card-star-container {
}

.service-card-heart-container {
}

.book-now-container {
  width: 150px;
  height: 30px;
  background: #03989e;
  box-shadow: 4px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.book-now {
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 95.6%;
  /* or 17px */

  color: #ffffff;
}

.service-hover-bottom-row {
  display: flex;
  width: 100%;
  height: 100%;

  justify-content: space-between;
  align-items: center;
}

.service-card-services {
  margin-top: -20px;
  display: flex;
  width: 50%;
  flex-direction: column;
}

.service-card-service-heading {
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 95.6%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 30px;
  padding-bottom: 3px;
  text-align: justify;
  /* or 10px */

  color: #ffffff;
}

.service-card-service-list {
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  text-align: left;
  gap: 3px;
  padding-left: 30px;
  line-height: 95.6%;
  color: #ffffff;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.serive-card-chat-phone {
  display: flex;
  width: 40%;
  justify-content: space-evenly;
  align-items: center;
}

.service-card-services {
}

.service-card-chat-container {
}

/* .allservice-container last */

/* footer */
/* .footer
{
  padding-left: 60px;
  background: #00838F;
border-radius: 0px;
height: 428px;
width: 100%;
display: flex;
color: #FFFFFF;
padding-top: 3vw;
}
.footer-col1
{
  width: 80%;
  padding: 1vw;
  display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 2vw;
}
.footer-logo, .footer-logo-container{
  width: 120px;
height: 120px;
}
.footer-text
{
  display: flex;
  flex-direction: column;
gap: 1vw;
}
.footer-company
{
    font-family: 'Raleway', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 25px;
line-height: 24px;
}
.footer-company-location
{
font-family: 'Raleway', sans-serif;;
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 19px;
}


.footer-col2
{
  width: 20%;
  padding: 2vw;
  display: flex;
  flex-direction: column;
  gap: 3vw;
}
.footer-utility
{
    display: flex;
  flex-direction: column;
  gap: 0.4vw;
}
.footer-Useful-Links
{
  font-family: 'Raleway', sans-serif;;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
}
.footer-about-us , .footer-contact-us ,.footer-terms-and-conditions
{
  font-family: 'Raleway', sans-serif;;
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 28px;
}



.footer-icon-container
{
    display: flex;
    align-items: center;
    gap: 2vw;
}
.footer-instagram-container , .footer-linkedin-container ,.footer-facebook-container
{
width: 41px;
height: 41px;
  background-color: white;
  border-radius: 100%;
  padding: 0.51vw;
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* mobile view css */
/* @media only screen and (max-width: 583px)
{

  .allservice-last-container
  {
    padding: 12px;
    padding-bottom: 20px;
  }

  .allservice-last-heading
  {
    padding: 0;
font-family: 'Lato';
font-style: normal;
font-weight: 900;
font-size: 24px;
line-height: 20px;
// identical to box height, or 83% 
text-align: justify;
letter-spacing: -0.2px;

color: #00838F;
padding-bottom:32px ;
  }

.allservice-last
{
  padding: 0;
display: grid;
    width: 100%;
    align-content: center;
    justify-content: center;
    grid-template-columns: 6fr 6fr;
    grid-column-gap: 2vw;
    grid-row-gap: 3vw;
    justify-items: center;
    align-items: center;
}
.allservicetab-container ,.allservicetab-image
{
  width: 45.267vw;
  height: 35.33vw;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.allservicetab-image
{
  width: 100%;
}
}  */

/* code for selectors */
/* putaoe logo jisme daily needs etc field hai */

.all-service-selector-cointainer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  width: 80px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.all-service-selector-cointainer2 {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.all-service-selector-cointainer-image {
  width: 100%;
  height: 80px;
  /* background-color: #105644; */
  border-radius: 100px;
  padding: 10px;
  z-index: 10000;
  display: flex;
  justify-content: flex-end;
}

/* .all-service-selector-cointainer-image:hover
{
  width: 500px;
} */

.all-service-selector-cointainer-image:hover ~ .all-service-selector-none {
  width: 300px;
  height: 60vh;
  overflow-y: auto;
  /* position: relative; */
  position: fixed;
  bottom: 100px;
  right: 30px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 15px 20px rgb(0 0 0 / 10%);
  z-index: 100;
  transition: all 0.3s ease-out;
  transform: scaleY(1);
}

.all-service-selector-cointainer-image > img {
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.all-service-selector-none {
  /* display: none; */
  visibility: hidden;
  color: #000000;
  transition: all 0.3s ease-out;
  transform: scaleY(0);
  width: 300px;
  height: 0vh;
  z-index: -100000;
}

.all-service-selector {
  width: 300px;
  height: 60vh;
  overflow-y: auto;
  /* position: relative; */
  position: fixed;
  bottom: 100px;
  right: 30px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 15px 20px rgb(0 0 0 / 10%);
  z-index: 1000;
  transition: all 0.3s ease-out;
  transform: scaleY(1);
}

.all-service-selector-heading {
  height: 8vh;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 131, 143, 1) 45%,
    rgba(0, 212, 255, 1) 100%
  );
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 15px;
  color: white;
  position: sticky;
  top: 0;
  font-weight: bold;
  font-size: 12px;
}

.all-service-selector-heading > div {
  display: none;
}

.all-service-selector-selectors {
  padding: 20px 45px;
}

.all-service-selector-tab {
  background: #f1f1f1;
  color: #00838f;
  padding: 6px 8px;
  font-size: 12px;
  border-radius: 6px;
  font-weight: bold;
  margin-top: 8px;
  width: 100%;
  cursor: pointer;
}

.all-service-selector-tab-selected {
  background-color: #00838f !important;
  color: #fff !important;
  padding: 6px 8px;
  font-size: 12px;
  border-radius: 6px;
  font-weight: bold;
  margin-top: 8px;
  width: 100%;
  cursor: pointer;
}

/* background-color: white;
  height: 50vh;
  width: 300px;
  overflow-y: auto; */

@media only screen and (max-width: 583px) {
  .all-service-selector-heading > div {
    display: flex;
    width: 30px;
    height: 30px;
  }

  .all-service-selector-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .all-service-selector {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    transform-origin: center center;
  }
}

/* mehul code css */

/* chat page css code */

.chat_main_container {
  height: 620px;
  width: 80%;
  left: -1px;
  top: 0px;
  border-radius: 10px;
  margin: 5vh auto 0;
  box-shadow: 0px 4px 30px 0px #00000040;
  display: grid;
  grid-template-columns: 375px 1fr;
  /* margin-bottom: 300px; */
}

.chat_box_left {
  background: #dbedef;
}

.chat_search_box {
  width: 355px;
  height: 35px;
  border-radius: 70px;
  background: #ffffff;
  margin: 0 auto;
}

.edit_icon {
  width: 100%;
  height: 25px;
  display: flex;
  align-items: end;
  position: relative;
}

.edit_icon img {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 53px;
}

.chat_box_right {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  position: relative;
  display: grid;
  grid-template-rows: 71px 1fr 50px;
  padding-bottom: 10px;
}

.chat_right_initimg {
  width: 300px;
  height: 300px;
}

.chat_search_box {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.chat_search_box input {
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  padding-left: 38px;
}

.chat_search_box img {
  width: 17px;
  height: 18px;
  margin-left: 11px;
}

.chat_option {
  width: 350px;
  height: 58px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: flex-end;
  padding-left: 10px;
  cursor: pointer;
}

.chat_option p {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: -0.3333333432674408px;
  text-align: center;
  margin: 0;
}

.chat_option li {
  list-style: none;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
}

.chat_circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #128c7e;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.chat_frds,
.chat_seller,
.chat_customer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat_circle {
  margin-left: 8px;
}

.chat_line {
  width: 95px;
  height: 4px;
  background: #128c7e;
  margin: 10px 0px 0px 15px;
  border-radius: 4px;
}

.chat_members {
  width: 100%;
  height: 440px;
  margin-top: 1px;
  overflow: hidden;
  overflow-y: auto;
}

.chat_member {
  width: 100%;
  height: 50px;
  padding: 0 15px;
  display: grid;
  grid-template-columns: 50px 1fr;
  column-gap: 10px;
}

.chat_member_overview {
  padding-top: 5px;
  display: grid;
  grid-template-rows: 20px 1fr;
}

.chat_member_name p:nth-child(1) {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.chat_member_name p:nth-child(2) {
  font-size: 11px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  color: #727272;
}

.chat_member_profile img {
  border-radius: 50%;
  background-color: black;
}

.chat_member_message p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #8a8a8a;
}

.chat_navbar {
  width: 100%;
  height: 70px;
  background-color: #dbedef;
  display: flex;
  align-items: center;
  padding-left: 18px;
  padding-right: 124px;
}

.chat_open_div {
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 50px 1fr 86px;
  column-gap: 10px;
}

.chat_open_profile {
  width: 50px;
  height: 50px;
}

.chat_open_profile img {
  border-radius: 50%;
}

.chat_open_calls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat_open_vc,
.chat_open_call {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
}

.COV {
  width: 22px;
  height: 15px;
}

.COC {
  width: 20px;
  height: 20px;
}

.chat_open_overview {
  height: 50px;
  align-items: space-around;
  cursor: pointer;
}

.chat_open_overview p:nth-child(1) {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.chat_open_overview p:nth-child(2) {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  /* line-height: 14px; */
  color: #b9bec4;
}

.chat_messages {
  /* padding-top: 43px; */
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 20px;
  padding-bottom: 150px;
  height: 469px;
}

.your_text,
.my_text {
  width: fit-content;
  max-width: 302px;
  box-sizing: border-box;
  padding: 11px;
  background: #e8ecef;
  border-radius: 10px;
  margin-left: 30px;
}

.my_text {
  float: right;
  display: block;
  background: #128c7e;
  border-radius: 10px;
  color: white;
  margin-right: 15px;
}

.your_text p,
.my_text p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #000000;
}

.your_chat_time {
  width: 100%;
  display: block;
  margin-left: 30px;
}

.my_chat_time {
  width: 100%;
  display: flex;
  justify-content: end;
  padding-right: 16px;
}

.your_chat_time p,
.my_chat_time p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #727272;
}

.last_div {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}

.chat_inputbox {
  width: 85%;
  height: 50px;
  margin-left: 20px;
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  align-items: center;
  padding: 0 10px;
  background: #e8ecef;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  column-gap: 10px;
}

.chat_inputbox input {
  border: none;
  outline: none;
  background: #e8ecef;
}

.emoji {
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
}

.emoji img:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emoji img:nth-child(2) {
  position: absolute;
  top: 7px;
  left: 6px;
}

.emoji img:nth-child(3) {
  position: absolute;
  top: 7px;
  right: 6px;
}

.emoji img:nth-child(4) {
  position: absolute;
  top: 13px;
  left: 5.5px;
}

.send_msg_btn {
  width: 21px;
  height: 23px;
  cursor: pointer;
}

.chat_record {
  width: 50px;
  height: 50px;
  position: relative;
  background: #128c7e;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.05);
  border-radius: 110px;
}

.chat_record img:nth-child(1) {
  position: absolute;
  top: 12px;
  left: 20px;
}

.chat_record img:nth-child(3) {
  position: absolute;
  top: 34px;
  left: 25px;
}

.chat_record img:nth-child(2) {
  position: absolute;
  top: 25px;
  left: 16px;
}

.chat_box_last {
  background: #dbedef;
  /* box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25); */
  display: none;
  border-radius: 10px;
}

.chat_box_last_navbar {
  width: 100%;
  height: 70px;
  padding: 5px 20px;
  display: flex;
  align-items: center;
}

.false_icon {
  width: 12px;
  height: 12px;
  position: relative;
  cursor: pointer;
  margin-bottom: 13px;
}

.false_icon img:nth-child(1) {
  position: absolute;
  top: 0;
}

.false_icon img:nth-child(2) {
  position: absolute;
  top: 0;
}

.chat_box_last_navbar p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-left: 20px;
}

.chat_profile_img {
  width: 100px;
  height: 100px;
  margin: 10px auto 0;
}

.user_name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
}

.chat_user_status,
.chat_user_about_data,
.chat_disappering_msgdataa {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #b9bec4;
}

.chat_user_about,
.chat_user_media,
.chat_mute_notification,
.chat_disappering_msgdata {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.chat_user_media_data {
  width: 90%;
  height: 60px;
  overflow: hidden;
  overflow-x: auto;
  margin: 0 auto;
  z-index: 100;
}

.chat_forscroll {
  width: fit-content;
  height: 60px;
  display: flex;
}

.chat_user_media_data1 {
  width: 60px;
  height: 60px;
  background-color: white;
  margin-left: 10px;
}

.chat_mute_notific {
  width: 100%;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
}

.chat_mute_icon {
  width: 36px;
  height: 18px;
  background: rgb(241, 241, 241);
  border-radius: 32px;
  position: relative;
}

.chat_mute_innercircle {
  width: 17px;
  height: 18px;
  background: rgb(190, 190, 190);
  border-radius: 32px;
  position: absolute;
  top: 0;
  /* left: 0; */
  /* right: 0; */
  left: 0px;
}

.chat_user_block,
.chat_user_report,
.chat_user_delete {
  display: flex;
}

.chat_user_block_div {
  width: 30px;
  position: relative;
}

.chat_user_block_div img {
  position: absolute;
  top: 0;
  left: 0;
}

.chat_user_block_div img:nth-child(2) {
  top: 3px;
  left: 3px;
}

.chat_user_blockdata,
.chat_user_reportdata,
.chat_user_deletedata {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #cc3169;
  margin: 0;
}

.chat_user_reporticon {
  width: 30px;
  height: 18px;
  position: relative;
}

.chat_user_reporticon img {
  position: absolute;
  top: 0;
  left: 0;
}

.chat_user_reporticon img:nth-child(1) {
  position: absolute;
  top: 0;
  left: 4px;
}

.chat_user_deleteicon {
  width: 30px;
  height: 14px;
  position: relative;
}

.chat_user_deleteicon img {
  position: absolute;
  top: 0;
  left: 0;
}

.chat_user_deleteicon img:nth-child(1) {
  margin-left: 1px;
  top: 4px;
}

.chat_user_deleteicon img:nth-child(4) {
  top: 2.5px;
  left: 0;
}

.chat_user_deleteicon img:nth-child(2) {
  left: 4px;
}

.chat_user_deleteicon img:nth-child(3) {
  top: 7px;
  left: 5px;
}

.chat_user_deleteicon img:nth-child(5) {
  top: 7px;
  left: 9px;
}

/* responsive css code for chat section */
@media (max-width: 1330px) {
  .chat_main_container {
    width: 90%;
    margin: 5vh auto 0;
  }

  .chat_navbar {
    padding-right: 25px;
  }

  .your_text {
    max-width: 200px;
  }

  .my_text {
    max-width: 200px;
  }
}

@media (max-width: 1180) {
  .chat_main_container {
    width: 90%;
    margin: 5vh auto 0;
    grid-template-columns: 35% 1fr 30%;
  }
}

/* chat section mobile view css */
.main_cont_mobile_chat {
  width: 100%;
  height: 620px;
  padding: 5px 15px;
  background: #dbedef;
}
