.offline-profile-service-main-page {
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.offline-profile-serivce-main-page-main-box-card {
  width: 100%;
  height: -moz-fit-content;
  height: 177px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px auto;
  padding: 1vw;
}
.offline-profile-serivce-main-page-main-box-card-image {
  width: calc(100% / 5);
}
/* best way for image how to write css for image */
.offline-profile-serivce-main-page-main-box-card-image > img {
  width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 150px;
    max-height: 150px;
}
.offline-profile-serivce-main-page-main-box-card-names {
  width: calc(100% / 5);
}
.offline-product-brand {
  font-size: 13px;
  font-weight: 700;
  color: #00838f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.offline-product-name {
  font-size: 16px;
  font-weight: 600;
  color: rgb(97, 92, 92);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.offline-product-type {
  font-size: 13px;
  font-weight: 600;
  color: red;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.offline-profile-serivce-main-page-main-box-card-quantity {
  height: 100%;
  /* width: calc(100% / 5); */
  padding-left: 10px;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  /* justify-content: space-evenly; */

  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.offline-profile-serivce-main-page-main-box-card-quantity > div {
  width: 100%;
  display: flex;

}
.offline-profile-serivce-main-page-main-box-card-quantity .service-color-left-view{
  justify-content: left;
  gap: 23px;
  position: relative;
  }
  .offline-profile-serivce-main-page-main-box-card-quantity .service-color-left-view  span:nth-of-type(2){
    margin-left: -25px;
    cursor: pointer;
  }
.offline-profile-serivce-main-page-main-box-card-quantity > div > select {
  /* width: 70px; */
  flex-grow: 1;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.556);
  border: 1px solid rgba(0, 0, 0, 0.516);
  padding: 0 1.2rem;
  max-width: 6rem;
  background-color: white;
}
.offline-profile-serivce-main-page-main-box-card-quantity
  > div
  > select
  > option {
  display: flex;
  justify-content: center;
}
.offline-profile-serivce-main-page-main-box-card-quantity > div > span {
  width: fit-content;
}

.offline-profile-serivce-main-page-main-box-card-price {
  width: calc(100% / 5);
  padding-left: 35px;
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;
  align-items: end;
}

.offline-profile-serivce-main-page-main-box-card-price > div {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  /* line-height: 16px; */
  /* text-align: right; */
  color: #ff6868;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.offline-profile-serivce-main-page-main-box-card-price > span {
  text-decoration: line-through;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  /* text-align: right; */
  color: rgba(0, 0, 0, 0.535);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.offline-profile-serivce-main-page-main-box-card-price > section {
  font-family: "Poppins";
  font-style: normal;
  color: green;
  font-weight: 600;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.offline-profile-serivce-main-page-main-box-card-buttons {
  width: calc(100% / 5);
  font-size: 17px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: rgba(0, 0, 0, 0.508);
}

.offline-profile-serivce-main-page-main-box-card-buttons > div {
  cursor: pointer;
}
.offline-profile-ongoing-and-pending-online-back {
  margin: 1vw;
  cursor: pointer;
}
.addPlus {
  background-color: #189ca8;
  color: white;
  width: 8rem;
  height: 2rem;
  cursor: pointer;
  border-radius: 0.4rem;
}
.addPlus:hover {
  background-color: #147c85;
}

.addPlus:active {
  transform: scale(0.9);
  transition: transform 0.2s ease;
}
.my-service-profile-products-section-selector-tab {
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: start;
}

.my-service-profile-products-section-selector-tab > span {
  padding: 1vw 2vw;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.512);
  cursor: pointer;
}
.underlinedOnPage {
  border-bottom: 2px solid #00838f;
}
.AllProductsSearchBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.AllProductsSearchBox > input {
  width: 90%;
  padding: 4px 10px;
  border-radius: 10px;
  border: 1px solid;
}
.AllProductsSearchBox > .sendIcon {
  font-size: 22px;
}
.allProducts-edit-button {
  padding: 4px 10px;
  color: red;
  background-color: #aae8ee;
  width: fit-content;
  border-radius: 4px;
  border: none;
}
.AllProducts-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.08);
  z-index: 9999;
}
.edit-price-container {
  position: relative;
  border-radius: 20px;
  height: 200px;
  width: 400px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.edit-price-heading{
  font-size: 20px;
}
.AllProducts-crossIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #00838f;
  font-size: xx-large;
  cursor: pointer;
}
.price-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: gray;
}
.edit-price-input {
  width: 80px;
  border-radius: 12px;
  padding: 4px 10px;
  background-color: #189ca8;
  border: none;
}
.edit-price-submit-button {
  width: 150px;
  background-color: #00838f;
  color: white;
  padding: 10px;
  border-radius: 12px;
  border: none;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .offline-profile-service-main-page {
    padding: 0 5px;
  }
  .my-service-profile-products-section-selector-tab {
    justify-content: space-around;
    margin: 10px 0px 20px 0px; 
  }
  .offline-profile-serivce-main-page-main-box-card {
    height: auto;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 10px 0;
  }
  .offline-profile-serivce-main-page-main-box-card-names {
    width: 60%;
    padding-bottom: 10px;
  }
  .offline-profile-serivce-main-page-main-box-card-price {
    width: auto;
    padding-left: 10px;
    margin-top: -70px;
  }
  .offline-product-name {
    white-space: unset;

    max-width: 146px;
  }
  .offline-product-type {
    white-space: unset;
    font-size: 10px;
    max-width: 146px;
  }
  .offline-profile-serivce-main-page-main-box-card-price .addPlus {
    width: 5rem;
  }
  .offline-profile-serivce-main-page-main-box-card-quantity {
    gap: 10px;
    height: fit-content;
  }
  .offline-profile-serivce-main-page-main-box-card-quantity > div > select {
    max-width: 7rem;
  }
  .offline-profile-serivce-main-page-main-box-card-image > img {
    height: 60px;
  }
  .offline-profile-ongoing-and-pending-online-back {
    position: absolute;
    left: 10px;
    top: 10px;
  }
}
