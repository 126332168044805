.generate-bill-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.generate-pdf-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.generate-pdf-form {
  height: fit-content;
  width: 450px;
  background-color: white;
  position: relative;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  height:98vh;
  overflow: scroll;
}
.generate-pdf-form-dropdown-list {
  position: absolute;
  top: 35px;
  z-index: 8;
  display: block;
  border-radius: 1px;
  width: 85%;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
}
.generate-pdf-form-dropdown-list > div {
  width: 100%;
  background-color: rgb(219, 217, 217);
}
.generate-pdf-form > div > label,.billdesktop>div>label {
  color: #00838f;
  font-size: 1.1rem;
  width: 100%;
}
.generate-pdf-form > div > label > span {
  color: red;
}
.user-icon {
  color: #00838f;
  font-size: 2rem;
}

.input-container {
  display: flex;
  flex-direction:column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.inputsection{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  width:100%;
}
.inputsection > input {
  width: 100%;
  height: 2rem;
  border: 1px solid rgba(0, 166, 182, 0.5);
  border-radius: 4px;
  padding: 0 10px;
  background-color: white;
}
.input-container > input[type="number"] {
  width: 85%;
  appearance: textfield;
}
.input-container > input {
  width: 100%;
  height: 2rem;
  border: 1px solid rgba(0, 166, 182, 0.5);
  border-radius: 4px;
  padding: 0 10px;
  background-color: white;
}

.input-radio-container {
  display: flex;
}
.input-radio-container > div {
  width: 50%;
}
.input-radio-container > div > input {
  margin-right: 10px;
}
.date-picker {
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 1.2rem;
  margin-bottom: 10px;
}
.date-picker > button {
  padding: 4px 6px;
  border-radius: 10px;
  border: none;
  width: 120px;
  height: 40px;
}
.date-picker > .current-date {
  border: 2px solid #4db6c0;
  background-color: white;
  color: black;
  white-space: nowrap;
  padding: 4px 6px;
  border-radius: 10px;

}
.date-picker > .select-date {
  background-color: #00838f;
  color: white;
  white-space: nowrap;
}
.crossIcon {
  color: #00838f;
  font-size: xx-large;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.big-button {
  height: 3rem;
  width: 100%;
  margin-top: 1.2rem;
  border: none;
  border-radius: 16px;
  background-color: #00838f;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:10px;
}
.nosuchcustomer{
  color: #cc0033;
  display:block;
  width: 100%;
  font-size: 14px;
  line-height: 15px;
  margin: 5px 0 0;
}
.checkinputtt{
  display:flex;
  flex-direction: row;
  align-items: center;
  gap:5px;
}
#checkinput{
  margin-top:2px;
}
.billdesktop{
  display:flex;
  flex-direction:row;
  justify-content: space-between;
}
.generate-pdf-form:has(.billdesktop){
  min-width: 40vw;
  height: max-content !important;
  max-width: 65vw;
  width: unset !important;
}
.sectiondesktopp{
  width:48%;
}
.billdesktop>div>label{
  white-space:nowrap;
}
.biiillamount{
  text-align:center;
}