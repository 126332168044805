.mypost-header-container
{
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
 padding-top: 26px;
}
.mypost-post-container
{
    height: inherit;
    padding-top: 25px;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    width: 100%;
    /* justify-content: center; */
    align-items: center;
    padding: 20px;
    gap: 20px;
}

.mypost-create-post
{
    padding-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}
.mypost-create-post>div
{
    width: max(50%,570px);
}
.mypost-create-post>div>a
{
    text-decoration: none;
}

 /* @media only screen and (max-width: 1300px)
 {
  .mypost-post-container
  {
   grid-template-columns: 6fr 6fr ;
  }
 } 
 @media only screen and (max-width: 798px)
 {
  .mypost-post-container
{
    grid-template-columns: 6fr;
}
} */
 .mypost-no-posts-container
 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 }
.mypost-no-posts-container>p
{
    color: #00838F;
    font-weight: bold;
    font-size: 28px;
}
.mypost-no-posts-container>img
{
    width: 400px;
    height: 400px;
}
.postaad{
  display:flex;
  flex-direction:row;
  justify-content: flex-end;
  padding:10px;
}
.my-post-container {
    height: inherit;
    padding-top: 25px;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  .postOverlay {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .my-post-container{
        height:500px;
        width:98%;
    }
    .my-post-container::-webkit-scrollbar{
        height:7px;
        margin-top:10px;
        display:flex;
        width:7px;
      }
      .my-post-container::-webkit-scrollbar-thumb {
        background-color: gray;
        border-radius: 5px;
      }
      .my-post-container::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        border-radius: 5px;
      }
  }