.notification-container {
  max-height: 89vh;
  width: 440px;
  overflow-y: auto;
}
.notification-container-header {
  padding: 5px;
  height: 65px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.notification-container-header > div {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1a1f36;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.notification-container-header .notification-close-button {
  font-size: 20px;
  margin-bottom: 4px;
}
.notification-container-header > div > div > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #9ea0aa;
}
.notification-container-header > section {
  font-family: "Inter";
  font-style: normal;
  text-align: end;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1a1f36;
}
.notification-container-header > section > img {
  margin-top: -1px;
  width: 14px;
  height: 14px;
}
.notification-container-message {
  position: relative;
  border-top: solid #a5acb8 1px;
}
.notification-container-message-unread {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  background: #90cdf4;
  border: 1px solid #4299e1;
  border-radius: 100%;
}
.notification-container-message-top {
  margin-top: 16px;
  padding: 0 16px;
  display: flex;
  gap: 18px;
}
.notification-container-message-top > img {
  width: 32px;
  height: 32px;
  border-radius: 24px;
  margin-right: 16px;
}
.notification-container-message-top > span {
  font-family: "Inter";
  display: inline-block;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1a1f36;
}
.notification-container-message-bottom {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #a5acb8;
  margin-bottom: 16px;
  margin-left: 64px;
}
.fulllyfetched {
  width: 100%;
  display: block !important;
}
@media screen and (max-width: 720px) {
  .notification-container-message-bottom {
    margin-top: 0px;
  }
  .notification-container-header {
    height: 56px;
  }
  .notification-container-message-top > svg {
    padding-top: 0px !important;
    margin-top: 10px;
  }
}
