.my-bank-account-profile-card
{
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    /* display: flex; */
    /* align-items: center; */
    padding: 20px 20px;
    height: fit-content;
}
.my-bank-account-profile-card-top
{
 font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #474747;
    margin-bottom:10px ;
}
.my-bank-account-profile-card-mid
{
 font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    color: #757575;
    display: flex;
    gap: 40px;
}
.my-bank-account-profile-card-bot
{
 width: 100%;
 display: flex;
 align-items: center;
 gap: 20px;
margin-top: 10px;
 
}
.my-bank-account-profile-card-bot>button
{
  border: none;
  padding: 1vw;min-width: 100px;
  border-radius: 40px;
    background: #00838F;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-evenly;

        font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 17px;
    letter-spacing: -0.2px;
    color: #FFFFFF;
}

@media screen and (max-width:720px) {
    .my-bank-account-profile-card-bot{
        justify-content: center;
    }
    .my-bank-account-profile-card-bot>button{
        font-size: 14px;
        width: 100px;
        min-width: auto;
        padding: 10px;
    }
}