.PutatoeServiceOptionCards-container-desktop-2
{
width: 32vw;
height: 230px;
    background: #FFFFFF;
box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3vw;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s transform cubic-bezier(0.4, 0, 1, 1), 0.3s box-shadow, 0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
}
.PutatoeServiceOptionCards-container-desktop-2:hover
{
  transform: scale(1.02);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06) !important;
}

.PutatoeServiceOptionCards-heading-2-desktop
{
     font-weight: bold;
    font-size: 14px;
    font-family: Raleway;
    color: black;
    text-decoration: none;
    width: 100%;
    display: flex;
    justify-content: center;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding: 0 1vw;
}
.PutatoeServiceOptionCards-heading-2-desktop>span
{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.PutatoeServiceOptionCards-heading-Ratings
{
 text-align: center;
    margin-top: 20px;
    text-decoration: none;
}
.PutatoeServiceOptionCards-heading-distance
{
 display: flex;
    font-size: 12px;
    color: #94969f;
    text-decoration: none;
    /* background-color: black; */
    display: flex;
    align-items: center;
}
.PutatoeServiceOptionCards-heading-distance>span>img
{
 margin-right: 4px;
 width:12px ;
 height:12px ;
}
.PutatoeServiceOptionCards-heading-description
{
 font-size: 11px;
 color: black;
 text-decoration: none;
}
    
@media only screen and (max-width: 680px){
    .PutatoeServiceOptionCards-container-desktop-2{
        width: 85vw;
    }
}