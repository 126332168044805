
.navbar-header-cart-modal-container
{
 position: absolute;
 top: 0;
     width: 46vw ;
    top: 55px ;
    right: 0px ;
    /* transform: translate3d(-708px, 0px, 0px);  */
    padding: 10px;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    max-height: 400px;
}
.navbar-header-cart-modal-container-item-list
{
 overflow: auto;
 max-height: 320px;
 width: 100%;
}
.navbar-header-cart-modal-container-button
{
 display: flex;
 align-items: center;
 justify-content: space-around;
 margin-top: 8px;
}
.navbar-header-cart-modal-container-button>div
{
 font-size: 0.9rem;
    background: #dbedef;
    /* margin: 20px 5px 0 5px; */
    padding: 10px 30px;
    border-radius: 5px;
    color: #027e88 !important;
    border: 1px solid #027e88;
    font-weight: 600;
}
.navbar-header-cart-modal-container-button>section
{
    font-size: 0.9rem;
    background: #dbedef;
    padding: 10px 30px;
    border-radius: 5px;
    color: #028b13;
    border: 1px solid #028b13;
    font-weight: 600;
    cursor: pointer;
}
.navbar-header-cart-modal-container-button>button
{
    font-size: 0.9rem;
    background: #027e88;
    /* margin: 20px 5px 0 5px; */
    padding: 10px 15px;
    color: #fff;
    border-radius: 5px;
    border: 1px solid #027e88;
    font-weight: 600;
    cursor: pointer;
}















/* //seconf fieldset */

.navbar-header-cart-modal-container-item
{
     margin-bottom: 8px;
         display: flex;
    justify-content: center;
    height: 200px;
    background: #f5fafa! important;
    border: 1px solid #027e8b;
    /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
    border-radius: 10px;
    color: #027e8b;
    align-items: center;
    padding:  0 0.5vw;
}

.navbar-header-cart-modal-container-item>div
{
width: 25%;
display: flex;
align-items: center;
justify-content: center;
object-fit: cover;   
/* specify how an image should resize   image ko fit    image crop */
    text-align: center;
    height: 90%;
}
.navbar-header-cart-modal-container-item>div>img
{
 max-height: 100%;
 width: 100%;
}

.navbar-header-cart-modal-container-item>section
{
 width: 67%;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: space-around;
}

.navbar-header-cart-modal-container-item-details>div
{
 font-size: 17px;
    /* width: 300px; */
    font-weight: 600;
    /* margin-top: 20px; */
    color: #027e8b !important;
}
.navbar-header-cart-modal-container-item-details>span
{
color: green;
font-size: 11px;
font-weight: 600;
}
.navbar-header-cart-modal-container-item-details>section
{
color: #28a745;
font-size: 11px;
font-weight: 600;
}

.navbar-header-cart-modal-container-item-quantity
{
 font-weight: 600;
}
.navbar-header-cart-modal-container-item-price
{
 font-weight: 600;
 /* cursor: pointer; */
}


.navbar-header-cart-modal-container-item>article
{
 width: 8%;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
 /* font-family: "FontAwesome"; */
    font-weight: 900;
    font-size: 1.5rem;
    cursor: pointer;
}

@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px){
    .navbar-header-cart-modal-container{
        right: -20vw;
        width: 400px;
    }
    .navbar-header-cart-modal-container-button{
        gap: 4px;
    }
    .navbar-header-cart-modal-container-button > div , 
    .navbar-header-cart-modal-container-button > section ,
    .navbar-header-cart-modal-container-button > button {
        font-size: 14px;
        padding: 10px;
    }
}