.my-dth-recharge-main-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 27px;
  font-family: "Ubuntu", sans-serif;
  /* justify-content: center; */

}


.recharge-form .error , .recharge-form .error {
  border: 1px solid #cc0033;
  outline: none;
}

.recharge-form .error-center-mobilepage{
text-align: center;
margin-top: -10px;
}


.mobile-recharge-inner-circle-2-plan-no-results-found{
display: flex;
align-items: center;
height: 100%;
flex-direction: column;
font-weight: 600;
justify-content: center;
}
.mobile-recharge-inner-circle-2-plan-no-results-found >span{
  font-weight: 400;
  font-size: 15px;
  color: rgb(102, 102, 102);
  padding-top: 10px;
  line-height: 18px;
}
.my-dth-recharge-main-page-container-heading {
  width: 100%;
  padding-left: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  font-size: 1.75rem;
  /* margin-bottom: 0.5; */
  font-weight: 500;
  /* line-height: 1.2; */
  font-family: "Ubuntu", sans-serif;
}
.mobile-recharge-inner-circle-2 .mobile-recharge-inner-circle-2-search{
  display: flex;
  justify-content: space-between;
  margin-right: 0px;
  margin-top: -80px;
  margin-bottom: 20px;
  align-items: end;
}
.mobile-recharge-inner-circle-2 .mobile-recharge-inner-circle-2-search>input{
  font-size: 1.143em;
  background-color: #f9f9fc;
  border: 2px solid #d8d8d8;
  border-radius: 10px;
  line-height: 14px;
  padding: 14px 0px 14px 13px;
  height: 40px;
  width: 200px;
  outline: none;
}
.mobile-recharge-inner-circle-2 .mobile-recharge-inner-circle-2-search>input:focus {
  border-color: #00838f; /* Change the border color when focused */

}
.my-dth-recharge-main-page-container-heading > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.my-dth-recharge-main-page-container-heading > div > span {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 14px !important;
  font-family: "Ubuntu", sans-serif;
}
.my-dth-recharge-main-page-container-heading > div > hr {
  width: 100px;
  margin: 0px 0 0 0;
  height: 2px;
  max-width: 100px;
  background: #00838f;
}
.my-dth-recharge-main-page-container-main-content {
  width: 100%;
  display: flex;
  margin-top: 44px;
  align-items: flex-start;
  justify-content: center;
}
.my-dth-recharge-main-page-container-main-content-col1 {
  width: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.my-dth-recharge-main-page-container-main-content-col1-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 25px;
}
.my-dth-recharge-main-page-container-main-content-col1-inputs-1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 13px;
  font-weight: 600;
  color: #2f2f2d;
}
.my-dth-recharge-main-page-container-main-content-col1-inputs-1 > input,
.my-dth-recharge-main-page-container-main-content-col1-inputs-1 > select {
  width: 220px;
  height: 35px;
  background: #e7e9ec;
  border: 1px solid rgba(0, 166, 182, 0.5);
  border-radius: 2px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 34px;
  text-align: center;
  outline: none;
  color: #545556;
}

.my-dth-recharge-main-page-container-main-content-col1-inputs-2 {
  width: 240px;
  font-size: 13px;
  font-weight: 600;
  color: #2f2f2d;
}
.my-dth-recharge-main-page-container-main-content-col1-inputs-2 > form {
  margin-top: 10px;
  /* display: flex;
    flex-direction: column;  */
}
.my-dth-recharge-main-page-container-main-content-col1-inputs-2 > form > label {
  font-weight: 500;
  font-size: 15px;
  margin-left: 4px;
}

.my-dth-recharge-main-page-container-main-content-col1-inputs-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-dth-recharge-main-page-container-main-content-col1-inputs-button > button {
  padding: 0.7vw 1.3vw;
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: #00838f;
  color: white;
  font-weight: 600;
}
.my-dth-recharge-main-page-container-main-content-col2
{
 width: 50%;
 margin-top: -100px;

}
.my-dth-recharge-main-page-container-main-content-col2 > div > img {
  width: 395px;
  height: 395px;
}

.my-dth-recharge-main-page-container-allProvider-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.my-dth-recharge-main-page-container-allProvider-content-all-provider {
  width: 80%;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  font-size: 25px;
}
.my-dth-recharge-main-page-container-allProvider-content-all-provider-card-holder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start; 
  overflow-x: auto; 
  scroll-behavior: smooth;
  padding: 0 10px;
  gap: 34px;
  padding-bottom: 20px;
  margin-bottom: 40px;
  
}

.my-dth-recharge-main-page-container-allProvider-content-all-provider-card-holder::-webkit-scrollbar{
  height:7px;
  margin-top:4px;
  display:flex;
}
.my-dth-recharge-main-page-container-allProvider-content-all-provider-card-holder::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 5px;
}
.my-dth-recharge-main-page-container-allProvider-content-all-provider-card-holder::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}

.my-dth-recharge-main-page-container-allProvider-content-all-provider-cards {
  margin-top: 10px;
  height: 88px;
  padding: 15px;
  min-width: 33%;
  border-radius: 10px;
  box-shadow: 1px 1px 2px #888888;
  background: #fff;
  max-width: 36%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  font-size: 13px;
  font-weight: 600;
  color: #2f2f2d;
}

.my-dth-recharge-main-page-container-allProvider-content-all-provider-cards-col1
  > div {
  width: 50px;
  height: 50px;
  border-radius: 200px;
}
.my-dth-recharge-main-page-container-allProvider-content-all-provider-cards-col1
  > div
  > img {
  width: 50px;
  height: 50px;
  border-radius: 200px;
}

.my-dth-recharge-main-page-container-allProvider-content-line-2 {
  margin-top: 20px;
  width: 90%;
}
.my-dth-recharge-main-page-container-allProvider-content-line-2 > div {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 22px;
}
.my-dth-recharge-main-page-container-allProvider-content-line-2 > p {
  color: rgba(0, 0, 0, 0.504);
  font-weight: 600;
  font-size: 13px;
}
.rechargeContainer {
  display: flex;
  width: 100%;
  height: 450px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 30px;
}
.recharge-form {
  width: 50%;
  align-self: end;
  height: 100%;
  padding-top: 60px;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.mobile-recharge-inner-circle-2 {
  width: 50%;
  height: 100%;
  align-self: end;
}
.mobile-recharge-inner-circle-2-top {
  width: 100%;
  height: 70px;
  padding-left: 28px;
  display: flex;
  align-items: center;
}
.loadingImg {
  height: 391px;
  width: 100%;
}
.loadingImg > img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.mobile-recharge-inner-circle-1-inputs > input,
.mobile-recharge-inner-circle-1-inputs > select {
  width: 50%;
  height: 35px;
  background: #e7e9ec;
  border: 1px solid rgba(0, 166, 182, 0.5);
  border-radius: 2px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 34px;
  text-align: center;
  outline: none;
  color: #545556;
}

.mobile-recharge-inner-circle-2-plan-container .plan-details-modal{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.mobile-recharge-inner-circle-2-plan-container  .plan-details-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  min-height: 320px;
  height: fit-content;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.mobile-recharge-inner-circle-2-plan-container  .plan-details-modal-content>.buttons-container{
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;

}
.mobile-recharge-inner-circle-2-plan-container  .plan-details-modal-content .plan-details-modal-content-button{
   display: flex;
   justify-content: space-between;

}
.mobile-recharge-inner-circle-2-plan-container  .plan-details-modal-content .plan-details-modal-content-button >button{
  color: #00838f;
  border: none;
  font-weight: bolder;
  background: transparent;
}
.mobile-recharge-inner-circle-2-plan-container  .plan-details-modal-content .plan-details-modal-content-button .plan-details-modal-heading{
font-weight: bold;
margin-bottom: 20px;

}
.mobile-recharge-inner-circle-2-plan-container  .plan-details-modal-content  .plan-details-modal-description-amount{
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  padding-bottom: 20px;
}
.mobile-recharge-inner-circle-2-plan-container  .plan-details-modal-content   .plan-details-modal-description-row-data-info{
  display: flex;
  justify-content: space-between;

}
.mobile-recharge-inner-circle-2-plan-container  .plan-details-modal-content   .plan-details-modal-description-row-data-info > div{
  display: flex;
  margin: 20px 0;
  flex-direction: column;
  width: 50%;
  color: #2f2f2d;
  font-weight: 800;
}
.mobile-recharge-inner-circle-2-plan-container  .plan-details-modal-content  .plan-details-modal-description-longdesc{
  font-size: 1em;
  line-height: 22px;
  margin-bottom: 30px;
}
.mobile-recharge-inner-circle-2-plan-container  .plan-details-modal-content   .plan-details-modal-description-row-data-info > div>span{
  font-size: 16px;
  font-weight: 500;
    color: #6B6B6B;
}
.mobile-recharge-inner-circle-2-plan-container  .plan-details-modal-content >.buttons-container > button{
  background: #00838f;
  background-color: #00838f;
  border: none;

  border-radius: 10px;
  color: #fff;
  width: 300px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 10px 5px;
  text-align: center;
  text-transform: uppercase;
}
@media screen and (max-width: 720px) {
  .my-dth-recharge-main-page-container {
    width: 100%;
  }
  .my-dth-recharge-main-page-container-main-content-col2{
    display: none;
  }
  .rechargeContainer
  {
    flex-direction: column;
    height: fit-content;
  }
  .mobile-recharge-inner-circle-2-empty>img
  {
    width: 300px;
    height: 300px;
  }
  .mobile-recharge-inner-circle-2
  {
    width: 100%;
    align-self: center;
  }
  .mobile-recharge-inner-circle-2-except-prepaid
  {
    display: none;
  }
  .my-dth-recharge-main-page-container-allProvider-content-all-provider
  {
    text-align: center;
    margin-top: 20px;
  }
  .my-dth-recharge-main-page-container-allProvider-content-all-provider-cards {
    flex: 0 0 60%;
    flex-direction:column;
    max-width: none;
    height:130px;
  }
  .my-dth-recharge-main-page-container-allProvider-content-all-provider-cards-col2{
    text-align:center;
  }
  .mobile-recharge-inner-circle-1-inputs > input, .mobile-recharge-inner-circle-1-inputs > select
  {
    width: 300px;
  }
  .recharge-form {
    width: 100%;
  }
  .profile-section-container2:has(.my-dth-recharge-main-page-container-allProvider-content){
    margin-bottom:0px;
  }
}
.error input,  .error select {
  border: 1px solid #cc0033;
  outline: none;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .mobile-recharge-inner-circle-2 .mobile-recharge-inner-circle-2-search{
    margin-top: unset;
  }
}

@media only screen and(min-device-width: 320px) and (max-device-width: 375px) {
  .my-dth-recharge-main-page-container-allProvider-content-all-provider-cards{
    min-width:84% !important;
  }
}