.Address-modal-mobile-container-background
{
 position: fixed;
 width: 100%;
 height: 100%;
 /* do something to stop scroll */
     background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
}
.Address-modal-mobile-container
{
 margin: 1.75rem auto;
 background: #fff;
 width: 90%;
 max-width: 500px;
 overflow-y: scroll;
    /* padding: 35px; */
    height: fit-content;
    /* right: -400px; */
    transition: all 0.6s ease;
    
}
/* .Address-modal-mobile-container-intro-in
{
 right: 0 !important;
 transition: all 0.6s ease;
} */
.Address-modal-mobile-cross
{
 padding: 1.5rem;
     display: block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.5;
}
.Address-modal-mobile-cross>div
{
    font-weight: 700;
    font-size: 18px;
    color: #343a40
}
.Address-modal-mobile-cross>span
{
font-size: 25px;
    text-align: left;
    color: #00838f;
    cursor: pointer;
    font-weight: 800;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}
.Address-modal-mobile-cross>span:hover
{

    opacity: 1;
}

.Address-modal-mobile-coupon-enter
{
 display: flex;
flex-direction: column;
    padding: 1rem;
    max-height: 200px;
    height: min( fit-content , 170px);
    overflow: scroll;
}
.Address-modal-mobile-coupon-enter-row
{
 margin-bottom: 0.5rem;
 /* display: flex; */
 align-items: center;
 justify-content: flex-start;
 width: 100%;
}
.Address-modal-mobile-coupon-enter-row>input
{
 margin-right: 10px;
}
.Address-modal-mobile-coupon-enter-row>span
{
font-size: 12px;
line-height: 1.2em;
}
.Address-modal-mobile-coupon-enter-row-buttons
{
 float:right!important;
 display: flex;
 align-items: center;
 justify-content: space-between;
 gap: 7px;
 margin-right: 10px;
}
.Address-modal-mobile-coupon-enter-row-buttons>span
{
line-height: 1.4em;
font-weight: 700;
font-size: 12px;
color: #00838f;
    cursor: pointer;
}
.Address-modal-mobile-coupon-enter-row-buttons>p{
line-height: 1.4em;
font-weight: 700;
font-size: 12px;
color: #00838f;
    cursor: pointer;
}







.Address-modal-mobile-coupon-enter-button-last
{
 padding: 8px;
    box-shadow: 0 -1px 4px 0 #0000004d;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.Address-modal-mobile-coupon-enter-button-last>button:nth-child(1)
{
 width: 50%;
 height: 45px;
     font-weight: 800;
     text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px ;
    border-radius: 4px ;
 text-align: center;
 background-color: transparent;
 outline: none;
 border: none;
 color:  #00838f ;
}
.Address-modal-mobile-coupon-enter-button-last>button:nth-child(2)
{
 width: 50%;
 height: 45px;
 text-align: center;
 background: #00838f;
 text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px ;
    border-radius: 4px ;
    border: none;
        color: #fff;
    font-weight: 700;
}
