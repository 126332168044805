.managecoupontotalsection{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    width:100%;
}
.couponcontent{
    width: 100%;
    min-height: auto;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin: 10px auto;
    padding: 1vw;
    cursor: pointer;
    position: relative;
}
.maincouponcontent{
    max-height: 680px;
    width: 98%;
    margin-top: 1vw;
    padding: 1vw;
    flex-grow: 1;
    overflow: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3px 4px;
    min-height: max-content;
}
.alllfetched11{
    position: absolute;
    width:100%;
}
.managecouponfront:has(.alllfetched11){
    position: relative;
}
.couponheading{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 33px;
    color: #2b2b2b;
    text-transform: capitalize;
}
.couponbaseprice{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #2b2b2b;
}
.couponbasepriceleft{
    font-weight:500;
}
.basepricebefore{
    font-size:17px;
    font-family: normal;
}
.couponinfocontent{
    display:flex;
    flex-direction:row;
    width:100%;
    justify-content: space-between;
}
.yes{
    font-weight:700;
    color: rgb(1, 110, 1);
}
.No{
    font-weight:700;
    color: rgb(118, 0, 0);
}

.couponright {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content:space-around;
}
.expirysection{
   display:flex;
   flex-direction:row;
   justify-content: space-around;
}
.couponleft{
    display:flex;
    flex-direction:column;
    gap:6px;
}
.greendate{
    font-weight: 700;
    color: rgb(1, 110, 1);
}
.reddate{
    font-weight: 700;
    color: rgb(118, 0, 0);
}
.maincouponcontent::-webkit-scrollbar {
    width: 5px;
    display: flex;
    margin-bottom: 30px;
}
.maincouponcontent::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 3px;
}
.maincouponcontent::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}
.couponheading1{
    font-size: 16px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px){
.couponcontent{
    flex-wrap: wrap;
    justify-content: space-around;
    min-height: auto;
}
.maincouponcontent{
    margin-bottom: 20px;
    height: 400px;
    /* overflow: scroll; */
    padding: 0 20px;
    grid-template-columns: 1fr;
}
.couponheading{
    font-size: 17px;
}
.couponheading1{
    font-size: 14px;
}
.couponbaseprice{
    font-size: 15px;

     
}

.manage-services-profile-service-main-page-main-box-card-price > span > img {
    width: 35px;
    height: 40px;
    cursor: pointer;
    margin-top: -65px;
/* margin-left: 487px; */
}
}
.manage-services-profile-service-main-page-main-box-card-price > span > img {

    margin-top: -60px;
}
