.my-service-profile-ongoing-and-pending-online .transaction-summary-container-inner{
margin-top: 20px;
width: 90%;
display: flex;
flex-direction: column;
gap: 20px;
justify-content: center;
align-items: center;
}
.my-service-profile-ongoing-and-pending-online .transaction-summary-container-inner .transaction-summary-box{
    background:#fff;
    width: 60%;
    border: 1px solid #00838f65;
    padding: 10px;
    border-radius: 10px;
}
.my-service-profile-ongoing-and-pending-online .transaction-summary-container-inner .transaction-summary-box >label{
    font-weight: 600;
    color: #00838f;

}
.my-service-profile-ongoing-and-pending-online .transaction-summary-container-inner .transaction-summary-box >div{
    width: 100%;
    border: 1px solid #00838f65;
    border-radius: 10px;
    padding: 5px;
 
}

.my-service-profile-ongoing-and-pending-online .transaction-summary-container-inner button{
    padding: 10px 50px;
    border-radius: 10px;
    background-color: rgb(235, 44, 44);
    color: #fff;
    font-weight: 500;
    border: none;
}

.transaction-summary-container-inner .summary-transacation-incoming-outgoing{
width: 60%;
background: rgba(11, 229, 241, 0.674);
padding: 10px;
border-radius: 10px;
}

.transaction-summary-container-inner .summary-transacation-incoming-outgoing .summary-transacation-incoming-outgoing-header{
color: red;
background-color: #fff;
border-radius: 17px;

text-align: center;

}
.transaction-summary-container-inner .summary-transacation-incoming-outgoing .summary-transacation-incoming-outgoing-header>h1{
    font-size: 19px;
    font-weight: 700;
    padding: 5px;
}
.transaction-summary-container-inner .summary-transacation-incoming-outgoing .summary-transacation-incoming-outgoing-details{
    display: flex;
    flex-direction: column;
     font-weight: 800;
}
.transaction-summary-container-inner .summary-transacation-incoming-outgoing .summary-transacation-incoming-outgoing-details span:first-child{
color: rgb(157, 151, 151);
}

.product-transactions-modal .product-transactions-modal-content .product-transactions-modal-body .loader-trans {
    display: inline-block;
    position: absolute;
    right: 28px; 
    top: 70px;
    width: 20px;
    height: 20px;
    border: 3px solid #ccc;
    border-radius: 50%;
    border-top-color: #00838f;
    animation: spin 1s ease-in-out infinite;
   
  }

.quantity-selection-modal{
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
display: flex;
align-items: center;
justify-content: center;
background-color: rgba(0, 0, 0, 0.5);
z-index: 9999;
}
.quantity-selection-modal-content{
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
}
.loadingss{
  align-items: unset;
  font-size: 19px;
}
  .transcaaaation{
    margin-bottom:0px !important;
  }
  #onlyforview{
    min-width:max-content;
    padding: 0px 20px 0px 20px;
  }
  .quantity-selection-modal-buttons{
    display: flex;
    column-gap: 10px;
    width: 100%;
    justify-content: end;
  }
  .modalok{
    width: 100px;
    height: 40px;
    background: #00838f;
    border-radius: 10px;
    border: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
  .quantity-selection-modal-checkboxes{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 10px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    height:auto;
  }
  .productlist,.selectAllInput{
    margin-bottom: unset;
    display: flex;
    gap: 10px;
    padding: 5px !important;
    align-items: center;
  }
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .my-service-profile-ongoing-and-pending-online .transaction-summary-container-inner {
        width: 100%;
    }
  .my-service-profile-ongoing-and-pending-online .transaction-summary-container-inner .transaction-summary-box{
    width: 90%;
  }
  .transaction-summary-container-inner .summary-transacation-incoming-outgoing {
    width: 90%;
  }
  #onlyforview{
    top:110px !important;
  }
}

.product-transactions-modal .product-transactions-modal-content .product-transactions-modal-body .product-transactions-transaction-list{
  max-height: 212px;
  overflow-y: scroll;
}
