.login-page-main-background-mobile
{
 height: 100vh;
 width: 100vw;
 background-color: rgba(0, 131, 143);;
 display: flex;
 align-items: center;
 justify-content: center;
 
}

.login-page-main-box-background-mobile
{
 background-color: white;
 padding: 2vw 5vw;
 width: 90%;
 height: 97%;
border-radius: 12px;
overflow: auto;
}
.login-page-main-box-heading
{
 width: 100%;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
}
.login-page-main-box-heading>div
{
 width: 100px;
 height: 100px;
}
.login-page-main-box-heading>div>img
{
 width: 100%;
 height: 100%;
}
.login-page-main-box-heading>section
{
 font-weight: 600;
 font-size: 29px;
}
.login-page-main-box-heading>article
{
 font-weight: 600;
 color: rgba(0, 0, 0, 0.459);
}
.login-page-main-box-input-box
{
 width: 100%;
}

.login-page-main-box-input-box-1
{
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
 margin-top: 50px;
 gap: 1vw;
}
.login-page-main-box-input-box-1>span
{
 width: 50px;
 height: 50px;
 box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
 display: flex;
 align-items: center;
 justify-content: center;
 border-radius: 100px;
}
.login-page-main-box-input-box-1>span>img
{
  width: 60%;
  height: 60%;
}
.login-page-main-box-input-box-1>input
{
 height: 50px;
 box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
 border: none;
 border-radius: 12px;
 padding: 3vw;
 width: 70%;
 font-size: 15px;
}
.login-page-main-box-input-box-2
{
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
 margin-top: 50px;
 gap: 1vw;
}
.login-page-main-box-input-box-2>span
{
 width: 50px;
 height: 50px;
 box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
 display: flex;
 align-items: center;
 justify-content: center;
 border-radius: 100px;
 /* padding: 2vw; */
}
.login-page-main-box-input-box-2>span>img
{
  width: 60%;
  height: 60%;
}
.login-page-main-box-input-box-2>div>input
{
border: none;
outline: none;
}
.login-page-main-box-input-box-2>div>span
{
 width: 22px;
 height: 19px;
}
.login-page-main-box-input-box-2>div>span>img
{
 height: 100%;
 width: 100%;
}

.login-page-main-box-input-box-2>div
{
 display: flex;
 align-items: center;
 justify-content: center;
 width: 70%;
 height: 50px;
 box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
 border: none;
 border-radius: 12px;
 padding: 3vw 2vw;
 width: 70%;
 font-size: 15px;
}
.login-page-main-box-input-box>article
{
 width: 95%;
 text-align:end ;
 margin-top: 10px;
 font-weight: 700;
 color:  rgba(0, 131, 143);
 font-size: 15px;

}
.login-page-main-box-input-box-3
{
 display: flex;
 align-items: center;
 justify-content: center;
 gap: 2vw;
 margin-top: 25px;

}
.login-page-main-box-input-box-3>input
{

 height: 20px;
 width: 20px;
 /* background-color: rgba(0, 131, 143); */
}
.login-page-main-box-input-box-3>input[type="checkbox"] {
  accent-color:  rgba(2, 139, 151, 0.674);
}
.login-page-main-box-input-box-3>label
{
 margin-bottom: 0;
 font-size: 15px;
 font-weight: 600;
 color: rgba(0, 0, 0, 0.499);
}
.login-page-main-box-input-box-3>label>span
{
 color: rgba(0, 131, 143);
 text-decoration: underline;
}
.login-page-main-box-button-store-mobile
{
width: 100%;
display: flex;
align-items: center;
justify-content: center;
gap: 3vw;
margin-top: 25px;
}
.login-page-main-box-button-store-mobile>button
{
 padding: 3vw 4vw;
 background-color: rgba(0, 131, 143);
 border: none;
 outline: none;
 border-radius: 12px;
 font-size: 17px;
 font-weight:600 ;
 color: white;
 min-height: 55px;

}

.login-page-main-box-or-design-mobile
{
 width: 100%;
 display: flex;
 align-items: center;
 margin-top: 10px;
}
.login-page-main-box-or-design-mobile>div
{
height: 1px;
width: 30%;
background-color: rgba(0, 0, 0, 0.625);
}
.login-page-main-box-or-design-mobile>span
{
 margin-top: 10px;
width: 40%;
display: flex;
align-items: center;
justify-content: center;
font-size: 17px;
font-weight: 600;
}
.login-page-main-box-google-login-mobile
{
margin-top: 25px;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
}
.login-page-main-box-google-login-mobile>img
{

 width: 64px;
 height: 64px;
}

.login-page-main-box-no-account-login-mobile
{
 margin-top: 15px;
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
 font-size: 15px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.499);
}
.login-page-main-box-no-account-login-mobile>span
{
color: rgba(0, 131, 143);
font-weight: 700;
}