.service-provider-card {
  position: relative;
  height: fit-content;
  width: 211px;
  background: #fcfcfc;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
}
.service-provider-card a {
  text-decoration: none;
  color: inherit;
}
.service-provider-image-container {
  width: 211px;
  height: 180px;
  border-radius: 8px;
}
.service-provider-image {
  width: 211px;
  height: 180px;
  border-radius: 8px;
  object-fit: contain;
}
.service-provider-card-screen .confirmation-modal-serviceprovider {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.service-provider-card-screen
  .confirmation-modal-serviceprovider
  .confirmation-modal-serviceprovider-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  max-height: 240px;
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.service-provider-card-screen
  .confirmation-modal-serviceprovider
  .confirmation-modal-serviceprovider-content
  .confirmation-modal-serviceprovider-buttons {
  display: flex;
  column-gap: 10px;
}
.service-provider-card-screen
  .confirmation-modal-serviceprovider
  .confirmation-modal-serviceprovider-content
  .confirmation-modal-serviceprovider-buttons
  > button {
  width: 100px;
  height: 40px;
  background: #00838f;
  border-radius: 10px;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.service-provider-card-details-top {
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 11px;
  padding-bottom: 5px;
}
.service-provider-card-details-brand-name {
  letter-spacing: 0.8px;
  font-weight: 800;
  font-size: 15px;
  line-height: 15px;
  color: #00838f;
  width:75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.service-provider-card-details-product-name {
  letter-spacing: 0.8px;
  font-weight: 600;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.service-provider-card-details-product-type {
  color: #00838fce;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.8px;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.service-provider-card-rating-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  text-align: right;

  color: #4f4f4f;
}
.service-provider-card-rating-container {
  width: 50%;
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding-left: 10px;
}
.service-provider-card-details-top-product-page {
  display: flex;
  flex-direction: row;
}
.service-provider-card-rating-container > section {
  display: flex;
  align-items: flex-start;
}
.service-card-card-star {
  width: 10px;
  height: 10px;
}

.service-provider-card-details-mid {
  padding: 11px;
  padding-top: 0;
  padding-bottom: 5px;
  cursor: pointer;
}
.service-provider-card-details-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
  /* identical to box height */

  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 5px;
}
.service-provider-card-details-price-cont {
  display: flex;
  gap: 0.5vw;
  justify-content: flex-start;
}
.service-provider-card-details-price {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  padding-top: 3px;
  /* identical to box height */
  max-width: 33.333%;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #464545;
}
.service-provider-card-details-price > span {
  font-size: 11px;
}
.service-provider-card-details-pricecut {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  padding-top: 3px;
  line-height: 14px;
  /* identical to box height */
  max-width: 66.33%;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #868686;
}
.service-provider-card-details-price-percentage {
  position: absolute;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;

  padding: 10px 40px;
  top: 0px;
  left: 0;
  border-radius: 6px 0px 15px;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height */
  color: #fff;
  background-color: #07b963;
}
.service-rating-instock-container {
  position: relative;
}
.service-rating-instock-container .service-stock-color-container {
  display: flex;
  margin-top: -5px;
  gap: 30px;
  padding: 0 11px;
  justify-content: space-between;
}
.service-rating-instock-container .service-stock-color-container > span {
  font-size: 12px;
  color: #00838f;
}
.service-stock-color-container > .shareIcon {
  cursor: pointer;
  font-size: 20px;
}

.service-rating-instock-container .service-unit-individual-qty {
  display: flex;
  margin-top: -5px;
  gap: 30px;
  padding: 0 11px;
}
.service-rating-instock-container .service-unit-individual-qty > span {
  padding-top: 8px;
  font-size: 12px;
  color: black;
  font-weight: 500;
}
.service-rating-instock-container .service-unit-individual-qty > span > span {
  font-weight: 400;
}
.service-rating-instock-container .service-color-choices ,.service-card-product-info-container-top-prime-quantity .service-color-choices{
  display: flex;
align-items: center;


}
.similar-product-heading{
font-size: 29px;
margin-bottom: 20px;
margin-top: 10px;
}
.service-rating-instock-container .service-color-choices >span ,.service-card-product-info-container-top-prime-quantity .service-color-choices >span{
line-height: 10px;
display: flex;
cursor: pointer;
font-size: 14px;
gap: 1.5px;
font-weight: 600;
align-items: center;
color: rgba(0, 0, 0, 0.5);
}

.service-rating-instock-container .service-color-choice ,
.service-card-product-info-container-top-prime-quantity .service-color-choice{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid gray;

  margin: 0 10px;


}
.service-rating-instock-container .additional-choices-container,.service-card-product-info-container-top-prime-quantity .additional-choices-container {
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 3px;
  flex-direction: column;
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  top: 37px;
  left: 60px;
  width: 25px;
  padding: 5px 1px;
  overflow-y: auto;
  max-height: 87px;
  position: absolute;
}
.service-rating-instock-container .additional-choice,.service-card-product-info-container-top-prime-quantity .additional-choice {
  width: 20px;
  height: 20px;

  border-radius: 50%;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.service-card-product-info-container-top-prime-quantity .service-unit-individual-qty{
display: flex;
gap: 20px;
}
.service-rating-instock-container
  .additional-choices-container
  .additional-choice ,.service-card-product-info-container-top-prime-quantity .additional-choice {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid gray;

  margin: 0 10px;
  background-color: red;
  cursor: pointer;
}

.service-provider-card-bottom {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 11px;

  padding-top: 0;

  background: #fcfcfc;
  border-radius: 0px 0px 8px 8px;
}
.service-provider-add-to-cart {
  outline: none;
  border: none;

  width: 95px;
  height: 28px;
  background: #00838f;
  border: 0.25px solid #cecece;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #fff;
}
.service-provider-add-to-cart-image {
  width: 22px;
  height: 50%;
}

.service-provider-card-bottom-counter {
  display: flex;
  align-items: center;
  gap: 10px;
}
.service-provider-card-bottom-counter-minus {
  padding: 0.1vw 0.4vw;
  border: 1px solid #00838f;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
  color: #00838f;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}
.service-provider-card-bottom-counter-count {
  padding: 0.1vw 0.4vw;
  border: 1px solid #00838f;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
  color: #00838f;
  font-weight: 600;
  font-size: 16px;
}
.service-provider-card-bottom-counter-plus {
  padding: 0.1vw 0.4vw;
  border: 1px solid #00838f;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
  color: #00838f;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}
.service-stock-color-container > .outOfStockSpan{
  color: red !important;
}
.service-provider-add-to-cart:has(.adddtocart){
  /* background: rgb(26, 173, 12); */
}
.adddtocart{
font-weight:500;
}
.service-provider-add-to-cart:has(.outoffstock){
  background: rgb(172, 12, 12);
}
.adddtocart{
font-weight:500;
}
@media screen and (max-width: 720px) {
  .spcard-mobile {
    /* height: 390px !important; */
  }
  .service-provider-image {
    width: 150px;
    height: 150px;
  }
  .service-provider-card {
    /* height: 220px; */
    width: 170px;
  }
  .service-providercard-mobile {
    /* height: 310px; */
    width: 150px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .service-provider-image-container {
    width: 150px;
    height: 150px;
  }
  .service-provider-add-to-cart {
    width: 20%;
    color: white;
    font-size: 25px;
    background-color: #027e8b;
  }
  .service-provider-add-to-cart > span {
    display: none;
  }
  .service-provider-card-bottom {
    justify-content: flex-start;
    padding-left: 10px;
  }
  .service-provider-card-details-price-cont {
    height: 40px;
  }
  .service-provider-card-details-price {
    display: flex;
    flex-direction: column;
    height: 40px;
    max-width: 100%;
  }
  .sp-cd-price-cont-mobile {
    display: none;
  }
  .sp-cd-price-cont-desktop {
    align-items: flex-start;
    width: 100%;
  }
  .sp-card-price-offer-cont {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .service-provider-add-to-cart-image {
    display: none;
  }
  .service-provider-card-bottom-counter {
    gap: 5px;
    justify-content: center;
  }
  .service-provider-card-bottom-counter-minus,
  .service-provider-card-bottom-counter-plus,
  .service-provider-card-bottom-counter-count {
    height: 20px;
    padding: 0 0.8vw;
    display: flex;
    align-items: center;
    justify-content: center;
    /* text-align: center; */
  }

  .service-provider-card-details-brand-name {
    width: 90%;
  }
  .service-provider-card-screen
    .confirmation-modal-serviceprovider
    .confirmation-modal-serviceprovider-content {
    width: 90%;
  }
  .service-provider-card-screen
    .confirmation-modal-serviceprovider
    .confirmation-modal-serviceprovider-content
    > h3 {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  .service-provider-card-rating-container{
    padding-left: 10px;
  }
}
@media screen and (min-width: 720px) {
  .sp-cd-price-cont-desktop {
    display: none;
  }
  .service-provider-plus-icon {
    display: none;
  }
}
