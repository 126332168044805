.my-service-profile-ongoing-and-pending-online {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1vw;
}
.my-service-profile-ongoing-and-pending-online-back {
  margin: 1vw;
  cursor: pointer;
}
.my-online-ongoing-pending-profile-time-filter-calander-box {
  width: 100%;
  /* padding: 30px 0 0 0; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.my-service-profile-ongoing-and-pending-online-selector {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}
.my-service-profile-ongoing-and-pending-online-selector-tab {
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* gap: 0px; */
}
.my-service-profile-ongoing-and-pending-online-selector-tab > span {
  padding: 1vw 2vw;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.512);
  /* border-bottom: 3px solid #1D8B97; */
  cursor: pointer;
}
.my-service-profile-ongoing-and-pending-online-selector-tab
  .offline-customer-name {
  font-weight: bold;
  margin-right: 20px;
}
.my-service-profile-ongoing-and-pending-online-selector-tab
  .offline-customer-product-type {
  font-weight: bold;
  background: #00838f;
  margin-right: 6px;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
}
.my-bills-profile-page-view-main-right-main-box-card-time {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}
.my-bills-profile-page-view-main-right-main-box-card-time > button {
  background-color: transparent;
  border-radius: 15px;
  border: 1.5px solid rgba(0, 0, 0, 0.267);
  font-size: 12px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.549);
  padding: 0.3vw 0.5vw;
}

.my-service-profile-ongoing-and-pending-online-box {
  flex-grow: 1;
  overflow-x: auto;
}
.my-service-profile-ongoing-and-pending-online-box::-webkit-scrollbar{
  width:4px;
  margin-top:4px;
  display:flex;
  margin-left:10px;
}
.my-service-profile-ongoing-and-pending-online-box::-webkit-scrollbar-thumb {
 background-color: gray;
  border-radius: 5px;
}
.my-service-profile-ongoing-and-pending-online-box::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}
.my-service-profile-ongoing-and-pending-online .order-status-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.my-service-profile-ongoing-and-pending-online .order-status-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  max-height: 240px;
  height: 100%;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.my-service-profile-ongoing-and-pending-online
  .order-status-modal-content
  > h3 {
  color: #00838f;
  font-weight: 600;
  padding-bottom: 20px;
}
.my-service-profile-ongoing-and-pending-online
  .order-status-modal-content
  > .buttons-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.my-service-profile-ongoing-and-pending-online
  .order-status-modal-content
  > .buttons-container
  > button {
  background: #00838f;
  background-color: #00838f;
  border: none;
  border-radius: 2px;
  border-radius: 4px;
  color: #fff;
  width: 300px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 10px 5px;
  text-align: center;
  text-transform: uppercase;
}
.my-service-profile-ongoing-and-pending-online
  .order-status-modal-content
  .service-provider-modal-right-inputs {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.my-service-profile-ongoing-and-pending-online
  .order-status-modal-content
  .service-provider-modal-right-inputs
  > input {
  width: 80%;
  margin: 0 auto;
  border: 1px solid;
  padding: 3px;
}
.my-service-profile-ongoing-and-pending-online
  .order-status-modal-content
  .service-provider-modal-right-inputs
  > span
  span {
  color: red;
}
.my-service-profile-ongoing-and-pending-online
  .order-status-modal-content
  .service-provider-modal-right-inputs
  > span {
  margin-left: 34px;
  font-weight: 600;
  color: #00838f;
}
.NoShowCenter {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  color: #00838f;
}
.my-order-profile-time-filter-calander-3 .my-order-profile-dropdown ,.manage-services-profile-service-main-page-add-service .my-order-profile-dropdown{
  position: relative;
  display: inline-block;
}

.my-order-profile-time-filter-calander-3 .my-order-profile-dropdown .ellipsis, .manage-services-profile-service-main-page-add-service .my-order-profile-dropdown .ellipsis {
  cursor: pointer;
  width: 20px;
  height: 30px;
  display: flex;
  flex-direction: column;

  gap: 2px;
  align-items: center;
  padding: 5px;
}
.my-order-profile-time-filter-calander-3
  .my-order-profile-dropdown
  .ellipsis
  .dot, .manage-services-profile-service-main-page-add-service .my-order-profile-dropdown .ellipsis .dot {
  width: 4px;
  height: 4px;
  background-color: #333;
  border-radius: 50%;
}

.my-order-profile-time-filter-calander-3 .dropdown-content, .my-bills-profile-page-view-main-right .dropdown-content{
  position: absolute;
  background-color: #fff;
  right: 0px;
  top: 80px;
  border-radius: 10px;
  height: fit-content;
  min-width: 260px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.my-order-profile-time-filter-calander-3 .dropdown-content ul ,.my-bills-profile-page-view-main-right .dropdown-content ul{
  list-style-type: none;
}

.my-order-profile-time-filter-calander-3 .dropdown-content ul li ,.my-bills-profile-page-view-main-right .dropdown-content ul li{
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
.my-order-profile-time-filter-calander-3
  .dropdown-content
  ul
  li:last-child:hover, .my-bills-profile-page-view-main-right .dropdown-content ul   li:last-child:hover {
  background: #c5c8c8;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.my-order-profile-time-filter-calander-3 .dropdown-content ul li:hover ,.my-bills-profile-page-view-main-right .dropdown-content ul li:hover{
  background: #c5c8c8;
  overflow: hidden;
}

/* product transcation css */
.product-transactions-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
  align-items: center;
  justify-content: center;
}

.my-service-profile-ongoing-and-pending-online
  .product-transactions-modal.open , .my-bills-profile-page-view-main-right   .product-transactions-modal.open {
  display: flex;
}
.my-service-profile-ongoing-and-pending-online
  .product-transactions-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.my-service-profile-ongoing-and-pending-online
  .product-transactions-modal-header
  > h2 {
  color: #00838f;
}
.product-transactions-modal .product-transactions-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  max-height: 700px;
  width: 100%;
  position: relative;
}
.product-transactions-modal
  .product-transactions-modal-content
  .product-transactions-modal-body {
  max-height: 400px;
  overflow-y: auto;
  z-index: -999;
}
.product-transactions-modal
  .product-transactions-modal-content
  .product-transactions-modal-body
  .product-transactions-transaction-list {
  list-style-type: none;
  padding: 10px;

  max-height: 212px;
  min-height: 160px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.product-transactions-modal
  .product-transactions-modal-content
  .product-transactions-modal-body
  .product-transactions-transaction-list
  li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}
.product-transactions-modal
  .product-transactions-modal-content
  .product-transactions-modal-body
  .product-transactions-transaction-list
  li:hover {
  background-color: #e1e3e3;
}

.product-transactions-modal
  .product-transactions-modal-content
  .product-transactions-modal-body
  .product-transactions-transaction-list
  li:last-child {
  border-bottom: none;
}
.product-transactions-modal
  .product-transactions-modal-content
  .product-transactions-modal-body
  .product-transactions-modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.product-transactions-modal
  .product-transactions-modal-content
  .product-transactions-modal-body
  .product-transactions-selected-transaction {
  font-weight: 500;
  color: #00838f;
}
.product-transactions-modal
  .product-transactions-modal-content
  .product-transactions-modal-body
  .product-transactions-selected-transaction
  > span {
  color: #00838f;
  font-weight: 600;
}
.product-transactions-modal
  .product-transactions-modal-content
  .product-transactions-modal-body
  .product-transactions-modal-footer
  button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  background-color: #00838f;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.product-transactions-modal .close-button {
  background-color: transparent;
  border: none;
  font-size: 34px;
  color: black;
  cursor: pointer;
}

.product-transactions-modal
  .product-transactions-modal-content
  input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 5px;
}

.product-transactions-modal
  .product-transactions-modal-content
  input[type="text"]:focus {
  border: 2px solid #00838f;
}
.manage-products-profile-service-main-page-inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 20px;
  padding-top: 0px;
  padding-top: 80px;
}
.manage-services-profile-service-main-page .manage-products-profile-inputs-align{
  padding-top: 0;
  display: flex;
  justify-content: center;
  gap: 0px;
  align-items: start;
}
 .dropdown-content a{
  text-decoration: none;
  color:unset;
}
.offlinesearchbox{
  display:flex;
  flex-direction:row;
  justify-content: flex-end;
  align-items: center;
  width:100%;
  margin-top:30px;
  /* padding: 0 20px; */

}
.offlinesearchbox > input{
  width:96%;
  border-radius: 10px;
  border: 1px solid;
  padding: 5px 10px;
  border: 1px solid silver;
}
.noshowceenter{
  margin-top: 20px;
  align-items: unset;
  height: unset;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .my-order-profile-time-filter-calander-3offline{
    justify-content: space-between;
  }
  .offlinesearchbox{
    margin-top:15px;
    justify-content: space-evenly;
  }
  .my-online-ongoing-pending-profile-time-filter-calander-box {
    flex-direction: column;
    gap: 10px;

    padding: 0 20px;
  }
  .my-service-profile-ongoing-and-pending-online-selector-tab {
    justify-content: space-around;
  }
  .my-service-profile-ongoing-and-pending-online-selector-tab > span {
    font-size: 13px;
  }
  .my-service-profile-ongoing-and-pending-online-selector {
    margin-top: 0px;
  }
  .my-service-profile-ongoing-and-pending-online .order-status-modal-content {
    width: 300px;
    max-height: 250px;
    height: fit-content;
  }
  .my-service-profile-ongoing-and-pending-online
    .order-status-modal-content
    > h3 {
    font-size: 1.3rem;
    margin-top: -30px;
  }

  .my-service-profile-ongoing-and-pending-online
    .order-status-modal-content
    > .buttons-container
    > button {
    width: 150px;
    font-size: 12px;
  }
  .my-service-profile-ongoing-and-pending-online-back {
    position: absolute;
    left: 10px;
    top: 10px;
  }
  .my-order-profile-time-filter-calander-3{
display: flex;
gap: 10px;
  }
  .my-order-profile-dropdown{
    justify-self: flex-end;
  }
  .my-order-profile-time-filter-calander-3 .dropdown-content{
    top: 250px;
  }

  .manage-services-profile-service-main-page .manage-products-profile-inputs-align{
    margin-top: 20px;
    flex-direction: column;
    gap: unset;
  }
  .my-service-profile-ongoing-and-pending-online-box{
    max-height:700px;
  }
  .modifyamountsection{
    display:flex;
    flex-direction:column;
    align-items: flex-end;
    gap:10px;
  }
  .my-bills-profile-page-view-main-right-main-box-card-modify-button{
    width:100%;
  }
  .my-bills-profile-page-view-main-right-main-box-card-modify-button > button{
    width:100%;
  }
  .my-bills-profile-page-view-main-right-main-box-card-price > span {
    text-align:center;
  }
  .my-bills-profile-page-view-main-right-main-box-cardmobileview{
    padding:8px 4px 5px 10px;
  }
  .my-bills-profile-page-view-main-right-main-box-cardmobileview .my-bills-profile-page-view-main-right-main-box-card-provider{
    width:100px;
  }
  .my-bills-profile-page-view-main-right-main-box-cardmobileview .modifyamountsection,.my-bills-profile-page-view-main-right-main-box-card,.modifyamountsection{
    margin-left:5px;
  }
  .product-transactions-modal .product-transactions-modal-content {
    width: 90%;
  }
  .product-transactions-modal {
    top: 20px;
  }
  .my-service-profile-ongoing-and-pending-online .product-transactions-modal-header > h2{
    font-size: 1.5rem;
  }
  .bacckbutton{
    width: 25px;
  }
  .my-order-profile-dropdown1{
    display: inline-flex !important;
    flex-grow:3;
    align-items: flex-end;
    justify-content: flex-end;

  }
}
