.divvv{
    margin-top: 60px;
}
.divvv .profile-account-setting-profile-detials-box{
    justify-content: center;
    gap:5px;
}
.divvv .profile-account-setting-profile-detials-box > .profile-account-setting-profile-details-box-field{
    width:15%;
}
 .profile-account-setting-profile-save button{
margin-bottom: 20px;
}
.profile-account-setting-profile-save button:disabled{
background-color: gray;
cursor: not-allowed;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .divvv .profile-account-setting-profile-detials-box > .profile-account-setting-profile-details-box-field{
        width:30%;
    } 
}