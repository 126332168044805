.my-bank-account-profile-main-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 27px;
}
.profile-wallet-main-page-add-accounts {

}
.profile-wallet-main-page-add-accounts > button {
  border: none;
  width: 272px;
  height: 52px;
  background: #00838f;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  letter-spacing: -0.2px;
  color: #ffffff;
}

.profile-wallet-main-page-my-accounts {
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-columns: 1fr;
  grid-gap: 10px;
  overflow: auto;
  width: 631px;
  margin: 0 auto;
  max-height: 630px;
  padding: 1vw;
  border: 1px solid #dde0e5;
}

.my-profile-wallet-page-transfer-top-back {
  margin-left: -10px;
  margin-right: 20px;
}
.my-profile-wallet-page-transfer-top-back > img {
  cursor: pointer;
}
.my-bank-account-profile-main-page-add-account-input {
  margin-top: 30px;
  padding: 0 25%;
}

.my-bank-account-profile-main-page-add-account-submit {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.my-bank-account-profile-main-page-add-account-submit > button {
  border: none;
  width: 272px;
  height: 52px;
  background: #00838f;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  letter-spacing: -0.2px;
  color: #ffffff;
}

.my-bank-account-profile-main-page-no-accounts {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.my-bank-account-profile-main-page-no-accounts > img {
  width: 500px;
  height: 500px;
}

.my-bank-account-profile-main-page-text {
  color: #00838f;
  font-weight: 600;
  font-size: 20px;
}
.addbankheadding{
  flex-direction:column;
}
.addbankheadding .addheadingspan{
  width:max-content;
  margin: auto;
}
@media screen and (max-width: 720px) {
  .profile-wallet-main-page-add-accounts {
    margin: unset;
  }
  .profile-wallet-main-page-my-accounts {
    border: none;
    width: 100%;
  }
  .my-bank-account-profile-main-page-add-account-input {
    padding: 0 10px;
    margin-top: unset;
  }
  .my-bank-account-profile-main-page-add-account-submit > button {
    width: fit-content;
    padding: 0 20px;
    border-radius: 10px;
  }

  .my-bank-account-profile-main-page-no-accounts > img {
    width: 300px;
    height: 300px;
  }
  .my-profile-wallet-page-transfer-top-back{
    display: flex;
    width: 100%;
    margin: unset;
  }
  .bacck1{
    position: absolute;
  }
}

