.news-page-article-container
{
 width: 32vw;
 background-color: #fff;
    border-radius: 6px;
    padding: 20px 25px 20px 25px;
    height: fit-content;
}
.news-page-article-image
{
 width: 100%;
 height: 236px;
 margin-bottom: 10px;
}
.news-page-article-image>img
{
 width: 100%;
 height: 236px;
}

.news-page-article-author
{
font-weight: 500;
    font-size: 12px;
    color: #00838f;
    margin-top: 2px;
    line-height: 1.2em;
}

.news-page-article-description
{
 color: #87898f;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.2em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    margin-top: 16px;
}
.news-page-article-content
{
     font-weight: 500;
    font-size: 12px;
    line-height: 1.2rem;
    color: #00838f;
    margin-top: 8px;
    

}
.news-page-article-content>span:hover
{
 cursor: pointer;
 color: purple;
}

@media screen and (max-width: 720px) {
    .news-page-article-container{
        width: 100%;
       
    }
  
}