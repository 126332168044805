.conditions-page-cont
{
 width: 100%;
 height: 100%;
     width: 100%;
    padding: 15px;
    margin-right: auto;
    margin-left: auto;
}

.conditions-page-inner{
     /* background-color: #f1f3f6; */
    border-radius: 8px;
    border: 1px solid #00838f;
    padding: 25px;
    padding-top: 0px;
}
.conditions-page-top
{
     display: flex;
        text-align: center;
            margin-right: -25px;
    margin-left: -25px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-bottom: 20px;
    height: 50px;
}
.conditions-page-top>span,.conditions-page-top>div
{
 width: 33.333%;
 display: flex;
 align-items: center;
 justify-content: center;
 cursor: pointer;
 padding: 5px;
}
.conditions-page-top>span
{
    background-color: #00838f;
    color: white;
    border-radius: 7px 7px 0 0 ;
}
.conditions-page-top>div
{
 /* background-color: #f1f3f6; */
    color: black;
}

.fo-14
{
font-size: 14px;
}
.fo-12
{
 font-size: 12px;
}
.fw-600
{
 font-weight: 700;
}
.mt-3
{
 margin-top: 1rem!important;
}
.ml-3
{
     margin-left: 1rem!important;
}