.my-serivce-main-page-view {
  width: 100%;
  height: 950px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.agency-section-container .agency-container-heading{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.agency-section-container .agency-container-heading .search-bar-container{
  display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 17px;
    width: 50%;

    margin-right: 20px;
}
.agency-section-container .agency-container-heading .search-bar-container >input{
  padding: 4px 10px;
  width: 90%;
  height: 32px;
  border-radius: 10px;
  border: 1px solid;
}
.agency-section-container{
  display: flex;
  flex-direction: column;
  /* margin-top: 42px; */
  min-height: 100vh;
  overflow: auto;
  margin-bottom: 20px;
  padding-right: 20px;
}
.my-service-profile-page-view-main-right {
  width: 100%;
  height: 100%;
  padding-top: 100px;
  padding-left: 20px;
  padding-right: 20px;
}
.my-service-profile-page-view-main-right .my-order-profile-subservice-filter{
  width: 100%;
  margin-top: -30px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}
.my-service-profile-page-view-main-right .my-order-profile-subservice-filter>select{

  max-width: 210px;
  color: #00838f;
  font-weight: 600;
  padding: 10px;
  outline: none;
  border: none;
  white-space: nowrap;      
  overflow: hidden;         
  text-overflow: ellipsis;  

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.my-service-profile-page-view-main-right .my-order-profile-subservice-filter>select {
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    radial-gradient(#ddd 70%, transparent 72%);
  background-position:
  calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - .5em) .5em;
    background-size:
    5px 5px,
    5px 5px,
    1.5em 1.5em;
    background-repeat: no-repeat;
    padding-right: 50px;
  }

 
.my-bills-options-hidden .manage-business-selectedsubservice{
color: #029aa8e8;
background-color: #ddd;
border-radius: 10px;
padding: 10px;
width: fit-content;
margin: 0 10px;
font-size: 15px;
font-weight: 600;
margin-top: -40px;
margin-bottom: 20px;
}

.my-bills-profile-page-view-main-right-headings {
  width: 100%;
  color: rgb(13, 82, 111);
  padding: 1vw;
  border: 1px solid rgba(0, 0, 0, 0.421);
  border-radius: 16px;
  margin-bottom: 10px;
  cursor: pointer;
}
.profile-section-container2 >div .addingprovider  {
  color: #fff;
  font-weight: 700;
  font-size: 1.3rem;
  
  
  }

  /* css for form1  */
.business-reg-container,
.business-registration-form {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-top: 50px;
  padding-right: 20px;
  max-height: 900px;
  overflow: auto;
}

.heading {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 700;
  color: #00838f;
  margin-bottom: 20px;
  margin-top: -20px;
}
.form-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 32px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 1rem;
}

.form-label {
  padding: 8px 0px;
  font-size: 14px;
  font-weight: 800;
  color: #00838f;
}

.required {
  color: red;
  font-size: 14px;
  margin-left: 4px;
}

.form-input,
.form-select {
  padding: 6px 12px;
  line-height: 2.3;
  color: #495057;
  width: 100%;
  background: #f1f1f1;
  border: none;
  border-radius: 0;
  font-size: 12px;
  letter-spacing: 0.5px;
}
.form-select-h {
  height: 38px;
  outline: none;
}

.form-input:focus {
  outline: none;
}

.button-container {
  display: flex;
  justify-content: center;
}

.save-button {
  cursor: pointer;
  background: #00838f;
  color: white;
  padding: 12px 30px;
  font-size: 14px;
  border-radius: 6px;
  font-weight: bold;
  margin-right: 5px;
  border: none;
}

.business-registration-form {
  height: 100%;
  overflow: auto;
}
.business-registration-form .inner-radio-conatiner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 32px;
}

.business-registration-form .form-input-radio {
  width: 14px;
  height: 14px;
  padding: 1px;
  -webkit-appearance: none;
  appearance: none;
  background-clip: content-box;
  border: 2px solid #00838f;
  background-color: #fff;
  border-radius: 50%;
}
.business-registration-form .form-fields2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
}

.business-registration-form .inner-radio-conatiner .select-option-radio {
  display: flex;
  align-items: center;
  gap: 10px;
}
.business-registration-form
  .inner-radio-conatiner
  .select-option-radio
  > label {
  margin: 0;
}
.business-registration-form
  .inner-radio-conatiner
  .form-group
  .options-to-select {
  display: flex;
  gap: 1rem;
}

.business-registration-form input[type="radio"]:checked {
  background-color: #00838f;
}
.innerOptions{
  width: 100%;
}

.agency-section-container::-webkit-scrollbar{
  display:flex;
  width:6px;
  margin-right:5px;
}
.agency-section-container::-webkit-scrollbar-track{
  background-color: grey;
  border-radius: 3px;
  width:100%;
}
.agency-section-container::-webkit-scrollbar-thumb{
  background-color:#495057;
  border-radius: 3px;
  width:100%;
}

.agency-section-container{
  width:97%;
}

.agency-section-container .popular-container-heading{
  padding-top:5px;
}
.form-groupmodify .error{
  color: #cc0033;
  display: inline-block;
  width: 100%;
  font-size: 13px;
  line-height: 15px;
  margin: 5px 0 0;
}
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px){
  .my-serivce-main-page-view {
    flex-direction: column;
    height: auto;
    
  }
  .my-service-profile-page-view-main-right{
    padding-top: 20px;
  }
  .my-serivce-main-page-view .business-reg-container, .my-serivce-main-page-view .business-registration-form{
    margin-bottom: 20px;
  }


.my-service-profile-page-view-main-right .my-order-profile-subservice-filter {
  margin-top: -10px;

}
.myprofileMaindivSecond:has(.agency-section-container),.my-serivce-main-page-view:has(.agency-section-container){
  background-color: #F5F5F5;
}
.my-bills-options1{
  white-space:nowrap;
}
.my-bills-options1 .profile-account-setting-container-left-1{
  width: max-content;
}
}
