.manage-services-profile-service-main-page {
  overflow: auto;
  position: relative;
}
.overlay {
  /* display: flex;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  justify-content: center;
  align-items: center; */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.my-bills-profile-page-view-main-right .addingproduct {
  color: #fff;
  font-weight: 700;
  font-size: 1.3rem;
  min-height: 100vh;
}
.manage-products-profile-serivce-main-page-main-box-card {
  width: 100%;
  /* min-height: -moz-fit-content; */
  min-height: 160px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  /* gap: 30px; */
  margin: 10px auto;
  padding: 1vw;
}
.manage-products-profile-serivce-main-page-main-box-card-image {
  width: calc(100% / 5);
}
/* best way for image how to write css for image */
.manage-products-profile-serivce-main-page-main-box-card-image > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 150px;
  max-height: 150px;
}
.manage-products-profile-serivce-main-page-main-box-card-names {
  padding-left: 10px;
  /* min-width: 100px; */
  flex-grow: 0.5;
}
.manage-products-profile-serivce-main-page-main-box-card-names > div {
  font-size: 15px;
  font-weight: 700;
  color: rgb(68, 17, 119);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.manage-products-profile-serivce-main-page-main-box-card-names > span {
  font-size: 15px;
  font-weight: 600;
  max-width: 90px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.manage-products-profile-serivce-main-page-main-box-card-names > section {
  font-size: 13px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.manage-products-profile-serivce-main-page-main-box-card-quantity {
  height: 100%;
  padding-left: 10px;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  /* overflow: hidden; */
  text-overflow: ellipsis;
}
.manage-products-profile-serivce-main-page-main-box-card-quantity > div,.offline-profile-serivce-main-page-main-box-card-quantity >div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.manage-products-profile-serivce-main-page-main-box-card-quantity .service-color-left-view{
justify-content: left;
gap: 23px;
position: relative;
}
.manage-products-profile-serivce-main-page-main-box-card-quantity .service-color-left-view  span:nth-of-type(2){
  margin-left: -25px;
  cursor: pointer;
}

.manage-products-profile-serivce-main-page-main-box-card-quantity div .service-color-choices,.offline-profile-serivce-main-page-main-box-card-quantity div .service-color-choices{
  display: flex;
  flex-direction: column;
  gap: 5px;

}
.switch1outofstock{
  position: relative;
  display: inline-block;
  width: 60px !important;
  height: 25px !important;
}
.switch1outofstock > .slider:before{
  height: 61% !important;
  width: 19px !important;
}
.manage-products-profile-serivce-main-page-main-box-card-quantity div .service-color-choice,.offline-profile-serivce-main-page-main-box-card-quantity div .service-color-choice{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid gray;

  margin: 0 10px;


}
.manage-products-profile-serivce-main-page-main-box-card-quantity div  .additional-choices-container, .offline-profile-serivce-main-page-main-box-card-quantity div .additional-choices-container {
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 3px;
  flex-direction: column;
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  top: 20px;
right: 76px;
  width: 25px;
  padding: 5px 1px;
  overflow-y: auto;
  max-height: 87px;
  position: absolute;
}
.manage-products-profile-serivce-main-page-main-box-card-quantity div  .additional-choice,.offline-profile-serivce-main-page-main-box-card-quantity div .additional-choice {
  width: 20px;
  height: 20px;

  border-radius: 50%;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.manage-products-profile-serivce-main-page-main-box-card-quantity div  .additional-choice ,.offline-profile-serivce-main-page-main-box-card-quantity div .additional-choice{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid gray;

  margin: 0 10px;
  background-color: red;
  cursor: pointer;
}

.manage-products-profile-serivce-main-page-main-box-card-quantity
  > div
  > select {
  /* flex-grow: 1; */
  display: flex;
  justify-content: center;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.556);
  border: 1px solid rgba(0, 0, 0, 0.516);
  padding: 0 1.2rem;
  width: 7rem;
}
.manage-products-profile-serivce-main-page-main-box-card-quantity
  > div
  > select
  > option {
  display: flex;
  justify-content: center;
  width:6rem;
}

.manage-products-profile-serivce-main-page-main-box-card-price {
  width: calc(100% / 7);
  padding-left: 35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.manage-products-profile-serivce-main-page-main-box-card-price > div {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  /* line-height: 16px; */
  /* text-align: right; */
  color: #ff6868;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.manage-products-profile-serivce-main-page-main-box-card-price > span {
  text-decoration: line-through;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  /* text-align: right; */
  color: rgba(0, 0, 0, 0.535);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.manage-products-profile-serivce-main-page-main-box-card-price > section {
  font-family: "Poppins";
  font-style: normal;
  color: green;
  font-weight: 600;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.manage-products-profile-serivce-main-page-main-box-card-buttons {
  width: calc(100% / 10);
  font-size: 17px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: rgba(0, 0, 0, 0.508);
}

.manage-products-profile-serivce-main-page-main-box-card-buttons > div {
  cursor: pointer;
}

.allfetched {
  width: inherit;
  text-align: center;
  color: #00838f;
  font-size: 24px;
}

.addproduct-imageContainer .imageWrapper {
  position: relative;
  height: 200px;
  min-width: 221px;
  padding: 20px;
}
.addproduct-imageContainer .imageWrapper .crossIcon-image {
  color: #00838f;
  font-size: xx-large;
  right: 20px;
  top: -10px;
  position: absolute;
  cursor: pointer;
}
.manage-services-profile-service-main-page .confirmation-modal,
.manage-services-profile-service-main-page .quantity-selection-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.add-service-provider-image-options-box  .image-selection-modal ,.manageProductActions .view-catalog-modal{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  width: 100%;
  height: 100%;
}

.add-service-provider-image-options-box  .image-selection-modal .image-selection-modal-content{
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 700px;
  max-height: 440px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  overflow: scroll;
}
.add-service-provider-image-options-box  .image-selection-modal .image-selection-modal-content .slider-image{
display: flex;
padding-top: 20px;
width: 100%;
overflow-x: scroll;
margin-bottom:30px;
}
/* .slider-image::-webkit-scrollbar{
  display:unset !important
  margin-bottom: 30px;
} */
.add-service-provider-image-options-box  .image-selection-modal .image-selection-modal-content .image-modal-footer{
  display: flex;
  height: 40px;
  width: 100%;
  justify-content: flex-end;
  align-items: end;
}
.add-service-provider-image-options-box  .image-selection-modal .image-selection-modal-content .image-modal-footer> button{
  background-color: #00838f;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
  border: none;
  font-weight: 700;
}
.manage-services-profile-service-main-page
  .quantity-selection-modal
  .quantity-selection-modal-content ,.manageProductActions .view-catalog-modal .view-catalog-modal-content{
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;

  max-height: 400px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
}
.manage-services-profile-service-main-page
  .confirmation-modal
  .confirmation-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  max-height: 240px;
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.manage-services-profile-service-main-page
  .confirmation-modal
  .confirmation-modal-content h3 >span{
    color: red;
  }
.manage-services-profile-service-main-page
  .confirmation-modal
  .confirmation-modal-content
  .confirmation-modal-buttons {
  display: flex;
  column-gap: 10px;
}

.manageProductActions .view-catalog-modal .view-catalog-modal-content .modal-catlog-conatiner{
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
}

.manageProductActions .view-catalog-modal .view-catalog-modal-content .modal-catlog-conatiner .service-dropdown,.manageProductActions .view-catalog-modal .view-catalog-modal-content .modal-catlog-conatiner .product-type-dropdown{
  width: 100%;
  display: flex;
justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  color: #00838f;
  font-size: 18px;
  font-weight: 600;
}
.manageProductActions .view-catalog-modal .view-catalog-modal-content .modal-catlog-conatiner .service-dropdown >select, .manageProductActions .view-catalog-modal .view-catalog-modal-content .modal-catlog-conatiner .product-type-dropdown >select{
  width: 60%;
  font-weight: 600;
  border: 2px solid #b9bebfb8;
  border-radius: 10px;
  font-size: 14px;
  color: #201f1f7f;
}

.manageProductActions .view-catalog-modal .view-catalog-modal-content .modal-catlog-conatiner .or{
  margin-top: -17px;
  color: #00838f;
  font-weight: 800;
}
.manage-services-profile-service-main-page
  .confirmation-modal
  .confirmation-modal-content
  .confirmation-modal-buttons
  > button,
.manage-services-profile-service-main-page
  .quantity-selection-modal
  .quantity-selection-modal-buttons
  > button ,.manageProductActions .view-catalog-modal .view-catalog-modal-content .view-catalog-modal-buttons > button{
  width: 100px;
  height: 40px;
  background: #00838f;
  border-radius: 10px;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.manage-services-profile-service-main-page .quantity-selection-modal .quantity-selection-modal-colors-container .quantity-selection-modal-colors >label{
  display: flex;
  gap: 10px;


}
.manage-services-profile-service-main-page .quantity-selection-modal .quantity-selection-modal-colors-container .quantity-selection-modal-colors{
  display: flex;
  flex-direction: column;
}
.manage-services-profile-service-main-page
  .quantity-selection-modal
  .quantity-selection-modal-buttons ,.manageProductActions .view-catalog-modal .view-catalog-modal-content .view-catalog-modal-buttons{
  display: flex;
  column-gap: 10px;
  width: 100%;
  justify-content: end;
}
.manage-services-profile-service-main-page
  .quantity-selection-modal
  .quantity-selection-modal-checkboxes {
  display: flex;
  flex-direction: column;
margin-bottom: 10px;
  padding: 10px;
  max-height: 332px;
  min-height: 160px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.manage-services-profile-service-main-page
  .quantity-selection-modal
  .quantity-selection-modal-checkboxes
  > label:not(:last-child) {
    padding: 10px;
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #ccc;
}
.manageProductActions {
  display: flex;
  /* flex-direction: row-reverse; */
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.my-service-profile-back-and-count {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.manageProductSearchBox {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.manageProductSearchBox > input {
  width: 90%;
  padding: 4px 10px;
  border-radius: 10px;
  border: 1px solid;
}
.manageProductSearchBox > .sendIcon {
  font-size: 22px;
}
.selectAllInput {
  display: flex;
  gap: 10px;
  padding: 10px;

}
.firstSecion {
  width: 70%;
}
.secondSection {
  width: 100%;
}
.add-service-subsection label {
  margin-bottom: unset;
  font-size: 14px;

}

.firstSecion .add-new-product-inputs{
display: flex;
flex-direction: row;
justify-content: space-between;
width: 100%;
align-items: center;
}
.firstSecion .add-new-product-inputs-other{
  display: flex;
flex-direction: row;
justify-content: flex-end;
width: 100%;

margin: 10px;

padding-right:15px ;
align-items: center;
padding-bottom: 10px;
}
.firstSecion .add-new-product-inputs>div{
  color: #201f1fcb;
}
.firstSecion .add-new-product-inputs select,.firstSecion .add-new-product-inputs textarea,.firstSecion .add-new-product-inputs-other input{
  margin-bottom: 0px;
  width: 50%;
  font-weight: 600;
  border: 2px solid #b9bebfb8;
  font-size: 14px;
  /* color: #404040; */
}
.add-service-provider-profile-right-inputs select{
  margin-bottom:0;
}
[tabindex] {
  outline: none !important;
}

.firstSecion .add-new-product-inputs select:focus,.firstSecion .add-new-product-inputs textarea:focus,.firstSecion .add-new-product-inputs-other input:focus{
  outline: 2px solid #00838f;
  border: none;
  font-weight: 600;
}
.secondSection .greenText-count-products-added{
  color: #201f1f9e;
  font-weight: 700;
}
.secondSection .greenText-count-products-added span{
  color: #00838f;
}
.secondSection table td label .plusIcon{
  color: #fff;
  background-color: #00838f;
  padding-left: 10px;
  width: 30%;
    font-size: 32px;
    
}
.secondSection table td label{
  background-color: #00838f;
  align-items: center;
  color: #fff;
  border-radius: 10px;
  overflow: hidden;
  font-weight: 500;
  padding-right: 10px;
  display: flex;
  gap: 10px;
}
.add-service-provider-image-options-box {
  height: fit-content;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 10px;
  gap: 20px;
}

.add-service-provider-image-options-box .table {

  border-collapse: collapse;
  margin-top: 20px;
  max-height: 300px;
  overflow-y: auto; /* Set overflow-y to scroll to show vertical scrollbar */

}


.manage-services-profile-service-main-page
  .quantity-selection-modal
  .quantity-selection-modal-checkboxes::-webkit-scrollbar, .add-service-provider-image-options-box .table::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
  display: flex;
}

.manage-services-profile-service-main-page
  .quantity-selection-modal
  .quantity-selection-modal-checkboxes::-webkit-scrollbar-thumb,.add-service-provider-image-options-box .table::-webkit-scrollbar-thumb {
  background-color: gray; /* Set the color of the scrollbar thumb */
  border-radius: 4px; /* Optional: Set border-radius for a rounded thumb */
}

.manage-services-profile-service-main-page
  .quantity-selection-modal
  .quantity-selection-modal-checkboxes::-webkit-scrollbar-track,.add-service-provider-image-options-box .table::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Optional: Set background color for the scrollbar track */
}





.add-service-provider-image-options-box .table .table-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.add-service-provider-image-options-box .table table .table-header thead,.add-service-provider-image-options-box .table table .table-header thead tr{
  width: 100%;
}

.add-service-provider-image-options-box .table table .table-header thead tr .table-header {
  min-width: 125px;
  max-width: 125px;
}
.add-service-provider-image-options-box .table table tbody tr .o-image{
min-width: 200px;
max-width:200px;
display: flex;
align-items: center;
color: #00838f;
font-weight: 600;
}
.add-service-provider-image-options-box .table table tbody tr .o-image button{
  color: #00838f;
  background: transparent;
  border: none;
  outline: none;
  padding-left: 10px;
  font-weight: 700;
  padding-right: 10px;
}

.add-service-provider-image-options-box .table table .table-header thead tr .o-sr,.add-service-provider-image-options-box .table table tbody tr .o-sr{
min-width: 70px;
max-width: 70px;
}
.add-service-provider-image-options-box .table table tbody tr .o-delete{
  min-width: 70px;
max-width: 70px;
color: #00838f;
}
.add-service-provider-image-options-box .table table tbody tr td >input,.add-service-provider-image-options-box .table table tbody tr td span input,.add-service-provider-image-options-box .table table tbody tr td >input:focus{
  width: 100%;
  border: none;
  outline: none;
}
.add-service-provider-image-options-box .table table .table-header thead tr .o-image{
width: 100%;
}
.add-service-provider-image-options-box  .table th, .table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}
.add-service-provider-image-options-box .table table tbody ,.add-service-provider-image-options-box .table table tbody tr{
  width: 100%;

}
.add-service-provider-image-options-box .table table tbody tr td{
  min-width: 125px;
  max-width: 125px;
  vertical-align: middle;
  color: #201f1f7f;
  font-weight: 600;
}
.add-service-provider-image-options-box .table table tbody tr td span{
  width: 100%;
  display: flex;
gap: 10px;

}
.add-service-provider-image-options-box .table th {
  background-color: #d3cfcfc5;
  color:#605b5bcb;
  
}

.add-service-subsection {
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  justify-content:center;
}



.quantityInput,
.quantityDropdown {

  width: 50%;

  height: 40px;
  border: none;
  border-radius: 20px;
  background: rgb(227, 227, 227);
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
cursor: pointer;
}
.extraDropdown,
.colorDropdown,
.moreColorDropdown {
  padding: 6px 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}
.extraDropdown {
  grid-column: 2/4;
  width: 40%;
  height: 40px;
}
.colorDropdown {
  grid-row: 2/3;

  width: 27%;
}
.moreColorDropdown {
  grid-column: 3/4;
  width: 15%;

}
.more-color-dropdown-data{
  position: absolute;
  right: 35px;
  top: 70px;
}
.more-quantity-dropdown-data {
  position: absolute;
  right: 50%;
  top: 70px;
}
.quantityInput > input {
  width: 80%;
  background: rgb(227, 227, 227);
  border: none;
  text-align: center;
  font-size: 13px;
}
.quantityInput > span {
  border-left: 1px solid white;
  padding: 6px;
  font-size: 13px;
  text-align: center;
  color: #00838f;
  margin-bottom: px;
}

.dropdown-options-product{
  display: flex;
  flex-direction: column;
  min-width: 200px;
  gap: 7px;
  background-color: #fff;
  padding: 20px 10px;
  color: #00838f;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  /* z-index: 89; */

}
.dropdown-options-product .quantity-option-other,.color-option-other{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.dropdown-options-product .quantity-option-other input,.dropdown-options-product .quantity-option-other select,.color-option-other input{
  width: 70%;
  font-weight: 600;
  padding: 4px;
  color: #201f1f9e;
  border: 2px solid #b9bebfb8;
  border-radius: 10px;
  font-size: 14px;
}
.dropdown-options-product .quantity-option-other button,.color-option-other button{
  border: none;
  background: #00838f;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  width: 100px;
}
.color-option,.quantity-option {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 2px 10px;
}
.color-option .color-box {
  width: 17px;
  height: 17px;
  margin-right: 10px;


}
.color-option:hover,.quantity-option:hover {
  background-color: #c1caca91;
  border-radius: 10px;

padding: 2px 8px;

}

.color-option > * {
  width: 33%;
}
.color-option .edit-color{
  width: 20%;
}
.dropdown-options-product .no-selected-color{
  width: 100%;
  display: flex;
cursor: pointer;
align-items: center;
justify-content: space-between;
width: 100%;
/* padding: 2px 10px; */
padding: 2px 14px 2px 10.5px 
}
.no-selected-color input{
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.color-option input[type="checkbox"],.quantity-option  input[type="checkbox"] { 
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.color-option input[type="checkbox"]:checked ,.quantity-option  input[type="checkbox"]:checked {
  accent-color: #00838f;

}

.ouutofstockbutton{
    height: 45px;
    background: #00838f;
    border: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 550;
    font-size: 15px;
    line-height: 24px;
    color: #ffffff;
    border-radius:10px !important;
    padding: 10px;
    cursor: pointer;
}
.add-service-provider-profile-right-inputs {
  grid-column: 1/2;
  width: 100%;
}
.my-job-post-service-profile-page-view-main-2 > .add-service-provider-profile-right-inputs{
  margin-bottom:34px;
  width:50%;
  padding-left: 50px;
}
.my-job-post-service-profile-page-view-main-2 > .add-service-provider-profile-right-inputs>select{
  margin-bottom:-26px;
  width:90%;
}
.my-job-post-service-profile-page-view-main-2 > .add-service-provider-profile-right-inputs> input{
  width:90%;
}
.my-service-profile-ongoing-and-pending-online-selector-tab + .my-job-post-service-profile-page-view-main-2{
  overflow: scroll;
  flex-grow:unset;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.add-service-provider-profile-right-inputs > label {
  border-radius: 12px;
  background: rgb(227, 227, 227);
  padding: 8px 10px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.plusIcon {
  color: #00838f;
  width: 35%;
  font-size: 36px;
}
.add-service-provider-profile-right-inputs-submit {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  position: relative;
}

.add-service-provider-profile-right-inputs-submit > label {
  border-radius: 12px;
  background: rgb(227, 227, 227);
  padding: 8px 10px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.coloroutof{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid gray;
  margin: 0 10px;
}
.outssstatus{
  width:100%;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.radiobuttons {
  width: 100%;
}
.input {
  display: flex;
  gap: 10px;
  font-size: 20px;
  color: gray;
}
.priceInfoRow {
  display: flex;
  width: 100%;
  border-radius: 12px;
  height: 45px;
  padding: 0 20px;
  height: 40px;
  border: none;
  border-radius: 20px;
  border: 2px solid rgb(227, 227, 227);
  justify-content: space-between;
  align-items: center;
}

.priceInput{
  width: 60%;
  border: none;
  outline: none;

}
.discountInput{
  width: 60%;
  border: none;
  outline: none;
  border-left: 2px solid rgb(227,227,227);
  text-align: center;
}

.slider-image::-webkit-scrollbar{
  width: 8px;
  display: flex;
  height: 2px;
  margin-bottom: 30px;
}
.slider-image::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 3px;
}
.slider-image::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.manage-services-profile-service-main-page-main-box::-webkit-scrollbar {
  width: 5px;
  display: flex;
  /* height: 7px; */
  margin-bottom: 30px;
}
.manage-services-profile-service-main-page-main-box::-webkit-scrollbar-thumb {
background-color: gray;
border-radius: 3px;
}
.manage-services-profile-service-main-page-main-box::-webkit-scrollbar-track {
background-color: #f1f1f1;
}
.add-service-provider-profile-right-inputs:has(.my-profile-my-bussiness-account-store-details){
  margin-bottom:10px;
}
.noresult {
  display: flex;
  justify-content: center;
  color: black;
  font-weight: 600;
  font-size: larger;
  font-family: "Poppins";
}
.my-job-post-service-profile-page-view-main-2 > .my-job-post-service-profile-page-image,.my-job-post-service-profile-page-view-main-2 > .add-service-provider-profile-right-inputs{
  width:50%;
  padding-left: 50px;
}
.my-job-post-service-profile-page-view-main-2 > .my-job-post-service-profile-page-image{
  margin-left:0px;
}
.my-job-post-service-profile-page-view-main-2 > .add-service-provider-profile-right-inputs> textarea{
  width:90%;
}
.useravailabilityinput{
  position: relative;
}
.useravailabilityinput .availability-message{
  position: absolute;
  margin-top:0px;
  top:0px;
    display: inline-block;
    /* width: 100%; */
    font-size: 13px;
    line-height: 15px;
    margin: 5px 0 0;
    font-weight:400;
}
.availability-message .usernameavailable{
  color:green;
}
.availability-message .usernamenotavailable{
  color:red;
}
.manage-services-profile-service-main-page .quantity-selection-modal .quantity-selection-modal-checkboxes11 > label:not(:last-child) {
  border-bottom:unset;
  display: flex;
  align-items: center;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .outofstockblock{
    width:100%;
    display:flex;
    justify-content:center;
  }
  
  .my-job-post-service-profile-page-view-main-2 > .add-service-provider-profile-right-inputs> textarea{
    width:100%;
  }
  .my-job-post-service-profile-page-view-main-2 > .my-job-post-service-profile-page-image {
    margin:auto;
  }
  .mobile-color-option-other{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.448);
    z-index: 9999;
    width: 100%;
    height: 100%;
  }
  .color-option-other{
    /* position: absolute;
    top:90%; */
    margin-left:40px;
    background-color:  #f2f2f2;
    align-items: center;
    border-radius:10px;
    padding:10px;
    margin-bottom: 10px;
  }
  .my-service-profile-ongoing-and-pending-online-selector-tab + .my-job-post-service-profile-page-view-main-2{
    justify-content: unset;
  }
  .my-job-post-service-profile-page-view-main-2[jobsections="yes"]{
    justify-content: space-around;
  }
  .my-job-post-service-profile-page-view-main-2 > .add-service-provider-profile-right-inputs>select{
    margin-bottom:0px;
    width:100%;
  }
  .my-job-post-service-profile-page-view-main-2 > .add-service-provider-profile-right-inputs {
    margin-bottom: 10px;
    width:100%;
    padding-left: 0px;
}
.my-job-post-service-profile-page-view-main-2 > .add-service-provider-profile-right-inputs> input{
  width:100%;
}
.my-job-post-service-profile-page-view-main-2 > .add-service-provider-profile-right-inputs > span{
  white-space:nowrap;
}
  .more-color-dropdown-data{
    /* flex-direction:row; */
    right:0px;
    width:65%;
    overflow-x:scroll;
    padding: 8px;
  }
  .color-option-mobile-parent{
    margin-left: 10px;
    height: 50px;
    overflow-x: auto;
    overflow-y: visible;
    display: flex;
    flex-direction: row;
    gap: 25px;
    padding: 2px 1.5px 2px 1.5px;
  }
  .color-option-mobile-parent::-webkit-scrollbar {
    /* width: 8px; */
    display: flex;
    height: 2px;
    margin-bottom: 30px;
    width:2px;
    margin-left: 10px;
}
.color-option-mobile-parent::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 3px;
}
.color-option-mobile-parent::-webkit-scrollbar-track {
  background-color: #f1f1f1;
border-radius: 3px;
}
.color-option-mobile-parent > .color-option {
  /* padding:20px 7px 20px 5px; */
  padding:10px 6px 10px 2px;
}
  .color-option span,.color-box span{
    width:auto;
    text-wrap: nowrap;
    margin-right:12px;
  }
  .color-option{
    align-items:center;
    white-space: nowrap;
  }
  .color-option-mobile-parent .color-box{
    min-width: 15px;
    max-width:16px;
  }
  .manage-products-profile-serivce-main-page-main-box-card {
    flex-wrap: wrap;
    justify-content: space-around;
    min-height: auto;
  }

  .manage-products-profile-serivce-main-page-main-box-card-names {
    width: 170px;
  }
  .manage-products-profile-serivce-main-page-main-box-card-quantity {
    gap: 10px;
    height: fit-content;
  }
  .manage-products-profile-serivce-main-page-main-box-card-price,
  .manage-products-profile-serivce-main-page-main-box-card-buttons {
    width: auto;
    gap: 10px;
    padding-left: 0px;
  }
  .manage-products-profile-serivce-main-page-main-box-card-names > span {
    max-width: 140px;
  }

  .manage-products-profile-service-main-page-inputs .addproduct-imageContainer {
    margin-left: unset;
    margin-bottom: unset;
    width: 100%;
    height: fit-content;
    padding: 0;
    gap: 30px;
    grid-column: 1/4;
  }
  .manage-products-profile-serivce-main-page-main-box-card-image {
    width: 70px;
    height: 80px;
  }
  .manage-products-profile-serivce-main-page-main-box-card-image > img {
    object-fit: unset;
  }
  .manage-products-profile-service-main-page-inputs
    .addproduct-imageContainer
    .imageWrapper {
    min-width: 191px;
  }
  .manage-products-profile-service-main-page-inputs
    .addproduct-imageContainer
    .imageWrapper
    > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .manage-products-profile-service-main-page-inputs
    .addproduct-imageContainer
    .imageWrapper
    .crossIcon {
    top: 10px;
    right: 10px;
  }
  .overlay {
    height: 100%;
    padding: 0 20px;
  }
  .overlay > .container {
    width: 100%;
  }
  .manageProductSearchBox {
    padding: 0 10px;
  }
  .firstSecion {
    width: 100%;
  }
  .secondSection{
    width: 100%;
  }
  .manage-services-profile-service-main-page {
    position: unset;
  }
  label {
    margin-bottom: unset;
  }

  .add-service-provider-image-options-box {

    height: fit-content;
    border-radius: 12px;
    display: flex;
    flex-wrap: wrap;
    padding: 15px 10px;
    gap: 20px;
    width: 100%;
  }
  .add-service-subsection {
    display: flex;
    column-gap: 10px;
    row-gap: 10px;
    width: 100%;
  }
  .quantityInput,
  .quantityDropdown {
    border: none;
    border-radius: 20px;
    background: rgb(227, 227, 227);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px !important;
    min-width: unset;
  }
  .extraDropdown,
  .colorDropdown,
  .moreColorDropdown {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }
  .extraDropdown {
    grid-column: 2/4;
  }
  .colorDropdown {
    grid-row: 2/3;
  }
  .moreColorDropdown {
    grid-column: 3/4;

    place-items: center;
  }
  .more-quantity-dropdown-data {
    left: 50%;
    min-width: 150px;
  }
  .quantityInput > input {
    width: 80%;
    background: rgb(227, 227, 227);
    border: none;
    text-align: center;
    font-size: 13px;
  }
  .quantityInput > span {
    border-left: 1px solid white;
    padding: 6px;
    font-size: 13px;
    text-align: center;
    color: #00838f;
    margin-bottom: px;
  }
  .add-service-provider-profile-right-inputs {
    grid-column: 1/2;

  }
  .firstSecion .add-new-product-inputs{
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-left: 0px;
    margin-top:20px !important;
  }
  .firstSecion .add-new-product-inputs select,.firstSecion .add-new-product-inputs textarea, .firstSecion .add-new-product-inputs-other input{
    width: 100%;
    /* color: #404040; */
  }
  .add-service-provider-profile-right-inputs > label {
    border-radius: 12px;
    background: rgb(227, 227, 227);
    padding: 8px 10px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
  .plusIcon {
    color: #00838f;
    width: 30px;
  }
  .priceInfoRow{
    padding: 0 10px;
  }
  .discountInput,.priceInput{
    width: 30%;
  }
  .addproduct-imageContainer .imageWrapper .crossIcon-image{
    top: unset;
    right: -10px;
  }

}

.selection12{
  display:none;
}

.color-option-parent{
  height: 130px;
  overflow-y: auto;
  gap: 7px;
  display: flex;
  flex-direction: column;
}

.color-option-parent::-webkit-scrollbar{
  display: flex !important;
  width:5px;
}
.color-option-parent::-webkit-scrollbar-track{
  width:100%;
  background-color: rgba(0, 0, 0, 0.24);
  border-radius: 10px;
}
.color-option-parent::-webkit-scrollbar-thumb{
  background-color:rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  width: 100%;
}
.outofstocklabel{
  padding:0px !important;
}
.quantity-selection-modal-checkboxes:has(.outofstocklabel){
  gap:10px;
}

.checkboxforoutofstock:checked + .slider:before {
  transform: translateX(170%);
}

