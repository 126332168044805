.cart-main-page-2 {
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  overflow: auto;
}
.flippedcart::-webkit-scrollbar {
  height: 7px;
  margin-top: 10px;
  /* margin-right:20px; */
  display: flex;
  width: 4px;
  position: absolute;
  left: 0;
}
.flippedcart::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 5px;
}
.flippedcart::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}
.cart-main-page-item-row-2 {
  /*   
 position: sticky;
 top: 85px; 
 align-self: flex-start; */
  width: 55%;
  margin-left: 8.33%;
  margin-right: 0.5%;
  padding: 1vw;
  max-height: calc(100vh + 240px);

  overflow: auto;
  /* direction: rtl; */
  /* margin-bottom: 1px; */
}
.flippedcart {
  /* width: 56%; */
  /* margin-left: 8.33%; */
  /* margin-right: 0.5%; */
  padding: 1vw;
  max-height: calc(100vh + 240px);
  overflow-y: auto;
  overflow-x: visible;
  direction: rtl;
  margin-top: 10px;
  margin-left: 25px;
}
.flippedcart .cart-main-page-item {
  width: 100%;
  /* margin-left: unset ; */
  /* padding-left: 8.33%; */
  margin-top: unset;
  direction: ltr;
  padding: 1vw;
  /* padding:0px ; */
}

.cart-main-page-item-row-delivery-address-2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 4px;
  padding: 16px 0px;
  border-bottom: 1px solid #eaeaec;
  height: 92px;
  background-color: #fff;
  margin-bottom: 12px;
  padding: 1vw;

  /* position: -webkit-sticky; 
  position: sticky;
  top: 0;
  align-self: flex-start;
  z-index :10; */
}
.cart-main-page-item-row-delivery-address-2 .cart-main-page-item-row-delivery-address-2-butoon-container button {
  height: 38px;
  border: 2px solid #00838f;
  padding-right: 20px;
  padding-left: 20px;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  color: #00838f;
  text-align: center;
  vertical-align: middle;
  background-color: transparent;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.cart-main-page-item-row-delivery-address-2-butoon-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.cart-main-page-item-row-delivery-address-2 > button:hover {
  color: #bec2d1;
}

.cart-main-page-item-row-delivery-address-3 {
  font-size: 11px;
  line-height: 15px;
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* margin-left: -20px; */
}

.cart-main-page-amount-row-2 {
  width: 45%;
  padding: 2rem 3rem;
  min-height: calc(100vh - 85px);
  /* margin-bottom: 100px; */
}
.cart-main-page-amount-row-heading-2 {
  /* font-family: "Raleway", sans-serif; */
  padding-top: 1vw;
  font-size: 14px;
  font-weight: 700;
  color: #535766;
  text-transform: uppercase;
  margin-bottom: 12px;
  text-decoration: none;
}
.cart-main-page-amount-row-bag-total-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  margin-left: 5px;
}
.cart-main-page-amount-row-bag-total-2 > div {
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  color: #535766;
}
.cart-main-page-amount-row-bag-total-2 > span {
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  color: #535766;
}
.cart-main-page-amount-row-bag-discount-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  margin-left: 5px;
}
.cart-main-page-amount-row-bag-discount-2 > div {
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  color: #535766;
}
.cart-main-page-amount-row-bag-discount-2 > span {
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  color: #535766;
}
.cart-main-page-amount-row-coupon-discount-2 {
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  align-items: center;
  margin-bottom: 12px;
}
.cart-main-page-amount-row-coupon-discount-2 > div {
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  color: #535766;
}
.cart-main-page-amount-row-coupon-discount-2 > span {
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  color: #535766;
}
.cart-main-page-amount-row-final-price-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eaeaec;
}
.cart-main-page-amount-row-final-price-2 > div {
  font-weight: 600;
  font-size: 15px;
  padding-top: 16px;

  color: #3e4152;
  line-height: 16px;
}
.cart-main-page-amount-row-final-price-2 > span {
  font-weight: 600;
  font-size: 15px;
  padding-top: 16px;

  color: #3e4152;
  line-height: 16px;
}
.cart-main-page-amount-row-button-2 {
  margin-top: 3rem;
  width: 100%;
  cursor: pointer;
  background: #00838f;
  color: white;
  /* padding: 11px 30px; */
  font-size: 14px;
  border-radius: 6px;
  font-weight: bold;
  margin-right: 5px;
  margin-bottom: 100px;
}
.cart-main-page-amount-row-button-2 > a > button {
  width: 100%;
  background: #00838f;
  color: white;
  /* padding: 10px 30px; */
  font-size: 14px;
  border-radius: 6px;
  font-weight: bold;
  border: none;
  letter-spacing: 1px;
  padding: 11px 30px;
  /* margin-bottom: 100px; */
}
.cart-main-page-amount-row-button-2 > button {
  width: 100%;
  background: #00838f;
  color: white;
  /* padding: 10px 30px; */
  font-size: 14px;
  border-radius: 6px;
  font-weight: bold;
  border: none;
  letter-spacing: 1px;
  /* margin-bottom: 100px; */
}

.cart-main-page-amount-row-coupon-heading-2 {
  font-size: 14px;
  font-weight: 700;
  color: #535766;
  text-transform: uppercase;
  margin-bottom: 12px;
  text-decoration: none;
}
.cart-main-page-amount-row-coupon-2 {
  padding-bottom: 16px;
  border-bottom: 1px solid #e6e6e9;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 59px;
}
.cart-main-page-amount-row-coupon-2 > div > img {
  width: 18px;
  height: 18px;
  margin-right: 3vw;
}
.cart-main-page-amount-row-coupon-2 > div > span {
  padding-right: 15px;
  padding-left: 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding: 7px 0;
}
.cart-main-page-amount-row-coupon-2 > button {
  height: 90%;
  border: 2px solid #00838f;
  padding-right: 22px;
  padding-left: 22px;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  color: #00838f;
  text-align: center;
  vertical-align: middle;
  background-color: transparent;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.cart-main-page-amount-row-coupon-2 > button:hover {
  color: #e0dede;
}

.cart-main-page-amount-row-delivery-day-heading-2 {
  font-size: 14px;
  font-weight: 700;
  color: #535766;
  text-transform: uppercase;
  margin-bottom: 12px;
  text-decoration: none;
  margin-top: 50px;
}

.cart-main-page-amount-row-delivery-day-box-2 {
  display: flex;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}
.cart-main-page-amount-row-delivery-day-box-2 > button {
  height: 38px;
  border: 2px solid #00838f;
  padding-right: 20px;
  padding-left: 20px;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  color: #00838f;
  text-align: center;
  vertical-align: middle;
  background-color: transparent;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 5px;
}
.cart-main-page-amount-row-delivery-day-box-2-selected {
  background-color: #00838f !important;
  color: #fff !important;
}

.cart-main-page-amount-row-delivery-day-box-3 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.cart-main-page-amount-row-delivery-day-box-3 > button {
  height: 38px;
  border: 2px solid #00838f;
  padding-right: 20px;
  padding-left: 20px;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  color: #00838f;
  text-align: center;
  vertical-align: middle;
  background-color: transparent;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 5px;
}
.cart-main-page-amount-row-delivery-day-box-3-selected {
  background-color: #00838f !important;
  color: #fff !important;
}

.cart-main-page-amount-row-payment-mode-2 > form {
}
.cart-main-page-amount-row-payment-mode-2 > form > input[type="radio"] {
  -webkit-appearance: none;
  padding-top: 3px;
  -moz-appearance: none;
  appearance: none;
  /* display: inline-block; */
  width: 14px;
  height: 14px;
  padding: 1px;
  background-clip: content-box;
  border: 2px solid #00838f;
  background-color: #fff;
  border-radius: 50%;
}
.cart-main-page-amount-row-payment-mode-2 > form > input[type="radio"]:checked {
  background-color: #00838f;
}

.cart-main-page-amount-row-payment-mode-2 > form > label {
  margin-top: -7px;
  font-weight: 700;
  font-size: 15px;
  color: #00838f;
  margin-left: 0.5rem !important;
  padding-bottom: 2px;
}
.cart-main-page-amount-row-payment-mode-2
  > form
  .payment-mode-is-wallet-payment-balance {
  color: #39a0aa;
  padding-left: 35px;
  margin-top: -10px;
  margin-bottom: -17px;
  font-weight: 500;
  font-size: 14px;
}

/* recharge page */
.cart-main-page-item-row-2-recharge {
  background-color: #fefefe;
  position: relative;
  padding: 10px;
  border-bottom: 1px solid #eaeaec;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.cart-main-page-item-row-2-recharge-col1 {
  height: 200px;
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cart-main-page-item-row-2-recharge-col1 > div {
  height: 100%;
  width: 230px;
  border-radius: 100px;
  overflow: hidden;
}
.cart-main-page-item-row-2-recharge-col1 > div > img {
  height: 100%;
  width: 230px;
}

.cart-main-page-item-row-2-recharge-col2 {
  /* flex-grow: 1; */
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1vw;
}

.cart-main-page-item-row-2-recharge-col2 > div {
  font-size: 20px;
  font-weight: 700;
}
.cart-main-page-item-row-2-recharge-col2 > span {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
}
.cart-main-page-item-row-2-recharge-col2 > section {
  font-size: 20px;
  font-weight: 700;
}
.backBtn {
  position: relative;
  top: 10px;
  left: 10px;
  font-size: 25px;
  cursor: pointer;
}

.cart-main-page-2 .insufficient-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.cart-main-page-2 .insufficient-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  max-height: 400px;
  height: 100%;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.cart-main-page-2
  .insufficient-modal-content
  .insufficient-modal-content-button {
  display: flex;
  justify-content: flex-end;
}
.cart-main-page-2
  .insufficient-modal-content
  .insufficient-modal-content-button
  > button {
  border: none;
  font-size: 22px;
  background: transparent;
  font-weight: 700;
}
.cart-main-page-2
  .insufficient-modal-content
  .insufficient-model-content-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cart-main-page-2
  .insufficient-modal-content
  .insufficient-model-content-header
  img {
  margin-top: -20px;
  max-width: 180px;
  max-height: 180px;
}
.cart-main-page-2
  .insufficient-modal-content
  .insufficient-model-content-header
  > h1 {
  color: #00838f;
  font-weight: 600;
  font-size: 24px;
}
.cart-main-page-2
  .insufficient-modal-content
  .insufficient-model-content-header
  > h4 {
  color: red;
  padding-bottom: 10px;
  font-size: 14px;
}
.cart-main-page-2
  .insufficient-modal-content
  .insufficient-model-content-footer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
}
.cart-main-page-2
  .insufficient-modal-content
  .insufficient-model-content-footer
  p {
  color: #3e4152;
  font-weight: 600;
  text-align: center;
}
.cart-main-page-2
  .insufficient-modal-content
  .insufficient-model-content-footer
  > button {
  background: #00838f;
  background-color: #00838f;
  border: none;
  border-radius: 2px;
  border-radius: 4px;
  color: #fff;
  width: 300px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 10px 5px;
  text-align: center;
  text-transform: uppercase;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .cart-main-page-2 {
    flex-direction: column;
  }
  .flippedcart {
    direction: ltr;
  }
  .cart-main-page-item-row-2 {
    width: 100%;
    height: fit-content;
    margin: 0;
    padding: 10px;
  }
  .cart-main-page-item-row-delivery-address-2 {
    padding: 14px;
    gap: 10px;
  }
  .cart-main-page-amount-row-2 {
    width: 100%;
    height: fit-content;
  }
  .cart-main-page-item-row-2-recharge-col1 > div {
    width: 100%;
    margin-top: 20px;
  }
  .cart-main-page-item-row-2-recharge-col1 > div > img {
    width: 100px;
    height: 100px;
  }
  .flippedcart {
    width: 95% !important;
    /* height: fit-content; */
    height: 70%;
    /* margin: 0; */
  }
}
