.profile-account-setting-right-new-address-heading
{
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 42px;
color: #25282C;
 width: 339px;
 padding-left: 22px;
border-bottom: 3px solid #00838F;
color: #2B2B2B;
margin-bottom: 60px;
margin-left: 45px;
margin-top: 22px;
margin-bottom: 41px;
}
.profile-account-setting-right-new-address-location
{
 width: 100%;
 display: flex;
 align-items: center;
 gap: 30px;

 justify-content: center;
 flex-direction: column;
 margin-bottom: 26px;
}
.profile-account-setting-right-new-address-location>button
{
 border: none;
 width: 272px;
height: 52px;
background: #00838F;
box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
display: flex;
align-items: center;
justify-content: space-evenly;
}
.profile-account-setting-right-new-address-location>button>img{
width: 20px;
height: 20px;
}
.profile-account-setting-right-new-address-location>button>p{
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 17px;
line-height: 17px;
/* identical to box height, or 100% */

letter-spacing: -0.2px;

color: #FFFFFF;
}
.profile-account-setting-right-new-address-location-or
{
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
 font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 17px;
/* identical to box height, or 85% */

letter-spacing: -0.2px;

color: #00838F;
margin-bottom: 78px;
}
.profile-account-setting-right-new-address-input
{
display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: dense;
  padding: 55px;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 40px;
}
.profile-account-setting-right-new-address-input>input{
 /* display: grid; */
 padding: 15px 0px 15px 20px;
width: 281px;
height: 47px;
background: #FFFFFF;
border: 1px solid #B8B8B8;
}
.profile-account-setting-right-new-address-input-check
{
 width: 100%;
 /* padding-right: 64px; */
 display: flex;
 justify-content: center;
 gap: 15px;
}
.profile-account-setting-right-new-address-input-check>input
{
width: 23px;
height: 23px;

background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.4);
box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;

}
.profile-account-setting-right-new-address-input-check>label
{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 25px;
/* identical to box height, or 154% */

letter-spacing: -0.2px;

color: #3C3C3C;
}


.profile-account-setting-right-new-address-input-result
{
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
margin-top: 50px;
}
.profile-account-setting-right-new-address-input-result>button
{
/* padding: 11px 90px; */
gap: 10px;

width: 301px;
height: 42px;
border: none;
background: #129BA7;
border-radius: 20px;
display: flex;
align-items: center;
justify-content:center ;

font-family: 'DM Sans';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 20px;
/* identical to box height, or 100% */

letter-spacing: -0.2px;

color: #FFFFFF;

}
.profile-account-setting-right-new-address-input-result>div
{
 cursor: pointer;
width: 301px;
height: 42px;
font-family: 'DM Sans';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 20px;
/* identical to box height, or 100% */

letter-spacing: -0.2px;

color: #00838F;
display: flex;
justify-content: center;
align-items: center;
}








@media screen and (max-width: 720px){

  .profile-account-setting-right-new-address-heading {
    width: fit-content;
    /* margin-top: -20px;
    margin-bottom: -15px; */
    text-align: center;
    margin-left: unset;

  }
  .profile-account-setting-right-new-address-location-or{
    margin-bottom: 20px;
  }
.profile-account-setting-right-new-address-input {
grid-template-columns: 1fr;
/* padding: 20px;
margin-bottom: 10px; */
}
.profile-account-setting-right-new-address-input-result {
  margin-bottom: 20px;
  padding: 20px;
  margin-top: 10px;
}

}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .profile-account-setting-right-new-address-input-check{
    justify-content: center;
    padding-right: unset;
    align-items: center;
  }
  .profile-account-setting-right-new-address-location >div {
    width: 90% !important;

  }
}