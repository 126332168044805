.my-service-profile-all-online-customer
{
 height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.my-service-profile-all-online-customer-lower-box
{
 width: 98%;
 height: 100%;
 /* overflow: scroll; */
 overflow: auto;
 display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    margin-top: 46px;
}
.my-service-profile-all-online-customer-lower-box::-webkit-scrollbar{
    height:7px;
    width:4px;
    margin-top:4px;
    display:flex;
  }
  .my-service-profile-all-online-customer-lower-box::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 5px;
  }
  .my-service-profile-all-online-customer-lower-box::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 5px;
  }
  
.my-service-profile-all-online-customer-lower-box-box
{
 width: 80%;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
   align-items: center;
    padding: 10px 0;
    height: 78px;
}
.my-service-profile-all-online-customer-lower-box-box-bottom-section{
    display: flex;
padding-right: 20px;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.my-service-profile-all-online-customer-lower-box-image
{
 width: 40px;
 height: 40px;
 border-radius: 100px;
 margin: 0 20px;
}
.my-service-profile-all-online-customer-lower-box-image>img
{
  width: 40px;
 height: 40px;
 border-radius: 100px;
}

.my-service-profile-all-online-customer-lower-box-user
{
 flex-grow: 1;
 font-size: 16px;
 font-weight: 600;
 color: rgba(0, 0, 0, 0.727);
 text-transform: capitalize;
 text-align: center;
}

.my-service-profile-all-online-customer-lower-box-price
{
 font-size: 16px;
 font-weight: 600;
 color: rgba(0, 0, 0, 0.727);
 text-align: center;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
.my-service-profile-all-online-customer-lower-box{
padding-bottom: 46px;
max-height: 700px;
}
.my-service-profile-all-online-customer-lower-box-box-bottom-section{
    flex-direction: column;
    padding-right: unset;
}
.my-service-profile-all-online-customer-lower-box-box{
  width: 100%;
}
}