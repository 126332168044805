.allservicetab-container
{
 width: 100%;
 /* height: 100%; */
 position: relative;
}
/* .allservicetab-image-container
{
  width: 48vw;
 height: 37vw;
} */
.allservicetab-image
{
 width: 100%;
 height: 100%;
}
.allservicetab-text
{
position: absolute;
bottom: 0;
width: 100%;
height: fit-content;
text-align: center;
background: linear-gradient(202.81deg, rgba(105, 100, 100, 0) -3.5%, rgba(60, 57, 57, 0.4) 30.22%, rgba(18, 17, 17, 0.45) 61.64%, rgba(0, 0, 0, 0.71) 85.2%);
}
.allservicetab-heading
{
 font-family: 'Lato';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 95.6%;
color: #FFFFFF;
}