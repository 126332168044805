.mobile-recharge-container {
  width: 100%;
  height: calc(100% - 85px);
  padding: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-recharge-inner-circle {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 9px;
  width: 88vw;
  height: 577px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile-recharge-inner-circle-1 {
  width: 40%;
  height: 100%;
  padding: 38px 53px;
}
.mobile-recharge-inner-circle-1-heading {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  line-height: 34px;
  color: #00838f;
}
.mobile-recharge-inner-circle-1-description {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 34px;
  margin-top: 10px;
  margin-bottom: 40px;
  /* text-align: center; */
  color: #8a8d92;
}
.mobile-recharge-inner-circle-1-inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}
.mobile-recharge-inner-circle-1-inputs > input,
.mobile-recharge-inner-circle-1-inputs > select {
  width: 90%;
  height: 35px;
  background: #e7e9ec;
  border: 1px solid rgba(0, 166, 182, 0.5);
  border-radius: 2px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 34px;
  /* identical to box height, or 258% */

  text-align: center;
  outline: none;
  color: #545556;
}
.mobile-recharge-inner-circle-1-inputs
  > input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mobile-recharge-inner-circle-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 22px;
}
.mobile-recharge-inner-circle-buttons > button {
  width: 150px;
  height: 40px;
  border: none;
  outline: none;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 34px;
  /* identical to box height, or 224% */

  text-align: center;

  color: #ffffff;
  background: #00838f;
  border-radius: 5px;
}
.mobile-recharge-inner-circle-buttons > span {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 900;
  font-size: 15px;
  line-height: 34px;
  text-align: center;
  cursor: pointer;
  color: #00838f;
}

.mobile-recharge-inner-circle-2 {
  width: 60%;
  height: 100%;
}
.mobile-recharge-inner-circle-2-empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-recharge-inner-circle-2-empty > img {
  width: 395px;
  height: 395px;
}

.mobile-recharge-inner-circle-2-full {
  width: 100%;
  height: 100%;
  padding: 38px 3px;
  padding-left: 0;
  padding-top: 0;
}
.mobile-recharge-inner-circle-2-top {
  width: 100%;
  height: 160px;
  padding-left: 28px;
  display: flex;
  align-items: center;
}
.mobile-recharge-inner-circle-2-top-scroll {
  width: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 11px;
  height: 80px;
}
.mobile-recharge-inner-circle-2-top-scroll > img {
  /* padding: 0 11px; */
  cursor: pointer;
}
.mobile-recharge-inner-circle-2-scroll {
  width: 100%;
  display: flex;
  align-items: center;
  height: 85%;
  justify-content: flex-start;
  overflow: auto !important;
  gap: 14px;
}

.mobile-recharge-inner-circle-2-top-scroll > div > span {
  width: max-content;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  /* line-height: 34px; */
  color: #6b6b6b;
  cursor: pointer;
  line-break: strict;
  padding: 0.2vw 0.5vw;
  white-space: nowrap;
}
.mobile-recharge-inner-circle-2-top-scroll > div > div {
  width: max-content;
  padding: 0.2vw 0.5vw;

  border-radius: 10px;
  position: relative;
  height: 63%;
  font-weight: bold;
  font-size: 18px;
  line-height: 34px;

  color: #6b6b6b;
  cursor: pointer;
  white-space: nowrap;
}
.mobile-recharge-inner-circle-2-top-scroll > div > div::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #00838f;
}
.mobile-recharge-inner-circle-2-plan-container {
  width: 100%;
  height: 321px;
  /* background: rgba(0, 131, 143, 0.23); */
  overflow: scroll;
  padding: 5px 7px 5px;
}

.mobile-recharge-inner-circle-2-plan-container-plan {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  background: #ffffff;
  transition: all 0.1s ease-in;
  border: 1px solid #00838f;
  border-radius: 8px;
}

.mobile-recharge-inner-circle-2-plan-container-plan:hover {
  width: calc(100% + 5px);
  height: 70px;
  filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.25));
}

.mobile-recharge-inner-circle-2-plan-container-plan-col1 {
  width: 85%;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
}
.mobile-recharge-inner-circle-2-plan-container-plan-col1-row1 {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 34px; */
  color: #000000;
  padding-bottom: 5px;
}
.mobile-recharge-inner-circle-2-plan-container-plan-col1-row2 {
  /* width: ; */
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 275;
  font-size: 10px;
  /* line-height: 34px; */
  color: #000000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.mobile-recharge-inner-circle-2-plan-container-plan-col1-row3 {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 34px; */
  color: #000000;
  display: flex;
  flex-direction: column;
}
.mobile-recharge-inner-circle-2-plan-container-plan-col1-row3 > span {
  font-size: 10px;
  /* line-height: 34px; */
  color: #6b6b6b;
}
.mobile-recharge-inner-circle-2-plan-container-plan-col2 {
  width: 30%;

  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
.mobile-recharge-inner-circle-2-plan-container-plan-col2-row1 {
  height: 50%;
  width: 100%;
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  /* identical to box height, or 420% */

  text-align: center;

  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-recharge-inner-circle-2-plan-container-plan-col2-row1 > div {
  width: 89px;
  background: #fffefe;
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  font-size: 1.8em;
  cursor: pointer;
  height: 25px;
  max-width: 100px;
  border: 1px solid #00838f;
  border-radius: 10px;
  background: #00838f;
  color: white;
}
.mobile-recharge-inner-circle-2-plan-container-plan-col2-row2 {
  cursor: pointer;
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #00838f;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.recharge-container {
  display: flex;
}

.recharge-container .myprofileMainDivFirst,
.payements-container .myprofileMainDivFirst {
  height: fit-content;
}

.recharge-container .profile-section-container2,
.payements-container .profile-section-container2 {
  height: fit-content;
}
.mobile-recharge-buttons-mobile-view{
  display: none;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .mobile-recharge-inner-circle-2-full,
  .mobile-recharge-inner-circle-2-top {
    padding: unset !important;
  }
  .mobile-recharge-inner-circle-2-plan-container-plan {
    height: auto;
    padding: 4px;
  }
  .mobile-recharge-inner-circle-2-plan-container-plan-col1-row2 {
    max-width: 180px;
    overflow: unset;
    white-space: unset;
  }
  .mobile-recharge-inner-circle-2-plan-container-plan-col2 {
    width: 35%;
  }
  .mobile-recharge-buttons-mobile-view{
    display: flex;
  }
}
