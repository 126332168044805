.container {
  position: relative;
  padding: 10px 20px;
  background-color: white;
  height: fit-content;
  max-height: 80vh;
  overflow-y: auto;
  width: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}
.modlaForm {
  width: 100%;
  height: 100%;
}

.singleLineInuts {
  display: grid;
  grid-template-columns: 100px 1fr;
}


.singleLineInuts .service-color-left-view{
  justify-content: space-between;

  width: 200px;
margin-bottom: 10px;
  align-items: center;
  }
  .singleLineInuts .service-color-left-view label{
    width: auto;
  }
  
  .singleLineInuts .service-color-choices{
    display: flex;

  
  }
  .singleLineInuts  .color-option-other{
margin-bottom: 10px;
width: 300px;
align-items: center;
  }
  .singleLineInuts  .color-option-other button{
    width: fit-content;
  }
  .singleLineInuts .service-color-choice{
    width: 17px;
    height: 17px;
    border-radius: 50%;
    border: 1px solid gray;


  
  
  }
  .singleLineInuts .service-color-left-view div{
    z-index: 999;
    cursor: pointer;
  }
  .singleLineInuts .color-option-other{
    z-index: 999;
  }
  .singleLineInuts .service-color-left-view .edit-manage-product-color{
    font-size: 30px;
cursor: pointer;
  }

.crossIcon1 {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #00838f;
  cursor: pointer;
}

.modalHeading {
  width: 100%;
  text-align: center;
  font-size: 24px;
  margin-top: 20px;
  color: #00838f;
}

.label {
  margin-bottom: 4px;
  width: 100%;
  color: gray;
  text-align: left;
}

.inputContainer {
  height: 35px;
  border: 2px solid #00838f;
  margin-bottom: 10px;
  text-align: center;
  width: 100px;
}

.productNameInput {
  width: 100%;
  text-align: start;
}

.formRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.imageContainer {
  position: relative;
  height: 200px;
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
}
.imageWrapper {
  position: relative;
  height: 200px;
  min-width: 300px;
}

.imageWrapper > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.imageInputForm {
  height: 100%;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageInputForm label {
  display: inline-block;
  padding: 10px 20px;
  background-color: #00838f;
  color: #ffffff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.imageInputForm input[type="file"] {
  display: none;
}

.imageInputForm label:hover {
  background-color: #006c76;
}

.handlerButtons {
  margin: 20px 0px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.handlerButtons > button {
  padding: 4px 20px;
  background-color: #00838f;
  color: white;
  border: none;
  font-size: 18px;
  border-radius: 6px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switchSlider {
  border: 2px solid;
  display: flex;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #00838f;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00838f;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

.singleLineInuts  .color-option-other{
  margin-left: unset;
  width: 284px;
}}