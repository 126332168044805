.my-feedback-main-page-view
{
 width: 100%;
 height: 100%;
 padding: 27px;
}
.my-feedback-main-page-type-buttons
{
 margin: 30px 0;
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: space-evenly;
}
.my-feedback-main-page-type-buttons>button
{
    border: none;
    width: 272px;
    height: 52px;
    background: white;
    /* box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25); */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 17px;
    letter-spacing: -0.2px;
    /* color: #FFFFFF; */
}
.my-feedback-main-page-type-buttons>button:hover
{
   border-bottom: 3px solid #1D8B97;
}
.my-feedback-main-page-data
{
 margin: 20px 0;
  overflow: auto;
  height: 630px;
  padding: 1vw;
     /* border: 1px solid #DDE0E5; */
}

.my-feedback-my-profile-add-feedback
{
 width: 100%;
 padding: 27px;
 height: 100%;
}
/* //third section */
.my-feedback-my-profile-inputs
{
 max-height: 650px;
 overflow: auto;
 margin: 30px auto;
 width: 90%;
}
.my-feedback-my-profile-inputs-submit
{
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
}
.my-feedback-my-profile-inputs-submit>button
{
 border: none;
    width: 222px;
    height: 52px;
    background: #00838F;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 17px;
    letter-spacing: -0.2px;
    color: #FFFFFF;
}

.my-feedback-main-page-no-feedback-given,.my-feedback-main-page-no-feedback-received{
   width: 100%;
   height: 100%;
   display: flex;
   align-items: flex-start;
   justify-content: center;
}
.my-feedback-main-page-no-feedback-given>img,.my-feedback-main-page-no-feedback-received>img
{
width: 500px;
height: 500px;
}