.search-page-desktop-main-view {
  height: fit-content;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5 !important;
}

.search-page-desktop-main-view-search-result {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  overflow: auto;
  padding: 1vw;
  /* filter: drop-shadow(0 -6mm 4mm rgb(160, 0, 210)); */
  /* background: linear-gradient(to top, transparent, 10%, white, 90%, transparent); */
  border: 1px solid #fbfbfb2b;
  /* animation-name: entry;
  animation-duration: 2s; */
  transform-origin: top;
}
.search-page-desktop-main-view-search-result::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  border: inherit;
  border-color: transparent;
  background: inherit;
  /* filter: blur(100px);
  -webkit-filter: blur(100px); */
  pointer-events: none;
  width: 100%;
  /* backdrop-filter: blur(1px); */
  z-index: 100;
  filter: blur(100px);
  -webkit-filter: blur(100px);
}
.search-page-desktop-main-view-search-result-product,
.search-page-desktop-main-view-search-result-service,
.search-page-desktop-main-view-search-result-user {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px 20px;
  display: flex;
  height: 195px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

}
.search-page-desktop-main-view-search-result-product-left,
.search-page-desktop-main-view-search-result-service-left,
.search-page-desktop-main-view-search-result-user-left {
  height: 100%;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-page-desktop-main-view-search-result-product-left > img,
.search-page-desktop-main-view-search-result-service-left > img,
.search-page-desktop-main-view-search-result-user-left > img {
  height: 115px;
  width: 115px;
  border-radius: 10px;
}

.search-page-desktop-main-view-search-result-product-right,
.search-page-desktop-main-view-search-result-service-right,
.search-page-desktop-main-view-search-result-user-right {
  flex-grow: 1;
  margin-left: 10px;
  /* white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis; */
}

/* basic text transforamtion like camel case all capital or lowercase only first word after space capital */
.search-page-desktop-main-view-search-result-product-right > span {
  font-size: 16px;
  font-weight: 700;
  color: rgb(73, 20, 123);
  text-transform: uppercase;
}
.search-page-desktop-main-view-search-result-product-right > section {
  font-size: 16px;
  font-weight: 700;
  color: rgb(31, 3, 22);
  text-transform: capitalize;
}
.search-page-desktop-main-view-search-result-product-right > article {
  font-size: 13px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.501);
}
.search-page-desktop-main-view-search-result-product-right > div {
  font-size: 14px;
  font-weight: 700;
  color: rgb(23, 12, 70);
}

.search-page-desktop-main-view-search-result-service-right > span {
  font-size: 16px;
  font-weight: 700;
  color: rgb(31, 3, 22);
  text-transform: uppercase;
}
.search-page-desktop-main-view-search-result-service-right > section {
  font-size: 16px;
  font-weight: 700;
  color: rgb(73, 20, 123);
  text-transform: capitalize;
}
.search-page-desktop-main-view-search-result-service-right > article {
  font-size: 13px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.501);
}
.search-page-desktop-main-view-search-result-service-right > div {
  font-size: 14px;
  font-weight: 700;
  color: rgb(23, 12, 70);
  display: flex;
  align-items: center;
}
.search-page-desktop-main-view-search-result-service-right > div > img {
  height: 15px;
  width: 15px;
}
.search-page-desktop-main-view-search-result-user-right > span {
  font-size: 17px;
  font-weight: 700;
}

@keyframes entry {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* //design    */
/* .search-page-desktop-main-view-type-select>label:hover
{
background: #7B52AB;
}


.search-page-desktop-main-view-type-select>input[type=radio]:checked ~ .search-page-desktop-main-view-search-result 
{
 background-color: #7B52AB;
} */

.search-page-desktop-main-view-type-select {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  cursor: pointer;
  font-family: "Titillium Web", sans-serif;
}
.search-page-desktop-main-view-type-select > span {
  font-size: 30px;
  letter-spacing: -1px;
  word-spacing: 2px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.316);
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-page-desktop-main-view-search-result-container {
  /* background-color: aqua; */
  width: 100%;
  /* height: 75%; */
  flex-grow: 1;
  position: relative;
  /* filter: drop-shadow(0 -6mm 4mm rgb(160, 0, 210)); */
  z-index: 100;
  padding: 1vw;
}

.search-page-desktop-main-view-search-result-container::before {
  content: "";
  position: absolute;
  z-index: 100;
  top: 0;
  height: 10px;
  /* filter: blur(100px);
  -webkit-filter: blur(100px); */
  pointer-events: none;
  width: 100%;
  /* backdrop-filter: blur(1px); */
  backdrop-filter: drop-shadow(4px 4px 10px blue);

  /* backdrop-filter: ;
backdrop-filter: ;
backdrop-filter: ;
backdrop-filter: hue-rotate(120deg);
backdrop-filter: invert(70%);
backdrop-filter: opacity(20%);
backdrop-filter: sepia(90%);
backdrop-filter: saturate(80%); */
  /* backdrop-filter: blur(-10px); */
  /* background-image:linear-gradient(to bottom,rgba(255,255,255,0),rgba(255,255,255,1) 90%) ; */
  z-index: 100;
}
.search-page-desktop-main-view-search-result-no {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-page-desktop-main-view-search-result-no .no-result-container {
  background: #59bbc48f;
  display: flex;

  padding: 30px;
  color: #00838f;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  align-items: center;
}
.search-page-desktop-main-view-search-result-no
  .no-result-container
  p:nth-child(1) {
  font-size: 28px;
  font-weight: bold;
  max-width: 310px;
}
.priceAndQuantity{
   display: flex;
   justify-content: space-between;
   align-items: center;
}

@media screen and (max-width: 720px) {
  .search-page-desktop-main-view-type-select {
    justify-content: space-evenly;
    gap: 20px;
    height: 100px;
  }
  .search-page-desktop-main-view-type-select > span:nth-child(2) {
    word-spacing: 1px;
  }

  .search-page-desktop-main-view-search-result {
    grid-template-columns: 1fr;
    padding-bottom: 20px;
    max-height: 100vh;
    height: auto;

    grid-gap: 20px;
  }
  .search-page-desktop-main-view-search-result-container {
    margin-top: -10px;
  }
  .search-page-desktop-main-view-search-result-product,
  .search-page-desktop-main-view-search-result-service,
  .search-page-desktop-main-view-search-result-user {
    width: 90%;
    margin: 0 auto;
    height: 200px;
  }
  .search-page-desktop-main-view-search-result-container {
    z-index: 1;
    height: 100vh;
  }
}
