.my-wallet-profile-send-money-background {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.267);
}
.my-wallet-profile-page-sendmoney-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-height: 380px;
  overflow-y: auto;
  z-index: 2000;
  background: #fffcfc;
  padding: 50px;
  position: relative;
}
.my-wallet-profile-send-money-background .nobank-account-div {
  width: 300px;
  background: #59bbc48f;
  display: flex;
  padding: 30px;
  color: #00838f;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  align-items: center;
}

.nobank-account-div .no-accounts-msg {
  font-size: 28px;
  font-weight: bold;
  max-width: 110px;
}

.my-wallet-profile-send-money-background .add-bank-account-button > button,
.my-wallet-profile-page-sendmoney-modal
  .bank-accounts-list
  .my-bank-account-profile-card
  .my-bank-account-profile-card-topheading
  > button,
.transfer-amount-submit > button {
  border: none;
  border-radius: 10px;
  width: fit-content;
  padding: 20px;
  height: 52px;
  background: #00838f;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  letter-spacing: -0.2px;
  cursor: pointer;
  color: #ffffff;
  margin-top: 20px;
}

.my-wallet-profile-page-sendmoney-modal
  .my-bank-account-profile-main-page
  .sendmoney-top {
  margin-top: 202px;
  padding-left: 10px;
}
.my-wallet-profile-page-sendmoney-modal
  .my-bank-account-profile-main-page
  .sendmoney-input {
  width: 100%;
  padding: 10px;
}

.my-wallet-profile-page-sendmoney-modal .bank-accounts-list {
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-columns: 1fr;
  grid-gap: 17px;
  overflow: auto;
  max-height: 330px;
  padding: 1vw;
}
.my-wallet-profile-page-sendmoney-modal
  .bank-accounts-list
  .my-bank-account-heading-withdrawal {
  color: #00838f;
  font-weight: 800;
}
.my-wallet-profile-page-sendmoney-modal
  .bank-accounts-list
  .my-bank-account-profile-card
  .my-bank-account-profile-card-topheading {
  display: flex;
  flex-direction: column;
}

.my-wallet-profile-page-sendmoney-modal
  .bank-accounts-list
  .my-bank-account-profile-card
  .my-bank-account-profile-card-topheading
  > div {
  font-weight: bold;
  color: #000;
}
.my-wallet-profile-page-sendmoney-modal
  .bank-accounts-list
  .my-bank-account-profile-card
  .my-bank-account-profile-card-topheading
  > div
  > span {
  font-weight: 400;
}

.my-wallet-profile-send-money-background .transfer-amount-page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 20px;
  flex-direction: column;
}

.my-wallet-profile-send-money-background
  .transfer-amount-page
  .sendmoney-transfer {
  padding-left: 0px;
}
.my-wallet-profile-send-money-background
  .transfer-amount-page
  .transfer-amount-input
  > input {
  height: 40px;
  width: 150px;
  margin-top: 20px;
  font-size: 12px;
  padding: 12px;
  border: 1px solid #d4d5d9;
  background-color: transparent;
  font-weight: 300;
  border-radius: 4px;
  line-height: 14px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .my-wallet-profile-page-sendmoney-modal,
  .my-wallet-profile-page-sendmoney-modal .bank-accounts-list {
    max-height: 500px;
    width: 80%;
    height: fit-content;
    padding: 10px;
    overflow-y: scroll;
  }
  .my-wallet-profile-page-sendmoney-modal
    .my-bank-account-profile-main-page
    .sendmoney-top {
    margin-top: unset;
    padding-left: unset;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-top: 50px;
  }

  .my-bank-account-profile-main-page-add-account-submit > button {
    margin-bottom: 10px;
  }
}
