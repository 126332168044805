.order-confiramtion-cart-page-container
{
    display: flex;
 height: 100vh;
 width: 100vw;
 overflow: auto;
 padding: 15px;
}
.order-confiramtion-cart-page-container .order-confiramtion-cart-page-container-left{
    width: 50%;
    max-height: calc(100vh - 210px);

    overflow: scroll;
}


.order-confiramtion-cart-page-container .order-confirmation-cart-page-items-shipped-container{
width: 50%;
display: flex;
justify-content: flex-end;
}
.order-confiramtion-cart-page-container .order-confirmation-cart-page-items-shipped-container .order-confirmation-cart-page-items-shipped-inner-container{
    padding: 20px;
    border: 1px solid rgb(193, 190, 190);
    border-radius: 20px;
    height: fit-content;
}

.order-confiramtion-cart-page-container .order-confirmation-cart-page-items-shipped-container .order-confirmation-cart-page-items-shipped-inner-container .order-confirmation-cart-page-heading{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
color: #00838f;
text-transform: capitalize;
font-weight: bold;
font-size: 1.5rem;
}
.order-confiramtion-cart-page-container .order-total-amount{
    display: flex;
    justify-content: center;
    align-items: center;

  width: 100%;


    
}
.order-confiramtion-cart-page-container .order-total-amount >span{
    background-color: #00838f;
    color: white;
    padding: 20px;
    border-radius: 20px;
    width: 100%;
    font-weight: 500;
    letter-spacing: 1.2px;
    font-size: 1.4rem;
    text-align: center;
}
.order-confiramtion-cart-page-container> .order-total-amount span{
    color: #00838f;
    font-weight: 600;
    background-color: #cfcece;
    border-radius: 20px;
  padding: 10px 20px;
    font-size: 1.4rem;
}

.order-confirmation-cart-page-items
{
    width: 100%;
 border: none;
    background-color: #fff;
    font-size: 14px;
    border-radius: 4px;
    position: relative;
    margin-bottom: 8px;
    display: flex;
    /* justify-content: center; */
}
.order-confirmation-cart-page-items-col-1
{
    width: 34%;
 padding: 0 15px;
 display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    object-fit: contain;
    text-align: center;
    height: 180px;

}
.order-confirmation-cart-page-items-col-1>img
{
 max-width: 100%;
    max-height: 150px;
    text-align: center;
    margin: auto;
    vertical-align: middle;
}
.order-confirmation-cart-page-items-col-2
{
 padding: 8px 15px ;
width: 60%;
}
.order-confirmation-cart-page-items-brand-name
{
 overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #dc3545!important;
    font-size: 14px;
    font-weight: 700;
}
.order-confirmation-cart-page-items-item-name
{
 overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #00838f;
    font-size: 14px;
    font-weight: 700;
}
.order-confirmation-cart-page-items-soldby
{
 overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #94969f;
    font-size: 12px;
}
.order-confirmation-cart-page-items-quantity
{
 overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #94969f;
    font-size: 12px;
}
.order-confirmation-cart-page-items-amount-qty
{
 overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #94969f;
    font-size: 12px;
    font-weight: 700;
}
.order-confirmation-cart-page-items-price
{
 overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #00838f;
    font-size: 16px;
    font-weight: 700;

}
.order-confirmation-cart-page-items-shipped-date
{
 overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* color: #94969f; */
    font-size: 12px;
 padding-top: 20px;
 font-weight: 400;
 color: #00838f;
}
.order-confirmation-cart-page-items-expected-time
{
    font-weight: 400;
    color: #00838f;
 overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* color: #94969f; */
    font-size: 12px;
}
.order-confirmation-cart-page-items-Return-policy
{
    font-weight: 400;
    color: #00838f;
 overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* color: #94969f; */
    font-size: 12px;
}


.order-confirmation-cart-page-items-col-3
{
 padding: 8px 15px;
 padding-left: 0px;
 /* width: 33.33333%; */

 font-weight: 400;
 color: #00838f;

}
.order-confirmation-cart-page-items-mobile-number
{
 overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* color: #94969f; */
    font-size: 12px;

}
.order-confirmation-cart-page-items-delivery-address 
{
max-height: 70px;
max-width: 400px;
height: 60px;
    /* color: #94969f; */
    font-size: 12px;
}
.order-confirmation-cart-page-items-delivery-address >p{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    white-space: normal;
}
.order-confirmation-cart-page-items-order-status-track
{
     position: relative;
    background-color: #ddd;
    height: 7px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 60px;
    margin-top: 50px;
}
.order-confirmation-cart-page-items-order-status-track-1
{
background: #00838f;
/* flex-grow: 1; */
    width: 25%;
    /* margin-top: -18px; */
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

}
.order-confirmation-cart-page-items-order-status-track-1>div
{
 margin-top: 18px;

    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}
.order-confirmation-cart-page-items-order-status-track-1>div>div
{
 background: #00838f;
    color: #fff;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    position: relative;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.order-confirmation-cart-page-items-order-status-track-1>div>div>img
{
 display: flex;
 align-items: center;
 justify-content: center;
 height: 34px;
 width: 34px;
}
.order-confirmation-cart-page-items-order-status-track-1>div>span
{
 font-weight: 400;
 color: #00838f;

    display: block;
    margin-top: 7px;
    font-size: 12px;
    text-align: center;
}






.order-confirmation-cart-page-items-order-status-track-2
{
background: #ddd;
/* flex-grow: 1; */
    width: 25%;
    /* margin-top: -18px; */
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

}
.order-confirmation-cart-page-items-order-status-track-2>div
{
 margin-top: 18px;

    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}
.order-confirmation-cart-page-items-order-status-track-2>div>div
{
 background: #ddd;
    color: #fff;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    position: relative;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 10px; */
}
.order-confirmation-cart-page-items-order-status-track-2>div>div>img
{
 margin-top: 10px;
 /* display: flex;
 align-items: center;
 justify-content: center; */
 height: 20px;
 width: 20px;
}
.order-confirmation-cart-page-items-order-status-track-2>div>span
{
    font-weight: 400;
    color: #00838f;
    display: block;
    margin-top: 7px;
    font-size: 12px;
    text-align: center;
}



.order-confirmation-cart-page-items-payment-status
{
 overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* color: #94969f; */
    font-size: 12px;
    font-weight: 400;
    color: #00838f;
}

.order-confirmation-cart-page-items-payment-status >span >span
{
color: #9ea0a9;
font-weight: 400;
}
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px){
    .order-confiramtion-cart-page-container{
        flex-direction: column;
        height: unset;
    }
    .order-confiramtion-cart-page-container .order-confiramtion-cart-page-container-left{
        width: 100%;
    }
    .order-confiramtion-cart-page-container .order-confirmation-cart-page-items-shipped-container {
        width: 100%;
    }
    .order-confirmation-cart-page-items{
        width: 100%;
        margin-bottom: 50px;
        height: fit-content;
    }
    .order-confirmation-cart-page-items > div{
        width: 100%;
    }
    .trackordernew>div
{
    flex-direction: row;
    position: fixed;
    left: 20%;
    z-index:1;
    gap:5px;
}
.order-confirmation-cart-page-items-order-status-track:has(.trackordernew){
    margin-left: 15%;
}
.timmee{
    align-items: baseline;
}
.datetime{
    padding-left:0px;
}
}