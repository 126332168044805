.service-provider-mobile-main-screen-filter-item
{
  width: fit-content;
 height: 80%;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: flex-start;
white-space: nowrap;
padding: 0.5vw 3vw;
background-color: white;
border-radius: 20px;
display: flex;
align-items: center;
justify-content: center;

/* padding-top: 2vw; */
}
.service-provider-mobile-main-screen-filter-item-image
{
  width: 30vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}
.service-provider-mobile-main-screen-filter-item-image>img
{
 width: 21vw;
 height: 21vw;
 border-radius: 100px;
}
.service-provider-mobile-main-screen-filter-item-text
{
font-family: 'Titillium Web';
font-style: normal;
font-weight: 300;
font-size: 15px;
line-height: 23px;
/* identical to box height */
text-align: center;

    color: #000000a1;
}