.mobile-loader {
  display: none;
}

.obj {
  width: 6px;
  height: 40px;
  background: #00838f;
  margin: 0 3px;
  border-radius: 10px;
  animation: loading 0.8s infinite;
}

.obj:nth-child(2),
.obj:nth-child(9) {
  animation-delay: 0.1s;
}

.obj:nth-child(3),
.obj:nth-child(10) {
  animation-delay: 0.2s;
}

.obj:nth-child(4),
.obj:nth-child(11) {
  animation-delay: 0.3s;
}

.obj:nth-child(5),
.obj:nth-child(12) {
  animation-delay: 0.4s;
}

.obj:nth-child(6),
.obj:nth-child(13) {
  animation-delay: 0.5s;
}

.obj:nth-child(7),
.obj:nth-child(14) {
  animation-delay: 0.6s;
}

.obj:nth-child(8),
.obj:nth-child(15) {
  animation-delay: 0.7s;
}

.obje {
  padding: 15px;
  color: #00838f;
  font-weight: bold;
  text-align: center;
}

.loading[stylechange="generatingpdfyes"] .obje{
  font-size: 18px;
}

@keyframes loading {
  0% {
    height: 0;
  }
  50% {
    height: 40px;
  }
  100% {
    height: 0;
  }
}

@media only screen and (max-width: 720px) {
  .loading {
    display: none !important;
  }
  .mobile-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
  }
  .loader {
    display: flex;
    width: 48px;
    height: 48px;
    border: 5px solid #00838f;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
