.product-Image-Mobile-Modal-background
{
 position: fixed;
 width: 100vw;
 height: 100vh;
 z-index: 100;
  background-color: black;
 opacity: 0.5;
}
.product-Image-Mobile-Modal-container
{
 position: fixed;
 z-index: 200;
 width: 319.09px;
height: 315px;
background-color: white;
opacity: 1;
top: 50%;
left: 50%;
margin-top: -157px; /* Negative half of height. */
margin-left: -159.5px;
display: flex;
align-items: center;
justify-content: center;
}
.product-Image-Mobile-Modal-image-container
{
 width: 90%;
 height: 90%;
}
.product-Image-Mobile-Modal-image-container>img
{
 width: 100%;
 height: 100%;
}