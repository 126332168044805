.service-provider-mobile-main-screen-products-card
{
 width: 100%;
height: 184px;
background: #FFFFFF;
border-radius: 5px;
}
.service-provider-mobile-main-screen-products-card-top
{
 display: flex;
 height: 67%;
 width: 100%;
}
.service-provider-mobile-main-screen-products-card-detail
{
 width: 50%;
 padding-left: 20px;
 padding-top: 12px;
}
.service-provider-mobile-main-screen-products-card-name
{
font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
/* identical to box height */
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;

color: #1E1E1E;

opacity: 0.8;
}
.service-provider-mobile-main-screen-products-card-cat
{
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
/* identical to box height */
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;

color: #1E1E1E;

}
.service-provider-mobile-main-screen-products-card-fix
{
 width: 100%;
 height: 92px;
 position: relative;

}
.service-provider-mobile-main-screen-products-card-fix-top
{
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding-top: 2vw;
}
.service-provider-mobile-main-screen-products-card-offer
{
  width: 30.333%;
font-family: 'Roboto';
font-style: normal;
font-weight: 800;
font-size: 10px;
line-height: 12px;
/* identical to box height */
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;

color: #05A602;

opacity: 0.8;
}
.service-provider-mobile-main-screen-products-card-price{
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 19px;
/* identical to box height */
width: 35.33%;

color: #000000;

opacity: 0.8;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}
.service-provider-mobile-main-screen-products-card-prev-price
{
 font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 14px;
/* identical to box height */
width: 34.33%;

color: #F90E00;

opacity: 0.8;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}

.service-provider-mobile-main-screen-products-card-fix-bottom
{
 display: flex;
 align-items: center;
 justify-content: space-around;
 padding-top: 2vw;
}
.service-provider-mobile-main-screen-products-card-fix-bottom>span
{
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
/* identical to box height */
width: 50%;


color: #1E1E1E;

opacity: 0.8;
}

.service-provider-mobile-main-screen-products-card-quantity-cont
{
  width: 50%;
 display: flex;
 align-items: center;
 gap: 0.3vw;
}
.service-provider-mobile-main-screen-products-card-quantity-cont>span{

}
.service-provider-mobile-main-screen-products-card-quantity
{
 outline: none;
 border: none;
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
color: #1E1E1E;
opacity: 0.8;
width: 100%;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}
.service-provider-mobile-main-screen-products-card-quantity:focus
{
  outline: none;
 border: none;
}



.service-provider-mobile-main-screen-products-card-fix-bottom-pro-avail
{
 font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
padding-top: 5vw;

color: #37B835;

opacity: 0.8;
}
.service-provider-mobile-main-screen-products-card-fix-bottom-pro-out
{
   font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
padding-top: 5vw;

color: #e12323;

opacity: 0.8;
}


.service-provider-mobile-main-screen-products-card-image
{
width: 50%;
display: flex;
align-items: center;
justify-content: center;
padding: 1vw;
}
.service-provider-mobile-main-screen-products-card-image>img
{
 width: 100%;
 height: 100%;
}
.service-provider-mobile-main-screen-products-card-bottom
{
 width: 100%;
height: 33%;
display: flex;
align-items: center;
justify-content: space-between;
}
.service-provider-mobile-main-screen-products-card-rating
{
padding-left: 20px;
}
.service-provider-mobile-main-screen-products-card-rating>img
{
 width: 20px;
 height: 20px;
}
.service-provider-mobile-main-screen-products-card-stat
{
 width: 60%;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
}
.service-provider-mobile-main-screen-products-card-stat>button
{
 height: 70%;
 width: 90%;

 background: #00838F;
border-radius: 6px;
font-family: 'Titillium Web';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 20px;
/* identical to box height, or 167% */

text-align: center;
letter-spacing: 1px;
text-transform: uppercase;

/* White/W100 */

color: #FFFFFF;
}



.service-provider-mobile-main-screen-products-card-stat-counter
{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw;
}
.service-provider-mobile-main-screen-products-card-stat-counter>div
{
  padding: 1.5vw 2.5vw;
  border: 1px solid #00838F;
  width: fit-content;
  border-radius: 8px;
  color: #00838F;
  font-weight: 600;
  font-size: 16px;
}