.ProfileSection-MyBillsContainer
{
 width: 100%;
 height: 100%;
 display: flex;
 align-items: flex-start;
}
.ProfileSection-MyBillsContainer-Left
{
width: 29.79%;
 height: 100%;
border-right: 1px solid rgba(0, 0, 0, 0.37);
}
.ProfileSection-MyBillsContainer-Right
{
  height: 100%;
width: calc(100% - 29.79%);
}

.ProfileSection-MyBillsContainer-Left-1-active
{
 margin-top: 92px;
 display: flex;
align-items: flex-start;
justify-content: space-between;
/* padding: 25px 115px 25px 0px; */
/* gap: 10px; */
width: 100%;
height: 69px;
}
.ProfileSection-MyBillsContainer-Left-1-active>div{
 visibility: visible;
 height: 100%;
 width: 4px;
 background-color: #1D8B97;
}
.ProfileSection-MyBillsContainer-Left-1-active>span
{
 padding:25px 115px 25px 0px;
 font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 17px;
line-height: 19px;
color: #015259;
}


.ProfileSection-MyBillsContainer-Left-2
{
 margin-top: 10px;
 display: flex;
align-items: flex-start;
justify-content: space-between;
/* padding: 25px 115px 25px 0px; */
/* gap: 10px; */
width: 100%;
height: 69px;
/* background: #E9E9EA; */
}
.aProfileSection-MyBillsContainer-Left-2>div{
  visibility: hidden;
  transition: all 0.3s;
}
.ProfileSection-MyBillsContainer-Left-2>span
{
 padding:25px 115px 25px 0px;
 font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 17px;
line-height: 19px;
color: #015259;
}
.ProfileSection-MyBillsContainer-Left-2:hover >div
{
 visibility: visible;
 height: 100%;
 width: 4px;
 background-color: #1D8B97;
}

.ProfileSection-MyBillsContainer-Left-2:hover
{
 background-color:  #E9E9EA;
}




.ProfileSection-MyBillsContainer-Left-1
{
 margin-top: 92px;
 display: flex;
align-items: flex-start;
justify-content: space-between;
/* padding: 25px 115px 25px 0px; */
/* gap: 10px; */
width: 100%;
height: 69px;
/* background: #E9E9EA; */
}

.ProfileSection-MyBillsContainer-Left-1>div{
  visibility: hidden;
  transition: all 0.3s;
}
.ProfileSection-MyBillsContainer-Left-1>span
{
 padding:25px 115px 25px 0px;
 font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 17px;
line-height: 19px;
color: #015259;
}

.ProfileSection-MyBillsContainer-Left-1:hover >div
{
 visibility: visible;
 height: 100%;
 width: 4px;
 background-color: #1D8B97;
}
.ProfileSection-MyBillsContainer-Left-1:hover
{
 background-color:  #E9E9EA;
}


.ProfileSection-MyBillsContainer-Left-2-active
{
 margin-top: 10px;
 display: flex;
align-items: flex-start;
justify-content: space-between;
/* padding: 25px 115px 25px 0px; */
/* gap: 10px; */
width: 100%;
height: 69px;
/* background: #E9E9EA; */
}
.ProfileSection-MyBillsContainer-Left-2-active>div{
 visibility: visible;
 height: 100%;
 width: 4px;
 background-color: #1D8B97;
}
.ProfileSection-MyBillsContainer-Left-2-active>span
{
 padding:25px 115px 25px 0px;
 font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 17px;
line-height: 19px;
color: #015259;
}


.ProfileSection-MyBillsContainer-Right-upper{
    border-bottom:  1px solid rgba(0, 0, 0, 0.37) ;
    height: 120px;
    padding: 2rem;
}
.ProfileSection-MyBillsContainer-Right-upper-first{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.ProfileSection-MyBillsContainer-Right-lower{
  padding: 0.5rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-bottom:  1px solid rgba(0, 0, 0, 0.37) ;
   text-align: center;
  
}
.ProfileSection-MyBillsContainer-Right-lower-Column4{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ViewAllButtons{
  width: 59px;
height: 16px;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 11px;
line-height: 16px;

/* identical to box height, or 145% */
letter-spacing: 0.5px;
color: rgba(94, 90, 90, 0.6);
border-radius: 5px;
}
.Amount{
  width: 37px;
height: 16px;
font-family: 'Roboto';
font-style: normal;
font-weight: 900;
font-size: 11px;
line-height: 16px;

/* identical to box height, or 145% */
letter-spacing: 0.5px;
color: rgba(0, 0, 0, 0.8);
}
.ProfileSection-MyBillsContainer-Right-lower-Column2{

/* Label Medium */
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 16px;

/* or 133% */
letter-spacing: 0.005em;

/* Black Primary/100 */
color: #1C1B1F;
}
.OrderId{
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 9px;
line-height: 16px;

/* identical to box height, or 178% */
letter-spacing: 0.5px;

color: rgba(94, 90, 90, 0.8);
}
.incoming{
  color: rgb(32, 214, 129);
}

.MyBills-ViewBillsDetail-Title{
  width: 158px;
height: 35px;

font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 35px;

color: #4F4F4F;
margin-left: 1.5rem;
margin-top: 2rem;
}
.MyBills-ViewBillsDetail-SecondDiv{
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 margin: 1rem 1rem;
}
.MyBills-ViewBillsDetail-SecondDivItems{
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;

/* identical to box height, or 150% */
letter-spacing: 0.005em;

color: #404040;
}
.MyBills-ViewBillsDetail-SecondDivDate{
  font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;

/* identical to box height, or 150% */
letter-spacing: 0.5px;

color: #333333;
}
.MyBills-ViewBillsDetail-ItemsList{
  display: flex;
  flex-direction: row;
  margin-left:2rem;
  justify-content: space-around;

 
  align-items: center;
  padding: 4px 12px;
  gap: 8px;
  isolation: isolate;
  
 
  
  background: #FFFFFF;
  border-bottom: 1px solid rgba(2, 126, 139, 0.3);



}
.MyBills-ViewBillsDetail-ItemsLisServiceProvider{

width: 60px;

}
.MyBills-ViewBillsDetail-ItemsListName{
  /* margin-right: 2rem; */
  width: 172px;
height: 23px;

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;

/* or 133% */
letter-spacing: 0.005em;

/* Black Primary/100 */
color: #1C1B1F;



}


.MyBills-ViewBillsDetail-ItemsListQuantity{
  width: 32px;
height: 16px;
/* margin-right: 2rem; */
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 11px;
line-height: 16px;

/* identical to box height, or 145% */
letter-spacing: 0.5px;

color: rgba(92, 92, 92, 0.8);


}

.MyBills-ViewBillsDetail-ItemsListPrice{
  width: 29px;
height: 16px;

font-family: 'Roboto';
font-style: normal;
font-weight: 900;
font-size: 16px;
line-height: 16px;

/* identical to box height, or 100% */
letter-spacing: 0.5px;

color: rgba(0, 0, 0, 0.8);
}


.MyBills-ViewBillsDetail-Payment{
  position: relative;
margin-top: 2rem;
 margin-left: 50%;
  width: 390px;
  height: 199px;
 
  background: red;
 
}
.MyBills-ViewBillsDetail-Payment-Title{
  position: absolute;
width: 108px;
height: 16px;
left: 17px;
top: 18px;

font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 16px;

/* identical to box height, or 123% */
letter-spacing: 0.005em;

color: #000000;
}




