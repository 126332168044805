.image-url-div >nav{
    margin-bottom: 3rem;
    background-color: #17a2b8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}
.image-url-div >nav >p{
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;

    font-size: 1.35rem;
    color: white;
   
}
.image-url-div .imageurl-container{
    padding:  0 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.image-url-div .imageurl-container .imageurl-input{
margin-bottom: 40px;

}

.image-url-div button{
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    background-color: #17a2b8;
color: #fff;
    border: 1px solid #17a2b8;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.image-url-div button:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
}
.image-url-div  .image-url-button{
    display: flex;
gap: 20px;
}
.image-url-div .show-image-url{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
.image-url-div .show-image-url >input{
    width: 100%;
    margin-bottom: 20px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}