.generate-bill-service-main-page {
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 800px;
}

.generate-bill-serivce-main-page-main-box-card {
  width: 100%;
  height: fit-content;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1vw;
  margin: 10px auto;
  row-gap: 1.2rem;
}
.generate-bill-card-section-one {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.generate-bill-card-section-two {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 1vw;
  align-items: center;
}
.generate-bill-card-section-two > button {
  width: 8rem;
  height: 2rem;
  background-color: #00838f;
  color: white;
  border-radius: 0.4rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.4rem;
}

.generate-bill-card-section-two button:hover {
  background-color: #147c85;
}

.generate-bill-card-section-two button:active {
  transform: scale(0.9);
  transition: transform 0.2s ease;
}

/* best way for image how to write css for image */
.generate-bill-serivce-main-page-main-box-card-image {
  width: 10rem;
  max-height: 8rem;
  object-fit: cover;
}
.generate-bill-product-data .service-color-choice {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid gray;
  margin: 2px 10px;

}
.generate-bill-product-data .service-color-left-view{
  display: flex;
align-items: center;

  }

.generate-bill-serivce-main-page-main-box-card-information {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
}

.generate-bill-product-name {
  font-size: 16px;
  font-weight: 600;
  color: rgb(97, 92, 92);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.generate-bill-serivce-main-page-main-box-card-quantity {
  height: 100%;
  width: calc(100% / 5);
  padding-left: 10px;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.generate-bill-serivce-main-page-main-box-card-quantity > div {
  width: 100%;
  display: flex;
}
.generate-bill-serivce-main-page-main-box-card-quantity > div > select {
  /* width: 70px; */
  flex-grow: 1;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.556);
  border: 1px solid rgba(0, 0, 0, 0.516);
  padding: 0 1.2rem;
  max-width: 6rem;
}
.generate-bill-serivce-main-page-main-box-card-quantity
  > div
  > select
  > option {
  display: flex;
  justify-content: center;
}
.generate-bill-serivce-main-page-main-box-card-quantity > div > span {
  width: 5rem;
}

.generate-bill-serivce-main-page-main-box-card-price {
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;
  margin: 2vw;
}

.generate-bill-serivce-main-page-main-box-card-price > div {
  font-family: "Poppins";
  font-style: normal;
  color: green;
  font-weight: 600;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.generate-bill-serivce-main-page-main-box-card-buttons {
  width: calc(100% / 5);
  font-size: 17px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: rgba(0, 0, 0, 0.508);
}

.generate-bill-serivce-main-page-main-box-card-buttons > div {
  cursor: pointer;
}
.generate-bill-ongoing-and-pending-online-back {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  row-gap: 1.2rem;
}
.generate-bill-ongoing-and-pending-online-back > img {
  width: fit-content;
}

.generate-bill-ongoing-and-pending-online-back > p {
  margin-left: 20px;
  font-size: 19px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.681);
}
.addPlus {
  background-color: #189ca8;
  color: white;
  width: 8rem;
  height: 2rem;
  cursor: pointer;
  border-radius: 0.4rem;
}
.generate-bill-totlal-amount {
  background-color: #00838f;
  color: white;
  border-radius: 0.8rem;
  width: 14rem;
  height: 4rem;
  margin: auto;
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  text-transform: uppercase;
}
.modify-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.generate-bill-modify-modal-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.generate-bill-modify-modal {
  height: 200px;
  width: 400px;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 1.2rem;
}
.generate-bill-modify-modal > h4 {
  color: #00838f;
}
.generate-bill-modify-modal > div > input {
  background-color: #4db6c0;
  height: 2rem;
  border-radius: 6px;
  padding: 10px;
  color: black;
  border: none;
  appearance: textfield;
}
.generate-bill-modify-modal > div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 1.2rem;
}
.generate-bill-modify-modal > button {
  background-color: #00838f;
  color: white;
  width: 6rem;
  border-radius: 6px;
  height: 2rem;
  border: none;
}
.generate-bill-modify-modal > button:active {
  transform: scale(0.9);
  transition: transform 0.2s ease;
}
.generate-bill-modify-modal > .crossIcon {
  color: #00838f;
  font-size: xx-large;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.totalAmount {
  text-align: center;
  font-size: 18px;
}
.generate-bill-service-main-page .generate-bill-main-box{
min-height: 70px;
 flex-grow: unset;
 height: fit-content;
}
.generate-bill-button-container {
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
}
.generate-bill-button {
  position: static;
  bottom: 0;
  width: fit-content;
  padding: 0 20px;
  height: 40px;
  color: white;

  margin-top: 2rem;
  border: none;
  border-radius: 16px;
  background-color: #00838f;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pdf-button {
  height: 60px;
  width: 400px;
}
.active-button:active {
  transform: scale(0.9);
  transition: transform 0.2s ease;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .generate-bill-card-section-one {
    padding: 0 4px;
  }
  .generate-bill-serivce-main-page-main-box-card-information {
    flex-direction: column;
  }
  .generate-bill-serivce-main-page-main-box-card-image {
    width: 100px;
    height: 60px;
    object-fit: scale-down;
  }
  .generate-bill-service-main-page {
    margin-bottom: 50px;
  }
  .pdf-button {
    width: 200px;
    height: fit-content;
    padding: 10px;
    font-size: 18px;
  }
  .generate-pdf-form {
    width: 100%;
    margin: 0 10px;

  }
}
