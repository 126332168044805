.login-page-forgot-password-mobile-modal-background
{
 position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
}
.login-page-forgot-password-mobile-modal
{
     margin: auto auto;
    background: #fff;
    width: 85%;
    max-width: 500px;
    overflow-y: scroll;
    height: -moz-fit-content;
    height: fit-content;
    transition: all 0.6s ease;
    position: relative;
}
.login-page-forgot-password-mobile-modal-top-cross
{
 position: absolute;
 top: 2vw;
 right: 3vw;
}
.login-page-forgot-password-mobile-modal-top
{
 margin-top: 1.5vw;
 width: 100%;
 text-align: center;
 font-size: 23px;
 font-weight: 600;
 color: rgba(0, 131, 143, 0.768);
}
.login-page-forgot-password-mobile-modal-mid
{
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
 padding: 5vw 0 ;
 height: 35%;
}
.login-page-forgot-password-mobile-modal-mid>div
{
 height: 100%;
 width: 50%;
}
.login-page-forgot-password-mobile-modal-mid>div>img
{
 height: 100%;
 width: 100%;
}

.login-page-forgot-password-mobile-modal-end
{
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
}
.login-page-forgot-password-mobile-modal-end>input
{
 width: 90%;
 height: 40px;
 padding: 2vw 4vw;
 background-color: rgba(0, 0, 0, 0.102);
 border: none;
 outline: none;
}

.login-page-forgot-password-mobile-modal-button
{
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
 margin-top: 15px;
 margin-bottom: 25px;
}

.login-page-forgot-password-mobile-modal-button>button
{
 padding: 2vw 4vw;
 border-radius: 8px;
 border: none;
 background-color: rgba(0, 131, 143, 0.768);
 color: white;
}











.login-page-change-password-mobile-modal-background
{
  position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
}
.login-page-change-password-mobile-modal
{
  margin: auto auto;
    background: #fff;
    width: 85%;
    max-width: 500px;
    overflow-y: scroll;
    height: -moz-fit-content;
    height: fit-content;
    transition: all 0.6s ease;
    position: relative;
}
.login-page-forgot-password-mobile-modal-top-cross
{
  position: absolute;
 top: 2vw;
 right: 3vw;
}
.login-page-forgot-password-mobile-modal-mid-2
{
 width: 100%;
}
.login-page-forgot-password-mobile-modal-mid-2-heading
{
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
 font-size: 18px;
 font-weight: 600;
 color: rgba(0, 0, 0, 0.427);
}



.login-page-forgot-password-mobile-modal-mid-2-inputs
{
 display: flex;
 align-items: center;
 justify-content: center;
}

.login-page-forgot-password-mobile-modal-mid-2-otp-input
{
 width: 80%;
 display: flex;
 align-items: center;
 justify-content: flex-end;
 padding-right: 2.5vw;
 gap: 2.5vw;
}


.login-page-forgot-password-mobile-modal-mid-2-otp-input>input
{
     height: 40px;
    /* border: none; */
    border-top: black;
    border-right: black;
    border-left: black;
    text-align: center;
    font-size: 14px;
    outline: none;
    border-radius: 8px;
}




.login-page-forgot-password-mobile-modal-mid-2-inputs>span
{
 width: 20%;
 text-align: center;
 color: rgb(0, 131, 143);
 font-weight: 600;
}

.login-page-forgot-password-mobile-modal-bot
{
 margin-top: 15px;
 width: 100%;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 gap: 1vw;
}

.login-page-forgot-password-mobile-modal-bot>input
{

     width: 90%;
    height: 40px;
    padding: 2vw 4vw;
    background-color: rgba(0, 0, 0, 0.102);
    border: none;
    outline: none;
    border-radius: 8px;
}