.mobile-recharge-history-model {
  width: fit-content;
  max-height: 450px;
  padding: 37px;
  background: #ceebe7;
  overflow: auto;
  z-index: 9;
}
.mobile-recharge-history-model > button {
  display: none;
}

.mobile-recharge-history-model-empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;

  color: #11737c;
}
.recharge-history-recharge-card {
  width: 100%;
  height: auto;
  display: flex;
  border-radius: 20px;
  align-items: center;
  padding: 10px 40px;
  background: #fffcfc;
  gap: 70px;
  margin-bottom: 14px;
}
.recharge-history-recharge-card-col1 {
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.recharge-history-recharge-card-col1-image {
  height: 50px;
  width: 50px;
  border-radius: 100px;
}
.recharge-history-recharge-card-col1-image > img {
  height: 50px;
  width: 50px;
  border-radius: 100px;
}

.recharge-history-recharge-card-col3 {
  width: 30%;
  height: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.recharge-history-recharge-card-col3 > div {
  width: 94px;
  height: 32px;
  background: #4bb543;
  border-radius: 20px;
  display: flex;
  align-items: center;

  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  color: #ffffff;
}
.recharge-history-recharge-card-col3 > button {
  width: 94px;
  height: 32px;
  background: #ff0000;
  border-radius: 20px;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  justify-content: center;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;

  color: #ffffff;
}
.recharge-history-recharge-card-col3
  .recharge-history-recharge-card-col3-repeat-plan {
  background: #11737c;
}

.recharge-history-recharge-card-col2 {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.recharge-history-recharge-card-col2-row1 {
  padding: 0;
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.recharge-history-recharge-card-col2-row1 > div {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #6d6969;
}
.recharge-history-recharge-card-col2-row1 > div .recharge-history-amount {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #11737c;
}
.recharge-history-recharge-card-col2-row2 {
  width: 361px;
  height: 50%;
  display: flex;
  flex-direction: column;
}
.recharge-history-recharge-card-col2-row2 > div {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  color: #6d6969;
}
.recharge-history-recharge-card-col2-row1
  .recharge-history--mobile-amount-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
width: 100%;
}
.recharge-history-recharge-card-col2-row2 > div > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #11737c;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .mobile-recharge-history-model {
    padding: 17px;
    width: 90%;
    padding-top: 50px;
    position: relative;
  }
  .mobile-recharge-history-model > button {
    display: flex;
    right: 20px;
    top: 0px;
    margin-bottom: 30px;
  }
  .recharge-history-recharge-card {
    border-radius: 20px;
    height: auto;
    row-gap: 10px;
    justify-content: space-around;
    padding-bottom: 14px;
display: flex;
flex-direction: column;
align-items: center;
    column-gap: 2px;
  }
  .recharge-history-recharge-card-col2-row1 > div {
    display: flex;
    font-size: 16px;
    justify-content: center;
    width: 100%;
  }
  .recharge-history-recharge-card-col2-row2 > div {
    flex-wrap: wrap;
  }
  .recharge-history-recharge-card-col2-row1 .recharge-history--mobile-amount-container{
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .recharge-history-recharge-card-col2-row1 .recharge-history--mobile-amount-container>span:first-child{
    font-size: 18px;
  }
  .recharge-history-recharge-card .recharge-history-recharge-card-col2 {
    width: 250px;
justify-content: center;
    align-items: center;
  }
  .recharge-history-recharge-card
    .recharge-history-recharge-card-col2
    .recharge-history-recharge-card-col2-row2 {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;

    margin-top: -5px;
  }

  .recharge-history-recharge-card .recharge-history-recharge-card-col3 {
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;

  }
  .recharge-history-recharge-card-col1-image, .recharge-history-recharge-card-col1-image >img {
    width: 80px;
    height: 80px;
  }
  .recharge-history-recharge-card-col1 {
    padding-left: 10px;
    grid-column: 1/2;
    justify-self: end;
    margin-right: 20px;
  }
  .recharge-history-recharge-card-col2-row1 {

    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
  }
}
