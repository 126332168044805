.invoice-pdf-container{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.141); 
  display: flex;
  justify-content: center;
  padding: 20px;
  align-items: center;
  /* z-index: 1000;  */
  position: relative;

}
.invoice-pdf-container2{
  width: 100%;
  height: 100%;
  position:fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.141); 
  display: flex;
  justify-content: center;
  padding: 20px;
  align-items: center;
  z-index: 1000; 

}
.paperselected{
  position: absolute;
  z-index: 1;
  top:12%;
  right:19.5%;
  border: 1px solid rgba(0,0,0,.516);
  border-radius: 4px;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  padding: 0 9px;
  /* width: 7rem; */
}
.pdf-buttons-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.invoice-pdf-content{
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: end;

}

.invoice-pdf-container .invoice-pdf-content .close-button{
  border: none;
  font-size: 22px;
  background: transparent;
  font-weight: 700;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.invoice-pdf-container .invoice-pdf-content .save-pdf,.pdf-buttons-container >button,.invoice-pdf-container2 .invoice-pdf-content button{
  border: none;
  font-size: 20px;
  background: #00838f;
  font-weight: 700;
width: fit-content;
  color: #fff;
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
}

@media print {
  body{
    margin: 0;
  }
    .headingtextprint{
      font-size: 45px !important;
    }
    .headingtextprint2{
      font-size: 32px !important;
    }
    .textprint1,.headingpart4{
      font-size:30px !important;
    }
    .headingtextprint3{
      font-size:28px !important;
      width:auto !important;
    }
    .textprint{
      font-size:30px !important;
    }
    .text4{
      font-size: 27px !important;
    }
    .headingtextprint4{
      font-size:32px !important;
    }
    .hrr{
      width:380px !important;
    }
}