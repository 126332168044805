.my-electricity-recharge-main-page-container
{
 width: 100%;
 height: 100%;
 display: flex;
 flex-direction: column;
 align-items: center;
 padding: 27px;
 font-family: 'Ubuntu', sans-serif;
 /* justify-content: center; */
 overflow: auto;
}
.my-electricity-recharge-main-page-container-heading
{
 width: 100%;
 padding-left: 30px;
 display: flex;
 align-items: center;
 justify-content: flex-start;
 gap: 10px;

 font-size: 1.75rem;
     /* margin-bottom: 0.5; */
    font-weight: 500;
    /* line-height: 1.2; */
 font-family: 'Ubuntu', sans-serif;
}


.myprofileMaindivSecond:has(.my-electricity-recharge-main-page-container) {
    width:71% !important;
}

.my-electricity-recharge-main-page-container-heading>div
{
 display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.my-electricity-recharge-main-page-container-heading>div>span
{
     margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 14px !important;
    font-family: 'Ubuntu', sans-serif;
}
.my-electricity-recharge-main-page-container-heading>div>hr
{
 width: 100px;
 margin: 0px 0 0 0 ;
    height: 2px;
    max-width: 100px;
    background: #00838F;
}




.my-electricity-recharge-main-page-container-main-content
{
  width: 100%;
 display: flex;
 margin-top: 44px;
 align-items: flex-start;
 justify-content: center;
}
.my-electricity-recharge-main-page-container-main-content-col1
{
  width: 50%;
 flex-grow: 1;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
}
.my-electricity-recharge-main-page-container-main-content-col1>form
{
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 height: 100%;
 width: 100%;
 gap:25px
}
.my-electricity-recharge-main-page-container-main-content-col1>form>input
{
 border: 1px solid silver;
    padding: 5px 10px;
    /* margin-top: 5px; */
    border-radius: 10px;
    font-size: 18px;
    outline: none;
}
.my-electricity-recharge-main-page-container-main-content-col1>span
{
font-size: 13px;
    font-weight: 600;
    color: #2f2f2d;
}



.my-dth-recharge-main-page-container-main-content-col1-inputs-1>form
{
 display: flex;
 flex-direction: column;

}
.my-dth-recharge-main-page-container-main-content-col1-inputs-1>form>input
{
    width: 220px;
    height: 35px;
    background: #E7E9EC;
    border: 1px solid rgba(0, 166, 182, 0.5);
    border-radius: 2px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 34px;
    text-align: center;
    outline: none;
    color: #545556;
}
.my-electricity-recharge-main-page-container-main-content-col2{
    width: 50%;
    margin-top: -100px;

}
.my-electricity-recharge-main-page-container-main-content-col2 >div>img{
    width: 400px;

    
}

@media screen and (max-width: 720px) {
    .bill-payments-col2-mobile-view
    {
        display: none;
    }
}