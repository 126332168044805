.service-provider-mobile-advertisement-product
{
     display: flex;
    flex-direction: column;
    width: 100%;
    height: 264px;
    position: sticky;
    top: 0;
    background-color: #128B96;
    padding: 10px;
    padding-bottom: 13px;
    padding-top: 17px;
}


/* top container */
.service-provider-mobile-advertisement-top-container-product
{
 display: flex;
 align-items: center;
    width: 100%;
    height: 73%;
    gap: 4vw;
    /* justify-content: space-between; */
}

/* bottom contianer */
.service-provider-mobile-advertisement-bottom-container-product
{
 display: flex;
 width: 100%;
 height: 27%;
 align-items: flex-end;
}



/* image */
.service-provider-mobile-advertisement-image-container-product
{
width: 30%;
height: 100%;
display: flex;
flex-direction: column;
/* justify-content: center; */
padding-top: 1vw;
align-items: center;
display: flex;
justify-content: center;
align-items: center;
}
.service-provider-mobile-advertisement-image-product
{
width: 94px;
height: 94px;
background-color: #FFFF;
border-radius: 100%;
display: flex;
align-items: center;
justify-content: center;
z-index: 2;
overflow: hidden;
}
.service-provider-mobile-advertisement-image-product>img
{
width: 97px;
height: 97px;

z-index: 1;
}

.service-provider-mobile-advertisement-rating-container-product
{

}
.service-view-card-star-product
{

}
.service-provider-mobile-advertisement-rating-text-product
{

}
.service-provider-mobile-advertisement-number-rating-product
{
font-family: 'Titillium Web';
font-style: normal;
font-weight: 700;
font-size: 10px;
line-height: 15px;

color: rgba(255, 255, 255, 0.7);
}


/* info */
.service-provider-mobile-advertisement-info-container-product
{

 width: 70%;
}


/* name */
.service-provider-mobile-advertisement-name-container-product
{

}
.service-provider-mobile-advertisement-name-product
{

font-family: 'Titillium Web';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 37px;

color: #FFFFFF;
}
.service-provider-mobile-advertisement-time-product
{
font-family: 'Titillium Web';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;

color: #F8F8F8;

opacity: 0.8;
}



/* follow button */

.service-provider-mobile-advertisement-follow-container-product
{
 width: 35%;
 position: relative;
 display: flex;
 justify-content: flex-end;
}
.service-provider-mobile-advertisement-follow-product
{
 outline: none;
 border: none;
 width: 100%;
height: 40px;

background: #FFFFFF;
border-radius: 50px;
}
.service-provider-mobile-advertisement-follow-text-product{
font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
/* identical to box height */


color: #1E1E1E;
}



/* popularity */

.service-provider-mobile-advertisement-popularity-container-product
{
 display: flex;
 width: 65%;
 justify-content: space-evenly;
}
.service-provider-mobile-advertisement-follower-container-product
{

}
.service-provider-mobile-advertisement-follower-number-product
{
font-family: 'Roboto';
font-style: normal;
font-weight: 800;
font-size: 22px;
line-height: 26px;
/* identical to box height */


color: #F8F8F8;
}
.service-provider-mobile-advertisement-follower-text-product
{
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
/* identical to box height */

text-align: right;

color: #F8F8F8;

opacity: 0.8;
}
.service-provider-mobile-advertisement-break-container-product
{

border: 0.1px solid #F8F8F8;
opacity: 0.3;
width: 1px;
}
.service-provider-mobile-advertisement-following-container-product
{

}
.service-provider-mobile-advertisement-item-container-product
{

}



/* details */ 

.service-provider-mobile-advertisement-details-container-product
{

}
.service-provider-mobile-advertisement-location-container-product , .service-provider-mobile-advertisement-delivery-container-product ,.service-provider-mobile-advertisement-pickup-container-product
{
 display: flex;
 gap: 4vw;
 align-items: baseline;
}

.service-provider-mobile-advertisement-detail-image-container-product
{
width: 15px;
height: 15px;
}
.service-provider-mobile-advertisement-detail-image-product
{
width: 15px;
height: 15px;
}
.service-provider-mobile-advertisement-detail-text-product
{
font-family: 'Titillium Web';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
color: #F8F8F8;
opacity: 0.8;
}


/* other */
.service-provider-mobile-advertisement-other-container-product
{
position: absolute;
display: flex;
gap: 5vw;
    top: -45px;
    left: 33px;
}

.service-provider-mobile-advertisement-chat-product
{
 width: 23px;
 height: 23px;
}

.service-provider-mobile-advertisement-chat-product>img
{
 width: 23px;
 height: 23px;
}

.service-provider-mobile-advertisement-phone-product
{
width: 23px;
height: 23px;
}

.service-provider-mobile-advertisement-phone-product>img
{
width: 23px;
height: 23px;
}