
.uploadedImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.writeNewBlog {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.contentContainer {
  width: 50vw;
  height: fit-content;
  max-height: 500px;
  overflow: auto;
  margin-top: -50px;
  padding: 2rem ;
  background-color: white;
  position: relative;
}
.inputSection {
  display: flex;
  gap: 20px;
}
.titleInput {
  width: 50%;
}
.categoryInput {
  width: 50%;
}
.writeNewBlogNav {
  width: 100%;
  height: 82px;
  background-color: white;
  border-bottom: 1px solid #7d7f7f;
}
.writeNewBlogNavItems {
  width: 445px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 155px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.writeNewBlogNavDropdown select {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 150%;
  color: #344054;
  border: none;
  outline: none;
}
.boldOption,
.lineOption,
.writeNewBlogNavDropdown {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  border-left: 1px solid #7d7f7f;
}
.bold {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 0.5s all;
}

.blogHeading {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  margin: 10px;
  color: #00838f;
}
.WBDesc {
  padding: 18px 0;
}
.WBcaregoryBody {
  /* margin: 80px 0 36px; */
  padding: 0 20px;
}
.WBDesc h1,
.WBcategory h1,
.WBcaregoryBody h1 {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
}
.writeNewBlogNavDropdown {
  justify-content: center;
  padding: 0;
}
.WBcnacleUpload {
  width: 100%;
}
.WBuploadImg {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 32px;
  padding: 83px 20px;
  position: relative;
  margin: 50px 0;
  border: 1px solid #ccc;
}
.WBuploadImg h1,
.WBuploadImg p {
  margin: 0;
}
.cancelCross {
  position: absolute;
  top: 25px;
  right: 35px;
  cursor: pointer;
}
.WBuploadImg h1 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  color: #344054;
}
.WBuploadImg p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #344054;
  margin-top: 20px;
}
.WBcategory {
  width: 491px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.WBcategory h1 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 150%;
  color: #344054;
}
.WBcategory select {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 150%;
  color: #344054;
  padding: 3px 20px;
  border: none;
  outline: none;
}
.publishBtn {
  display: block;
}
.publishBtn button {
  width: 124px;
  height: 46px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  border: none;
  outline: none;
  background: #027e8b;
  border-radius: 10px;
  color: #ffffff;
  margin-top: 20px;
}
.WBcaregoryTextArea textarea {
  width: 100%;
  padding: 40px;
  background: #ffffff;
  border: none;
  outline: none;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 20px;
}
.WBcaregoryTextArea textarea::placeholder {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: #667085;
}
.blogCategory {
  background-color: white;
  color: #747474;
}

.blogCategory select {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  border: 2px solid #ccc;
  border-radius: 5px;
  width: 100%;
  max-width: 300px;
  background-color: white;
  border: 1px solid #d0d5dd;
}
.blogInput {
  width: 100%;
  height: 45px;
  outline: none;
  border: 1px solid #ccc;
  padding: 0 2rem;
}
.create-blog-text-options {
  width: 100%;
  height: fit-content;
  display: flex;
  border: 0.5px solid rgba(125, 127, 127, 0.5);
  border-bottom: 0;
}
.create-blog-text-options-caption {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #828282;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 0.5px solid rgba(125, 127, 127, 0.5);
  flex-grow: 2;
}
.create-blog-text-options-style-biu {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-grow: 1;
}

.create-blog-text-options-style-list {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-grow: 1;
}

.public-DraftEditor-content {
  height: 15rem;
  width: 100%;
  border: 1px solid rgba(125, 127, 127, 0.5);
  padding: 4px;
  background-color: white;
}
.field-required {
  color: red;
}

/* new */
.imageContainerBlog {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  width: 100%;
  height: 200px;

  text-align: center;

  color: #344054;
}

.imageContainerBlog label > input {
  display: none;
}
.imageContainerBlog label {
  cursor: pointer;
  border: 2px solid black;
  padding: 1.2rem;
  border-radius: 0.8rem;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: -4px 10px #004d55;
}
.imageContainerBlog label:hover {
  transform: translate(-3px, 1px);
  box-shadow: -2px 6px #004d55;
}
.create-blog-text-options-style-biu > button,
.create-blog-text-options-style-list > button {
  min-width: 10px;
  width: 35px;
  height: 35px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  background: white;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  margin: 5px;
  transition: all 0.5s ease;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .writeNewBlog {
    width: 100%;
    padding: 0;
  }
  .contentContainer {
    width: 100%;
    color: #00838f;
    position: relative;
  }
  .blogInput {
    height: calc(2.25rem + 2px);
    width: 100%;
    border-radius: 0px;
    color: #495057;
    padding: 0 10px;
  }

  .blogHeading {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .WBcaregoryBody,
  .create-blog-text-box {
    margin: 0;
  }
  .create-blog-text-options-style-biu > button,
  .create-blog-text-options-style-list > button {
    min-width: 10px;
    width: 35px;
    height: 35px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
    background: white;
    cursor: pointer;
    border-radius: 50%;
    border: none;
    margin: 5px;
    transition: all 0.5s ease;
  }
  .imageContainerBlog > label {
    width: 60%;
    height: 50px;
    font-size: 10px;
  }
  .create-blog-text-options {
    width: 100%;
  }
  .public-DraftEditor-content {
    width: 100%;
    border: 1px solid rgba(125, 127, 127, 0.5);
    margin-bottom: 0;
  }
  .create-blog-text-options-caption {
    font-size: 14px;
    width: fit-content;
    padding: 0;
  }
  .create-blog-text-options-style-list {
    border-left: 0;
  }
  .create-blog-text-options-style-biu {
    border-right: 0;
  }
  .create-blog-characters {
    display: none;
  }
  .publishBtn > button {
    margin-top: 20px;
  }
}
