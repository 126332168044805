.commentContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
    margin-bottom: 1.2rem;
    background-color: white;
    /* border-radius: 1.2rem; */
    height: 4rem;
    position: relative;
    overflow: hidden;
    height: fit-content;
    align-items: start;
    padding: 0 4px;
}
.commentcontainer1{
    overflow: visible;
}
.commentUser{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.commentData{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}
.commentDots{
    margin-left: auto;
}
.commentDots > img{
    cursor: pointer;
}
.moreOptionModal{
    position: absolute;
    color: black;
    right: 20px;
    top: 10px;
    border: 1px solid black;
    /* padding: 8px 12px 8px 12px; */
    z-index: 10;
    background-color: white;
    border-radius:7%;
    /* font-family: 'Comic Neue'; */
}
.moreOptionModal>p{
    cursor: pointer;
}

.commentData h6{
    font-size: 14px;
    color: #888;
    margin: 0;
    padding: 0;
}
.commentData p{
    justify-self: center;
}

.commentUser img{
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
}
.moreOptionModal > .deleteclick{
    /* border-top:2px solid black; */
    background-color:red;
    padding: 3px 24px 3px 24px;
    color:white;
}
.moreOptionModal > .editclick{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: center;
    padding: 3px 24px 3px 24px;
}