.footer {
  background: #00838f;
  border-radius: 0px;
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 50px;
  padding: 50px;
  color: #ffffff;
}
.footer-col1 {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
}
.footer-logo-container {
  width: 200px;
  height: 200px;
}
.footer-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.footer-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-text > h2 {
  font-size: 40px;
}
.footer-text > div {
  font-size: 14px;
}

.footer-col2 {
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  align-items: start;
}
.footer-col2 > div > h2 {
  text-transform: uppercase;
  font-size: 24px;
}
.footer-col2-links {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.footer-col3 {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
.footer-col3-heading {
  font-size: 20px;
  text-transform: uppercase;
}
.inner-divs {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
.footer-col3-img {
  height: 100px;
  width: 100%;
  object-fit: contain;
}

.footer-Useful-Links {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.footer-about-us,
.footer-contact-us,
.footer-terms-and-conditions {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 28px;
}

.footer-icon-container {
  display: flex;
  align-items: center;
  gap: 2vw;
}
.footer-instagram-container,
.footer-linkedin-container,
.footer-facebook-container {
  width: 41px;
  height: 41px;
  color: #00838f;
  font-size: 23px;
  background-color: white;
  border-radius: 100%;
  padding: 0.51vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-instagram-container > img,
.footer-linkedin-container > img,
.footer-facebook-container > img {
  width: 100%;
  height: 100%;
}
.showMoreFooter {
  display: none;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* .showMoreFooter {
    display: block;
    border: 2px solid;
    background-color: #00838f;
    color: white;
    font-size: 20px;
    border-radius: 20px;
    padding: 4px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    margin: 20px;
  } */
  .showMoreFooter {
    position: fixed; /* Fixed position */
    bottom: 0; /* Stick it to the bottom of the viewport */
    left: 0; /* Align it to the left edge */
    right: 0; /* Align it to the right edge */
    display: block;
    border: 2px solid;
    background-color: #00838f;
    color: white;
    font-size: 20px;
    border-radius: 20px;
    padding: 4px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px; /* Add margin to the left and right */
    cursor: pointer;
    z-index: 999; /* Ensure it appears above other content */
  }

  .footer {
    padding-left: unset;
    flex-direction: column;
    width: unset;
    margin:0px;
    padding: 20px;
    gap: 5px;
    height: fit-content;
    border-radius:20px 20px 0px 0px;
  }
  .footer-col1 {
    display: none;
  }
  .footer-col2 {
    flex-direction: column-reverse;

  }

  .footer-col2-links {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .footer-col2-links > div:first-child {
    border-left: unset;
  }
  .footer-col2-links > div {
    margin-left: 4px;
    padding-left: 4px;
    border-left: 1px solid white;
  }
  .footer-col3 {
    flex-direction: column;
    width: 100%;
    align-items: start;
    gap: 20px;
  }
  .inner-divs {
    align-items: start;
    gap: 3px;
  }
  .footer-icon-container {
    gap: 30px;
  }
  .footer-col3-img {
    margin-left: -17px;
    margin-top: -17px;
  }
}
/* @media screen and (max-width: 720px) {
  .footer {
    position: sticky;
    display: flex;
    flex-direction: column;
    font-size: small;
    padding-left: 10px;
    height: auto;
    z-index: 13;
  }
  .version {
    display: none;
  }
  .footer-company {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .footer-company-location {
    font-size: 16px;
    font-weight: 550;
  }
  .footer-terms-and-conditions {
    margin-bottom: 24px;
  }
  .footer-logo,
  .footer-logo-container {
    display: none;
    width: 80px;
    height: 80px;
    display: none;
  }
  .footer-logo-container {
    margin-bottom: 10px;
  }
  .footer-col3 {
    width: 100%;
    padding: 10px;
    display: inline;
  }
  .footer-col1 {
    padding: 10px;
    width: 100%;
  }
  .footer-Useful-Links {
    font-weight: 600;
    line-height: 33px;
  }
  .footer-about-us,
  .footer-contact-us,
  .footer-terms-and-conditions {
    line-height: 23px;
  }
  .footer-instagram-container,
  .footer-linkedin-container,
  .footer-facebook-container {
    font-size: 20px;
  }
  .footer-instagram-container,
  .footer-linkedin-container,
  .footer-facebook-container {
    font-size: 20px;
  }
} */
