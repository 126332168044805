.addcouponsection{
    padding-top:0px;
}
.coupontypeselection{
    width:90%;
}
/* .coupontypeselection .add-new-product-inputs {
    justify-content: space-around;
} */
h1 {
    color: green;
}

.multipleSelection {
    width: 300px;
    background-color: #BCC2C1;
}

.selectBox {
    position: relative;
}

.selectBox select,.text {
    width: 100%;
    font-weight: bold;
}

.overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

#checkBoxes {
    display: none;
    border: 1px #8DF5E4 solid;
}

#checkBoxes label {
    display: block;
}

#checkBoxes label:hover {
    background-color: #4F615E;
    color: white;
    /* Added text color for better visibility */
}
.checkboxcontainer {
    margin-bottom: 0px;
    width: 50%;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    position: relative;
}
.checkboxcontainer .couuuponcombo{
    width:100% !important;
    border:0px !important;
    outline:0px !important;
    font-family: "Roboto";
    font-style: normal;
    border: 2px solid #b9bebfb8 !important;
    font-weight: 800 !important;
    font-size: 13px !important;
    line-height: 153.69% !important;
    color: #0000006b !important;
    padding: 4px;
    border-radius: 10px;
    padding: 5px;
    display:flex;
    justify-content:space-between;
}
.productslist{
    display:flex;
    flex-direction:column;
    width:100%;
    border: 2px solid #b9bebfb8 !important;
    position:absolute;
    top:100%;
    border-radius:10px;
    max-height: 250px;
    overflow: auto;
    z-index:9999;
    background-color: white;
}
.productslist::-webkit-scrollbar{
    width: 4px !important;
    display: flex !important;
    height: 7px;
    margin-bottom: 30px;
}
.productslist::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 3px;
}
.productslist::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}
input[type="checkbox"]{
    width:7%;
    height:15px;
}
.checkboxcontainer input[type="text"]{
    font-family: "Roboto";
    font-style: normal;
    border: 2px solid #b9bebfb8 !important;
    font-weight: 800 !important;
    font-size: 13px !important;
    line-height: 153.69% !important;
    color: #0000006b !important;
    border-radius:10px;
    padding:4px;
    outline:0px;
}
.couponparent label{
    align-items: center;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 153.69% !important;
    color: #0000006b !important;
    padding: 5px 15px;
}
.arrowdown{
    width:10px;
}
.checkboxchild{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items:center;

}
.checkboxchild div{
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
}
.checkboxchild div label{
    margin-bottom:0px;
    color:black !important;
}
.checksellected1{
    display:flex;
    flex-direction: row;
    width: 50%;
    gap:30px;
    align-items:center;
    justify-content:center;
}
.checksellected1 input , .amountsection input{
font-family: "Roboto";
font-style: normal;
border: 2px solid #b9bebfb8 !important;
font-weight: 800 !important;
font-size: 13px !important;
line-height: 153.69% !important;
color: #0000006b !important;
border-radius:10px;
padding:4px;
outline:0px;
}
.amountsection input{
    width:110%;
}
.termsdiv{
    display:flex;
    flex-direction:row;
    width:100%;
    justify-content:space-between;
}
.useercheck{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.termsection{
    width:100%;;
}
.add-service-provider-profile-right-inputs:has(.cashbackyes){
    justify-content:center;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .coupontypeselection{
        width:100%;
    }
    .checkboxcontainer{
        width:100%;
    }
    .checksellected1{
        width:100%;
        flex-direction:column;
        align-items: unset;
        gap:10px;
    }
    .amountsection,.checksellected{
        width:100%;
    }
    .amountsection input{
        width:100%;
    }
    .checksellected{
        justify-content: flex-start;
        align-items: flex-start;
    }
}