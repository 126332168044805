.likes_container{

display: flex;
gap: 10px;
align-items: center;
}
.messageDiv{
    font-weight: 400;
    display: flex;
    align-items: start;
    justify-content: start;
}
.extractedTextDiv {
    width: 500px;
    white-space: normal;
    word-wrap: break-word; 
  }
  @media screen and (max-width: 720px) {
  .messageDiv {
gap: 3px;

flex-wrap: wrap;
  }
}