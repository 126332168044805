.my-feedback-profile-feedback-card-main {
  width: 100%;
  height: fit-content;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  /* gap: 30px; */
  margin: 10px auto;
  padding: 1vw;
}
.my-feedback-profile-feedback-card-row1 {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.my-feedback-profile-feedback-card-image {
  width: 25%;
  padding: 0 15px;
}
.my-feedback-profile-feedback-card-image > img {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: 100%;
}
.my-feedback-profile-feedback-card-col2 {
  height: 100%;
  width: 70%;
}
.my-feedback-profile-feedback-card-col2 > div {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #474747;
  margin-bottom: 20px;
}
.my-feedback-profile-feedback-card-col2 > div > div > input {
  margin-bottom: 7px;
}
.my-feedback-profile-feedback-card-col2 > section {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
  color: #757575;
}

.my-feedback-profile-feedback-card-col2 > div > input {
  height: 40px;
  /* width: 100%; */
  font-size: 12px;
  padding: 12px;
  border: 1px solid #d4d5d9;
  background-color: transparent;
  font-weight: 300;
  border-radius: 4px;
  line-height: 14px;
}

.my-feedback-profile-feedback-card-col3 {
  height: 180px;
  width: 5%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: relative;
}
.my-feedback-profile-feedback-card-col3 > div {
  cursor: pointer;
  background-color: black;
  width: 4px;
  height: 4px;
  background: #c4c4c4;
  border-radius: 100px;
  /* position: relative; */
}
.my-feedback-profile-feedback-card-col3-thredots {
  width: 90px;
  background: #ffffff;
  box-shadow: 2px 1px 9px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 28px;
  right: 60px;
}
.my-feedback-profile-feedback-card-col3-thredots > span {
  padding: 0.3vw 1vw;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: #757575;
  cursor: pointer;
}

.my-feedback-profile-feedback-card-row2 {
  width: 100%;
}
.my-feedback-profile-feedback-card-row2-replies {
  width: 80%;
  margin: 5px auto;
}
.my-feedback-profile-feedback-card-col2 > article {
  margin-top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
  color: #757575;
  cursor: pointer;
}
.my-feedback-profile-feedback-card-col2 > input {
  margin-top: 10px;
  height: 40px;
  width: 100%;
  font-size: 12px;
  padding: 12px;
  border: 1px solid #d4d5d9;
  background-color: transparent;
  font-weight: 300;
  border-radius: 100px;
  line-height: 14px;
}

/* //reply card */

.my-feedback-profile-feedback-card-replies-cont {
  width: 100%;
  padding: 1vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: fit-content;
}
.my-feedback-profile-feedback-card-replies-col1 {
}
.my-feedback-profile-feedback-card-replies-col1 > img {
  border-radius: 40px;

  width: 40px;
  height: 40px;
}
.my-feedback-profile-feedback-card-replies-col2 {
  width: 85%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1vw;
}
.my-feedback-profile-feedback-card-replies-col2 > div {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  min-width: 70px;
  font-size: 13px;
  line-height: 21px;
  color: #474747;
  /* margin-bottom: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.my-feedback-profile-feedback-card-replies-col2 > div > section {
  font-family: "Poppins";
  font-style: normal;
  text-align: justify;
  max-width: fit-content;
  padding-right: 30px;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #757575;
}
.my-feedback-profile-feedback-card-replies-col2 > div > article {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #757575;
}
.my-feedback-profile-feedback-card-replies-col3 {
  width: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  position: relative;
}

.my-feedback-profile-feedback-card-replies-col3 > div {
  cursor: pointer;
  background-color: black;
  width: 4px;
  height: 4px;
  background: #c4c4c4;
  border-radius: 100px;
}
@media screen and (max-width: 720px) {
  .my-feedback-profile-feedback-card-image > img {
    height: 20vh;
  }
  .my-feedback-profile-feedback-card-replies-col2 > div > article {
    font-weight: 500;
    font-size: 9px;
  }
  .my-feedback-profile-feedback-card-replies-col2 {
    padding: 0 10px;
  }
  .my-feedback-profile-feedback-card-replies-col2 > div > section {
    max-height: 100px;
    overflow-y: auto;
    scroll-behavior: smooth;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .my-feedback-profile-feedback-card-row2-replies {
    width: 100%;
    margin: unset;
    padding: 0 10px;
  }
  .my-feedback-profile-feedback-card-replies-cont {
    align-items: center;
    justify-content: space-between;
  }
  .my-feedback-profile-feedback-card-replies-col2 {
    width: 100%;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    overflow: hidden;
  }
  .my-feedback-profile-feedback-card-replies-col2 > div {
    width: 100%;
  }
  .my-feedback-profile-feedback-card-replies-col2 > div > section {
    max-height: unset;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: "...";
    cursor: pointer;
  }
  .expand {
    height: fit-content;
    max-height: 100px;
    max-width: 210px;
    overflow-y: scroll;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
  }
}
