.cart-main-page {
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.cart-main-page-item-row {
  width: 68%;
  margin-left: 8.33%;
  margin-right: 0.5%;
  padding: 1vw;
  height: calc(100vh - 85px);
  overflow: scroll;
}
.cart-main-page-item-row-flipped{
  width: 68%;
  margin-left: 8.33%;
  margin-right: 0.5%;
  padding: 1vw;
  height: calc(100vh - 85px);
  overflow: auto;
  direction: rtl;
  margin-top:30px;
  margin-bottom:10px;
}
.cart-main-page-item-row-flipped .cart-main-page-item-row {
  width:100%;
  margin-left: unset;
  margin-right: unset;
  direction: ltr;
  height:auto;
}

.cart-main-page .cart-main-page-item-row{
  margin-top: 10px;
}
.cart-main-page-amount-row {
  width: 32%;
  padding: 2rem 3rem;

  height: calc(100vh - 85px);
}
.cart-main-page-item-row-flipped::-webkit-scrollbar{
  height:7px;
  margin-top:10px;
  display:flex;
  width:4px;
}
.cart-main-page-item-row-flipped::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 5px;
}
.cart-main-page-item-row-flipped::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}
.cart-main-page-amount-row-heading {
  /* font-family: "Raleway", sans-serif; */
  font-size: 14px;
  font-weight: 700;
  color: #535766;
  text-transform: uppercase;
  margin-bottom: 12px;
  text-decoration: none;
}
.cart-main-page-amount-row-bag-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  margin-left: 5px;
}
.cart-main-page-amount-row-bag-total > div {
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  color: #535766;
}
.cart-main-page-amount-row-bag-total > span {
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  color: #535766;
}
.cart-main-page-amount-row-bag-discount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  margin-left: 5px;
}
.cart-main-page-amount-row-bag-discount > div {
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  color: #535766;
}
.cart-main-page-amount-row-bag-discount > span {
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  color: #535766;
}
.cart-main-page-amount-row-coupon-discount {
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  align-items: center;
  margin-bottom: 12px;
}
.cart-main-page-amount-row-coupon-discount > div {
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  color: #535766;
}
.cart-main-page-amount-row-coupon-discount > span {
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  color: #535766;
}
.cart-main-page-amount-row-final-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eaeaec;
}
.cart-main-page-amount-row-final-price > div {
  font-weight: 600;
  font-size: 15px;
  padding-top: 16px;

  color: #3e4152;
  line-height: 16px;
}
.cart-main-page-amount-row-final-price > span {
  font-weight: 600;
  font-size: 15px;
  padding-top: 16px;

  color: #3e4152;
  line-height: 16px;
}
.cart-main-page-amount-row-button {
  margin-top: 3rem;
  width: 100%;
  cursor: pointer;
  background: #00838f;
  color: white;
  /* padding: 19px 30px; */
  font-size: 14px;
  border-radius: 6px;
  font-weight: bold;
  margin-right: 5px;
}
.cart-main-page-amount-row-button > a > button {
  width: 100%;
  background: #00838f;
  color: white;
  padding: 19px 30px;
  font-size: 14px;
  border-radius: 6px;
  font-weight: bold;
  border: none;
  letter-spacing: 1px;
}
/* .cart-main-page-amount-row-button > a{
  width:100%;
} */
.cart-main-page-empty-cart-image {
  height: 650px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4fbf6;
}
.cart-main-page-empty-cart-image > img {
  width: 50%;
  height: 100%;
}
.LoadingContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 720px) {
  .cart-main-page-item-row-flipped .cart-main-page-item-row {
    width:100%;
  }
  .cart-main-page-item-row-flipped{
    width: 95%;
    margin-left: 13px;
    margin-right: 10px;
    margin-top:10px;
    direction:ltr;
    max-height: calc(100vh - 85px);
    height: unset;
  }
  .cart-main-page-empty-cart-image {
    height: 600px;
    align-items: flex-start;
  }
  .cart-main-page-empty-cart-image > img {
    width: 300px;
    height: 300px;
  }
}
