
.admin-profile-time-filter-calander-box {
    width: 100%;
    padding: 0 80px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap:10px;
}
.admin-profile-time-filter-calander-box  .admin-time-filter-calander-1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #2f2f2d;
}
.admin-profile-time-filter-calander-box .admin-time-filter-calander-3 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #2f2f2d;
    align-items: center;
}
.admin-profile-time-filter-calander-box  .admin-time-filter-calander-1 > input {
    border: 1px solid silver;
    padding: 5px 10px;
    /* margin-top: 5px; */
    border-radius: 10px;
    font-size: 18px;
    outline: none;
}

.admin-profile-time-filter-calander-box .admin-time-filter-calander-3> select {
    border: 1px solid silver;
    padding: 5px 10px;
    /* margin-top: 5px; */
    border-radius: 10px;
    font-size: 13px;
    outline: none;
    color: #6c757d !important;
    /* height: 35px; */
    /* margin-left: 25px; */
    width: 148px;
}
.adminnoresult{
    color: #000;
    display: flex;
    font-family: Poppins;
    font-size: larger;
    font-weight: 600;
    justify-content: center;
    width:100%;
}
.cardsection:has(.adminnoresult){
    grid-template-columns: unset;
}

.admin-page-view-main-right-data   .admin-page-view-main-right-data-box{
max-width: 93%;
margin: 0 auto;
}
.admin-page-view-main-right-data{
  z-index:1;
}
.admin-page-view-main-right-heading-data {
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 137%;
    letter-spacing: -0.8px;
    color: #1e293b;
    padding-left: 4vw;
    width: 100%;
  align-items: center;
    display: flex;
    gap: 50px;
  }
  .admin-page-view-main-right-data-bill-option1 {
    justify-content: space-between;
    padding-top:10px;
  }
  .admin-page-view-main-right-data-box-card1 {
    width: 100%;
    min-height: 80px;
    border-bottom: 1px solid rgba(26, 192, 213, 0.655);
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding:10px; */
    row-gap:10px;
    column-gap:10px;
    flex-wrap: wrap;
    padding:0px 8px 0px 8px;
  }
  .admin-page-view-main-right-data-box-card1 .admin-page-view-main-right-data-box-card-img{
    width:45px;
    height:45px;
  }
  .admin-page-view-main-right-data-box-cardparent{
   height: 500px;
    overflow:scroll;
  }
  .admin-page-view-main-right-heading-data  span {
    border-bottom: solid 4px #00838f;
    padding-bottom: 4px;
    padding-left: 10px;
    display: inline-block;
    padding-right: 16px;
  }
  .admin-page-view-main-right-heading-data > img {
    cursor: pointer;
  }
  .namephone{
    display:flex;
    flex-direction:column;
    justify-content: first baseline;
  }
  .seerviceimage{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: end;
  }
  .naaame{
    font-weight:600;
  }
  .phhhone{
    font-size: 15px;
  }
  .serviceprokaimage{
    width: 35px;
    height: 35px;
    border: 1px solid rgba(128, 128, 128, 0.512);
    border-radius: 50%;
    object-fit:contain;
  }
  .viewprod{
    background: transparent;
    border: none;
    color: #00838f;
    font-weight: 500;
    margin-left:9px;
  }
  .admin-page-view-main-right-data-box {
    width: 100%;
    margin-top: 1vw;
    padding: 1vw;
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
  
  .admin-page-view-main-right-data-box-card {
    width: 100%;
    min-height: 80px;
    border-bottom: 1px solid rgba(26, 192, 213, 0.655);
    /* border-bottom: 1px solid black; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .admin-page-view-main-right-data-box-card-img {
    width: 45px;
    height: 45px;
    border-radius: 100px;
    /* background-color: black; */
  }
  .admin-page-view-main-right-data-box-card-img > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 100px;
  }
  .admin-page-view-main-right-data-box-card-color {
    font-size: 14px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.354);
    display: flex;
    gap: 3px;
    align-items: center;
  }
  .admin-page-view-main-right-data-box-card-color1{
    color:rgb(57, 57, 57);
  }
  .admin-page-view-main-right-data-box-card-color .service-color-choice {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid gray;
    margin: 0 10px;
    cursor: pointer;
  }
  .admin-page-view-main-right-data-box-card-des {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    gap: 0;
    padding-left: 35px;
    text-align: justify;
    /* gap: 5px; */
    width: calc((100% - 40px) / 5);
    /* text-align: center; */
  }

  .admin-page-view-main-right-data-box-card-des > span {
    font-size: 15px;
    /* line-height: -1px; */
    font-weight: 600;
  }
  .admin-page-view-main-right-data-box-card-des > span:nth-child(1) {
    color: rgb(105, 34, 105);
    text-transform: capitalize;
  }
  .admin-page-view-main-right-data-box-card-des > span:nth-child(2) {
    font-weight: 500 !important;
    text-transform: capitalize;
    min-width: 90px;
    max-width: 200px;
    display: inline-block;
  }
  .admin-page-view-main-right-data-box-card-des > span:nth-child(3) {
    font-weight: 500 !important;
    font-size: 13px;
    /* text-transform: capitalize; */
  }
  .admin-page-view-main-right-data-box-card-mobile-info:has(.my-order-profile-card-box-item-status-view-more){
    min-width:20%;
  }
  .admin-page-view-main-right-data-box-card-qty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: calc((100% - 40px) / 4);
    text-align: center;
  
    font-size: 14px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.354);
  }
  .admin-page-view-main-right-data-box-card-price {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: calc((100% - 40px) / 4);
    text-align: center;
  
    font-size: 17px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.719);
  }
  .admin-page-view-main-right-data-box-card-provider-button >button
  {    font-size: 13px;
    font-weight: 700;
    background-color: #00838f;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    color: #ffffff;
    width: fit-content;
    
}
.Choose,.Choosed{
    background-color: rgb(22, 159, 7);
    color: rgb(255, 255, 255);
    border-radius: 6px;
    cursor: pointer;
    padding: 7px 14px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.Choosed{
  background-color: grey;
}
.admin-page-view-main-right-data-box-card-buttonss{
  display:flex;
  flex-direction:row;
  gap:20px;
}
.providerlists{
  z-index:9999;
  width:100%;
  height:100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);

}
.providerlistmodal{
  background-color: white;
  width:85%;
}
.admin-page-view-main-right-data-box-card-des11{
  width:max-content;
}
.colorsdivvs{
  display: inline-flex;
  align-items: center;
}
.colorsdivvs .admin-initial-choice{
  margin: 0 5px;
}
.alloteddserviceproviderr {
  font-size: 13.5px;
  font-weight: 600;
  white-space:break-spaces;
}
.availbablleservice{
  font-size: 17px;
    font-weight: 580;
    background-color: #00838f;
    padding: 6px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    color: #ffffff;
    width: -moz-fit-content;
    width: fit-content;
    margin-right:7px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.viiewsecction{
  display:flex;
  flex-direction:row;
  width:100%;
  justify-content: space-between;
}
@media screen and (min-width: 770px){
  .admin-page-view-main-right-data-box-card-provider-button {
    width: 15%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
}

@media screen and (max-width: 770px) {
  .availbablleservice{
    font-size:14px;
  }
    .admin-profile-time-filter-calander-box {
        flex-direction: column;
        padding: 0 10px;
align-items: stretch;
gap: 20px;
    }
    .admin-page-view-main-right-data-box-card-price {
        margin-top: -10px;
    }
    .admin-page-view-main-right-data-box {
        align-items: center;
    }
    .admin-page-view-main-right-data-box-card-des{
        width: calc((100% - 40px) / 2);
    }
  
    .admin-page-view-main-right-data-box-card-not-serviceprovider-price{        margin-top: -71px;}
    .admin-page-view-main-right-data-box-card-des {
        padding-left: unset;
    }
    .admin-page-view-main-right-data-box-card-provider-button >button{
        margin: 10px 0;
        width: calc(100vw - 160px);
    }
    .admin-page-view-main-right-data-box-card-des .admin-page-view-main-right-data-box-card-mobile-info {
        display: flex;
        width: calc(100vw - 120px);
        align-items: center;
        gap: 5px;
        margin-top: 4px;
    }
    .admin-page-view-main-right-data-box-card-des11{
      width:max-content;
    }
    .admin-page-view-main-right-data-box-card-img1{
      width:40px;
      height:unset;
    }
    .alloteddserviceproviderr{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: calc(100vw - 160px);
    font-weight: 600;
    }
}
.onlyforpendingprrice{
  width:100px;
}
.onlyforpendingdes{
  width: calc((100% - 40px) / 2);
  padding-left:17%;
}
.adddd{
  font-size: 15px;
  font-weight: 700;
  color: #00838f;
}
.my-order-profile-card-box-item-detailsadmin{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.my-order-profile-card-box-item-details-contentadmin{
background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    width: 60%;
    overflow-y: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    height:80%;
    overflow:auto;
}

.my-order-profile-card-box-item-details-contentadmin::-webkit-scrollbar {
  display: flex;
  margin-bottom: 30px;
  width: 7px;
}
.my-order-profile-card-box-item-details-contentadmin::-webkit-scrollbar-thumb {
  background-color:rgb(57, 57, 57);
  border-radius: 3px;
}
.my-order-profile-card-box-item-details-contentadmin::-webkit-scrollbar-track {
  background-color: #cacaca;
  border-radius: 3px;
}
.headingproductlist{
  font-size:19px;
  margin-bottom:unset;
  margin-top: unset;
}
.my-orders-modal-details-close-btnadmin{
    color: #00838f;
    font-size: 23px;
    font-weight: 600;
    border: none;
    background: none;
    cursor: pointer;
}
.my-order-profile-card-box-item-details-contentadmin > .HEADINGGADMIN{
 padding:5px 3px 5px 0px;
 display:flex;
 justify-content: space-between;
 gap:9px;
}
.productlistadminmainbody{
  display:flex;
  flex-direction: column;
}
@media screen and  (max-width: 770px)  {
  .admin-page-view-main-right-data-box-card-buttonss{
    /* width:100%; */
    justify-content: center;
  }
  .admin-page-view-main-right-data-box-card{
    align-items: baseline;
  }
  
}
@media screen and  (max-width: 487px)  {
  .my-order-profile-card-box-item-details-contentadmin{
    width:90%;
  }
 .viiewsecction{
  flex-direction:column;
  gap:10px;
 }
 .availbablleservice{
  width:98%;
  margin:auto;
 }
 .prriceee{
  font-size: 14px;
  white-space:nowrap;
 }
 .adddmindes{
  font-size: 12px;
}
}

@media screen and  (max-width: 690px)  {
  .my-order-profile-card-box-item-details-contentadmin{
    width:80%;
  }
}