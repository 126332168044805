.myblog-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 26px;
}
.myblog-blog-container {
  height: inherit;
  padding-top: 25px;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.myblog-blog-view{
  margin-top: 20px;
}
.myblog-blog-view .myblog-blog-page-heading{
  color: #1e293b;
  display: flex;
  font-family: Noto Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  justify-content: space-between;
  letter-spacing: -.8px;
  line-height: 137%;
  padding-left: 8vw;
  width: 100%;
}
.myblog-blog-view .myblog-blog-page-heading>span {
  border-bottom: 4px solid #00838f;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 20px;
}
.myblog-create-blog {
  padding-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.myblog-create-blog > div {
  width: max(50%, 570px);
}
.myblog-create-blog > div > a {
  text-decoration: none;
}
.MyBlogContainer {
  border: 2px solid #f4fbf6;
  background-color: white;
  border-radius: 2rem;
  padding: 1.2rem;
  height: 600px;
  width: fit-content;
  overflow: hidden;
  position: relative;
}
.blogOverlay {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  position: fixed;
  top: 0;
  left:0;
}
.EmptyBlogContainer{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 400;
}
.blogsofAll1{
  cursor: pointer;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .MyBlogContainer{
        min-height: 500px;
        max-height: 600px;
        height: fit-content;
        min-width: 100%;
        margin-top: 20px;
    }
    .myblog-blog-container:has(.MyBlogContainer1){
      /* height:unset; */
      height:500px !important;
    }
    .myblog-blog-view .myblog-blog-page-heading{
      justify-content: center;
    }
    .myblog-blog-container{
      padding: 40px 10px;
      height: 100vh;
      overflow: auto;
    }
    .myblog-blog-container:has(.MyBlogContainer1){
      padding-top:0px;
    }
    .myblog-blog-view:has(+.MyBlogContainer1){
      margin-top:0px;
    }
    .MyBlogContainer1{
      overflow:hidden;
      background-color:unset;
      max-height:unset;
      border-radius:0px;
      padding:unset;
      cursor: unset;
      margin-top:0px;
    }
    .blogOverlay{
      justify-content: center;
    }
  .myblog-blog-view {
    padding-bottom: 12px;
  }
}