.admin-service-profile-page-view-main{
    width: 100%;

}
.admin-service-profile-page-view-main .admin-service-profile-page-view-main-container{
    display: flex;
/* max-width: 90%; */
flex-wrap: nowrap;
    justify-content: space-between;
    margin: 20px auto ;
    overflow-x:scroll;
}
.admin-service-profile-page-view-main .admin-service-profile-page-view-main-headings{
        color: rgb(13, 82, 111);
    padding: 1vw;
    border: 2px solid rgba(0, 0, 0, 0.363);
    border-radius: 16px;
    margin-bottom: 10px;
    cursor: pointer;
    white-space:nowrap;
    margin: 0px 10px;
}
.admin-service-profile-page-view-main .admin-service-profile-page-view-main-headings >span {
    display:flex;
    width: 100%;
    align-items: center;
}
.admin-service-profile-page-view-main .admin-service-profile-page-view-main-headings-active{
background: #00838f;
color: white;
border: 1px solid rgba(11, 187, 200, 0.219);
}
.justloader{
    width:20px;
    height:20px;
    background-color:transparent;
    border-top:3px solid rgb(46, 46, 46);
    border-bottom:3px solid rgb(113, 113, 113);
    border-left:3px solid rgb(113, 113, 113);
    border-right:3px solid rgb(113, 113, 113);
    border-radius:50%;
    animation-name: loader;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    vertical-align:center;
}
.justloader1{
    width:20px;
    height:20px;
    background-color:transparent;
    border-top:3px solid rgb(13, 82, 111);
    border-bottom:3px solid rgb(204, 215, 220);
    border-left:3px solid rgb(204, 215, 220);
    border-right:3px solid rgb(204, 215, 220);
    border-radius:50%;
    animation-name: loader1;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    vertical-align:center;
}
@keyframes loader {
    0%{
        border-top:3px solid rgb(46, 46, 46);
        border-bottom:3px solid rgb(113, 113, 113);
        border-left:3px solid rgb(113, 113, 113);
        border-right:3px solid rgb(113, 113, 113);
    }
    25%{
        border-right:3px solid rgb(46, 46, 46);
        border-bottom:3px solid rgb(113, 113, 113);
        border-left:3px solid rgb(113, 113, 113);
        border-top:3px solid rgb(113, 113, 113);
    }
    50%{
        border-bottom:3px solid rgb(46, 46, 46);
        border-top:3px solid rgb(113, 113, 113);
        border-left:3px solid rgb(113, 113, 113);
        border-right:3px solid rgb(113, 113, 113);
    }
    100%{
        border-left:3px solid rgb(46, 46, 46);
        border-bottom:3px solid rgb(113, 113, 113);
        border-top:3px solid rgb(113, 113, 113);
        border-right:3px solid rgb(113, 113, 113);
    }
  }
@keyframes loader1 {
    0%{
        border-top:3px solid rgb(13, 82, 111);
        border-bottom:3px solid rgb(204, 215, 220);
        border-left:3px solid rgb(204, 215, 220);
        border-right:3px solid rgb(204, 215, 220);
    }
    25%{
        border-right:3px solid rgb(13, 82, 111);
    border-bottom:3px solid rgb(204, 215, 220);
    border-left:3px solid rgb(204, 215, 220);
    border-top:3px solid rgb(204, 215, 220);
    }
    50%{
        border-bottom:3px solid rgb(13, 82, 111);
    border-top:3px solid rgb(204, 215, 220);
    border-left:3px solid rgb(204, 215, 220);
    border-right:3px solid rgb(204, 215, 220);
    }
    100%{
        border-left:3px solid rgb(13, 82, 111);
    border-bottom:3px solid rgb(204, 215, 220);
    border-top:3px solid rgb(204, 215, 220);
    border-right:3px solid rgb(204, 215, 220);
    }
  }
@media screen and (max-width: 720px) {
    .admin-service-profile-page-view-main .admin-service-profile-page-view-main-container{
        flex-wrap: nowrap;
        max-width: unset;
        gap: 20px;
        padding: 0 10px;
        overflow-x: auto;
    }
    .admin-service-profile-page-view-main .admin-service-profile-page-view-main-headings {
 
        white-space: nowrap;
        padding: 10px;
        margin:unset;
    }
}