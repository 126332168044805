.notification-mobile-page-view-main
{
 width: 100%;
 height: 100vh;
}
.notification-mobile-page-view-main-holder
{
 width: 100%;
 height: calc(100vh - 60px);
 position: relative;
 background-color: white;
 display: flex;
 align-items: center;
 justify-content: center;
}

.notification-mobile-page-view-main-back-design
{
 width: 100%;
 height: 185px;
 position: absolute;
 background-color: rgba(0, 131, 143);
 z-index: 1;
 border-radius: 0 0 70px 70px;
 top: 0;
}
.notification-mobile-page-view-main-noti-box-list
{
 position: relative;
 z-index: 2;
 width: 90%;
 height: 90%;
 /* margin: auto; */
 background-color: rgb(241, 240, 240);
 border: 7px solid white;
 border-radius: 8px;
 padding: 2vw;
 overflow: auto;
 display: flex;
 /* align-items: center;
 justify-content: center; */
 flex-direction: column;
 gap: 10px;
 
}
.notification-mobile-page-view-main-noti-box
{
 width: 100%;
 background-color: white;
 border-radius: 8px;
 padding: 2vw;
 /* display: flex; */
 box-shadow: 0px 2px 5px rgba(0, 234, 255, 0.255);
}

.notification-mobile-page-view-main-noti-box-new
{
 width: 100%;
 padding-left: 2vw;
 color: red;
}
.notification-mobile-page-view-main-noti-box-col1
{
 width: 25%;

}

.notification-mobile-page-view-main-noti-box>div
{
 display: flex;
}
.notification-mobile-page-view-main-noti-box-col1
{
 width: 20%;
 display: flex;
 align-items: center;
 justify-content: center;
}
.notification-mobile-page-view-main-noti-box-col1>div
{
 height: 35px;
 width: 35px;
}
.notification-mobile-page-view-main-noti-box-col1>div>img
{
 width: 100%;
 height: 100%;
}


.notification-mobile-page-view-main-noti-box-col2
{

}

.notification-mobile-page-view-main-noti-box-row1
{
 color: rgb(3, 172, 28);
 font-weight: 600;
 margin-bottom: 8px;
}
.notification-mobile-page-view-main-noti-box-row2
{
 font-size: 15px;
 font-weight: 500;
}