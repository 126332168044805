.putatoeServiceView-container-mobile
{
  height: 100%;
 width: 100vw;
    background-color: #f4fbf6;
    /* padding-top: 20px; */
}
.allservice-mobile-last-all-service-hndle-on-sub-service
{
 width: 100%;
}