.cart-coupon-modal-container-background
{
 position: fixed;
 width: 100%;
 height: 100%;
 /* do something to stop scroll */
     background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
}
.cart-coupon-modal-container
{
 background: #fff;
 width: 360px;
 overflow-y: scroll;
    padding: 35px;
    height: 100vh;
    /* right: -400px; */
    transition: all 0.6s ease;
}
/* .cart-coupon-modal-container-intro-in
{
 right: 0 !important;
 transition: all 0.6s ease;
} */
.cart-coupon-modal-cross
{
     display: block;
    width: 100%;
    font-size: 25px;
    text-align: left;
    color: #00838f;
    cursor: pointer;
    font-weight: 800;
    line-height: 1.5;
}

.cart-coupon-modal-coupon-enter
{
 display: flex;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.cart-coupon-modal-coupon-coupon{
    overflow-y: auto;
    max-height: 500px;
    min-height: 700px;
    scroll-behavior: smooth;
}
.cart-coupon-modal-coupon-enter>button
{
     height: 40px;
    padding: 10px 18px;
    background-color: #00838f;
    font-weight:600;
        font-size: 12px;
        color: #fff!important;
        border: none;
            border-radius:  14px;
}
.cart-coupon-modal-coupon-no-coupon
{
     border: 1px solid #d4d5d9;
    margin-top: 30px;
    padding: 28px;
    text-align: center!important;
}
.cart-coupon-modal-coupon-no-coupon>img
{
 width: 100%;
 height: 230px;
}

.cart-coupon-modal-coupon-coupon .coupon-info{
    margin-top: 20px;
    border-bottom: 2px solid rgb(175, 174, 174);
}

.cart-coupon-modal-coupon-coupon .coupon-info h2{
    font-size: 13px;
    color:#00838f ;
    background: #49a3a777;
    font-weight: 600;
    width: fit-content;
    border-radius: 10px;
    padding: 8px;
}
.couuponname{
    font-weight: 650;
    font-size: 12px;
}
.cart-coupon-modal-coupon-coupon .coupon-info p{
    padding-bottom: 10px;
    font-size: 14px;
}
.loadingHolder{
    display: flex;
   margin-top: 100px;
}