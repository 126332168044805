.manage-services-profile-service-main-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.manage-services-profile-service-main-page-add-service {
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 50px;
}
.manage-services-profile-service-main-page-add-service > button {
  width: 160px;
  height: 45px;
  background: #00838f;
  border-radius: 10px;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.manage-services-profile-service-main-page-main-box-before {
  margin-left: 20px;
  font-size: 19px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.681);
  margin-top: 10px;
}

.manage-services-profile-service-main-page-main-box {
  height: 680px;
  width: 100%;
  margin-top: 1vw;
  padding: 1vw;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.manage-services-profile-service-main-page-main-box-card {
  width: 100%;
  min-height: 80px;
  border-top: 1px solid rgb(0 0 0 / 22%);
  border-bottom: 1px solid rgb(0 0 0 / 22%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6vw;
}
.manage-services-profile-service-main-page-main-box-card-provider {
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
  /* width: calc((100% - 40px)/4); */
  /* text-align: center; */
}

.manage-services-profile-service-main-page-main-box-card-price {
  display: flex;
  align-items: center;
}
.manage-services-profile-service-main-page-main-box-card-price > span {
  height: 40px;
  width: 35px;
}
.manage-services-profile-service-main-page-main-box-card-price > span > img {
  width: 35px;
  height: 40px;
  cursor: pointer;
}

.manage-services-profile-service-main-page-input- {
  width: 100%;
  /* margin-top: 60px; */
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.manage-services-profile-service-main-page-input-field-1 {
  width: 95%;
  display: flex;
  flex-direction:column;
  align-items: center;
  gap: 15px;
  /* padding-right: 10vw;  */
  justify-content: space-evenly;
  padding-left: 20px;
}
.manage-services-profile-service-main-page-input-field-1 > .add-service-provider-profile-right-inputs{
  width:50%;
  display:flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.manage-services-profile-service-main-page-input-field-1 > .add-service-provider-profile-right-inputs > select{
  width:100%;
  padding-right: 20px;
}
.manage-services-profile-service-main-page-input-fields > span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 153.69%;
  color: #000000;
  opacity: 0.5;
  /* margin-left: 47px; */
  padding-bottom: 20px;
  text-align: justify;
  display:flex;
  flex-direction:row;
  justify-content: center;
  /* width: 350px; */
}

.manage-services-profile-service-main-page-input-field-button {
  width: 95%;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-right: 10vw; */
}
.manage-services-profile-service-main-page-input-field-button > button {
  padding: 0.4vw 1.3vw;
  width: 160px;
  height: 45px;
  background: #00838f;
  border-radius: 10px;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.add-service-provider-profile-right-inputs >span{
  width:100%;
}
.mycontainermanagesevice{
  width:30%;
  height:40%;
}
.mycontainermanagesevicecenter{
  display:flex;
  flex-direction:column;
  justify-content:space-around;
  align-items:center;
  height:75%;
}
.mycontainermanagesevicecenter .headingOfFieldChange{
  font-size:22px;
  font-weight:600;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .manage-services-profile-service-main-page-main-box::-webkit-scrollbar {
    width: 8px !important;
    display: flex;
    height: 7px;
    margin-bottom: 30px;
  }
  .manage-services-profile-service-main-page-main-box::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 6px !important;
  }
  .manage-services-profile-service-main-page-main-box::-webkit-scrollbar-track {
    border-radius:6px !important;
  background-color:#f1f1f1;
  }

  .manage-services-profile-service-main-page-add-service {
    padding: unset;
    padding-right: 10px;
  }
  .manage-services-profile-service-main-page-add-service > button {
    width: 120px;
    font-size: 12px;
  }
  /* .manage-services-profile-service-main-page-main-box-before {
    margin-top: 30px;
  } */
  .manage-services-profile-service-main-page-main-box {
    margin-bottom: 20px;
    height:500px;
    /* overflow: scroll; */
    padding:0 20px;
  }
  .manage-services-profile-service-main-page-input-fields {
    margin-top: 50px;
  }
  .manage-services-profile-service-main-page-input-field-1 {
    padding: 5px;
    gap: 25px;
    justify-content: unset;
    flex-direction: column;
    align-items: unset;
    width:100%;
  }
  .manage-services-profile-service-main-page-input-fields > span {
    padding-bottom: unset;
    margin-left: unset;
    padding-left:10px;
    padding-right:10px;
  }
  .add-service-provider-profile-right-inputs {
    margin-top: unset;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: 10px;
    width: 100%;
  }
  .add-service-provider-profile-right-inputs > input,
  .add-service-provider-profile-right-inputs > select,
  .add-service-provider-profile-right-inputs > textarea {
    margin-bottom: unset;
    /* flex: 1; */
    width: 100%;
    background: rgb(229, 229, 229);
  }
  .manage-services-profile-service-main-page-input-field-button {
    margin-top: 20px;
    padding-right: unset;
  }
  .manage-services-profile-service-main-page-main-box-before {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .manage-services-profile-service-main-page-input-field-1 > .add-service-provider-profile-right-inputs{
    flex-direction:column;
    align-items: unset;
    width:100%;
  }
  .mycontainermanagesevice{
    width:75%;
  }
  .mycontainermanagesevicecenter .headingOfFieldChange{
    font-size:18px;
    font-weight:600;
  }
}
