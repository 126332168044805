.cart-main-page-item
{
    background-color: #fefefe;
position: relative;
padding: 10px;
    border-bottom: 1px solid #eaeaec;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.cart-main-page-item-cross
{
 font-weight: 800 ;
 font-size: 20px;
 color: #00838f;
 cursor: pointer;
 position: absolute;
    top: 4px;
    right: 38px;

}
.cart-main-page-item-col1
{
height: 200px;
width: 230px;
display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.cart-main-page-item-col1>img{
height: 100%;
width: 230px;
}
.cart-main-page-item-col2
{
 width: 100%;
 padding-left: 1.5rem;
}
.cart-main-page-item-col2-brand
{
     font-weight: 700;
    font-size: 14px;
}

.cart-main-page-item-col2-product_name
{
     overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
        color: #00838f;
        font-size: 13px;
        font-weight: bold;
}
.cart-main-page-item-col2-coupon_discount,.cart-main-page-item-col2-quantity,.cart-main-page-item-col2-product_quantity,.cart-main-page-item-col2-seller
{
 color: #94969f;
 font-weight: 400;
    line-height: 1.5;
    text-align: left;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-size: 13px;
}
.cart-main-page-item-col2-short_description
{
 color: green;
 font-weight: 400;
    line-height: 1.5;
    text-align: left;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-size: 13px;
}
.cart-main-page-item-col2-rates
{
 display: flex;
 gap: 6px;
 align-items: center;
 justify-content: flex-start;
}
.cart-main-page-item-col2-rates>div
{
font-size: 13px;
    font-weight: 700;
        line-height: 1.5;
    color: #212529;
    text-align: left;
}
.cart-main-page-item-col2-rates>span
{
font-size: 13px;
    font-weight: 700;
        line-height: 1.5;
    color: #212529;
    text-align: left;
    text-decoration: line-through;
    color: #94969f;
}
.cart-main-page-item-col2-rates>p
{
font-size: 15px;
    font-weight: 700;
        line-height: 1.5;
    color: #212529;
    text-align: left;

    color: #00838f;
}

.cart-main-page-item-col2-colorname{
display: flex;
color: #94969f;
font-size: 13px;
line-height: 1.5;
align-items: center;
}
.cart-main-page-item-col2-colorname .service-color-choice {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid gray;
    margin: 0 10px;
    cursor: pointer;
  }
.cart-main-page-item-col2-counter
{
 display:flex ;
 justify-content: flex-start;
 align-items: center;
}
.cart-main-page-item-col2-minus
{
 width: 28px;
 height: 28px;
    border-radius: 3px;
    border: 1px solid #00838f;
    background-color: #f4f4f5;
    color: #333;
    padding: 0;
    text-align: center;
    font-size: 0.9em;
    margin-right: 2.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.cart-main-page-item-col2-count
{
 width: 28px;
 height: 28px;
    border-radius: 3px;
    border: 1px solid #00838f;
    background-color: #f4f4f5;
    color: #333;
    padding: 0;
    text-align: center;
    font-size: 0.9em;
    margin-right: 2.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.cart-main-page-item-col2-plus
{
 width: 28px;
 height: 28px;
    border-radius: 3px;
    border: 1px solid #00838f;
    background-color: #f4f4f5;
    color: #333;
    padding: 0;
    text-align: center;
    font-size: 0.9em;
    margin-right: 2.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 900;
}


@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px){
    .cart-main-page{
        flex-direction: column;
    }
    .cart-main-page-item{
        justify-content: center;
        width: 100%;
        padding: 10px;
        gap: 10%;
        height: fit-content;
        
    }
    .cart-main-page-item-row-flipped .cart-main-page-item,.flippedcart .cart-main-page-item{
        gap:45px;
    }
    .cart-main-page-item-cross{
        right: 10px;
    }
    .cart-main-page-item-row{
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 100%;
        margin: 0;
    }
    .cart-main-page-item-col1{
        height: 80px;
        width: 80px;
    }
    .cart-main-page-item-col1 > img{
        object-fit: contain;
    }
    .cart-main-page-item-col2{
        padding:0;
        width: 50%;
        height: fit-content;
        line-height: normal;
    }
    .cart-main-page-item-col2-rates{
        flex-wrap: wrap;
    }
    .cart-main-page-item-col2-rates > div{
        width: 100%;
        font-size: 20px;
        margin-top: 4px;
    }
    .cart-main-page-item-col2-short_description{
        display: none;
    }
    .cart-main-page-item-col2-counter > div{
        margin: 10px 10px 0 0;
    }
    .cart-main-page-amount-row{
        width: 100%;
        height: fit-content;
    }
}