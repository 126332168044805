.sidebar-container
{
position: fixed;
width: 60px;
/* height: 652px; */
height: 100vh;
    background: #027E8B;
    top: 0;
    z-index: 10;
}
.sidebar-noHover{
padding-top: 69px;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
        align-items: center;
}
.sidebar-noHover-image
{
  height: 30px;
  width: 30px;
  border-radius: 100%;
}
.sidebar-decision
{
  padding-top: 69px;
overflow: hidden;
  width: 100%;
flex-direction: column;
position: absolute;
width: 0px;
height: 100%;
opacity: 0;
background: #027E8B;
z-index: 20;
  transition: all 0.6s ease;
  transition: width 0.4s;
}
.sidebar-container:hover{
  z-index: 20;
}
.sidebar-container:hover .sidebar-decision
{
  opacity: 1;
display: flex;
flex-direction: column;
position: absolute;
width: 284px;
height: 100%;
background: #027E8B;
  transition: all 1s ease;
}
.sidebar-container:hover .sidebar-noHover{
  display: none;
}

@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px){
  .sidebar-container{
    display: none;
  }
}