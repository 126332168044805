.my-bills-profile-page-view-main {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.my-bills-profile-page-view-main-left {
  width: 19.79%;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.37);
  padding-top: 74px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.my-bills-profile-page-view-main-right {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.my-bills-profile-page-view-left-top {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  border-bottom: 3px solid #1d8b97;
  color: #2b2b2b;
  margin-bottom: 60px;
}

/* .my-bills-profile-page-view-left-top-heading
{

} */

.my-bills-profile-page-view-main-right-top {
  width: 100%;
  padding: 0 1vw;
}
.my-bills-profile-page-view-main-right-main-box-before {
  margin: 0vw;
  margin-top: 3.5vw;
  margin-left: 2vw;
  /* text-transform: capitalize; */
  font-size: 19px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.681);
}
.my-bills-profile-page-view-main-right-main-box-before > span {
  color: rgba(0, 0, 0, 0.798);
  font-weight: 700;
}

/* //ways to use flex grow */
.my-bills-profile-page-view-main-right-main-box {
  width: 100%;
  margin-top: 1vw;
  padding: 1vw;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.my-bills-profile-page-view-main-right-main-box-card {
  width: 98%;
  height: 80px;
  border-top: 1px solid rgb(0 0 0 / 22%);
  border-bottom: 1px solid rgb(0 0 0 / 22%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.my-bills-profile-page-view-main-right-main-box-card-img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: black;
}
.my-bills-profile-page-view-main-right-main-box-card-img > img {
  width: 100%;
  height: 100%;
  border-radius: 100px;
}

.my-bills-profile-page-view-main-right-main-box-card-provider {
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
  width: calc((100% - 278px) / 4);
  text-align: center;
}

.my-bills-profile-page-view-main-right-main-box-card-des {
  display: flex;
  flex-direction: column;
  font-size: 11px;
  font: 700;
  color: rgba(0, 0, 0, 0.476);
  width: calc((100% - 40px) / 4);
  text-align: center;
}
.my-bills-profile-page-view-main-right-main-box-card-modify-button >button{
  padding: 0.2vw 0.3vw;
  outline: none;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  background-color: rgba(16, 156, 226, 0.221);

}
.my-bills-profile-page-view-main-right-main-box-card-time {
  font-size: 12px;
  font-weight: 600;
  width: calc((100% - 40px) / 4);
  text-align: center;
}

.my-bills-profile-page-view-main-right-main-box-card-price {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: calc((100% - 40px) / 4);
  text-align: center;
}
.my-bills-profile-page-view-main-right-main-box-card-price > span {
  font-size: 13px;
  font-weight: 700;
  background-color: #00838f;
  padding: 5px 20px;
  border-radius: 10px;
  cursor: pointer;
  color: #ffffff;
  width: 100px;
}
.my-bills-profile-page-view-main-right-main-box-card-price
  > .buttons-container
  > button {
  background-color: transparent;
  border-radius: 15px;
  border: 1.5px solid rgba(0, 0, 0, 0.267);
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.549);
  padding: 0.3vw 0.5vw;
}

.my-bills-profile-page-view-main-right-main-box-card-price
  > .buttons-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

/* part -2 */
.my-bills-profile-page-view-main-right-data {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.my-bills-profile-page-view-main-right-heading-data .my-order-profile-dropdown .ellipsis {
  cursor: pointer;
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: column;
justify-content: flex-end;
  gap: 2px;
  align-items: flex-end;
  padding: 5px;
}
.my-bills-profile-page-view-main-right-heading-data .my-order-profile-dropdown .ellipsis
.dot {
width: 4px;
height: 4px;

background-color: #333;
border-radius: 50%;
}

.my-bills-profile-page-view-main-right-data .dropdown-content {
  position: absolute;
  top: 80px;
  right: 0;
  padding: 2px 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.264);
  border-radius: 4px;
  z-index: 9;

}

.my-bills-profile-page-view-main-right-data .dropdown-content button {
  display: block;
  width: 100%;
  padding: 10px;
  text-align: left;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.my-bills-profile-page-view-main-right-heading-data {
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 137%;
  letter-spacing: -0.8px;
  color: #1e293b;
  padding-left: 4vw;
  width: 100%;
align-items: center;
  display: flex;
  gap: 50px;
}
.my-bills-profile-page-view-main-right-data-bill-option {
  justify-content: space-between;
}

.my-bills-profile-page-view-main-right-heading-data  span {
  border-bottom: solid 4px #00838f;
  padding-bottom: 4px;
  padding-left: 10px;
  display: inline-block;
  padding-right: 16px;
}
.my-bills-profile-page-view-main-right-heading-data > img {
  cursor: pointer;
}

.my-bills-profile-page-view-main-right-data-box {
  width: 100%;
  margin-top: 1vw;
  padding: 1vw;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.my-bills-profile-page-view-main-right-data-box-card {
  width: 100%;
  min-height: 80px;
  border-bottom: 1px solid rgba(26, 192, 213, 0.655);
  /* border-bottom: 1px solid black; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.my-bills-profile-page-view-main-right-data-box-card-img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  /* background-color: black; */
}
.my-bills-profile-page-view-main-right-data-box-card-img > img {
  width: 100%;
  height: 100%;
  border-radius: 100px;
}
.my-bills-profile-page-view-main-right-data-box-card-color {
  font-size: 14px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.354);
  display: flex;
  gap: 3px;
  align-items: center;
}
.my-bills-profile-page-view-main-right-data-box-card-color .service-color-choice {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid gray;
  margin: 0 10px;
  cursor: pointer;
}
.my-bills-profile-page-view-main-right-data-box-card-des {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  gap: 0;
  padding-left: 35px;
  text-align: justify;
  /* gap: 5px; */
  width: calc((100% - 40px) / 2);
  /* text-align: center; */
}
.my-bills-profile-page-view-main-right-data-box-card-des > span {
  font-size: 15px;
  /* line-height: -1px; */
  font-weight: 600;
}
.my-bills-profile-page-view-main-right-data-box-card-des > span:nth-child(1) {
  color: rgb(105, 34, 105);
  text-transform: capitalize;
}
.my-bills-profile-page-view-main-right-data-box-card-des > span:nth-child(2) {
  font-weight: 500 !important;
  text-transform: capitalize;
  min-width: 90px;
  max-width: 200px;
  display: inline-block;
}
.my-bills-profile-page-view-main-right-data-box-card-des > span:nth-child(3) {
  font-weight: 500 !important;
  font-size: 13px;
  /* text-transform: capitalize; */
}

.my-bills-profile-page-view-main-right-data-box-card-qty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: calc((100% - 40px) / 4);
  text-align: center;

  font-size: 14px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.354);
}
.my-bills-profile-page-view-main-right-data-box-card-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: calc((100% - 40px) / 4);
  text-align: center;

  font-size: 17px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.719);
}

.my-bills-profile-page-view-main-right-main-box-no-transactions {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.my-bills-profile-page-view-main-right-main-box-no-transactions > img {
  width: 500px;
  height: 500px;
}
.my-bills-options {
  width: 100%;
}
.my-bills-show-incoming-outgoing {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  height: 60px;
  justify-content: space-between;
}
.my-bills-show-incoming-outgoing .my-order-profile-dropdown {
  position: relative;
  display: inline-block;
  right: 30px;
}
.my-bills-show-incoming-outgoing .my-order-profile-dropdown .ellipsis {
  cursor: pointer;
  width: 20px;
  height: 30px;
  display: flex;
  flex-direction: column;

  gap: 2px;
  align-items: center;
  padding: 5px;
}
.my-bills-show-incoming-outgoing .my-order-profile-dropdown .ellipsis .dot {
  width: 4px;
  height: 4px;
  background-color: #333;
  border-radius: 50%;
}

.my-bills-show-incoming-outgoing .dropdown-content {
  position: absolute;
  background-color: #fff;
  right: 0px;
  top: 80px;
  border-radius: 10px;
  height: 78px;
  min-width: 260px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.my-bills-show-incoming-outgoing .dropdown-content ul {
  list-style-type: none;
}

.my-bills-show-incoming-outgoing .dropdown-content ul li {
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
.my-bills-show-incoming-outgoing .dropdown-content ul li:last-child:hover {
  background: #c5c8c8;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.my-bills-show-incoming-outgoing .dropdown-content ul li:hover {
  background: #c5c8c8;
  overflow: hidden;
}
.my-show-bills-profile-ongoing-and-pending-online-selector {
  width: 100%;
  height: 100px;
  margin-top: -50px;
  display: flex;
  flex-direction: column;
}
.my-bill-show-profile-ongoing-and-pending-box {
  flex-grow: 0.5;
  overflow: scroll;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .my-bill-show-profile-ongoing-and-pending-box{
    width:90%;
  }
  .my-bills-profile-page-view-main {
    flex-direction: column;
  }
  .my-bills-profile-page-view-main-left {
    border: none;
    width: 100%;
    height: fit-content;
    padding-top: 10px;
    overflow: hidden;
  }

  .my-bills-profile-page-view-main-right-main-box-card-mobileview {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 10px auto;

    padding: 10px 20px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
  .my-bills-profile-page-view-main-right-main-box-card-des,
  .my-bills-profile-page-view-main-right-main-box-card-price,
  .my-bills-profile-page-view-main-right-main-box-card-time,
  .my-bills-profile-page-view-main-right-main-box-card-provider {
    text-align: left;
    justify-content: space-between;
    align-items: flex-start !important;
  }
  .my-bills-profile-page-view-main-right-main-box-card-provider {
    width: 100px;
  }
  .my-bills-profile-page-view-main-right-main-box-card-provider2{
    width: 135px;
  }
  .my-bills-profile-page-view-main-right-data-box {
    align-items: center;
  }
  .my-bills-profile-page-view-main-right-data-box-card {
    width: 90%;
  }
  .my-bills-profile-page-view-main-right-main-box-card {
    justify-content: space-around;
  }
  .my-bills-profile-page-view-main .my-bills-profile-page-view-main-right2{
padding: 10px;
  }
  .my-bills-profile-page-view-main-right {
    min-height: 300px;
    height: auto;
  }
  
  .my-bills-profile-page-view-left-top {
    margin-bottom: 10px;
    border: none;
    font-size: 15px;
    line-height: 46px;
  }
  .my-bills-options {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .my-bills-options-hidden {
    display: none;
  }

  .profile-account-setting-container-left-1-selected {
    /* display: none; */
    width: 100%;
    max-height: 4px;
  }
  .my-bills-profile-page-view-main-right-main-box-no-transactions > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .my-bills-profile-page-view-main-right-main-box-card-price
    > .buttons-container {
    justify-content: flex-end;
    gap: 20px;
    width: auto;
  }
  .my-bills-profile-page-view-main-right-main-box-card-price
    > .buttons-container
    svg {
    width: 30px;
    height: 30px;
  }
  .my-bills-profile-page-view-main-right-main-box-card-price
    > .buttons-container
    > button {
    padding: 7px 14px;
  }
  .my-bills-profile-page-view-main-right-main-box-card-des,
  .my-bills-profile-page-view-main-right-main-box-card-time,
  .my-bills-profile-page-view-main-right-main-box-card-price {
    width: auto;
    flex-wrap: wrap;
  }
  .my-bills-profile-page-view-main-right-data-box-card-des > span {
    display: flex;
  }
  .my-bills-profile-page-view-main-right-data-box-card-des {
  padding-left: unset;
  }
  .my-bills-profile-page-view-main-right-data-box-card-des  .my-bills-profile-page-view-main-right-data-box-card-mobile-info{
display: flex;
width: calc(100vw - 120px);


align-items: center;

gap: 5px;

  }
  .my-bills-profile-page-view-main-right-data-box-card-des  .my-bills-profile-page-view-main-right-data-box-card-mobile-info span{
   font-size: 14px;
  }
  .my-bills-profile-page-view-main-right-heading-data .my-order-profile-dropdown {
    width: auto;
  }
  .incoming_outgoingsections{
    margin-top:40px !important;
  }
  .my-bills-profile-page-view-main-right-heading-data[value="showbills"]{
    justify-content: center;
  }
  .mobilefont{
    font-size: 25px;
  }
  .my-bills-profile-page-view-main-right-heading-data:has(.mobilefont){
    margin-top:unset;
    padding-left:2vw;
  }
}
