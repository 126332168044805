.deliveryheading{
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 137%;
    letter-spacing: -0.8px;
    color: #1e293b;
    border-bottom: solid 4px #00838f;
    margin: 0px auto 0px auto;
}
.my-order-profile-time-filter-calander-box:has(.deliveryheading){
    width:100%;
}
.modifyaddress{
    padding: 1vw 2vw;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    color: rgba(42, 42, 42, 0.5);
}
.deliverysection{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
}
.selectedpagedelivery{
    border-bottom: 3px solid #1D8B97;
    color: black !important;
}
@media screen and (max-width:500px) {
    .deliveryheading{
        margin-top:40px;
    }
}