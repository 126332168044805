.job-application-profile-view {
  width: 100%;
  height: 100%;
  padding: 27px;
}
.job-application-profile-input {
  margin: 20px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  overflow: auto;
  height: 520px;
  padding: 1vw;
  border: 1px solid #dde0e5;
}
.crossIcon-imagepath{
  color:red;
}
.loaderdiv{
  padding:70px 0px 70px 0px;
}
.crossIcon-imagediv{
  position: absolute;
  top:7%;
  right:5%;
  background-color: white;
  border-radius:50%;
  cursor: pointer;
}
.job-application-profile-input1{
  margin: 20px 0;
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-gap: 10px;
  overflow: auto;
  height: 520px;
  padding: 0.5vw;
  border: 1px solid #dde0e5;
}
.job-application-profile-input1 > .job-application-profile-input{
  width:99.5%;
  margin:0px !important;
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-gap: 10px;
  overflow: auto;
  /* height: 520px; */
  padding: 0px 10px 0px 0px !important;
  border: unset !important;
}
.job-application-profile-submit-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.job-application-profile-submit-buttons > button {
  border: none;
  width: 272px;
  height: 52px;
  background: #00838f;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  letter-spacing: -0.2px;
  color: #ffffff;
}
.job-application-field-required {
  font-size: 16px;
  display: inline;
  color: red;
}

.errormessageforjob{
  color: #cc0033 !important;
  display: inline-block !important;
  width: 100% !important;
  font-size: 14px !important;
  line-height: 15px !important;
  margin: 5px 0 0 !important;
  font-weight:400 !important;
}
.error1{
  border: 1px solid #cc0033 !important;
  outline: none !important;
}
.add-address-modal-coupon-enter-image{
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
}
.add-address-modal-coupon-enter-image-section, .add-address-modal-coupon-enter-image-section img{
  border-radius:50%;
  /* border: 10px solid red; */
}
.add-address-modal-coupon-enter-image-section{
  position: relative;
  width:200px;
  height:200px;
}
.add-address-modal-coupon-enter-image-section img{
  width:100%;
  height:100%;

}
.imagelabel{
  margin-top:4px;
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  cursor: pointer;
  color: #0066ff;
}
.add-address-modal-coupon-enter-image input{
  display:none;
}
.job-application-profile-input::-webkit-scrollbar{
  width:4px;
  margin-top:4px;

  display:flex;
}
.job-application-profile-input::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 5px;
}
.job-application-profile-input::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .job-application-profile-view {
    padding: 10px 27px;
  }
  .job-application-profile-input ,.job-application-profile-input1{
    grid-template-columns: 1fr;
    height: fit-content;
    border: none;
  }
  .profile-wallet-main-page-heading {
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding-left: unset;
    margin-bottom: 20px;
    }
  .profile-wallet-main-page-heading > span {
    font-size: 23px;
  }
  .loaderdiv{
    padding:10px 0px 10px 0px;
  }
}
