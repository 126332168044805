.all-myproducts-profile-serivce-main-page-main-box {
  display: grid;
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  overflow: auto;
  max-height: 630px;
  padding: 1vw;
}
.all-myproducts-profile-serivce-main-page-main-box-card {
  width: 100%;
  max-height: 200px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 10px 20px 20px 20px;
}

.all-myproducts-profile-serivce-main-page-main-box-card-amount {
  width: 100%;
  height: calc(100% / 4);
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.522);
  display: flex;
  align-items: center;
}
.all-myproducts-profile-serivce-main-page-main-box-card-amount > span {
  color: green;
  font-size: 18px;
  font-weight: 600;
}

.all-myproducts-profile-serivce-main-page-main-box-card-name {
  width: 100%;
  height: calc(100% / 4);
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.524);
  display: flex;
  align-items: self-end;
}
.all-myproducts-profile-serivce-main-page-main-box-card-name > span {
  color: black;
  font-size: 14px;
  font-weight: 600;
}
.all-myproducts-profile-serivce-main-page-main-box-card-qty {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}
.all-myproducts-profile-serivce-main-page-main-box-card-qty button {
  background-color: white;
  border: 1px solid black;
  padding: 3px 5px;
  border-radius: 10px;
  color: #027e8b;
}
.all-services-profile-service-main-page-add-service > button {
  width: 160px;
  height: 45px;
  background: #00838f;
  border-radius: 10px;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.all-myproducts-profile-serivce-main-page-main-box-card-qty select {
  outline: none;
  border: none;
  padding: 0 5px;
}
.all-myproducts-profile-serivce-main-page-main-box-card-desc {
  width: 100%;
  height: calc(100% / 4);
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.524);
  display: flex;
  align-items: center;
}
.all-myproducts-profile-serivce-main-page-main-box-card-desc > span {
  color: black;
  font-size: 16px;
  font-weight: 600;
}
.all-myproducts-profile-serivce-main-page-main-box-card-icon {
  width: 100%;
  height: calc(100% / 4);
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.524);
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.all-myproducts-profile-serivce-main-page-main-box-card-icon > img {
  width: 30px;
  height: 30px;
  cursor: pointer;
  fill: #027e8b;
}
.all-myproducts-profile-serivce-main-page-main-box-card-icon > i {
  font-size: 26px;
  cursor: pointer;
  color: #027e8b;
}

.all-services-profile-service-main-page {
  width: 100%;
  height: 100%;
  /* padding: 0px 10px; */
}
.all-myproducts-profile-service-main-page-inputs {
  margin-top: 30px;
  padding: 0 25%;
}
.all-myproducts-profile-service-main-page-inputs-1 {
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
}
.all-myproducts-profile-service-main-page-inputs-1 > span {
  font-size: 16px;
  font-weight: 700;
  color: #00838f;
  letter-spacing: 0.2px;
  padding-bottom: 8px;
}
.all-myproducts-profile-service-main-page-inputs-1 > input {
  height: 40px;
  width: 100%;
  font-size: 12px;
  padding: 12px;
  border: 1px solid #d4d5d9;
  background-color: transparent;
  font-weight: 300;
  border-radius: 4px;
  line-height: 14px;
}

.all-myproducts-profile-service-main-page-inputs-submit {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.all-myproducts-profile-service-main-page-inputs-submit > button {
  border: none;
  width: 272px;
  height: 52px;
  background: #00838f;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  letter-spacing: -0.2px;
  color: #ffffff;
}
#number-select {
  background-color: white;
}
.all-services-profile-service-main-page-main-box-before{
  margin-left: 20px;
  font-size: 19px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.681);
  margin-top: 10px;
}
.issusesolev{
  justify-content: unset !important;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .all-services-profile-service-main-page {
    padding: unset;
  }
  .all-services-profile-service-main-page  .all-myproducts-profile-serivce-main-page-main-box-card-desc {
height: unset;
flex-wrap: wrap;
width: 90%;

  }
  .all-myproducts-profile-serivce-main-page-main-box-card {
    max-height: 250px;
    height: fit-content;
  }
  .all-myproducts-profile-serivce-main-page-main-box {
    grid-template-columns: 1fr;
    padding: 0 20px;
    max-height: 570px;
    grid-gap: 10px;
  }
  .all-services-profile-service-main-page-main-box-before {
    text-align: end;
    margin-top: 10px;
    margin-right: 10px;
  }
  .all-myproducts-profile-service-main-page-inputs{
    padding: 0 20px;
  }
}
