.search-mobile-page-view-main
{
 width: 100%;
 height: 100vh;
 background-color: white;
}
.search-mobile-page-view-main-content
{
 width: 100%;
 height: calc(100vh - 60px);
 display: flex;
 flex-direction: column;
 /* //made a child element(result part) take all leftover space of a div using flex-grow: 1*/
}
.search-mobile-page-view-main-content-search-options
{
 position: relative;
 z-index: 2;
 width: 100%;
 display: flex;
 justify-content: center;
 flex-direction: column;
 align-items: center;
 gap: 10px;
}
.search-mobile-page-view-main-content-search-options1
{
 width: 100%;
 display: flex;
 justify-content: center;
 align-items: center;
 margin-top: 10px;
}
.search-mobile-page-view-main-content-search-options1>input
{
 width: 90%;
 padding: 2vw 4vw;
 outline: none;
    border: none;
    display: flex;
    position: relative;
    background: #FFFFFF;
    /* box-shadow: 2px 6px 6px rgba(0, 0, 0, 0.25); */
    border-radius: 12px;
    z-index: 10;
}


.search-mobile-page-view-main-content-search-options2
{
 width: 90%;
 background-color: white;
 border-radius: 0 0 70px 70px;
 display: flex;
 align-items: center;
 justify-content: space-evenly;
}
.search-mobile-page-view-main-content-search-options2>span
{
 text-align: center;
 font-weight: 600;
 color: rgb(1, 91, 99);
}
.search-mobile-page-view-main-content-search-result
{
 margin-top: 15px;
 flex-grow: 1;
 /* background-color: black; */
 width: 100%;
 display: flex;
 align-items: center;
 /* justify-content: center; */
 flex-direction: column;
 gap: 10px;
 overflow: auto;
}
.search-mobile-page-view-main-content-search-result-not-found
{
 width: 100%;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
}
.search-mobile-page-view-main-content-search-result-not-found>div
{
     background-color: #cfead6;
     padding: 3vw 6vw;
     color: rgb(1, 91, 99);
     font-weight: 600;
     font-size: 25px;
     text-align: center;
     margin-bottom: 5px;
     border-radius: 12px;
}
.search-mobile-page-view-main-content-search-result-not-found>div>section
{
 font-size: 15px;
}



.search-mobile-page-view-main-content-search-result-product-box
{
 width: 95%;
 box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.552);
 padding: 2vw;
 display: flex;
 border-radius: 12px;
 gap: 2vw;
 /* height: 200px; */
}

.search-mobile-page-view-main-content-search-result-product-box-col1
{
 width: 12%;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
}
.search-mobile-page-view-main-content-search-result-product-box-col1>div
{
 width: 100%;
 /* height: 100%; */
 
}
.search-mobile-page-view-main-content-search-result-product-box-col1>div>img
{
 width: 100%;
 height: 100%;
}
.search-mobile-page-view-main-content-search-result-product-box-col3
{
  width: 22%;
 height: 100%;
 display: flex;
 align-items: flex-start;
 justify-content: center;
 font-weight: 700;
 color: rgba(0, 0, 0, 0.658);
}

.search-mobile-page-view-main-content-search-result-product-box-col2
{
 flex-grow: 1;
 padding: 0 2vw;
}

.search-mobile-page-view-main-content-search-result-product-box-col2>div
{
 font-weight: 700;
 color: rgba(0, 0, 0, 0.658);
  white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
}

.search-mobile-page-view-main-content-search-result-product-box-col2>span
{
 font-weight: 700;
 color: rgb(1, 91, 99);
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
}
.search-mobile-page-view-main-content-search-result-product-box-col2>section
{
  font-weight: 600;
  font-size: 13px;
 color: rgba(0, 0, 0, 0.658);
  white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
}
.search-mobile-page-view-main-content-search-result-product-box-col2>article
{
  font-weight: 600;
  font-size: 13px;
 color: rgba(0, 0, 0, 0.658);
  white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
}
.search-mobile-page-view-main-content-search-result-product-box-col2>article>span
{
 color: green;
 font-size: 15px;
}












.search-mobile-page-view-main-content-search-result-service-box-col1
{
 width: 15%;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
}
.search-mobile-page-view-main-content-search-result-service-box-col1>div
{
 width: 100%;
 /* height: 100%; */
 
}
.search-mobile-page-view-main-content-search-result-service-box-col1>div>img
{
 width: 100%;
 height: 100%;
}


.search-mobile-page-view-main-content-search-result-service-box-col2
{
 flex-grow: 1;
 display: flex;
 flex-direction: column;
}
.search-mobile-page-view-main-content-search-result-service-box-col2>div
{
 font-weight: 700;
 color: rgb(38, 138, 147);
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
 font-size: 14px;

}
.search-mobile-page-view-main-content-search-result-service-box-col2>span
{
 font-weight: 600;
  font-size: 13px;
 color: rgba(0, 0, 0, 0.658);
  /* white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis; */
}
.search-mobile-page-view-main-content-search-result-service-box-col2>section
{
 font-weight: 600;
  font-size: 13px;
 color: rgba(0, 0, 0, 0.658);
}








.search-mobile-page-view-main-content-search-result-user-box-col1
{
 width: 15%;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
}
.search-mobile-page-view-main-content-search-result-user-box-col1>div
{
 width: 100%;
 /* margin: 0 3vw; */
 /* height: 100%; */
 
}
.search-mobile-page-view-main-content-search-result-user-box-col1>div>img
{
 width: 100%;
 height: 100%;
 border-radius: 100%;
}


.search-mobile-page-view-main-content-search-result-user-box-col2
{
 height: 100%;
 display: flex;
 align-items: center;
 flex-grow: 1;
 padding-left: 2vw;


  font-weight: 700;
 color: rgb(38, 138, 147);
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
 font-size: 14px;
 
}