.profile-wallet-main-page-view {
  width: 100%;
  height: 100%;
  padding: 42px;
}
.profile-wallet-main-page-heading {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  display: flex;
  justify-content: space-between;
  line-height: 137%;
  letter-spacing: -0.8px;
  color: #1e293b;
  padding-left: 8vw;
  width: 100%;
}

.profile-wallet-main-page-heading > span {
  border-bottom: solid 4px #00838f;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 20px;
}
.profile-wallet-main-page-top {
  height: 303px;
  width: 100%;
  display: flex;
}
.profile-wallet-main-page-top-col1 {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.profile-wallet-main-page-wallet-card {
  height: 264px;
  width: 419px;
  background: linear-gradient(
    68.44deg,
    #1a8a95 0%,
    #6ba6ff 49.27%,
    #dd7dff 100%
  );
  border-radius: 12px;
  padding: 27px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.profile-wallet-main-page-wallet-card-col1 {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9.6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: #000000;
}
.profile-wallet-main-page-wallet-card-col1 >div{
  align-items: center;
  display: flex;
}
.profile-wallet-main-page-wallet-card-col1 >div>b{
font-size: 20px;
margin-left: 4px;
} 
.profile-wallet-main-page-wallet-card-col2 {
  display: flex;
  height: 100%;
  width: 50%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.profile-wallet-main-page-wallet-card-col2 > b {
  font-family: "Poppins";
  font-style: normal;
  font-size: 15.9857px;
  line-height: 24px;
  letter-spacing: -0.319714px;
  padding-left: 20px;
  color: #000000;
}
.profile-wallet-main-page-wallet-card-col2 > div {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 92px;
  height: 38px;
  background: #ffffff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.profile-wallet-main-page-wallet-card-col2 > div > p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  color: #424242;
  text-align: center;
}

.profile-wallet-main-page-top-col2 {
  height: 100%;
  width: 50%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-wallet-main-page-top-col2-top {
  width: 350px;
  height: 98px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.profile-wallet-main-page-top-col2-top > div {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.319714px;
  text-decoration-line: underline;
  color: #4f4e4e;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
}
.profile-wallet-main-page-top-col2-top > section > p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  text-decoration-line: none;
  color: #7e7e7e;
}
.profile-wallet-main-page-top-col2-bot {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.profile-wallet-main-page-top-col2-bot-1,
.profile-wallet-main-page-top-col2-bot-2,
.profile-wallet-main-page-top-col2-bot-3 {
  width: 130px;
  height: 110.03px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 20px;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* //bottom */

.profile-wallet-main-page-bot {
  margin-top: 10px;
  overflow: auto;
  height: 503px;
  width: calc(100% + 42px);
  background-color: #ffffff;
  margin-left: -42px;
  margin-right: -42px;
  padding: 10px;
  padding-right: 0;
}

.profile-wallet-main-page-bot-heading {
  width: 100%;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 162.5%;
  letter-spacing: -0.8px;
  color: #1e293b;
  text-align: center;
}
.profile-wallet-main-page-bot-heading2 {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #64748b;
  text-align: center;
}
.profile-wallet-main-page-bot-table-head {
  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
  padding: 0px 16px;
  background: #f9fafb;
  border: 1px solid #dde0e5;

  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: rgba(100, 116, 139, 0.7);
}
.profile-wallet-main-page-bot-table-head > span:nth-child(1) {
  width: 10%;
  text-align: center;
}
.profile-wallet-main-page-bot-table-head > span:nth-child(2) {
  width: 12%;
  text-align: center;
}
.profile-wallet-main-page-bot-table-head > span:nth-child(3) {
  width: 20%;
  text-align: center;
}
.profile-wallet-main-page-bot-table-head > span:nth-child(4) {
  width: 15%;
  text-align: center;
}
.profile-wallet-main-page-bot-table-head > span:nth-child(5) {
  text-align: center;
  width: 20%;
}
.profile-wallet-main-page-bot-table-head > span:nth-child(6) {
  width: 23%;
  text-align: center;
}

.profile-wallet-main-page-bot-table-data {
  width: 100%;
}

.profile-wallet-main-page-bot-table-data-row,.smallscreen-table-data-row {
  width: 100%;
  height: 63px;
  display: flex;
  align-items: center;
  border-bottom: 0.8px solid #dee2e6;
  padding: 0 16px;

  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #64748b;
  text-align: center;
}
.smallscreen-table-data-row{
  display: none;
}

.profile-wallet-main-page-bot-table-data-row > span:nth-child(1) {
  width: 10%;
}
.profile-wallet-main-page-bot-table-data-row > span:nth-child(2) {
  width: 12%;
}
.profile-wallet-main-page-bot-table-data-row > span:nth-child(3) {
  width: 20%;
}
.profile-wallet-main-page-bot-table-data-row > span:nth-child(4) {
  width: 15%;
  color: green;
  font-weight: 700;
}
.profile-wallet-main-page-bot-table-data-row > div {
  width: 15%;
  color: red;
  font-weight: 700;
}
.profile-wallet-main-page-bot-table-data-row > span:nth-child(5) {
  width: 20%;
}
.profile-wallet-main-page-bot-table-data-row > span:nth-child(6) {
  width: 23%;
}

.profile-wallet-main-page-bot-table-show-more {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
.profile-wallet-main-page-bot-table-show-more > button {
  width: 99px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #adb5bd;
  border-radius: 6px;

  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* Dark */

  color: #1e293b;
}
@media screen and (max-width: 720px) {
  .profile-wallet-main-page-heading {
    font-size: 23px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .profile-wallet-main-page-view{
    padding: 5px;
  }
  .profile-wallet-main-page-top {
    width: 100%;
    height: fit-content;
    flex-direction: column;
    overflow: hidden;
  }
  .profile-wallet-main-page-top-col1 {
    width: 100%;
    padding: 20px;
    margin-top: 10px;
  }
  .profile-wallet-main-page-top-col2 {
    width: 100%;
  }
  .profile-wallet-main-page-top-col2-bot {
    margin: 20px 0px;
    gap: 20px;
  }
  .profile-wallet-main-page-top-col2-bot-1,
  .profile-wallet-main-page-top-col2-bot-2,
  .profile-wallet-main-page-top-col2-bot-3 {
    width: 100px;
    height: 100px;
    font-size: 10px;
  }
  .profile-wallet-main-page-top-col2-bot-1 > img,
  .profile-wallet-main-page-top-col2-bot-2 > img,
  .profile-wallet-main-page-top-col2-bot-3 > img {
    width: 30px;
  }
  .profile-wallet-main-page-bot {
    width: 100%;
    height: fit-content;
    margin: unset;
    padding: 0 8px;
  }
  .profile-wallet-main-page-bot-table-data {
    width: 100%;
  }
  .profile-wallet-main-page-view {
    padding: 0;
  }
  .profile-wallet-main-page-bot-table-head{
    display: none;
  }
  .smallscreen-table-data-row{
    flex-direction: column;
    gap: 4px;
    height: fit-content;
    padding: 10px;
    display: flex;
  }
  .table-data-row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:7px;
  }
  .table-data-row-last{
    justify-self: center;
  }
  .table-data-row > .column{
    color: black;
    font-weight: 400;
  }
  .column > span{
    color: #424242;
  }
  .column > .failedColor{
    color: red;
  }
  .column > .successColor{
    color: green;
  }
  .smallscreen-table-data-rowwallet{
    text-align: unset;
  }
  .smallscreen-table-data-rowwalletmobile{
    align-items: unset;
  }
  .profile-wallet-main-page-wallet-card-col1 >div {
    white-space: nowrap;
}

}
