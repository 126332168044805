.product-Image-Modal-background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: black;
  opacity: 0.5;
}
.product-Image-Modal-container {
  position: fixed;
  z-index: 200;
  width: 450px;
  height: 450px;
  background-color: white;
  opacity: 1;
  top: 50%;
  left: 50%;
  margin-top: -207px; /* Negative half of height. */
  margin-left: -209.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3vw;
  gap: 2vw;
}
.product-Image-Modal-image-container {
  width: 300px;
  height: 300px;
  overflow: hidden;
}
.product-Image-Modal-image-container > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 300px;
  max-height: 250px;
}
.product-Image-Modal-image-sec-container {
    height: 150px;
  display: flex;
  flex-direction: row;
  gap: 2vw;
  width: 100%;
  overflow-x: scroll;
}
.product-Image-Modal-image-sec .bright-active-image{
  filter: brightness(1.2);
    padding:  4px;
    border-radius: 10px;
    border: 1px solid #00838f;
}
.product-Image-Modal-image-sec {
  min-width: 80px;
  max-height: 80px;
}
.product-Image-Modal-image-sec > img {

    width: 100%;
    height: 100%;
 
    object-fit: contain;
}
.product-Image-Modal-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

@media screen and (max-width: 720px) {
  .product-Image-Modal-container {
    width: 330px;
    margin-top: 0px;
    padding-top: 40px;
    margin-left: 0;
    left: 50%;
    top: 59%;
    height: fit-content;
    transform: translate(-50%, -50%);
  }
  .product-Image-Modal-image-container {
    width: 250px;
  }
}
