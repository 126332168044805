.my-wallet-profile-add-money-background {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.267);
}
.my-wallet-profile-page-addMoney-modal {
  width: 646px;
  height: 433px;
  z-index: 2000;
  background: #fffcfc;
  padding: 50px;
  position: relative;
}

.my-wallet-profile-page-addMoney-modal > p {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 21px;
  text-align: center;
  color: #1f1f1f;
}
.my-wallet-profile-page-addMoney-modal > div {
  height: calc(100% - 33px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  z-index: 4000;
  position: relative;
  padding-bottom: 50px;
}
.my-wallet-profile-page-addMoney-modal > div > section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.my-wallet-profile-page-addMoney-modal > div > section > span {
  font-weight: 700;
  font-size: 40px;
  line-height: 21px;
}
.my-wallet-profile-page-addMoney-modal > div > section > input {
  width: 308px;
  height: 40.87px;
  background: #ffffff;
  border: 0.4px solid #474646;
  border-radius: 30px;
  padding: 21px;
  z-index: 4001;
  position: relative;
}

.my-wallet-profile-page-addMoney-modal > div > button {
  width: 85px;
  height: 35px;
  background: #1b1b1b;
  border: 0.4px solid rgba(255, 255, 255, 0.4);
  border-radius: 30px;

  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #ffffff;
}
.my-wallet-profile-page-addMoney-modal-image {
  position: absolute;
  z-index: 3000;
}
.my-wallet-profile-page-addMoney-modal-image > img {
  position: absolute;
  top: 12px;
  right: 40px;
  z-index: 3000;
}
.close-icon {
  position: absolute;
  top: 0px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 30px;
  font-weight: bold;
}

.my-wallet-profile-page-addMoney-modal-image > img:nth-child(2) {
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .my-wallet-profile-page-addMoney-modal {
    width: 80%;
    height: 300px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  .my-wallet-profile-page-addMoney-modal-image{
    display: none;
  }
  .my-wallet-profile-page-addMoney-modal > div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: unset;
    padding-bottom: unset;
  }
  .my-wallet-profile-page-addMoney-modal > div > section {
    width: 100%;
  }

  .my-wallet-profile-page-addMoney-modal > div > section > span {
    font-size: 25px;
  }
  .my-wallet-profile-page-addMoney-modal > div > button {
    width: 160px;
    height: 40px;
  }
  .my-wallet-profile-page-addMoney-modal > p {
    font-size: 23px;
  }
}
