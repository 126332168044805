.my-order-profile-main-page {
  width: 100%;
  height: 100%;
  padding: 42px;
}

.profile-order-main-page-heading {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 137%;
  letter-spacing: -0.8px;
  color: #1e293b;
}

.profile-order-main-page-heading > span {
  border-bottom: solid 4px #00838f;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 20px;
}

.my-order-profile-time-filter-calander-box {
  width: 100%;
  padding: 30px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.my-order-profile-time-filter-calander-1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: -200px;
  font-size: 13px;
  font-weight: 600;
  color: #2f2f2d;
}
.my-order-profile-time-filter-calander-1 > span {
  font-size: 18px;
  margin-left: 5px;
}
.my-order-profile-time-filter-calander-1 > input {
  border: 1px solid silver;
  padding: 5px 10px;
  /* margin-top: 5px; */
  border-radius: 10px;
  font-size: 18px;
  outline: none;
}

.my-order-profile-time-filter-calander-3 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 13px;
  font-weight: 600;
  color: #2f2f2d;
  align-items: center;
}
.my-order-profile-time-filter-calander-3 > span {
  font-size: 13px;
  margin-left: 5px;
}
.my-order-profile-time-filter-calander-3 > select {
  border: 1px solid silver;
  padding: 5px 10px;
  /* margin-top: 5px; */
  border-radius: 10px;
  font-size: 13px;
  outline: none;
  color: #6c757d !important;
  /* height: 35px; */
  /* margin-left: 25px; */
  width: 148px;
}

/* /part2 */
.my-order-profile-order-box-container {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  overflow: auto;
  height: 630px;
  padding: 1vw;
  /* border: 1px solid #DDE0E5; */
}

.my-order-profile-order-box-container::-webkit-scrollbar{
  height:7px;
  margin-top:10px;
  display:flex;
  width:4px;
}
.my-order-profile-order-box-container::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 5px;
}
.my-order-profile-order-box-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}
.myorder_loading {
  height: 6rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem;
}
.myorder_loading > span {
  font-size: 24px;
  color: #00838f;
}

.my-order-profile-card-box {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 300px;
  /* border: #00838F;
 background-color: #00838F; */
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 10px 20px 20px 20px;
  /* height: -moz-fit-content; */
  /* height: fit-content; */
}

.my-order-profile-card-box-time {
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.555);
  font-weight: 600;
}
.my-order-profile-card-box-item-status-shipped-time{


  font-size: 13px;
  color: rgba(0, 0, 0, 0.555);
  font-weight: 600;
}
.my-order-profile-card-box-time .my-order-profile-card-box-time-right >span {
  font-weight: 600;
  font-size: 17px;
  color: #1e293b;
}
.my-order-profile-card-box-item-detail {
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.my-order-profile-card-box-item-detail-left {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  width: 30%;
  height: 200px;
}
.my-order-profile-card-box-item-detail-left >span{
  display: flex;
  padding-left: 10px;
  font-weight: 700;
  color: #00838f;
  align-items: flex-start;
  height: 100%;
  margin-top: 20px;
  min-width: 30px;

}
.my-order-profile-card-box-item-details-content .my-order-profile-card-box-item-detail-left > img {
  padding-left: 20px;
}
.my-order-profile-card-box-item-detail-left > img {
  width: 100%;
  height: 100%;

}

.my-order-profile-card-box-item-detail-right {
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.my-order-profile-card-box-item-detail-right > div {
  font-size: 15px;
  font-weight: 700;
  color: black;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  position: relative;
  padding-bottom: 5px;

}
.my-order-profile-card-box-item-detail-right > div:first-child::after{
  content: '';
  position: absolute;
  left: 0;
  width: 40px;
  top: calc(100% - 5px); 


  bottom: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #0198a6;
}
.my-order-profile-card-box-item-detail-right .my-order-profile-card-box-item-status-view-more{

}
.my-order-profile-card-box-item-status button,.my-order-profile-card-box-item-detail-right .my-order-profile-card-box-item-status-view-more >button{
  background: transparent;
  border: none;
  color: #00838f;
  font-weight: 500;
}
.my-order-profile-card-box-item-detail-right > span {
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  padding-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.my-order-profile-card-box-item-detail-right > section {
  font-size: 10px;
  color: #0000008d;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.my-order-profile-card-box-item-detail-right > article {
  font-size: 13px;
  font-weight: 600;
  display: flex;
  gap: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.my-order-profile-card-box-item-detail-right > article > div {
  width: 0.7px;
  background-color: black;
  height: 21px;
}

.my-order-profile-card-box-item-status {
  margin-top: 15px;
  font-size: 15px;
  font-weight: 700;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 14px;
}
.my-order-profile-card-box-item-status > div {
  display: flex;
  align-items: center;
}

.my-order-profile-card-box-item-status > div > span {
  color: #00838f;
  font-weight: 500;
}
.my-order-profile-card-box-item-status > div > section {
  color: rgb(244, 8, 8);
  font-weight: 500;
}
.my-order-profile-card-box-item-status > div > img {
  height: 20px;
  margin-right: 5px;
}
.my-order-profile-card-box-item-status > div > p {
  color: black;
  font-weight: 500;
}

.my-order-profile-card-box-item-status-cancel {
  margin-top: 10px;
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-order-profile-card-box-item-status-cancel > button {
  background-color: #00838f;
  color: white;
  border: none;
  outline: none;
  padding: 3px 23px;
  border-radius: 10px;
}

/* noshow */
.my-order-profile-order-box-container-no-order {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.my-order-profile-order-box-container-no-order > img {
  width: 500px;
  height: 500px;
}

.my-order-profile-card-box-item-detail-right .my-order-profile-card-box-item-status-view-more{
width: fit-content;
gap: 20px;
display: flex;
justify-content: space-between;

}
.my-order-profile-card-box-item-detail-right .my-order-profile-card-box-item-status-view-more .my-order-profile-card-box-item-status-more-images{
  display: flex;
  gap: 5px;
}
.my-order-profile-card-box-item-detail-right .my-order-profile-card-box-item-status-view-more .my-order-profile-card-box-item-status-more-images >img{
  width: 35px;
  height: 35px;

  border: 1px solid rgba(128, 128, 128, 0.512);
  border-radius: 50%;
}
/* modal css */
.my-order-profile-order-box-container .my-order-profile-card-box-item-details {
  position: fixed;
  top: 0;
  left: 0;

  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;

}

.my-order-profile-order-box-container .my-order-profile-card-box-item-details .my-order-profile-card-box-item-details-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
gap: 20px;
  max-width: calc(100% - 600px);
  max-height: calc(100% - 240px);
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.my-order-profile-order-box-container .my-order-profile-card-box-item-details .my-order-profile-card-box-item-details-content::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
  display: flex;

}

.my-order-profile-order-box-container .my-order-profile-card-box-item-details .my-order-profile-card-box-item-details-content::-webkit-scrollbar-thumb {
  background-color: gray; /* Set the color of the scrollbar thumb */
  border-radius: 4px; /* Optional: Set border-radius for a rounded thumb */

}

.my-order-profile-order-box-container .my-order-profile-card-box-item-details .my-order-profile-card-box-item-details-content::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Optional: Set background color for the scrollbar track */

}
.my-order-profile-order-box-container .my-order-profile-card-box-item-details .my-order-profile-card-box-item-details-content .my-order-profile-card-box-tem-details-header{
position: relative;
display: flex;
justify-content: space-between;
width: 100%;
align-items: center;
}
.my-order-profile-order-box-container .my-order-profile-card-box-item-details .my-order-profile-card-box-item-details-content .my-order-profile-card-box-tem-details-header .my-order-profile-card-box-item-status-total-items{
color: #00838f;
font-weight: 800;
font-size: 18px;
}
.my-order-profile-order-box-container .my-orders-modal-details-close-btn {
  position: absolute;
  top:0;
  right: 0;
  color: #00838f;
  font-size: 1.9rem;

  font-weight: 700;
  border: none;
  background: none;

  cursor: pointer;
}
.track button{
  padding:3px 27px;
}
.trackorrder{
  position: fixed;
  z-index:999;
  top:0;
  bottom:0;
  right:0;
  left:0;
  background-color:rgba(0, 0, 0, 0.7);
  display:flex;
  align-items:center;
  justify-content: center;
}
.trackorrder .trackorderparent{
  width:65%;
  height:max-content;
  background-color:white;
  border-radius:10px;
  position: relative;
  padding:10px;
}
.trackorderparent .order-confirmation-cart-page-items-order-status-track{
  position: relative;
  background-color: #ddd;
  height: 7px;
  display: flex;
  margin-bottom: 60px;
  margin-top: 50px;
}
.trackorderparent .order-confirmation-cart-page-items-order-status-track .order-confirmation-cart-page-items-order-status-track-1{
margin-top:unset;
}
.trackorderparent .order-confirmation-cart-page-items-order-status-track .order-confirmation-cart-page-items-order-status-track-2{
margin-top:unset;
}
.trackorderparent .order-confirmation-cart-page-items-order-status-track .order-confirmation-cart-page-items-order-status-track-3{
margin-top:unset;
}
.trackorderparent .order-confirmation-cart-page-items-order-status-track .order-confirmation-cart-page-items-order-status-track-4{
margin-top:unset;
}
.tracking_status,.order_status{
  display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #00838f;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 1.5rem;
}
.order_status{
  font-size: 1rem;
}
.cannncel{
  white-space: nowrap;
}
.trackordernew{
  justify-content: end !important;
}
.trackordernew>div>span{
  white-space: nowrap;
  font-size:16px !important;
}
.trackordernew>div{
  align-items: end;
  margin-top:30px !important;
}
.trackordernew>div {
  align-items:end !important;
}
.cannncelstage{
  background: rgb(158, 2, 2);
  /* flex-grow: 1; */
  width: 25%;
  /* margin-top: -18px; */
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-confirmation-cart-page-items-order-status-track-1>div,.cannncelstage>div {
  align-items:end;
}
.cannncelstage>div>div{
  background: rgb(158, 2, 2);
  color: #fff;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  position: relative;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cannncelstage>div{
  width:100%;
}
.cannncelstage>div>div>img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
}
.cannncelstage>div>span {
  font-weight: 400;
  color: rgb(158, 2, 2);;
  display: block;
  margin-top: 7px;
  text-align: center;
  white-space: nowrap;
  font-size:16px !important;
}
.cannncelstage>div {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: end;
  /* justify-content: center; */
}
.datetime{
  font-size:10px !important;
  font-weight: 550;
  padding-left: 20px;
}
.timmee{
  display: inline-flex !important;
  flex-direction: column;
  align-items:end;
  justify-content:center;
}
.ordersstaus{
  font-size:12px !important;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 720px) {
  .my-order-profile-main-page {
    padding: 20px;
  }
  .profile-order-main-page-heading {
    font-size: 23px;
  }
  .my-order-profile-time-filter-calander-box {
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    padding: unset;
  }
  .my-order-profile-time-filter-calander-1 > span {
    font-size: 14px;
  }
  .my-order-profile-time-filter-calander-1,
  .my-order-profile-time-filter-calander-3 {
    width: 100%;
    margin: 0;
  }

  .my-order-profile-order-box-container {
    grid-template-columns: 1fr;
    max-height: 580px;
    padding: unset;
  }
  .my-order-profile-card-box-item-status {
    flex-direction: column;
    align-items: flex-end;
    gap: 3px;
    width: 50%;
    margin-top:0;
    padding-right: 10px;
  }
  .my-order-profile-card-box-time-right {
    display: flex;
    flex-direction: row;
    align-items: center;
}
  .my-order-profile-card-box-item-detail-right {
    width: 50%;
    overflow:visible !important;
    padding-left: 10px;
  }
  .my-order-profile-order-box-container-no-order > img {
    margin-top: 20px;
    width: 250px;
    height: 250px;
  }
  .my-order-profile-card-box {
    padding: unset;
    height: auto;
    padding:10px 0px 30px 0px;
    gap: 20px;
    max-height: fit-content;

  }
  .my-order-profile-order-box-containerorder{
    display:flex;
    flex-direction:column;
    align-items: center;

  }
  .my-order-profile-order-box-containerorder .my-order-profile-card-box{
    width:98%;
  }
  .my-order-profile-card-box-item-detail-right .my-order-profile-card-box-item-status-view-more {
    margin-top: 10px;
    width: calc(100% - 10px);
    overflow:visible;
  }
  .my-order-profile-card-box-time {
    position: absolute;
    padding:15px;
    align-items: center;
  }
  .my-order-profile-card-box-item-detail-left {
    margin-top: 30px;
    width: 100%;
    /* padding-left: 10px; */
  }
  .my-order-profile-card-box-item-detail-left > img {
    object-fit: contain;
    height:87%;
    /* padding-left:0px !important;
    width:140px; */
  }
  .my-order-profile-card-box-item-details-content .my-order-profile-card-box-item-detail .my-order-profile-card-box-item-detail-left img{
    padding-left:0px !important;
    width: 190px;
  }
  .my-order-profile-card-box-item-detail-left-class{
    margin:auto;
  }
  .my-order-profile-card-box-item-detail {
    flex-direction: row;
    align-items:baseline;
    justify-content: space-around;
  }
    .my-order-profile-card-box-item-details-content .my-order-profile-card-box-item-detail {
      align-items:baseline;
      gap:20px;
      flex-direction:column !important;
    }
    .my-order-profile-card-box-item-details-content .my-order-profile-card-box-item-detail .my-order-profile-card-box-item-detail-right{
      flex:1;
      overflow-x: hidden !important;
      display:flex;
      flex-direction: row;
      width:100%;
      justify-content :space-between;
      padding-left:17px;
      padding-right:10px;
    }
    .my-order-profile-card-box-item-detail-right > div:first-child::after{
      top:calc(0% + 23.5px) !important;
    }
    .my-order-profile-card-box-item-details-content .my-order-profile-card-box-item-detail .my-order-profile-card-box-item-detail-right .my-order-profile-card-box-item-detail-right-subsectionleft span 
    {
      display: inline-block;
      font-size: 15px;
      font-weight: 500;
      overflow: hidden;
      padding-top: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .my-order-profile-card-box-item-details-content .my-order-profile-card-box-item-detail .my-order-profile-card-box-item-detail-right .my-order-profile-card-box-item-detail-right-subsectionleft div{
      color: #000;
      font-size: 15px;
      font-weight: 700;
      overflow-x: hidden;
      padding-bottom: 5px;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;
    } 
    .my-order-profile-card-box-item-details-content .my-order-profile-card-box-item-detail .my-order-profile-card-box-item-detail-right .my-order-profile-card-box-item-detail-right-subsectionright article{
      flex-wrap:wrap;
      /* overflow-x: scroll; */
      text-overflow: ellipsis;
      display: flex;
      font-size: 13px;
      font-weight: 600;
      gap: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .my-order-profile-card-box-item-detail-right-subsectionleft,.my-order-profile-card-box-item-detail-right-subsectionright{
      display:flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .my-order-profile-card-box-item-details-content .my-order-profile-card-box-item-detail .my-order-profile-card-box-item-detail-right .my-order-profile-card-box-item-detail-right-subsectionright article > div{
      background-color: #000;
      height: 21px;
      width: 0.7px;
    }
    .my-order-profile-card-box-item-details-content .my-order-profile-card-box-item-detail-right-subsectionright div{
      font-size: 15px;
      font-weight: 700;
      color: black;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      position: relative;
      padding-bottom: 5px;
      justify-content: flex-end;
    }
    .my-order-profile-card-box-item-details-content .my-order-profile-card-box-item-detail .my-order-profile-card-box-item-detail-left{
      display:flex;
      justify-content: unset;
      flex:1;
      align-items:first baseline;
    }
    .my-order-profile-card-box-item-status-shipped-time{
      font-size: 11px;
    }

  .my-order-profile-order-box-container .my-order-profile-card-box-item-details .my-order-profile-card-box-item-details-content {
    max-width: 90%;
    max-height: calc(100% - 100px);
  }
  .trackordernew>div {
    align-items: end;
    margin-top: 55px !important;
}
.trackorrder{
  position: fixed;
  z-index:999;
  top:0;
  bottom:0;
  right:0;
  left:0;
  background-color:rgba(0, 0, 0, 0.7);
  display:flex;
  align-items:center;
  justify-content: center;
}
.crossIcon {
  width:13px;
}
.trackorrder .trackorderparent {
  width:80%;
  height:max-content;
}
.trackorderparent .order-confirmation-cart-page-items-order-status-track {
  width:2%;
  margin-left: auto;
  margin-right:auto;
  height:350px !important;
  border-radius:4px;
}
.trackorderparent .order-confirmation-cart-page-items-order-status-track {
  flex-direction:column;
}
.trackordernew{
  height:100px;
}
.trackorderparent .order-confirmation-cart-page-items-order-status-track .order-confirmation-cart-page-items-order-status-track-1{
  margin-top:unset;
  width:100%;
}
.curvestage{
    border-radius: 4px 4px 0px 0px;
  }
  .curvestage1{
    border-radius: 0px 0px 4px 4px;

  }
  .trackorderparent .order-confirmation-cart-page-items-order-status-track .order-confirmation-cart-page-items-order-status-track-2{
  margin-top:unset;
  width:100%;
  border-radius: 4px;
  }
  .trackorderparent .order-confirmation-cart-page-items-order-status-track .order-confirmation-cart-page-items-order-status-track-3{
  margin-top:unset;
  width:100%;
  border-radius: 4px;
  }
  .trackorderparent .order-confirmation-cart-page-items-order-status-track .order-confirmation-cart-page-items-order-status-track-4{
  margin-top:unset;
  width:100%;
  border-radius: 4px;
  }
  .order-confirmation-cart-page-items-order-status-track-1>div{
    /* align-items:unset !important; */
    width:100%;
  }
  .order-confirmation-cart-page-items-order-status-track-2>div{
    /* align-items:unset !important; */
    width:100%;
  }
  .order-confirmation-cart-page-items-order-status-track-3>div{
    /* align-items:unset !important; */
    width:100%;
  }
  .order-confirmation-cart-page-items-order-status-track-4>div{
    /* align-items:unset !important; */
    width:100%;
  }
  .trackordernew>div{
    align-items:center !important;
  }
  .tracking_status{
    font-size:1.2rem;
  }
  .order_status{
    font-size:0.8rem;
  }
  .cannncelstage{
    width:100%;
    border-radius:0px;
  }
}