.chat-box-left-container
{
 width: 34.78625%;
 height: 100%;
}
.chat-box-left-edit-row
{
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: flex-end;
 padding: 21px 57px;
 padding-right:24px
}
.chat-box-left-edit-row>svg
{
 height: 30px;
 width: 30px;
 cursor: pointer;
}
.chat-box-search-chat-container
{
 padding: 0 20px;
 width: 100%;
}
.chat-box-search-chat
{
 background-color: aliceblue;
 width: 100%;
 height: 35px;
background: #FFFFFF;
border-radius: 70px;
padding-left: 11px;
display: flex;
align-items: center;
justify-content: flex-start;
gap: 4px;
margin-bottom: 40px;
}
.chat-box-search-chat>input
{
 width: calc(100% - 4px );
 border-radius: 70px;
 height: 100%;
 padding-left: 17px;
 border: none;
 
}
.chat-box-search-chat>input:focus
{
 border: none;
 outline: none;
}
.chat-box-search-chat>img
{
 
}
.chat-box-left-category-handle
{
 display: flex;
 align-items: center;
 justify-content: space-around;
 height: 40px;
}
.chat-box-left-category-handle>div
{
 width: 30%;
 height: 100%;
/* font-family: 'Inter'; */
font-style: normal;
font-weight: 600;
font-size: 17px;
line-height: 17px;
color: #000000;
cursor: pointer;
border-bottom: 4px solid #128C7E;
display: flex;
align-items: center;
justify-content: center;
}
.chat-box-left-category-handle>span
{
 width: 30%;
 height: 100%;
 /* font-family: 'Inter'; */
font-style: normal;
font-weight: 600;
font-size: 17px;
line-height: 17px;
color: #000000;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
}
.chat-box-left-container-chat-icons
{
width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    max-height: 380px;
    overflow: auto;

}

.chat-box-right-container
{
 width: 65.2137468%;
 height: 100%;
 background: #FFFFFF;
}
.chat-box-right-container-empty-case
{
 width: 100%;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
}
.chat-box-right-container-empty-case>img
{
 height: 400px;
 width: 400px;
}
.chat-box-right-container-full-case
{
 height: 70px;
 width: 100%;
 background: #DBEDEF;
 display: flex;
 align-items: center;
 justify-content: space-between;
}
.chat-box-right-container-full-case-name
{
 width: 50%;
 padding-left: 17.5px;
 display: flex;
 align-items: center;
 justify-content: flex-start;
 gap: 10px;
}
.chat-box-right-container-full-case-name-image
{
 width: 50px;
 height: 50px;
 border-radius: 100px;
 background-color: #000000;
}
.chat-box-right-container-full-case-name-image>img
{
width: 50px;
 height: 50px;
 border-radius: 100px;
}
.chat-box-right-container-full-case-name-name
{
 font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
/* identical to box height */


color: #000000;
}

.chat-box-right-container-full-case-icons
{
 width: 50%;
  display: flex;
 align-items: center;
 justify-content: flex-end;
 gap: 10px;
 padding-right: 7.637vw;
}
.chat-box-right-container-full-case-icons-image
{
 background: #F8F8F8;
 border-radius: 100px;
 width: 38px;
 height: 38px;
 display: flex;
 align-items: center;
 justify-content: center;
}
.chat-box-right-container-full-case-icons-image>img
{
 height: 15.625px;
width: 22.499998092651367px;
}
.chat-box-actual-chat-place
{
 width: 100%;
 height: calc(100% - 141px);
 overflow: auto;
 padding-bottom: 10px;
}
.chat-box-actual-chat-place .emoji-picker{
    position: absolute;
    top: 50%;
    left: 50%;
}
.chat-box-actual-chat-place-send-message
{
 display: flex;
 width: 100%;
 height: 70px;
 padding-bottom: 20px;
 padding-left: 18px;
 justify-content: space-around;
}
.chat-box-actual-chat-place-send-message-mes
{
 display: flex;
 background: #E8ECEF;
box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.05);
border-radius: 20px;
width: 41vw;
align-items: center;
}
.chat-box-actual-chat-place-send-message-mes>input
{
width: calc(100% - 52px - 46px);
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
 background-color: transparent;
 border: none;
 outline: none;
}
.chat-box-actual-chat-place-send-message-mes>div
{
 width: 46px;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
}
.chat-box-actual-chat-place-send-message-mes>div>img{

 width: 20px;
height: 23px;
}
.chat-box-actual-chat-place-send-message-mes>span
{
 width: 52px;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
}
.chat-box-actual-chat-place-send-message-mes>span>img
{
width: 24px;
height: 24px;
}




.chat-box-actual-chat-place-send-message-mic
{
 margin: 0 27px;
 background: #128C7E;
box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.05);
border-radius: 110px;
width: 50px;
height: 50px;
display: flex;
align-items: center;
justify-content: center;
}
.chat-box-actual-chat-place-send-message-mic>img
{
width: 30px;
height: 30px;
}
.backButton{
    display: none;
}


@media screen and (max-width: 720px) {
    .chat-box-left-container,  .chat-box-right-container{
        width: 100%;


    }
    .chat-box-search-chat-container  .chat-box-search-chat {
        margin-bottom: 10px;
    }
    .chat-box-actual-chat-place-send-message-mes {
        width: 76vw;
    }
    .chat-box-actual-chat-place-send-message-mic {
transform: scale(0.8);
        margin: 0 10px;
    }
    .chat-box-right-container-full-case-name-image {
        width: 40px;
        height: 40px;
    
    }
    .chat-box-right-container-full-case-name-image>img {
        width: 40px;
        height: 40px;

    }
    .chat-box-right-container-full-case-name {
        width: 70%;
    }
    .chat-box-right-container-full-case-name-name {
        width: 120px;
        font-size: 15px;
    }
    .chat-box-right-container-full-case-icons-image {
        width: 30px;
        height: 30px;
    }
    .chat-box-right-container-full-case-icons-image>img {
        height: 15.625px;
        width: 15.499998px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .chat-box-left-container,.chat-box-right-container{
        position: absolute;
       height: 100%;
        width: 100%;
    }
    .chat-box-right-container{
        z-index: -1;

    }
    .chat-box-right-container-empty-case>img {
        height: 200px;
        width: 200px;
    }
    .showRightChat{
        z-index: 1;

    }
    .chat-box-right-container .backButton{
        display: block;
        font-size: 20px;
        cursor: pointer;
    }
    .chat-box-left-container-chat-icons{
max-height: 430px;
overflow-y: auto;
    }
}