.selection-panel-container
{
  width: 170px;
  height: 100%;
 display: flex;
 flex-direction: column;

 overflow: auto;
}

.Price-container {
  width: 100%;
  display: flex;
      align-items: flex-start;
    justify-content: center;
     padding: 29px 0px 0px 14px;
     flex-direction: column;
     text-align: justify;
}

.price-slider {
  display: flex;
  justify-content: center;
  -webkit-appearance: none;
  width: 99%;

  height: 3px;
  background: #d3d3d3;
  outline: none;
  margin-left: 6px;
background: rgba(89, 84, 84, 0.4);
backdrop-filter: blur(50px);
/* Note: backdrop-filter has minimal browser support */

border-radius: 100px;
}

.price-slider:hover {
  opacity: 1;
}

.price-slider::-webkit-slider-thumb {
  -webkit-appearance: none;

  width: 10px;
height: 10px;
background: #fff;
padding: 2.8px;
border: 6px solid #00838f;
box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.25);
border-radius: 10px;
}

.price-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;

  background: #00838f;
  cursor: pointer;
}





.price-button-container
{
 padding-top: 10px;
 width: 100%;
 display: flex;
 justify-content: space-between;
 gap: 3px;


}


.price-button-container>button
{
 outline: none;
 border: none;
 font-family: 'Roboto';
font-style: normal;
font-weight: 400;
letter-spacing: 0.9px;
font-size: 9px;
line-height: 11px;


 width: 34px;
height: 21px;
background: transparent;
border-radius: 4px;

color: black
}
.rangeData{
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  padding: 0 10px;
}

@media only screen and (max-width: 720px){
  .selection-panel-container{
    width: 100%;
    padding-top: 30px;

  }
  .price-slider {
    width: 80%;
  }
  .price-button-container{
    width: 83%;
  }
}