.service-provider-mobile-advertisement
{display: flex;
 flex-direction: column;
 width: 100%;
 height: 264px;
 position: sticky;
 top: 0;
 background-color: #128B96;
 padding: 10px;
 padding-bottom: 24px;
 padding-top: 32px;
}


/* top container */
.service-provider-mobile-advertisement-top-container
{
 display: flex;
    width: 100%;
    height: 60%;
    gap: 4vw;
    /* justify-content: space-between; */
}

/* bottom contianer */
.service-provider-mobile-advertisement-bottom-container
{
 display: flex;
 width: 100%;
 height: 40%;
 align-items: flex-end;
}



/* image */
.service-provider-mobile-advertisement-image-container
{
width: 25%;
display: flex;
flex-direction: column;
/* justify-content: center; */
padding-top: 1vw;
align-items: center;
}
.service-provider-mobile-advertisement-image
{
width: 74px;
height: 74px;
}
.service-provider-mobile-advertisement-image>img
{
width: 74px;
height: 74px;
}

.service-provider-mobile-advertisement-rating-container
{

}
.service-view-card-star
{

}
.service-provider-mobile-advertisement-rating-text
{

}
.service-provider-mobile-advertisement-number-rating
{
font-family: 'Titillium Web';
font-style: normal;
font-weight: 700;
font-size: 10px;
line-height: 15px;

color: rgba(255, 255, 255, 0.7);
}


/* info */
.service-provider-mobile-advertisement-info-container
{
justify-content: flex-start;
}


/* name */
.service-provider-mobile-advertisement-name-container
{

}
.service-provider-mobile-advertisement-name
{

font-family: 'Titillium Web';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 37px;
/* white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis; */
color: #FFFFFF;
}
.service-provider-mobile-advertisement-time
{
font-family: 'Titillium Web';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;

color: #F8F8F8;

opacity: 0.8;
}



/* follow button */

.service-provider-mobile-advertisement-follow-container
{
 width: 35%;
 position: relative;
 display: flex;
 justify-content: flex-end;
}
.service-provider-mobile-advertisement-follow
{
 outline: none;
 border: none;
 width: 100%;
height: 40px;

background: #FFFFFF;
border-radius: 50px;
}
.service-provider-mobile-advertisement-follow-text{
font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
/* identical to box height */


color: #1E1E1E;
}



/* popularity */

.service-provider-mobile-advertisement-popularity-container
{
 display: flex;
 width: 65%;
 justify-content: space-evenly;
}
.service-provider-mobile-advertisement-follower-container
{

}
.service-provider-mobile-advertisement-follower-number
{
font-family: 'Roboto';
font-style: normal;
font-weight: 800;
font-size: 22px;
line-height: 26px;
/* identical to box height */


color: #F8F8F8;
}
.service-provider-mobile-advertisement-follower-text
{
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
/* identical to box height */

text-align: right;

color: #F8F8F8;

opacity: 0.8;
}
.service-provider-mobile-advertisement-break-container
{

border: 0.1px solid #F8F8F8;
opacity: 0.3;
width: 1px;
}
.service-provider-mobile-advertisement-following-container
{

}
.service-provider-mobile-advertisement-item-container
{

}



/* details */ 

.service-provider-mobile-advertisement-details-container
{

}
.service-provider-mobile-advertisement-location-container , .service-provider-mobile-advertisement-delivery-container ,.service-provider-mobile-advertisement-pickup-container
{
 display: flex;
 gap: 4vw;
 align-items: baseline;
}

.service-provider-mobile-advertisement-detail-image-container
{
width: 15px;
height: 15px;
}
.service-provider-mobile-advertisement-detail-image
{
width: 15px;
height: 15px;
}
.service-provider-mobile-advertisement-detail-text
{
font-family: 'Titillium Web';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
color: #F8F8F8;
opacity: 0.8;
}


/* other */
.service-provider-mobile-advertisement-other-container
{
position: absolute;
display: flex;
gap: 5vw;
    top: -45px;
    left: 33px;
}

.service-provider-mobile-advertisement-chat
{
 width: 23px;
 height: 23px;
}

.service-provider-mobile-advertisement-chat>img
{
 width: 23px;
 height: 23px;
}

.service-provider-mobile-advertisement-phone
{
width: 23px;
height: 23px;
}

.service-provider-mobile-advertisement-phone>img
{
width: 23px;
height: 23px;
}