.post-comment-box
{
/* height: 130px; */
width: 100%;
display: flex;
justify-content: flex-start;
padding: 1vw;
position: relative;
/* max-height: 15%; */
align-items: center;
}
.post-comment-box-image-container
{
 /* background-color: bisque; */
width: 35px;
height: 35px;
border-radius: 50%;
margin-right:1vw ;
}
.post-comment-box-image-container>img
{
width: 35px;
height: 35px;
border-radius: 50%;
}
.post-comment-box-details
{
width: 100%;
}
.post-comment-box-details>div{
margin-bottom: 5px;
text-decoration: none;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 12px;
}
.post-comment-box-details>section
{
text-decoration: none;
    font-family: "Raleway", sans-serif;
    /* line-height:1.4rem; */
    font-size: 11px;
    color: #94969f;
}
.post-comment-box-details>span{
text-decoration: none;
    font-family: "Raleway", sans-serif;
    color: #000;
    font-weight: 500;
    /* line-height:1.4rem; */
    font-size: 10px;
}

.post-comment-box-dots
{
 width: 5px;
 height: 100%;
 display: flex;
 flex-direction: column;
 gap: 1px;
 position: relative;
 cursor: pointer;
}

.post-comment-box-dots-modal
{
 position: absolute;
top: 10px;
right: 10px;
 z-index: 100;
 display: flex;
 flex-direction: column;
 border: 1px solid black;
 z-index: 10;
 background-color: white;
 border-radius: 7%;
}
.post-comment-box-dots-modal .editclick{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 3px 24px 3px 24px;
}
.post-comment-box-dots-modal .deleteclick{
    background-color: red;
    padding: 3px 24px 3px 24px;
    color: white;
}
.post-comment-box-dots-modal>span
{z-index: 99;
font-weight: 600;
font-size: 14px;
    color: #00838f;
    cursor: pointer;
}
.post-comment-box-dots-modal:hover
{
    filter: brightness(0.8);
}