.cart-coupon-modal-mobile-container-background
{
 position: fixed;
 width: 100%;
 height: 100%;
 /* do something to stop scroll */
     background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.cart-coupon-modal-mobile-container
{
 background: #fff;
 width: 90vw;
 overflow-y: scroll;
    padding: 35px;
    height: 95vh;
    /* right: -400px; */
    transition: all 0.6s ease;
}
/* .cart-coupon-modal-mobile-container-intro-in
{
 right: 0 !important;
 transition: all 0.6s ease;
} */
.cart-coupon-modal-mobile-cross
{
     display: block;
    width: 100%;
    font-size: 25px;
    text-align: left;
    color: #00838f;
    cursor: pointer;
    font-weight: 800;
    line-height: 1.5;
}

.cart-coupon-modal-mobile-coupon-enter
{
 display: flex;
    height: 40px;
    align-items: center;
    margin-top: 15px;
}
.cart-coupon-modal-mobile-coupon-enter>input
{
 font-size: 14px;
    height: 40px;
    width: 220px;
    border-radius: 4px 0px 0px 4px;
    padding-left: 16px;
    border: 1px solid #d4d5d9;
}
.cart-coupon-modal-mobile-coupon-enter>button
{
     height: 40px;
    padding: 10px 18px;
    background-color: #00838f;
    font-weight:600;
        font-size: 12px;
        color: #fff!important;
        border: none;
            border-radius: 0px 4px 4px 0px;
}
.cart-coupon-modal-mobile-coupon-no-coupon
{
     border: 1px solid #d4d5d9;
    margin-top: 30px;
    padding: 28px;
    text-align: center!important;
}
.cart-coupon-modal-mobile-coupon-no-coupon>img
{
 width: 100%;
 height: 230px;
}