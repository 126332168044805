.service-provider-mobile-main-screen
{
background: linear-gradient(180deg, #E8F5FF 0%, #CBE9FF 31.26%, #E8F5FF 100%);
border-radius: 20px 20px 0px 0px;
padding:20px ;
padding-top: 0;
    padding-bottom: 264px;
    /* neccsary padding botttom dont mess with it */
    min-height: 100vh;
}
.service-provider-mobile-main-screen-filter
{
 /* background-color: black; */
 position: relative;
 height: 104px;
 padding: 1vw;
 padding-top: 5vw;
 width: 100%;
}
.service-provider-mobile-main-screen-filter-2
{
    position: relative;
 height: 204px;
 padding: 1vw;
 padding-top: 5vw;
 width: 100%;

}
.service-provider-mobile-main-screen-filter-heading
{
 height: 30%;
 display: flex;
 align-items: center;
 gap: 3Svw;
}
.service-provider-mobile-main-screen-filter-heading>img
{
 width: 20px;
 height: 30px;
}
.service-provider-mobile-main-screen-filter-heading>span
{
font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 23px;

color: #007D89;
}
.service-provider-mobile-main-screen-filter-item-container
{
 display: flex;
 overflow: auto;
 height: 50%;
 align-items: center;
 padding-top: 3vw;
 gap: 2vw;
}
.service-provider-mobile-main-screen-filter-item-container2
{
     display: flex;
 overflow: auto;
 height: 50%;
 align-items: center;
 /* padding-top: 3vw; */
 gap: 2vw;
}
.service-provider-mobile-main-screen-products-heading{
 font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 23px;

color: #007D89;
padding-bottom: 30px;
}

.service-provider-mobile-main-screen-products-container
{
display: flex;
flex-direction: column;
gap: 10vw;
}
.service-provider-mobile-main-screen-products
{

}


.service-provider-mobile-main-screen-filter-by-price-row1
{
    width: 100%;
    /* margin: 1vw; */
}
.service-provider-mobile-main-screen-filter-by-price-row1>span
{
float: right;
text-align:center;
margin-bottom: 2vw;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #1E1E1E;
    opacity: 0.8;
}