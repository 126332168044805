.service-provider-view
{
 background-color: #F5F5F5;
 display: flex;
}
.service-provider-view .filter-icon {
  display: none;
}
.selection-sidebar-view
{
width: 270px;
height: 949px;
}
.service-provider-mainscreen
{
 padding: 9px;
 width: 100%;
 display: flex;
 flex-direction: column;
 /* background-color: black; */
}
.service-provider-card-screen
{
 padding: 19px;
 display: grid;
    width: 100%;
    align-content: space-between;
    justify-content: center;
    grid-template-columns: 6fr 6fr 6fr 6fr 6fr;
    /* grid-column-gap: 0.987vw; */
    grid-row-gap: 2vw;
    justify-items: center;
    align-items: center;

    max-height: calc(949px - 161px);
    overflow: auto;
}

.loading {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1 / span 5;
  width: 100%;
  font-size: 17px;
}

.finishmsg {
  margin-top: 10px;
  color: #00838f;
  font-weight: bold;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1 / span 5;

  width: 90%;
  height: 1px;
  border:1px solid #00838f  ;
  /* display: none; */
}

.finishmsg>h5 {
  background-color: #F5F5F5;;
  padding: 0vw 1vw;
}

.service-provider-selector-subservice{
padding: 20px 0;
width: 100%;
}
.service-provider-selector-subservice>select {
  height: 40px;
  width: 25%;
  font-size: 12px;
  padding: 10px;
  border: 2px solid #d4d5d9;
  background-color: transparent;
  font-weight: 400;
  border-radius: 4px;
  line-height: 14px;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}




 @media only screen and (max-width: 1300px)
 {
  .service-provider-card-screen
{
    grid-template-columns: 6fr 6fr 6fr 6fr;
}
.loading
{
  grid-column: 1 / span 4;
}
.finishmsg
{
  grid-column: 1 / span 3;
}
 } 
@media only screen and (max-width: 1150px)
 {
  .service-provider-card-screen
{
    grid-template-columns: 3fr 3fr 3fr 3fr;
}
.loading
{
  grid-column: 1 / span 4;
}
.finishmsg
{
  grid-column: 1 / span 3;
}
 } 
 @media only screen and (max-width: 1065px)
 {
  .service-provider-card-screen
{
    grid-template-columns: 3fr 3fr 3fr ;
}
.loading
{
  grid-column: 1 / span 3;
}
.finishmsg
{
  grid-column: 1 / span 3;
}
 } 
  @media only screen and (max-width: 865px)
 {
  .service-provider-card-screen
{
    grid-template-columns: 1fr 1fr ;
    gap: 20px;
    padding-bottom: 100px;
}
.service-provider-selector-subservice>select {
  width: 50%;
}
.loading
{
  grid-column: 1 / span 2;
}
.finishmsg
{
  grid-column: 1 / span 3;
}
 }

 @media only screen and (max-width: 720px){
  .loading
  {
    grid-column: 1 ;
  }
  .finishmsg
  {
    grid-column: 1/3;
  }
  .service-provider-view{
    position: relative;
    min-height: 100vh;
  }
  .selection-sidebar-view{

    width: 100%;

    position: sticky;
    z-index: 12;


  }
  .selection-sidebar-view.hidden {
    display: none; 
    /* to hide selection panel if any other modal opens */
  }

  .service-provider-mainscreen{
position: absolute;
z-index: 11;
top: 2px;
bottom: -9px;
  }
  .filter-icon + .selection-sidebar-view{
visibility: hidden;
  }
  .filter-icon.active + .selection-sidebar-view{
    visibility: visible;
    display: flex;
    background: #fff;
  }
  


  .filter-icon{
    width: 40px;
    border-radius: 20px;
    padding: 5px;
    margin: 10px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    right: 0;
    position: fixed;
    top: 122px;
    z-index: 99;
    height: 30px;
    background: #17a2b8;
    color: #fff;
  }
 }












.obj {
  width: 6px;
  height: 40px;
  background: #00838f;
  margin: 0 3px;
  border-radius: 10px;
  animation: loading 0.8s infinite;
}

.obj:nth-child(2),
.obj:nth-child(9) {
  animation-delay: 0.1s;
}

.obj:nth-child(3),
.obj:nth-child(10) {
  animation-delay: 0.2s;
}

.obj:nth-child(4),
.obj:nth-child(11) {
  animation-delay: 0.3s;
}

.obj:nth-child(5),
.obj:nth-child(12) {
  animation-delay: 0.4s;
}

.obj:nth-child(6),
.obj:nth-child(13) {
  animation-delay: 0.5s;
}

.obj:nth-child(7),
.obj:nth-child(14) {
  animation-delay: 0.6s;
}

.obj:nth-child(8),
.obj:nth-child(15) {
  animation-delay: 0.7s;
}

.obje {
  padding: 15px;
  color: #00838f;
  font-weight: bold;
  text-align: center;
}

@keyframes loading {
  0% {
    height: 0;
  }
  50% {
    height: 40px;
  }
  100% {
    height: 0;
  }
}



