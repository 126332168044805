.post-card-modal-main-background {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background-color: black;
  opacity: 0.5;
}
.post-card-modal-container-my-promotions {
  transform: translate(-20%, -55%) !important;
}
.post-card-modal-container {
  background-color: white;
  padding: 20px 30px;
  position: relative;
  border: 2px solid #00838f;
  border-radius: 10px;
  height: 515px;
  overflow: scroll;
  width: 500px;
  position: relative;
}
.mypost-card-modal-container {
  position: fixed;
  z-index: 200;
  width: 60%;
  height: 515px;
  background-color: white;
  opacity: 1;
  margin-top: 5%;
  margin-left: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  gap: 10px;
  overflow: hidden;
}
.post-card-modal-image {
  width: 100%;
  height: 60%;
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 10px 0px;
}
.post-card-modal-image > img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.post-card-modal-comments-heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.post-card-modal-details {
  width: 100%;
  height: 100%;
}
.post-card-post-text {
  max-height: 27px;
  overflow: auto;
}
.post-card-post-text-heading {
  max-height: none;
  width: 100%;
  overflow: initial;
}
.post-card-modal-top-row-cross {
  /* width: 100%; */
  text-align: end;
  color: #000000;
  font-size: 20px;
  font-weight: 900;
  font-family: "Roboto";
  font-style: normal;
  cursor: pointer;
  position: fixed;
  top: 3px;
  right: 15px;
}

.post-card-modal-post-text {
  width: 100%;
  /* min-height: fit-content; */
  padding: 6px 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  display: flex;
  /* align-items: center; */
  overflow-y: scroll;
  min-height: 20%;
  max-height: 20%;
}

.post-card-modal-post-image-hr {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 3vw;
  margin-top: 4px;
  height: 1px;
  background-color: rgba(128, 128, 128, 0.819);
  text-align: center;
  width: calc(100%-6vw);
}
.post-card-modal-populartiy {
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 90%;
  overflow: hidden;
}
.post-card-modal-populartiy-likes {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding-left: 8px;
  color: #515151;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
}

.post-card-modal-populartiy-other {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1vw;
}
.post-card-modal-populartiy-other > span {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #515151;
}
.post-card-modal-populartiy-other > div {
  width: 4px;
  height: 4px;
  background-color: gray;
  border-radius: 100px;
}
.post-card-modal-post-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 45px;
}
.post-card-modal-post-icon1 {
  width: 30%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.post-card-modal-post-icon1 {
  width: 30%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.post-card-modal-post-icon1 > img,
.post-card-modal-post-icon2 > img {
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.post-card-modal-add-comment {
  padding: 7px 20px;
  background: #efeeee;
  display: flex;
  align-items: center;
  border-radius: 100px;
  position: fixed;
  width: 90%;
  overflow: hidden;
  /* margin-top: 10px; */
}
.post-card-modal-add-comment > img {
  height: 26px;
  width: 26px;
  margin-right: 5px;
  border-radius: 50%;
}
.post-card-modal-add-comment > input {
  background: #f6f6f6;
  outline: none;
  border: none;
  width: 80%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.36);
  background-color: inherit;
}
.post-card-modal-add-comment-image {
  float: left;
  display: flex;
  gap: 25px;
}
.post-card-modal-add-comment-image > img {
  height: 20px;
  width: 20px;
}

.post-card-modal-post-comments {
  width: 100%;
  overflow-y: auto;
  /* overflow: auto; */
  max-height: 200px;
}
.post-card-modal-post-comments::-webkit-scrollbar {
  width: 3px !important;
  display: flex;
  height: 7px;
  margin-bottom: 30px;
}
.post-card-modal-post-comments::-webkit-scrollbar-track {
border-radius: 6px !important;
background-color: #f1f1f1;
}
.post-card-modal-post-comments::-webkit-scrollbar-thumb {
background-color: gray;
border-radius: 6px !important;
}
.post-card-modal-post-comments > p {
  text-align: center;
}
.post-card-modal-top-row-details {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  gap: 10px;
}
.post-card-modal-top-row-details img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
}
.post-card-modal-top-row-info {
  width: 100%;
}
.modal_loading {
  height: 100vh;
  width: 100vw;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.likesAndMorePosts {
  display: flex;
  align-items: center;
  margin-top: 20px;
  column-gap: 20px;
  padding: 0 10px;
}

.likesPosts {
  display: flex;
  gap: 10px;
  width: fit-content;
  align-items: center;
}
.likesPosts > span {
  margin-left: -20px;
}
.likesPosts > span,
.commentsPosts > span {
  font-size: 14px;
}

.commentsPosts {
  display: flex;
  gap: 10px;
  height: 20px;
  width: fit-content;
  align-items: center;
}
.commentsPosts > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.sharePost {
  display: flex;
  gap: 10px;
  height: 20px;
  width: fit-content;
  align-items: center;
  margin-left: auto;
}
.shareBlog > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.userprofileImage {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.userprofileImage > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin: 0 auto;
}
@media screen and (max-width: 720px) {
  .post-card-modal-container {
    width: 100%;
    /* transform: translate(-50%, -42%); */
    padding: 10px 15px;
  }
  .post-card-modal-populartiy {
    width: 88%;
    padding: 10px 0;
    margin: 3px 0;
  }
  .post-card-modal-add-comment {
    margin-top: 15px;
  }
}
