.allservice-last-container
{

background: #f5f5f5;
padding-bottom: 149px;
}

.allservice-last-container .allservice-not-available{
  display: flex;
  justify-content: center;
  font-weight: 600;
  color: #03989E;
  font-size: 20px;
margin-left: 44px;
}
.allservice-last
{
display: grid;
    width: 100%;
    align-content: center;
    justify-content: center;
    grid-template-columns: 6fr 6fr 6fr;
    /* grid-column-gap: 0.987vw; */
    grid-row-gap: 2vw;
    justify-items: center;
    align-items: center;
}
.allservice-last-heading
{
  padding-left: 36px;
    color: #03989e;
    font-family: Titillium Web,sans-serif;
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 53px;
padding-bottom:10px ;

}

/* all service tab */

.allservicetab-container-desktop
{
width: 437px;
height: 250px;
position: relative;
    background: #FFFFFF;
box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3vw;
    cursor: pointer;
    transition: 0.3s transform cubic-bezier(0.4, 0, 1, 1), 0.3s box-shadow, 0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
}
.allservicetab-container-desktop:hover
{
  transform: scale(1.02);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06) !important;
    cursor: pointer;
}
.allservicetab-image-container
{
  width: 100%;
  height: 100%;
}
.allservicetab-image
{
  width: 100%;
  height: 100%;
}
.allservicetab-desktop-text
{
  width: 100%;
  position: absolute;
  height: 100%;
  background: linear-gradient(202.81deg,hsla(0,2%,40%,0) -3.5%,rgba(60,57,57,.4) 30.22%,hsla(0,3%,7%,.45) 61.64%,rgba(0,0,0,.71) 85.2%);
  overflow: hidden;

  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 5px;
  bottom: 0;
  color: #fff;
  justify-content: flex-end;
}
.allservicetab-heading-list
{
  width: 100%;
}
.allservicetab-heading-list>a{
  /* padding-left: 15px; */
  display: flex;
  flex-direction: column;
}
.allservicetab-heading-desktop
{
font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 900;
font-size: 20px;
line-height: 28px; 
color: #fff;

overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}
.allservicetab-list
{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 11px;
width: 100%;
display: flex;
color: #000000;
}

.allservicetab-list>span{
    overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
}




 @media only screen and (max-width: 2442px)
 {

 }
 @media only screen and (max-width: 1300px)
 {
  .allservice-last
{
    grid-template-columns: 6fr 6fr 6fr;
}
  .allservicetab-container-desktop
  {
    width: 29vw;
    height: 16.5vw;
  }
 } 
 /* @media only screen and (max-width: 949px)
 {
  .allservice-last
{
    grid-template-columns: 6fr 6fr;
}
 }  */
 @media only screen and (max-width: 680px)
 {
  .allservice-last
{
    grid-template-columns: 6fr 6fr;
}
  .allservicetab-container-desktop 
  {
    width: 40vw;
    height: 30vw;
}
 } 










 

/* mobile view css */
@media only screen and (max-width: 583px)
{

  .allservice-last-container
  {
    padding: 12px;
    padding-bottom: 20px;
  }

  .allservice-last-heading
  {
    padding: 0;

font-size: 23px;
line-height: 20px;
/* identical to box height, or 83% */
text-align:left;
/* letter-spacing: -0.2px; */

color: #00838F;
padding-bottom:32px ;
  }

.allservice-last
{
  padding: 0;
display: grid;
    width: 100%;
    align-content: center;
    justify-content: center;
    grid-template-columns: 6fr 6fr;
    grid-column-gap: 2vw;
    grid-row-gap: 3vw;
    justify-items: center;
    align-items: center;
}
.allservicetab-container ,.allservicetab-image
{
  width: 45.267vw;
  height: 35.33vw;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.allservicetab-image
{
  width: 100%;
}
.allservicetab-image-container {
  position: relative;

}

.allservicetab-heading-desktop {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* .allservicetab-desktop-text,.allservicetab-image
{
  background: linear-gradient(202.81deg,hsla(0,2%,40%,0) -3.5%,rgba(60,57,57,.4) 30.22%,hsla(0,3%,7%,.45) 61.64%,rgba(0,0,0,.71) 85.2%);
} */
} 