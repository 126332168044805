.profile-account-setting-right-new-address-input>div>input{
 /* display: grid; */
 padding: 15px 0px 15px 20px;
width: 281px;
height: 47px;
background: #FFFFFF;
border: 1px solid #B8B8B8;
}
.form-groupmodifyinput,.form-groupmodifyselect{
    padding: 6px 12px !important;
    line-height: 2.3 !important;
    color: #495057 !important;
    width: 100% !important;
    background: #f1f1f1 !important;
    border: 0px !important;
    border-radius: 0 !important;
    font-size: 12px !important;
    letter-spacing: 0.5px !important;
    outline:none;
}
.profile-account-setting-right-new-address-input:has(.form-groupmodify input){
    padding:0px !important;
    width:98%;
}
.profile-account-setting-right-container:has(.form-groupmodify){
    overflow:scroll !important;
    padding:25px 10px !important;
}
.profile-account-setting-right-container-2:has(.form-groupmodify){
    height:650px !important;
}
.profile-account-setting-right-container-2:has(.form-groupmodify)::-webkit-scrollbar {
    width: 3px !important;
    display: flex;
    /* height: 7px; */
    margin-left: 5px !important;
  }
  .profile-account-setting-right-container-2:has(.form-groupmodify)::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 6px !important;
  }
  .profile-account-setting-right-container-2:has(.form-groupmodify)::-webkit-scrollbar-track {
    border-radius:6px !important;
  background-color:#f1f1f1;
  }

.profile-account-setting-right-new-address-input>div>select{
padding: 10px 0px 10px 20px;
width: 281px;
height: 47px;
background: #FFFFFF;
border: 1px solid #B8B8B8; 
}

.profile-account-setting-right-new-address-input-check>label:before {
display:none !important
}
.profile-account-setting-right-new-address-input>div>p{
 /* display: grid; */
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 17px;
/* identical to box height, or 85% */

letter-spacing: -0.2px;

color: #302F2F;
padding-bottom: 10px;
}
.error input, .error select {
    border: 1px solid #cc0033;
    outline: none;
}
.errorborder{
    padding: 6px 12px !important;
    line-height: 2.3 !important;
    color: #495057 !important;
    width: 100% !important;
    background: #f1f1f1 !important;
    border: 1px solid #cc0033 !important;
    outline: none !important;
    border-radius: 0 !important;
    font-size: 12px !important;
    letter-spacing: 0.5px !important;
}
@media screen and (max-width: 720px){
    .profile-account-setting-right-new-address-input>div>input{
        width: 100%;
    }
    .profile-account-setting-right-container-2:has(.form-groupmodify)::-webkit-scrollbar{
        display:none;
    }
}
