.perticulerBlog {
  width: 100%;
  height: 100%;
  column-gap: 40px;
  padding: 1rem 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.particularBlogUserProfileRow {
  width: 100%;
  height: 71px;
  display: grid;
  grid-template-columns: 70px 1fr 20px;
  align-items: center;
  margin-bottom: 10px;
}
.userProfileImage {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.userProfileImage > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin: 0 auto;
}

.userProfileInfo > p {
  font-style: normal;
  font-size: 18px;
  color: #000000;
  margin: 0;
}
.userProfileInfo > .date {
  font-size: 14px;
}
.perticulerBlogDiv {
  background-color: white;
  padding: 20px 30px;
  position: relative;
  border: 2px solid #00838f;
  border-radius: 10px;
  height: 515px;
  overflow: scroll;
  width: 500px;
  position: relative;
}

.foodTheory {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.foodBtn {
  width: 122px;
  height: 26px;
  background: #283a61;
  border-radius: 3px;
  color: rgba(255, 255, 255, 0.85);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.perticulerBloggerInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  column-gap: 1.2rem;
}
.perticulerBlogDP {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
.particularBlogDescription {
  font-size: 30px;
  color: red;
}
.inthisBlogDiv {
  background: #f5f5f5;
  border: 1px solid #dddddd;
  border-radius: 7px;
  padding: 20px;
  margin-top: 40px;
  max-height: 400px;
  overflow-y: auto;
}
.inthisBlogDiv h1 {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 160%;
  color: #000000;
}
.inthisBlogDiv ol li {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: #303030;
}
.perticulerBlogContent {
  height: fit-content;
  min-height: 25px;
  margin-bottom: 10px;
}
.perticulerBlogContent h1 {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  color: #303030;
}
.perticulerBlogContent p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #434343;
}

.perticulerBlogTitle h1 {
  font-size: 22px;
  margin-bottom: 5px;
}
.perticulerBlogDate {
  margin-bottom: 1.2rem;
  color: #434343;
}
.perticulerBlogImg {
  width: 100%;
  max-height: 300px;
  height: fit-content;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.perticulerBlogImg > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
}
.singleBlogComment {
  margin-top: 10px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 1.2rem;
  border-radius: 28px;
  border: 2px solid #dddddd;
  padding: 0 8px;
}
.blogCommentUserProfileImg {
  height: 26px;
  width: 26px;
  margin-right: 5px;
  border-radius: 50%;
}
.singleBlogComment input {
  width: 100%;
  height: 2.4rem;
  border: none;
  font-size: 11px;
}
.singleBlogComment input:focus {
  border: none;
  outline: none;
}
.singleBlogComment .sendIcon {
  cursor: pointer;
  color: #00838f;
}
.shareAndMore {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-bottom: 0;
}
.likeImg,
.commentsBlog,
.shareIcon {
  display: flex;
  justify-self: space-around;
  align-items: center;
}
.shareAndMore p {
  margin: 0 0 0 5px;
}
.commentHeading {
  font-size: 20px;
  margin-bottom: 1.4rem;
}
.likeIcon {
  font-size: 22px;
  color: #00838f;
  cursor: pointer;
}
.blogSmallHeading {
  margin-top: 20px;
  font-size: 20px;
}

.likesAndMoreBlogs {
  display: flex;
  align-items: center;
  margin-top: 20px;
  column-gap: 20px;
  padding: 0 10px;
}

.likesBlogs {
  display: flex;
  gap: 10px;
  width: fit-content;
  align-items: center;
}
.likesBlogs >span{
  margin-left: -20px;
}
.likesBlogs >span,.comments >span{
  font-size: 14px;
}

.comments {
  display: flex;
  gap: 10px;
  height: 20px;
  width: fit-content;
  align-items: center;
}
.comments > img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.shareBlog {
  display: flex;
  gap: 10px;
  height: 20px;
  width: fit-content;
  align-items: center;
  margin-left: auto; 
}
.shareBlog > img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .perticulerBlog {
    padding: 10px;
  }
  .perticulerBlogDiv {
    width: 100%;
  }
  .perticulerBlogTitle > h1 {
    font-size: 22px;
  }
  .perticulerBlogDate > p {
    font-size: 14px;
  }
  .perticulerBlogImg {
    padding: 20px;
  }
  .commentHeading,
  .perticulerBlogContent > h1 {
    font-size: 22px;
  }
  .commentData > p,
  .commentData > h6 {
    font-size: 14px;
  }
  .inthisBlogDiv {
    padding: 20px;
  }
  .commentContainer {
    padding: 0;
  }
  .likesBlogs {
    width: fit-content;
    gap: 5px;
  }
  .likesBlogs >span{
    margin-left: 0px;
  }
  .commentData {
    align-items: start;
  }
  .comments > span {
    font-size: 14px;
  }
  .perticulerBlogContent p{
    display:inline;
  }
}
