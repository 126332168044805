.loan-page-main-view
{
 min-height: 85vh;
 display: flex;
 align-items: center;
 justify-content: center;
}
.loan-page-inner-rec
{
 width: 81.85vw;
 height: auto;
     box-shadow: -5px -4px 3px 0px rgb(255 255 255 / 43%), 5px 4px 3px 0px rgb(88 88 88 / 43%);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin:30px;
}
.loan-page-inner-rec-col1
{
    width: 31%;
    height: 100%;
}
.loan-page-inner-rec-col1-image
{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.loan-page-inner-rec-col1-image>img
{
    width: 100%;
    height: 270px;
}
.loan-page-inner-rec-col2
{
   width: 69%;
    height: 100%;
    padding: 60px 110px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}
.loan-page-inner-rec-col2-top
{
    width: 100%;
}
.loan-page-inner-rec-col2-top-heading
{
    color: #00838f;
    font-weight: 700;
    font-size: 2rem;
        margin-bottom: 0.5rem;
        text-align: left;
}
.loan-page-inner-rec-col2-inputs:has(.loan-page-inner-rec-col2-inputs-child){
    row-gap:3rem;
    /* overflow:auto; */
}
.loan-page-inner-rec-col2-inputs:has(.loan-page-inner-rec-col2-inputs-child)::-webkit-scrollbar{
    width:3px;
    margin-top:4px;
    display:flex;
  }
  .loan-page-inner-rec-col2-inputs:has(.loan-page-inner-rec-col2-inputs-child)::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 5px;
  }
  .loan-page-inner-rec-col2-inputs:has(.loan-page-inner-rec-col2-inputs-child)::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 5px;
  }
.loan-page-inner-rec-col2-top-desc
{
        margin-top: 20px;
    color: #87898f;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 1vw;
}

.loan-page-inner-rec-col2-inputs
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 1vw;

}
.loan-page-inner-rec-col2-inputs> .loan-page-inner-rec-col2-inputs-child, .loan-page-inner-rec-col2-inputs> .loan-page-inner-rec-col2-inputs-child{
    height:50px;
    width:48%;

}

.loan-page-inner-rec-col2-inputs>input, .loan-page-inner-rec-col2-inputs>select,.loan-page-inner-rec-col2-inputs> .loan-page-inner-rec-col2-inputs-child > input, .loan-page-inner-rec-col2-inputs> .loan-page-inner-rec-col2-inputs-child > select
{
    height: 40px;
    width: 100%;
    font-size: 12px;
    padding: 12px;
    border: 1px solid #d4d5d9;
    background-color: transparent;
    font-weight: 300;
    border-radius: 4px;
    line-height: 14px;
    width: 48%;
}

.loan-page-inner-rec-col2-inputs> .loan-page-inner-rec-col2-inputs-child > input, .loan-page-inner-rec-col2-inputs> .loan-page-inner-rec-col2-inputs-child > select{
    width:97% !important;
}
.loan-page-inner-rec-col2-inputs>select
{
font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 16px;
color: #7C7A7A;
padding-bottom: 0;
padding-top:0 ;
display: flex;
align-items: center;
}
.loan-page-inner-rec-col2-inputs>select>option
{

font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 16px;

color: #7C7A7A;
}

.loan-page-inner-rec-col2-submit
{
width: 100%;
padding-top: 20px;
display: flex;
justify-content: center;
margin-top: 30px;
}
.loan-page-inner-rec-col2-submit>button{
    border: none;
    width: auto;
    padding: 10px 20px;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    color: #fff;
    background-color: #00838f;
    text-decoration: none;
    border-radius: 5px;
}
.loan-page-inner-rec-col2:has(.loan-page-inner-rec-col2-inputs-child){
    padding:35px 110px;
}
.loan-page-inner-rec-col2-inputs-child .error{
    color: #cc0033;
    display: inline-block;
    width: 100%;
    font-size: 14px;
    line-height: 15px;
    margin: 5px 0 0;
}
.loan-page-inner-rec-col2-inputs-child .errorborder{
    border-radius:4px !important;
    background-color: unset !important;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .loan-page-inner-rec-col2-inputs> .loan-page-inner-rec-col2-inputs-child, .loan-page-inner-rec-col2-inputs> .loan-page-inner-rec-col2-inputs-child{
        width:100%;
    }

    .loan-page-inner-rec{
        height: fit-content;
        flex-direction: column;
        box-shadow: none;
    }
    .loan-page-inner-rec-col1{
        width: 100%;
    }
    .loan-page-inner-rec-col2{
        width: 100%;
        padding: 10px;
    }
    .loan-page-inner-rec-col2:has(.loan-page-inner-rec-col2-inputs-child){
        padding: 10px !important;
    }
    .loan-page-inner-rec-col2-inputs {
        flex-direction: column;
        width: 100%;
        margin-top: 10px;
        gap: 1rem;
    }
    .loan-page-inner-rec-col2-inputs>input, .loan-page-inner-rec-col2-inputs>select {
        width: 100%;
    }
    .loan-page-inner-rec-col2-top-heading {
        font-size: 20px;
    }
    .loan-page-inner-rec-col2{
        margin-bottom: 50px;
    }
    .loan-page-inner-rec-col2-inputs:has(.loan-page-inner-rec-col2-inputs-child){
        overflow: unset;
    }
    .loan-page-inner-rec-col2-submit {
        margin-top:40px;
    }
}