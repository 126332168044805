.add-address-modal-mobile-container-background
{
     position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
    overflow-y: auto;
}
.add-address-modal-mobile-container
{
margin: 1.75rem auto;
    background: #fff;
    width: 90%;
    max-width: 500px;
    overflow-y: scroll;
    /* padding: 35px; */
    height: -moz-fit-content;
    height: fit-content;
    /* right: -400px; */
    transition: all 0.6s ease;
    padding: 5vw 10vw;
    overflow-y: auto;
    border-radius: 12px;
}
/* .add-address-modal-mobile-container-intro-in
{
 right: 0 !important;
 transition: all 0.6s ease;
} */
.add-address-modal-mobile-cross
{
     display: block;
    width: 100%;
    font-size: 25px;
    text-align: right;
    color: #00838f;
    cursor: pointer;
    font-weight: 800;
    line-height: 1.5;

}

.add-address-modal-mobile-coupon-enter
{
 display: flex;
 flex-direction: column;
}

.add-address-modal-mobile-coupon-enter-input
{
padding: 8px 0px;
display: flex;
flex-direction: column;
}
.add-address-modal-mobile-coupon-enter-input>span
{
font-size: 16px;
    font-weight: 700;
    color: #00838f;
    letter-spacing: 0.2px;
    padding-bottom: 8px;
}
.add-address-modal-mobile-coupon-enter-input>input
{

 height: 40px;
    width: 100%;
    font-size: 12px;
    padding: 12px;
    border: 1px solid #d4d5d9;
    background-color: transparent;
    font-weight: 300;
    border-radius: 4px;
    line-height: 14px;
}
.add-address-modal-mobile-coupon-enter-input>select
{
    height: 40px;
    width: 100%;
    font-size: 12px;
    padding: 12px;
    border: 1px solid #d4d5d9;
    background-color: transparent;
    font-weight: 300;
    border-radius: 4px;
    line-height: 14px;
}
.add-new-address-input-check
{
 display: flex;
 align-items: center;
 justify-content: flex-start;
 gap: 6px;
 color: #94969f;
 font-size: 12px;
 margin-bottom: 10px;
}
.add-new-address-input-check>label
{
 margin: 0;
}





.add-address-modal-mobile-coupon-enter-submit
{
 width: 100%;
  /* padding: 8px;
  margin: 0 -1.5rem; */
  margin-top: 3vw;
  margin-bottom: 5vw;
    /* box-shadow: 0 -1px 2px 2px #0000004d; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.add-address-modal-mobile-coupon-enter-submit>button
{
 width: 95%;
  height: 45px;
 text-align: center;
 background: #00838f;
 text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px ;
    border-radius: 4px ;
    border: none;
        color: #fff;
    font-weight: 700;
}