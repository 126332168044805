.dashboard{ 

    display: flex;
    background: #0291A0;
    height: 93px;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: flex-end;
}
.dashboard-container
{
  width: 20%;
  display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.dashboard-container >a
{
  color: #007bff;
    text-decoration: none;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.dashboard-image-container
{
  height: fit-content;
  width: fit-content;
  padding: 12px;
  background-color: #FFFFFF;
  border-radius: 100%;
}
.dashboard-image-container >img{
  width: 30px;
  height: 30px;
}
.dashboard-image-text{
font-family: 'Poppins', sans-serif;
font-weight: 400;
font-size: 16px;
line-height: 24px;
text-align: center;
color: #FFFFFF;
    height: 24px;
  overflow: hidden; 
}

@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px){
  .dashboard{
    padding: 10px;
    height: auto;
    scroll-behavior: smooth;
    overflow-x: auto;
    gap: 34px;

    justify-content:flex-start;
  }
  .dashboard-image-text{
    font-size: 12px;
    font-weight: 900;
  }
  
}