.header-icons {
  height: 7vh;
  width: 10vw;
  cursor: pointer;
}
.navbar {
  position: sticky;
  top: 0;
  padding: 0.4vw 3vw;
  height: 85px;
    background-color: #00838f;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* align-content: space-around; */
    flex-wrap: nowrap;
    z-index:999;
}
.putatoe-logo {
  display: flex;
}
.putatoe-logo-image {
  height: 70px;
  width: 70px;
}
.putatoe-logo-text {
  padding-left: 1vw;
  position: relative;
  display: block;
  cursor: pointer;
}
.putatoe-logo-text-upper {
  color: white;
}
.putatoe-logo-text-lower {
  position: absolute;
  color: white;
  bottom: 0;
  right: -1vw;
}
.nav-search-bar-desktop {
  width: 21vw;
  display: flex;
  position: relative;
  border-radius: 10px;


}
.navbar-short {
  padding-left: 60px;
  position: sticky;
  top: 85px;
  padding: 0.1vw 3vw;
  height: 6vh;
  background-color: #00838f;
  display: none;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 19;
}
.navbar-visibility-hidden{
  visibility: hidden;
}
.notification-header {
  margin: 0%;
  padding: 0%;
  display: block;
  cursor: pointer;
  position: relative;
}
/* .notification-none
{
  position: absolute;
  top: 0px;
  right: 0px;
  display: block;
} */
.notification-header-modal {
  position: relative;
}
.notification-show {
  background-color: white;
  z-index: 100;
  display: block;
  width: 28vw;
  min-width: 300px;
  min-height: 2vw;
  position: absolute;
  will-change: transform;
  top: 9px;
  /* left: -40px; */
  right: 7px;
  /* right: 0px; */
  /* transform: translate3d(-308px, 4px, 0px); */
  display: flex;
  /* padding :1vw; */
  gap: 1vw;
  filter: drop-shadow(6px 6px 10px rgba(0, 0, 0, 0.5));
  /* border-radius: 12px; */
  align-items: center;
}
.notification-logo,
.notification-logo > img {
  height: 50px;
  width: 50px;
}
.notification-text > div {
  color: #94969f;
  font-size: 12px;
  font-weight: 600;
}

.login-otp-modal {
  position: fixed;
  background-color: transparent;
  z-index: -100;
  height: 350px;
  width: 350px;
  top: 0;
  right: calc(50% - 150px);
}
.otp-modal-show {
  padding: 1vw;
  background-color: white;
  z-index: -1;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.otp-logo {
  width: 80%;
  /* background-color: #000000; */
}
.otp-logo > img {
  width: 100%;
}
.verify-otp-button {
  border: none;
  padding: 0.5vw;
  width: fit-content;
  padding: 10px 20px;
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  background-color: #00838f;
  text-decoration: none;
  border-radius: 12px;
}

.verify-otp-text {
  color: #00838f;
  font-weight: 600;
  padding: 1vw;
  padding-bottom: 0;
}
.verify-otp-number {
  padding: 1vw;
  color: #000000;
  font-size: 12px;
  font-weight: 600;
}
.resend-otp {
  color: #94969f;
  font-size: 13px;
}

.otp-input {
  display: flex;
  gap: 1vw;
  padding: 0.5vw;
}
.otp-input > input {
  width: 3vw;
  height: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.otp-input > input::-webkit-scrollbar {
  display: none;
}

.search-modal {
  position: absolute;
  background-color: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  min-width: 44vw;
  border-radius: 10px;
  padding: 1vw;
  height: fit-content;
  top: 40px;
  max-height: 250px;
  overflow: auto;
}
.search-modal > div {
  cursor: pointer;
}

.nav-search-bar-desktop-mobile {
  display: none;
  width: 100%;
}
.search-bar-desktop {
  height: 35px;
  border: none;
  width: 90%;
  background-color: white;
  padding-left: 0.5vw;
  position: relative;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.search-bar-desktop::placeholder {
  font-weight: 200;
  color: #5e6160;

  display: flex;
  padding-left: 0px;
  align-items: center;
}
.search-bar-desktop:focus {
  outline: none;
}
.search-icon-desktop {
  display: flex;
  padding-left: 10px;
  align-items: center;
  position: relative;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.search-icon-image {
  height: 24px;
  width: 24px;
}
.chat-icons {
  height: 24px;
  width: 24px;
  cursor: pointer;
}
.bell-icon {
  height: 24px;
  width: 24px;
}
.cart-icon {
  height: 24px;
  width: 24px;
}
.login-button {
  background: transparent;
  border-radius: 12px;
  cursor: pointer;
  width:9vw;

  text-align: center;
border: 2px solid #fff;

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  height: 40px;
  /* identical to box height */


  color: #fff;
}
.login-sidebar-container {
  top: 0;
  left: 0;
  position: fixed;
  height: 100vh;
  display: block;
  width: 360px;
  z-index: 100;
  background-color: #f4f4f4;
  color: #00838f;
  padding: 35px;
  transition: all 0.6s ease;
}
.login-sidebar-container-none {
  display: none;
}
.close-login-sidebar {
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 2px;

  display: block;
  width: 100%;
  font-size: 25px;
  text-align: right;
  color: #00838f;
  padding-bottom: 1rem;
}
.close-login-sidebar span {
  cursor: pointer;
}
.input-login-sidebar {
  margin-bottom: 1rem;
  width: 100%;
}
.input-sidebar {
  width: 100%;
  background-color: #fff;
  padding: 12px 14px;
  display: block;
  width: 100%;
  border: none;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  font-size: 12px;
  letter-spacing: 0.5px;
}
.input-sidebar:focus {
  outline: none;
}
.login-otp-button-sidebar {
  border: none;
  width: 100%;
  padding: 10px 20px;
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  background-color: #00838f;
  text-decoration: none;
}
.form-separator-sidebar {
  height: 12px;
  margin: 20px 0 32px;
  text-align: center;
  border-bottom: 1px solid #e4e5e7;
}
.form-separator-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  padding: 0 8px;
  color: #62646a;
}
.login-password-sidebar {
  border: none;
  width: 100%;
  padding: 10px 20px;
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  background-color: #00838f;
  text-decoration: none;
}
.no-account-login-sidebar {
  width: 100%;
  text-align: center;
  font-size: 12px;
}
.create-account-login-sidebar {
  cursor: pointer;
  text-decoration: none;
}
.search {
  display: none;
  margin-top: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 1vw 2vw;
  background-color: #2f8676;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* align-content: space-around; */
  flex-wrap: nowrap;
  z-index: 100;
}
.search-bar {
  display: none;
  caret-color: #105644;
  border: none;
  border-radius: 10px;
  width: 85vw;
  height: 5vh;
  font-size: 2vw;
  font-weight: 200;
  color: #5e6160;
  display: flex;
  text-align: center;
  align-items: center;
  padding: 2vw;
}
.search-bar::placeholder {
  display: none;
  font-size: larger;
  font-weight: 200;
  color: #5e6160;
  display: flex;
  text-align: center;
  align-items: center;
  padding: 2vw;
}
:focus::placeholder {
  opacity: 0.4;
  transition: color 0.5s;
}
.predictionDiv{
  padding: 4px;
}
.predictionDiv:hover{
  background-color: #94969f;
}

.login-details-header {
  display: flex;
  align-items: center;
  gap: 1.4vw;
  position: relative;
  cursor: pointer;
}
.login-details-header-image {
}
.login-details-header-image > img {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  object-fit: cover;
}
.login-details-header-details {
}
.login-details-header-details-name {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */

  color: #ffffff;
}
.login-details-header-details-location {
}
.login-details-header-details-location > img {
  height: 15px;
  width: 15px;
}
.login-details-header-details-location > span {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
}

/* dropdown */
.login-details-header-dropdown-none {
  position: absolute;
  top: 55px;
  width: 13.8vw;
  list-style: none;
  text-align: center;
  display: none;
}
.login-details-header-dropdown {
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  overflow: hidden;
  border-radius: 0.375rem;
  top: 55px;
  width: 13.8vw;

  list-style: none;
  text-align: center;
  /* display: none; */
}
.login-details-header-dropdown > span {
  background-color: white;
}
.login-details-header-dropdown > span > div {
  background-color: hsla(0, 0%, 100%, 0.912);
  font-weight: 700;
  padding: 1vw;
}
.login-details-header-dropdown > span hr {
  height: 0;
  opacity: 0.5;
  margin: 0;
  border-top: 1px solid #061f25;
}

/* cart modal neccasary css */
.cart-header-main-header-image > a {
  position: relative;
}
.cart-header-main-header-image > a > div {
  position: absolute;
  color: white;
  top: -5px;
  right: -19px;
  font-weight: 600;
  font-size: 13px;
  background-color: red;
  /* padding: 10px; */
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}

.cart-header-main-header-image {
  position: relative;
  height: 100%;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-header-main-header-image-show-on-hover {
  display: none; 
}
.cart-header-main-header-image-show-on-hover:hover {
  display: block;  
}

.cart-header-main-header-image:hover
  .cart-header-main-header-image-show-on-hover {
  display: block;
}
.cart-header-main-header-image
  > a
  > img:hover
  .cart-header-main-header-image-show-on-hover {
  display: block;
}
.chaticon-container {
  position: relative;
}
.notification-header > span ,.chaticon-container > span{
  position: absolute;
  color: white;
  top: -5px;
  right: -15px;
  font-weight: 600;
  font-size: 13px;
  background-color: red;
  /* padding: 10px; */
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}
.navbar-hamburger-menu {
  display: none;
}
.no-user-icon{
  display: none;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .navbar {
    height: 65px;
    /* position: relative; */
    /* margin-bottom: -10px; */
  }
  .navbar-hidden
  {
    display: none;
  }

  .putatoe-logo {
    width: 450px;
    height: 60px;
    padding: 4px;
    overflow: hidden;
  }
  .putatoe-logo-image {
    max-width: 50px;
    max-height: 50px;
  }
  .putatoe-logo-text > h1 {
    font-size: 26px;
    font-weight: 900;
  }
  .putatoe-logo-text > span {
    font-size: 13px;
    width: 100%;
    text-align: center;
  }
  .putatoe-location,
  .chat-icons {
    display: none;
  }
  .navbar .voice-icon {
    display: none;
  }
  .notification-header,
  .cart-header-main-header-image,
  .login-details-header,
  .login-button,
  .navbar-hamburger-menu,
  .search-modal {
    position: relative;
  }
  .notification-header {
    right: 58px;
  }
  .notification-header > img {
    height: 22px;
  }
  /* .notification-header > span{
  height: 16px;
  width: 16px;
  font-size: 12px;
  right: -10px;
} */
  .cart-header-main-header-image {
    right: 40px;
  }
  .cart-image > img {
    height: 22px;
  }
  /* .cart-header-main-header-image > a > div {
  height: 16px;
  width: 16px;
  font-size: 12px;
  right: -10px;
} */
  .login-button {
    overflow: hidden;
    right: 20px;
  }
  .login-details-header {
    right: 15px;
  }
  .login-details-header-details {
    display: none;
  }
  .login-details-header-dropdown {
    width: 100px;
    position: absolute;
    right: 1px;
    top: 50px;
  }
  .chaticon-container > span {
    display: none;
  }
  .navbar-hamburger-menu {
    display: block;
    right: 15px;
    font-size: 24px;
    color: white;
    cursor: pointer;
  }
  .search-modal {
    min-width: 70vw;
left: 10%;
margin-top: 14px;
  }
  .search-bar-desktop {
    font-size: 15px;
    height:33px !important;

  }
  .search-icon-desktop .search-icon-image {
    height: 19px;
    width: 19px;
  }
  .search-icon-desktop {
    width: 30px !important;
    height: 33px;

  }
  
  .notification-show{
    right: -80px;
    border-radius: 10px;
    padding: 10px;
  }
  .login-button {
    display: flex;
    width: 48px;
    padding-left: 3px;
    justify-content: center;
    align-items: center;
    background: transparent !important;
    border: none !important;
  }
  .login-button > span {
    display: none;
  }
  .login-button  .no-user-icon{
    display: block;
    color: white;
    width: 100%;
    font-size: 32px;

  }
  .navbar-header-cart-modal-container{
    display: none;
  }
}
