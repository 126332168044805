.my-job-post-service-profile-page-view-main {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  overflow: auto;
  height: 630px;
  padding: 1vw;
  position: relative;
}
.my-job-post-service-profile-page-view-main-nearby-profile-image1{
  width:15%;
}
.my-job-post-service-profile-page-view-main-nearby-profile-detailrightbasiceducation{
  flex-grow:1;
}
.my-job-post-service-profile-page-view-main-nearby-profile-image1 img,.my-job-post-service-profile-page-view-main-nearby-profile-detailleftimage img{
  width:100%;
  border-radius:50%;
}
.my-job-post-service-profile-page-view-main:has(.my-job-post-service-profile-page-view-main-nearby-profile1){
  grid-template-columns: unset;
  width:98%;
  margin:0px auto;
}
.my-job-post-service-profile-page-view-main-nearby-profile1{
  min-height: unset !important;
}
.myjobprofilenearbyflexparent {
  display: flex;
  justify-content: space-between;
  /* min-height: unset; */
  align-items:center;
}

.my-job-post-service-profile-page-view-main-card-box {
  width: 100%;
  min-height: 234px;
  max-height: 300px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px 20px 20px;
  justify-content: center;
  overflow: hidden;
}
.my-job-post-service-profile-page-view-main-card-box-item-detail {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.my-job-post-service-profile-page-view-main-card-box-item-detail-left {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 100%;
}
.my-job-post-service-profile-page-view-main-card-box-item-detail-left > img {
  width: 100%;
  height: 100%;
}
.my-job-post-service-profile-page-view-main-card-box-item-detail-right {
  width: 50%;
}
.my-job-post-service-profile-page-view-main-card-box-item-detail-right > div {
  font-size: 15px;
  font-weight: 700;
  color: rgb(68, 17, 119);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.my-job-post-service-profile-page-view-main-card-box-item-detail-right > span {
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.my-job-post-service-profile-page-view-main-card-box-item-detail-right
  > section {
  font-size: 10px;
  color: #0000008d;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.my-job-post-service-profile-page-view-main-card-box-item-detail-right
  > article {
  font-size: 13px;
  font-weight: 600;
  display: flex;
  gap: 4px;
  overflow-y: auto;
  max-height: 80px;
}
.my-job-post-service-profile-page-view-main-salary {
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.my-job-post-service-profile-page-view-main-card-box-item-status-cancel {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-job-post-service-profile-page-view-main-card-box-item-status-cancel
  > button {
  background-color: #00838f;
  color: white;
  border: none;
  outline: none;
  padding: 3px 23px;
  border-radius: 10px;
}

.my-job-post-service-profile-page-view-main-2 {
  flex-grow: 1;
  overflow: scroll;
}
.my-job-post-service-profile-page-view-main-input {
  margin-top: 30px;
  padding: 0 25%;
}
.my-job-post-service-profile-page-view-main-input-box {
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
}
.my-job-post-service-profile-page-view-main-input-box > span {
  font-size: 16px;
  font-weight: 700;
  color: #00838f;
  letter-spacing: 0.2px;
  padding-bottom: 8px;
}
.my-job-post-service-profile-page-view-main-input-box > input {
  height: 40px;
  width: 100%;
  font-size: 12px;
  padding: 12px;
  border: 1px solid #d4d5d9;
  background-color: transparent;
  font-weight: 300;
  border-radius: 4px;
  line-height: 14px;
}
.my-job-post-service-profile-page-view-main-input-submit {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 20px;
}
.my-job-post-service-profile-page-view-main-input-submit > button {
  border: none;
  width: 272px;
  height: 52px;
  background: #00838f;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  letter-spacing: -0.2px;
  color: #ffffff;
}

.my-job-post-service-profile-page-view-main-nearby-profile {
  width: 100%;
  min-height: 160px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 10px 20px 20px 20px;
  justify-content: space-evenly;
}
.my-job-post-service-profile-page-view-main-nearby-profile-head {
  width: 100%;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.827);
  font-size: 18px;
  font-weight: 600;
}
.my-job-post-service-profile-page-view-main-nearby-profile-foi,
.my-job-post-service-profile-page-view-main-nearby-profile-skills,
.my-job-post-service-profile-page-view-main-nearby-profile-exp,.detailrightbasicflexsection {
  font-size: 15px;
  width: 100%;
  /* white-space: nowrap; */
  color: rgba(0, 0, 0, 0.47);
  font-weight: 600;
}
.detailrightbasicflexsection{
  width: auto;
}
.my-job-post-service-profile-page-view-main-nearby-profile-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-job-post-service-profile-page-view-main-nearby-profile-image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-job-post-service-profile-page-view-main-nearby-profile-image > img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 20px;
}
.my-job-post-service-profile-page-view-main-nearby-profile-delete {
  display: flex;
  justify-content: flex-end;
  margin-top: -30px;
}
.my-job-post-service-profile-page-view-main-nearby-profile-button > button {
  width: 130px;
  height: 28px;
  background: #00838f;
  margin-top: 10px;
  border-radius: 10px;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #ffffff;
}
.my-job-post-service-profile-page-image {

  height: 100px;
  width: 200px;
  position: relative;
}
.my-job-post-service-profile-page-image >span{
color: black;
display: inline-block;
font-weight: normal;
margin-bottom: 20px;
}
.my-job-post-service-profile-page-image > img {
  height: 100px;
  width: 100px;
  object-fit: contain;
}
.crossIconEditProvider {
  position: absolute;
  color: #00838f;
  cursor: pointer;
}
.imageInputForm {
  justify-content: unset;
}
.my-job-post-service-profile-page-view-main-nearby-profile-detail{
  display:flex;
  gap:8px;
}
.my-job-post-service-profile-page-view-main-nearby-profile-detailleft,.my-job-post-service-profile-page-view-main-nearby-profile-detailrightbasic{
  width:30%;
  background-color: #e1e2e7;
  border-radius:20px;
  display:flex;
  flex-direction:column;
  /* justify-content:center; */
  padding: 10px;
  margin-bottom:10px;
}
.my-job-post-service-profile-page-view-main-nearby-profile-detailrightbasicflex{
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 30px;
  column-gap:10px;
  padding:20px 20px 20px 30px;
}
.my-job-post-service-profile-page-view-main-nearby-profile-detailright{
  width:70%;
  display:flex;
  flex-direction:column;
}
.my-job-post-service-profile-page-view-main-nearby-profile-detailrightbasic{
  width:100%;
}
.basicinformation{
  padding-left:20px;
}
.edducation .notavailble1{
  display:flex;
  justify-content: center;
  font-weight: 700;
  color: rgb(92, 91, 91);
  font-family: "Poppins";
  font-size:22px;
  align-items:center;
  height:100%;
}
.edducation:has(.notavailble1){
  height:100%;
}
.my-job-post-service-profile-page-view-main-nearby-profile-detailrightbasiceducation:has(.notavailble1){
  flex-grow:1;
}
.my-job-post-service-profile-page-view-main-nearby-profile-detailleftimage{
  width: 60%;
  margin: auto;
  margin: 0px auto 10px auto;
}
.my-job-post-service-profile-page-view-main-nearby-profile-detailleftimage img{
  border-radius:50%;
  width:100%;
  height:100%;
  margin:auto;
}
.my-job-post-service-profile-page-view-main-nearby-profile-detaillefttextname,.basicinformation,.detailrightbasicflexsection div{
  font-size:17px;
  font-weight:700;
  color: rgb(92, 91, 91);
  font-family: "Poppins";
}
.pos{
  font-size:16px;
}
.skills{
  margin-top:25px;
  padding-left:10px;
}
.aligns{
  display:flex;
  align-items:center;
  justify-content:center;
}
.notavailable, .detailrightbasicflexsection div{
  font-size:14px;
}
.skillssectionss span{
  font-size:14px;
  background-color: #ffffff;
  padding:8px;
  color: rgb(92, 91, 91);
  border-radius:20px;
}
.skillssectionss{
  display:flex;
  flex-direction:row;
  gap:7px;
  width:100%;
  flex-wrap:wrap;
  margin-top:5px;
}
.basicinformation{
  width:100%;
}
.edducation{
  padding:20px 20px 20px 50px;
}
.deggree{
  font-size: 17px;
  font-weight:500;
  font-family: "Poppins";
}
.percentage{
  font-size:14px;
}
.my-job-post-service-profile-page-view-mainsecond{
  grid-template-columns:1fr 1fr 1fr;
  height:auto;
  overflow:auto;
}
.my-job-post-service-profile-page-view-mainsecond::-webkit-scrollbar{
  width:4px;
  margin-top:4px;
  display:flex;
}
.my-job-post-service-profile-page-view-mainsecond::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 5px;
}
.my-job-post-service-profile-page-view-mainsecond::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}
.userlocation1{
  background-color: #fff;
  border: none;
  height: 35px;
  line-height: 2;
  padding:5px;
  position: relative;
 width:100%;
  border:1px solid rgb(193, 193, 193);
  border-radius:6px;
  outline: 0px;
  padding: .375rem .75rem;
  color: #495057;
}
.buttton{
  /* margin-right:50px; */
  width:40% !important;
  justify-content: center !important;
  gap:7px;
  border-radius:6px;
}
.useerlocationdiv:has(.userlocation1){
 width:40%;
 position: relative;
}
#seaaarchmodaladdress{
  margin-top:0px;
  left:0px;
  width:100%;
  min-width: 120%;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .my-job-post-service-profile-page-view-main-input {
    padding: 20px;
    margin-top: -20px;
  }
  .my-job-post-service-profile-page-view-main {
    grid-template-columns: 1fr;
    padding: 0 20px;
    height: auto;
    padding-bottom: 30px;
  }
  .my-job-post-service-profile-page-view-main-2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: fit-content;
    padding: 10px;
  }
  .my-job-post-service-profile-page-view-main-nearby-profile-detail{
    flex-direction: column;
    padding: 10px;
  }

.my-job-post-service-profile-page-view-main-nearby-profile-detailleft,.my-job-post-service-profile-page-view-main-nearby-profile-detailright{
width: 100%;
}
.my-job-post-service-profile-page-view-main-nearby-profile-detailrightbasicflex{
  grid-template-columns: 1fr;
}
.my-job-post-service-profile-page-view-main-nearby-profile-detaillefttextname .deggree {
font-size: 15px;
}
.my-job-post-service-profile-page-view-main-nearby-profile1 {
  overflow:hidden;
}
.my-job-post-service-profile-page-view-main-nearby-profile-image1,.my-job-post-service-profile-page-view-main-nearby-profile-detailleftimage{
  width:100%;
  display:flex;
  justify-content: center;
}
.my-job-post-service-profile-page-view-main-nearby-profile-image1 img,.my-job-post-service-profile-page-view-main-nearby-profile-detailleftimage img{
  width:30%;
}
.myjobprofilenearbyflexparent {
  flex-direction: column-reverse;
  align-items: unset;
}
.my-job-post-service-profile-page-view-mainsecond{
  margin:auto;
  grid-template-columns:1fr;
  width:90%;
  height: 500px;
}
.my-job-post-service-profile-page-view-mainsecond > .my-job-post-service-profile-page-view-main-nearby-profile{
  height: max-content;
}
.my-job-post-service-profile-page-view-mainsecond::-webkit-scrollbar{
  width:4px;
  margin-top:4px;
  display:flex;
}
.my-job-post-service-profile-page-view-mainsecond::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 5px;
}
.my-job-post-service-profile-page-view-mainsecond::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}
.buttton{
  /* margin-right:50px; */
  width:90% !important;
}
.useerlocationdiv:has(.userlocation1){
  width:40%;
 }
 #seaaarchmodaladdress{
  min-width: 100%;
 }
}