.checklist-container {
  padding: 14px 0px 0px 14px;
}
.checklist-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  letter-spacing: 1.2px;
  font-size: 13px;
  line-height: 15px;
  padding-left: 5px;
  color: #000000;
  margin-top: 10px;
  padding: 0 10px;
  margin-bottom: 10px;
}
.checklist-items-container {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  text-align: right;
  max-height: 184px;
  min-height: 30px;
  overflow-y: scroll;
  color: #4f4f4f;
}

.checklist-items-container > form {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.checklist-list {
  display: flex;
  height: 17px;
  align-items: center;
}

.checklist-list > label {
  margin: 0;
  padding-left: 4px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  /* //here is how to limit in one line */
  overflow: visible;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.checklist-list > label >span {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 2px;
}
input[type="checkbox"] + label::before {
  content: ""; /* Checkmark symbol */
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  text-align: center;
  vertical-align: middle;
  background-color: #f7f6f6;
  border: 0.13rem solid #00838f;
  border-radius: 5px;
  cursor: pointer;
}

/* Style the custom checkbox when checked */
input[type="checkbox"]:checked + label::before {
  content: "✓";
  background-color: #00838f; /* Change color when checked */
  color: #fff; /* Change text color when checked */
}

.checklist-item {
  display: none;
}

@media screen and (max-width: 720px) {
  .checklist-heading {
    font-size: 20px;
  }
  .checklist-items-container {
    font-size: 14px;
  }
}
