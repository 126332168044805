.mobile-recharge-plan-detail-model-background {
  /* position: absolute;
  cursor: pointer;
  width: 100%;
  min-height: 100%;

  padding-top: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.267); */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.mobile-recharge-plan-detail-model {
  width: 525px;
  height: 330px;
  z-index: 2000;
  background: #ceebe7;
}
.mobile-recharge-plan-detail-model-top {
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 23px;
  justify-content: flex-end;
  padding-bottom: 23px;
}

.mobile-recharge-plan-detail-model-top-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.mobile-recharge-plan-detail-model-bottom-col1 {
  width: 40%;
  padding-left: 20px;
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* text-align: center; */

  color: #000000;

  /* opacity: 0.8; */
}
.mobile-recharge-plan-detail-model-bottom-col2 {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  width: 60%;
  height: fit-content;
  color: #000000;
}

.mobile-recharge-plan-detail-model-bottom {
  height: 130px;
  width: 100%;
  display: flex;
}

.mobile-recharge-plan-detail-model-bottom-col2a {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* width: 60%; */
  width: fit-content;
  height: fit-content;
  color: #000000;
}
.mobile-recharge-plan-detail-model-bottom-col3 {
  float: right;
  width: 169px;
  height: 130px;
  margin-top: 25px;
}
.mobile-recharge-plan-detail-model-bottom-col3 > img {
  width: 169px;
  height: 130px;
}
