.contact-us-main-page
{
     background-color: #f1f3f6;
 height: max( 100% , fit-content );
 width: 100%;
 padding-top: 1rem!important;
 padding-bottom: 3rem!important;
 width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
        font-family: "Raleway", sans-serif;
    scroll-behavior: smooth;
}
.contact-us-main-page-top
{
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: space-around;
 /* gap: 2%; */
}
.contact-us-main-page-img
{
 width: 49%;
 display: flex;
 align-items: flex-start;
 justify-content: center;
}
.contact-us-main-page-img>img
{
 width: 100%;
}
.contact-us-main-page-form
{
 width: 49%;
 padding: 0 20px ;
}

.add-address-modal-coupon-enter-input>textarea
{
height: 125px;
    resize: none;
    padding: 8px 10px;
    border-radius: 3px;
    transition: all 0.3s ease;
    /* /* outline: none; */
    border: 1px solid #d4d5d9;
    background-color: transparent;

}
.contact-us-main-page-submit
{
 width: 100%;
 align-items: center;
 justify-content: center;
 margin-top: 10px;
}
.contact-us-main-page-submit>button
{
     padding: 10px 20px;
    font-size: 15px;
    border: 0px;
    background: #00838f;
    color: #fff;
    cursor: pointer;
    border-radius: 3px;
    outline: none;
    font-weight: bold;
    letter-spacing: 0.09em;
}














.contact-us-main-page-bottom
{
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: space-around;
}
.contact-us-main-page-bottom-box
{
 width: 30%;
 display: flex;
 /* height: 100%; */
 gap: 2%;
 align-items: center;
 padding: 0.5rem!important;
}
.contact-us-main-page-bottom-box-image
{
width: 13%;
display: flex;
align-items: center;
justify-content: center;
}
.contact-us-main-page-bottom-box-image>img
{
width: 100%;
}
.contact-us-main-page-bottom-box-text
{
width: 85%;
color: #94969f;
font-size: 12px;
}

@media screen and (max-width: 720px){
    .contact-us-main-page-top,    .contact-us-main-page-bottom{
        flex-direction: column;
    }
    .contact-us-main-page-img,    .contact-us-main-page-form,.contact-us-main-page-bottom-box{
        width: 100%;
    }
    .contact-us-main-page-submit {
        margin-bottom: 30px;
    }


}