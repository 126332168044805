.service-name-id1 {
  background: #405f61;
}

.service-name-container {
  font-family: "Playfair Display", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 120.6%;
}
.service-name-support-text {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 95.6%;
}
.service-name-see-more {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 95.6%;
}

.service-provider {
  width: 100%;
  display: flex;
  overflow-x: auto;
  gap: 26px;
  align-items: center;
  scroll-behavior: smooth;
  position: relative;
  background-color: #f5f5f5;
}
.service-provide-head {
  width: 100%;
  height: 350px;
  margin: 0 auto;
  display: flex;
  scroll-behavior: smooth;
  align-items: center;
}

.bestseller-scroll {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  visibility: hidden;
  margin-top: 50px;
}
.bestseller-scroll-container > button > span {
  display: flex;
  align-items: center;
  text-align: center;
  color: #07484a;
  font-size: 30px;
}
.bestseller-scroll-dots {
  width: 12px;
  height: 12px;
  background: #878787;
  visibility: visible;
  border-radius: 100px;
}
.bestseller-scroll-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1vw;
}

.bestseller-scroll-left {
  z-index: 19;
  visibility: visible;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 100px 0px; */
  gap: 4px;
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  border: none;
  outline: none;
}

.bestseller-scroll-right {
  z-index: 19;
  visibility: visible;
  border: none;
  outline: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 100px 0px; */
  gap: 4px;
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
}

.service-card-container {
  display: flex;
  position: relative;
  width: 350px;
  margin-bottom: 10px;
  height: 300px;
  background: #ffffff;
  /* box-shadow: 0px 4px 50px rgb(0 0 0 / 10%); */
  border-radius: 10px;
  padding: 10px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
}
.service-card-image {
  width: 330px;
  height: 230px;
  border-radius: 10px;
}
.service-card-name-container {
  position: absolute;
  width: 200px;
  top: 158px;
  height: 30px;
  left: 75px;
  top: 209px;

  background: #405f61;
  box-shadow: 4px 2px 10px rgba(0, 0, 0, 0.25);
  /* overflow: hidden; */
  padding: 0.2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in;
}

/* //how to ellipse the text , fix text in one line , ... , why text not fixing in one line bcz need to have a fix width , can fixed width be in % ans -Yes if the parent is fixed width */
.service-card-name {
  height: 17px;
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 95.6%;
  /* overflow: hidden; */
  /* or 17px */
  width: 100%;
  display: flex;
  justify-content: center;

  color: #ffffff;
}

.service-card-name > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.service-card-contact-container {
  width: 200px;
  height: 30px;
  display: flex;
  align-items: center;

  background: #03989e;
  border-radius: 10px;
  transition: all 0.2s ease-in;
}
.service-card-contact {
  width: 100%;
  text-align: center;
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 95.6%;
  /* or 17px */

  color: #ffffff;
}
.service-card-hover {
  position: absolute;
  width: 352px;
  height: 112px;
  top: 188px;

  background: rgba(68, 68, 68, 0.8);
  border-radius: 0px 0px 10px 10px;
  opacity: 0;
  transition: all 0.3s ease-in;
  padding: 1vw;
  display: flex;
  flex-direction: column;
}

.service-card-container:hover .service-card-hover {
  opacity: 1;
}
.service-card-container:hover .service-card-name-container {
  top: 158px;
}
.service-card-container:hover .service-card-contact-container {
  opacity: 0;
}
.service-hover-top-row {
  /* width: 100%;
  height: 50%; */
  /* display: flex; */
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
}
.service-hover-star-heart {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  gap: 1vw;
}

.service-card-star-container {
}
.service-card-star,.service-card-heart {
  width: 15px;
  height: 15px;
}
.service-card-heart-container {
}
.service-card-servicable-distance {
  width: 100%;
  font-size: 11px;
}
.service-card-phone-container .service-card-phone,.service-card-chat{
width: 30px;
height: 30px;
}
.service-card-servicable-distance >svg{
  height: 17px;
  width: 17px;
}
.service-card-service-subheading {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.book-now-container {
  width: 150px;
  height: 30px;
  background: #03989e;
  box-shadow: 4px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.book-now {
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 95.6%;
  /* or 17px */

  color: #ffffff;
}
.service-hover-bottom-row {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}
.service-card-services {
  margin-top: -20px;
  display: flex;
  width: 50%;
  flex-direction: column;
}
.service-card-service-heading {
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 95.6%;
  padding-left: 30px;
  padding-bottom: 3px;
  text-align: justify;
  /* or 10px */

  color: #ffffff;
}
.service-card-service-list {
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 95.6%;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.serive-card-chat-phone {
  display: flex;
  width: 40%;
  justify-content: space-evenly;
  align-items: center;
}

/* single service */
.service-container
{
  /* background-color: #F5F5F5;; */
  width: 95%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 1vw;
  position: relative;
}

.service-name
{
color: #03989E;
cursor: pointer;
}
.service-name-id1
{

    background: #405F61;
}

.service-name-container
{
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 700;
  padding-left: 4px;
font-size: 22px;
line-height: 120.6%;
}
.service-name-support-text
{
  font-family: 'Open Sans', sans-serif;;
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 95.6%;
}
.service-name-see-more
{
font-family: 'Roboto', sans-serif;;
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 95.6%;
}

/* @media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px){
  .service-container{
    padding-left: 20px;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
  .service-provide-head{
    width: 100%;
  }
  .service-card-container{
    width: 100%;
  }
} */
@media screen and (max-width: 720px) {
  .service-name {
  text-align: left;
  width: 100%;

  }
  .service-name-container{
    font-size: 16px;
  }
  .service-container{
    align-items: center;
  }
  .service-card-image
  {
    width: 250px;
    height: 180px;
  }
  .service-provide-head
  {
    margin: 0 ;
    width: 100%;
    justify-content: center;
  }
  .service-provider
  {
    height: 350px;
width: 100%;

  }
  .service-card-container
  {
    width:100%;
  }
  .service-card-name-container{
    width: 180px;
    left: 45px;
    top: 160px;
  }
  .service-card-container .service-card-hover {
 width: 270px;
 margin-top: 7px;
 height: 105px;
 opacity: 1;
}
.service-card-hover{
  opacity: 1;
}
.service-card-contact-container {
  display: none !important;
}
}