.cart-main-page-mobile-view
{
 width: 100vw;
 height: 100vh ;
}
.cart-main-page-mobile-view-header
{
 position: sticky;
 top: 0;
 width: 100%;
     width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 60px;
 background-color: rgba(0, 131, 143);
 z-index: 100;
 box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.522);
}
.cart-main-page-mobile-view-header>div
{
margin: 0 20px;
height: 25px;
width: 25px;
}
.cart-main-page-mobile-view-header>div>img
{
 height: 100%;
 width: 100%;
}
.cart-main-page-mobile-view-header>span
{
    height: 100%;
     overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 95.6%;
    color: #FFFFFF;
    display: flex;
    align-items: center;
}

/* price details */
.cart-main-page-mobile-view-price-details
{
 margin: 10px auto;
 padding: 3vw 2vw;
 background-color: #FFFFFF;
 width: calc(100% - 4vw );
 min-height: 220px;
 border-radius: 10px;

}
.cart-main-page-mobile-view-price-details-heading
{
 font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.2px;
    color: #000000;
    padding-bottom: 1vw;
}

.cart-main-page-mobile-view-price-details-inner-details
{
 width: 100%;
 padding: 3vw 0;
 display: flex;
 flex-direction: column;
 gap: 1.5vw;
 border-top: solid 1px rgba(0, 131, 143, 0.88);
 border-bottom: solid 1px rgba(0, 131, 143, 0.88);
}
.cart-main-page-mobile-view-price-details-bag-total,.cart-main-page-mobile-view-price-details-discount,.cart-main-page-mobile-view-price-details-coupon,.cart-main-page-mobile-view-price-details-sub-total
{
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: space-between;
}
.cart-main-page-mobile-view-price-details-total
{
 padding-top: 2vw;
  width: 100%;
 display: flex;
 align-items: center;
 justify-content: space-between;
 color: rgba(0, 131, 143, 0.88);

 /* font-family: 'Poppins', sans-serif; */
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
}



/* apply coupon */
.cart-main-page-mobile-view-coupon
{
 margin: 10px auto;
    padding: 2vw 5vw;
    background-color: #FFFFFF;
    width: calc(100% - 4vw );
    min-height: 60px;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cart-main-page-mobile-view-coupon>div
{
 /* font-family: 'Poppins', sans-serif; */
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 15px;
    color: #00000097;
}
.cart-main-page-mobile-view-coupon>button
{
 height: 100%;
 background-color: rgba(0, 131, 143, 0.88);
 padding: 2vw 4vw;
 border-radius: 7px;
 box-shadow: none;
 border: none;


  font-family: 'Lato';
  letter-spacing: -0.2px;
 font-style: normal;
    font-weight: 500;
    font-size: 17px;
    /* line-height: 15px; */
    color: #FFFFFF;
}




/* bookings */
.cart-main-page-mobile-view-booking
{
  margin: 10px auto;
 padding: 3vw 2vw;
 background-color: #FFFFFF;
 width: calc(100% - 4vw );
 min-height: 220px;
 border-radius: 10px;
}
.cart-main-page-mobile-view-booking-heading
{
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: flex-start;
 gap: 30px;
 padding-bottom: 10px;
}
.cart-main-page-mobile-view-booking-heading>div
{
 height: 25px;
width: 25px;
}
.cart-main-page-mobile-view-booking-heading>div>img{
 width: 100%;
 height: 100%;
}
.cart-main-page-mobile-view-booking-heading>span
{
 font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.2px;
    color: #000000;

}

.cart-main-page-mobile-view-booking-day-row-1
{
 font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    /* line-height: 20px; */
    letter-spacing: -0.2px;
    color: #000000d9;
}
.cart-main-page-mobile-view-booking-day-row-2
{
 display: flex;
 width: 100%;
 overflow: auto;
 gap: 4vw;
 margin: 2vw 0;
}
.cart-main-page-mobile-view-booking-day-row-2>button
{
 border: none;
 outline: none;
 white-space: nowrap;
 padding: 2vw 4vw;
 border-radius: 16px;



 font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    /* line-height: 20px; */
    letter-spacing: -0.2px;
    color: #000000;
}

.cart-main-page-mobile-view-booking-day-2-selected
{
 background-color: rgba(0, 131, 143, 0.88);
 color: #FFFFFF !important;
}





/* addresss  */

.cart-main-page-mobile-view-address-details
{
  margin: 10px auto;
 padding: 3vw 2vw;
 background-color: #FFFFFF;
 width: calc(100% - 4vw );
 min-height: 170px;
 border-radius: 10px;
 display: flex;
 align-items: center;
 justify-content: center;
}
.cart-main-page-mobile-view-address-details-col-1
{
 width: 85%;
 height: 100%;
}
.cart-main-page-mobile-view-delivery-name
{
font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    /* line-height: 20px; */
    letter-spacing: -0.2px;
    color: rgba(0, 131, 143, 0.88);
}
.cart-main-page-mobile-view-delivery-address
{

 font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    /* line-height: 20px; */
    letter-spacing: -0.2px;
    color: #000000;
}
.cart-main-page-mobile-view-delivery-mobile
{
font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    /* line-height: 20px; */
    letter-spacing: -0.2px;
    color: rgba(0, 131, 143, 0.88);



  
}

.cart-main-page-mobile-view-address-details-col-2
{
 width: 15%;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
}

.cart-main-page-mobile-view-address-details-col-2>div
{
 height: 25px;
width: 25px;
}
.cart-main-page-mobile-view-address-details-col-2>div>img{
 width: 100%;
 height: 100%;
}


/* total amount boottom  */
.cart-main-page-mobile-view-bottom-fixed
{
 width: 100%;
 height: 80px;
 position: sticky;
 bottom: 0;
 background-color: #d6f0f1fb;
 display: flex;
 align-items: center;
 justify-content: center;
 /* gap: 20px; */
 padding-top: 10px;
 /* position: sticky; */
 /* top: calc(100vh - 60px); */
}
.cart-main-page-mobile-view-bottom-fixed-col1
{
 width: 50%;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 background-color: #FFFFFF;
 height: 100%;

   /* font-family: 'lato'; */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    /* line-height: 20px; */
    letter-spacing: -0.2px;
    color: #0000007a;
}
.cart-main-page-mobile-view-bottom-fixed-col1>span
{
 color: #000000;
}

.cart-main-page-mobile-view-bottom-fixed-col2
{
  width: 50%;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 background-color: #FFFFFF;
 height: 100%;
}
.cart-main-page-mobile-view-bottom-fixed-col2>button
{

 height: 70%;
 background-color: rgba(0, 131, 143, 0.88);
 padding: 2vw 6vw;
 border-radius: 7px;
 box-shadow: none;
 border: none;


  font-family: 'Lato';
  /* letter-spacing: -0.2px; */
 font-style: normal;
    font-weight: 600;
    font-size: 17px;
    /* line-height: 15px; */
    color: #FFFFFF;
}





.cart-main-page-mobile-view-no-item-box-outer
{
    width: 100%;
    height: calc(100vh - 60px);
    /* height: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;

}


.cart-main-page-mobile-view-no-item-box
{
    width: 90%;
    background-color: white;
    border: 1px solid black;
    border-radius: 12px;
    padding: 3vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.cart-main-page-mobile-view-no-item-box-col1
{
    width: 50%;
    height: 100%;
    padding: 5vw;
}
.cart-main-page-mobile-view-no-item-box-col1>img
{
    width: 100%;
    height: 100%;
}

.cart-main-page-mobile-view-no-item-box-col2
{
    width: 50%;
    height: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    color: rgb(7, 94, 75);
}