.cardbody{
    position: relative;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    padding:10px;
    /* width:30%; */
    align-items:center;
    justify-content:center;
}
.cardsection{
    display:grid;
    grid-template-columns:1fr 1fr 1fr;
    width:95%;
    grid-gap:20px 15px;
    margin:auto;
    padding-bottom: 20px;
}
.buttonsectionelement{
    margin-top: 10px;
    font-weight:500;
    cursor: pointer;
    border: 1.5px solid rgba(0, 0, 0, 0.267);
    border-radius: 15px;
    padding: 7px 14px;
    color: #00838f;
}
.adminnoresult > img{
    width:400px;
    height:400px;
}
.adminbuttonsection{
    width:100%;
    display:flex;
    justify-content:space-around;
}
.cardbody .ellipsis{
    cursor: pointer;
    width: 20px;
    height: 30px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
    padding: 5px;
}
.cardbody .my-order-profile-dropdown
{
    position: relative;
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.cardbody .ellipsis .dot {
width: 4px;
height: 4px;
background-color: #333;
border-radius: 50%;
}
.login-details-header-dropdownadmin{
    width:max-content;
    position: absolute;
    right: 1px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    overflow: hidden;
    border-radius: 0.375rem;
    top: 30px;
    list-style: none;
    text-align: center;
    padding:8px 10px;
}
.login-details-header-dropdownadmin > span{
    font-size:11px;
    font-weight:700;
    cursor: pointer;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 671px){
    .cardsection{
        grid-template-columns:1fr;
    }
    .adminnoresult > img{
        width:250px;
        height:250px;
    }
}
@media only screen and (min-device-width: 671px) and (max-device-width: 1000px){
    .cardsection{
        grid-template-columns:1fr 1fr;
    }
}
