.login-otp-verification-modal-mobile-background
{
     position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
}
.login-otp-verification-modal-mobile-main-box
{
 margin: auto auto;
    background: #fff;
    width: 85%;
    max-width: 500px;
    overflow-y: scroll;
    /* padding: ; */
    height: -moz-fit-content;
    height: fit-content;
    transition: all 0.6s ease;
}
.login-otp-verification-modal-mobile-main-box-row1
{ 
 height: 50px;
 display: flex;
 align-items: center;
 justify-content:center ;
 font-weight: 600;
 font-size: 21px;
 color: rgba(0, 131, 143);
 border-bottom: 1px solid rgba(0, 0, 0, 0.446);

}
.login-otp-verification-modal-mobile-main-box-row2
{
 padding: 10vw;
 display: flex;
 align-items: center;
 justify-content: space-around;
}
.login-otp-verification-modal-mobile-main-box-row2>input
{
 height: 40px;
 /* border: none; */
 border-top: black;
 border-right: black;
 border-left: black;
 /* display: flex;
 align-items: center;
 justify-content: center; */
 text-align: center;
 font-size: 14px;
 outline: none;
}
.login-otp-verification-modal-mobile-main-box-row3
{

  height: 50px;
 display: flex;
 align-items: center;
 padding: 2vw 5vw;
 justify-content:space-between;
 font-weight: 600;
 font-size: 14px;
 color: rgba(0, 131, 143);;
}
.login-otp-verification-modal-mobile-main-box-row3>span
{

}
.login-otp-verification-modal-mobile-main-box-row3>div
{
 display: flex;
 gap: 3vw;
}
.login-otp-verification-modal-mobile-main-box-row3>div>span
{

}