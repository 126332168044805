.delete-service-provider-profile-service-main-page {
  width: 100%;
  margin-top: 160px;
}
.delete-service-provider-profile-service-main-page > span {
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 153.69%;
  color: #000000;
  opacity: 0.76;
  /* margin-left: 47px; */
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  letter-spacing: 1.1px;
  word-spacing: 2.1px;
}
.delete-service-provider-profile-service-main-page-buttons {
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.delete-service-provider-profile-service-main-page-buttons > button {
  padding: 10px 30px;
  border: none;
  outline: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: white;
  font-weight: 600;
}

.delete-service-provider-profile-service-main-page-buttons
  > button:nth-child(1) {
  background-color: #00838f;
}

.delete-service-provider-profile-service-main-page-buttons
  > button:nth-child(2) {
  background-color: rgba(255, 0, 0, 0.898);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .delete-service-provider-profile-service-main-page {
    margin-top: 50px;
  }
  .delete-service-provider-profile-service-main-page > span {
    text-align: center;
  }
}
