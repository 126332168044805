.my-profile-wallet-page-transfer-view
{
 width: 100%;
 height: 100%;
 padding: 42px;
}
.my-profile-wallet-page-transfer-top
{
 width: 100%;
 padding-bottom: 8px;
}
.my-profile-wallet-page-transfer-top>div>img
{
 cursor: pointer;
}
.my-profile-wallet-page-transfer-main-body
{

 width: 836px;
min-height: 702px;
background: #f3f3f3;
margin: auto;
padding: 27px;
}
.my-profile-wallet-page-transfer-main-body-top
{
 font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;
color: rgba(13, 13, 13, 0.89);
margin-bottom: 70px;
}
.my-profile-wallet-page-transfer-main-body-top>span
{
 padding-left: 10px;
 padding-right: 100px;
 border-bottom: 4px solid  #00838F;
}




.my-profile-wallet-page-transfer-main-body-mid
{
 display: flex;
 flex-direction: column;
 gap: 20px;

width: fit-content;
margin: auto;
 margin-bottom: 8px;
}
.my-profile-wallet-page-transfer-main-body-mid-input
{
display: flex;
justify-content: center;
}
.my-profile-wallet-page-transfer-main-body-mid-input>input
{
 width: 355px;
height: 40px;
background: #FFFFFF;
border: 1px solid rgba(10, 10, 10, 0.12);
border-radius: 12px;
padding: 12px;
}

.my-profile-wallet-page-transfer-main-body-mid-amount
{
 margin-top: 86px;
 display: flex;
 align-items: center;
 justify-content: center;
 gap: 50px;
}
.my-profile-wallet-page-transfer-main-body-mid-amount-col1
{

}
.my-profile-wallet-page-transfer-main-body-mid-amount-col1>span
{
 font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 23px;

color: #000000;
}
.my-profile-wallet-page-transfer-main-body-mid-amount-col1>section
{
 font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 23px;

color: #000000;
}
.my-profile-wallet-page-transfer-main-body-mid-amount-col1>section>input
{
 width: 100px;
height: 34px;
outline: none;
border: none;
padding: 18px;
background: rgba(98, 98, 98, 0.2);
border-radius: 20px;
}
.my-profile-wallet-page-transfer-main-body-mid-amount-col2
{

}
.my-profile-wallet-page-transfer-main-body-mid-amount-col2>button
{
 width: 159px;
height: 52px;
background: #1A8A95;
border-radius: 16px;
outline: none;
border: none;
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
/* identical to box height */

text-align: center;

color: #FFFFFF;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px){
    .my-profile-wallet-page-transfer-main-body{
        width: 100%;
        min-height: fit-content;
    }
    .my-profile-wallet-page-transfer-main-body-top{
        font-size: 23px;
    }
    .my-profile-wallet-page-transfer-main-body-top > span{
        padding: unset;
    }
    .my-profile-wallet-page-transfer-main-body-mid-amount-col1 {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    .my-profile-wallet-page-transfer-main-body-mid-amount{
        margin-top: 20px;
        flex-direction: column;
        gap: 20px;
    }
    .my-profile-wallet-page-transfer-main-body-mid-amount-col1 > section > input{
        width: unset;
    }
}
