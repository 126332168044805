/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Raleway&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Mulish&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Overpass+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap"); */

/* #root
  {
    width: 100vw;
  } */

select {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f4fbf6; */
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.a {
  display: fixed;
  left: 0;
  top: 0;
}
button {
  cursor: pointer;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  /* width: 100vw; */
}
body {
  margin: 0;
  height: 100%;
  background-attachment: fixed;
}
/* ::-webkit-scrollbar {
    display: none;
} */

/* css for desktop view */
.desktop-view {
  display: block;
}
.mobile-view {
  display: none;
}
/* Smartphones (portrait and landscape) ----------- */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .desktop-view {
    /* display: block; */
    display: block;
  }
  /* .mobile-view
{
  display: none;
  background-color: #d6f0f1fb;
}  */
}

@media only screen and (min-device-height: 320px) and (max-device-height: 480px) {
  .desktop-view {
    display: block;
  }
  /* .mobile-view
{
  display: block;
  background-color: #d6f0f1fb;
}  */
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-device-height: 600) {
  .desktop-view {
    display: block;
  }
  /* .mobile-view
{
  display: none;
} */
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  .desktop-view {
    display: block;
  }
  .mobile-view {
    display: none;
  }
}

/* iPhone 4 ----------- */
/* @media
only screen and (-webkit-min-device-pixel-ratio : 1.5),
only screen and (min-device-pixel-ratio : 1.5) {
.desktop-view
{
  display: block;
}
.mobile-view
{
  display: none;
} 
} */

/* 

@media only screen and (max-width: 583px)
{
  .desktop-view
{
  display: none;
}
.mobile-view
{
  display: block;
  background-color: #d6f0f1fb;
} 
} */

@media only screen and (max-width: 583px) {
  .allservice-last-container {
    padding: 12px;
    padding-bottom: 20px;
  }

  .allservice-last-heading {
    padding: 0;
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 20px;
    /* identical to box height, or 83% */
    text-align: justify;
    letter-spacing: -0.2px;

    color: #00838f;
    padding-bottom: 32px;
  }

  .allservice-last {
    padding: 0;
    display: grid;
    width: 100%;
    align-content: center;
    justify-content: center;
    grid-template-columns: 6fr 6fr;
    grid-column-gap: 2vw;
    grid-row-gap: 2vw;
    justify-items: center;
    align-items: center;
  }
  .allservicetab-container,
  .allservicetab-image {
    width: 181px;
    height: 140px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .allservicetab-image {
    width: 100%;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.loan-page-inner-rec-col2-inputs > select {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #7c7a7a;
  padding-bottom: 0;
  padding-top: 0;
  display: flex;
  align-items: center;
}

/* Blog section css */

.blogMain {
  width: 100%;
  height: 100vh;
  padding: 20px;
  font-family: "Poppins", sans-serif;
  background-color: rgb(244, 251, 246);
  display: flex;
  flex-direction: column;
  position: relative;
}
.blogOverlay {
  background-color: #00000065;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  z-index: 90;
  position: absolute;
}
.addBlogButton {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.backButtonBlog {
  display: none;
  font-size: 23px;
  cursor: pointer;
}
.addBlogButton > button {
  color: white;
  background-color: #00838f;
  padding: 10px 18px;
  border: none;
  border-radius: 10px;
  /* margin-bottom: 10px; */
  justify-content: flex-end;
}

.allBlogs {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  position: relative;
  display: flex;
  height: 90vh;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 21px;
  row-gap: 50px;
  height: 90vh;
}
.blogMyProfile {
  width: 80%;
  height: fit-content;
  border: 0.5px solid rgba(0, 0, 0, 0.4);
  border-radius: 15px 15px 15px 15px;
  position: relative;
  /* blog ui changes */
  display: none;
}
.blogMyProfileHeader {
  width: 100%;
  height: 100%;
  border-radius: 15px 15px 0 0;
  object-fit: cover;
}
.blogMyImage {
  width: 70px;
  height: 70px;
  position: absolute;
  border-radius: 50%;
  top: 50px;
  left: 20px;
}
.blogMyImage img {
  border-radius: 50%;
  height: 60px;
  width: 60px;
}
.blogMyprofileInformation {
  margin-top: 2.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  font-size: 18px;
}
.blogMyprofileInformation div {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  font-size: 14px;
}
.blogDummyLine {
  width: 100%;
  height: 0;
  border: 0.5px solid #999999;
  position: relative;
}
.myBlogOption {
  list-style: none;
  margin-top: 0.8rem;
}
.myBlogOption li {
  margin-bottom: 0.4rem;
  height: 3rem;
  padding: 1rem 0.8rem;
}
.myBlogOption li:hover {
  background-color: #999999;
  cursor: pointer;
}
.seeWhoIsHiring {
  width: 100%;
  height: 252px;
  position: relative;
  top: 265px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.blogNav {
  width: 100%;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  column-gap: 30px;
}
.blogProfileSmall,
.newBlog {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.newBlog {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blogProfileSmall img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.downangle {
  width: 12px;
  height: 8px;
}
.filterLine {
  width: 100%;
  height: 40px;
  padding: 10px 0;
  display: grid;
  align-items: center;
  grid-template-columns: 6fr 100px 147px;
}
.filterOptions {
  width: 344px;
  height: 224px;
  background-color: #fff;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;
  overflow-y: auto;
  float: right;
  z-index: 200;
  position: absolute;
  top: 105px;
  right: 0;
  display: none;
}

.moreOption2,
.moreOption3 {
  width: 89px;
  height: 90px;
  position: absolute;
  top: 62px;
  right: 30px;
  float: right;
  display: flex;
  flex-direction: column;
  align-items: space-around;
  background: #ffffff;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  border-radius: 3px;
  display: none;
  padding: 0 10px;
}

.filterOptions ul li,
.moreOption li,
.moreOption2 li,
.moreOption3 li {
  padding: 5px 0;
  list-style: none;
}
.lineBlog {
  height: 1px;
  border: 0.5px solid rgba(0, 0, 0, 0.5);
}
.filterBox {
  display: flex;
  justify-content: space-around;
  background: #ffffff;
  border: 0.6px solid rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 4px 0;
}

.moreIconBlog {
  display: flex;
  align-items: center;
}

.marker {
  height: 10rem;
  margin-top: 2rem;
  padding: 1rem 2rem;
}

.marker {
  height: 10rem;
}

.blogposts_loading {
  height: 6rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem;
}

.youMayAlsoLike p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 33px;
  color: #121416;
  margin-bottom: 20px;
}

.youMayAlsoLikeInfoBox {
  border: 1px solid black;
  border-radius: 15px;
  padding: 1.2rem;
  background-color: white;
}
.youMayAlsoLikeInfoBox p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}
.dateAndTime {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.2rem;
}
.clockimgblog {
  width: 50px;
  height: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clockimgblog img:nth-child(2) {
  margin-left: -10px;
}
.blogMain img {
  cursor: pointer;
}

/* mobile screen blog */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .blogMain {
    padding: 1rem;
  }
  .addBlogButton {
    justify-content: space-between;
  }
  .backButtonBlog {
    display: block;
  }
}

/* personal Blog css */
.myBlogBlog {
  width: 100%;
  padding: 100px 168px 100px 86px;
  /* display: none; */
}
.myBlogBlogC {
  width: 100%;
  /* background-color:red; */
  position: relative;
  padding-bottom: 77px;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.4);

  border-radius: 0px 0px 15px 15px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.myBlogBlogNav {
  width: 100%;
  background: #6a878a;
  border-radius: 20px 20px 0px 0px;
  height: 70px;
}
.myBlogBlogImg {
  width: 128px;
  /* background-color:yellow; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  position: absolute;
  top: 10px;
  left: 92px;
}
.myBlogBlogImgg {
  width: 70px;
  height: 70px;
}
.myBlogBlogImgg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.myBlogBlogMyName h1 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}
.myBlogBlogMyName p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #828282;
}
.fAndS {
  width: 80%;
  margin-right: 0;
  display: flex;
  justify-content: space-between;
  float: right;
  padding: 20px 200px;
}
.fAndS p {
  margin: 0;
}
.followers {
  width: 270px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: right;
}
.followers p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #828282;
}
.savedBlogs {
  width: 315px;
  float: right;
}
.savedBlogs ul {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.savedBlogs ul li {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #161616;
}
.writeYourBlog {
  width: 570px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 50px;
}

.blogImage2 img {
  width: 100%;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .allBlogs {
    padding: 10px 20px;
    row-gap: 10px;
  }
}

@media (max-width: 1220px) {
  .blogNav {
    padding: 0 20px;
  }
  .blogsofAll2 {
    width: 100%;
  }
}
@media (max-width: 964px) {
  .myBlogBlog {
    padding: 40px 10px;
  }
}
@media (max-width: 1509px) {
  .fAndS {
    padding: 20px;
  }
}
