.post-card-container {
  width: 420px;
  height: 530px;
  /* max-height: 588px; */
  /* height: fit-content; */
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #00838f;
  border-radius: 10px;
  position: relative;
  margin-bottom: 2rem;
  background-color: white;
  padding: 2rem;
  padding: 15px 20px;
  /* padding-top: 1rem; */
}
.post-card-detail-container {
  margin-top: 5px;
}
.post-card-top-row {
  width: 100%;
  height: 71px;
  display: grid;
  grid-template-columns: 70px 1fr 20px;
  align-items: center;
}
.post-card-top-row-details {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.post-card-top-row-details > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin: 0 auto;
}
.post-card-top-row-info p:nth-child(1),
.post-card-post-text > p {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  color: #000000;
  margin: 0;
}
.post-card-top-row-info p:nth-child(2) {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #515151;
  margin: 0;
}
.post-card-top-row-dots {
  cursor: pointer;
}
.post-card-top-row-dots > div {
  width: 4px;
  height: 4px;
  background: #c4c4c4;
  border-radius: 100px;
  position: relative;
}
.post-card-top-row-dots-option-no {
  display: none;
}
.post-card-top-row-dots-option {
  width: 90px;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 28px;
  right: 20px;
  background: white !important;
}
.post-card-top-row-dots-option > span {
  padding: 8px 14px;
  background: white;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}
.post-card-top-row-dots-option > span:hover,
.post-card-top-row-dots-option > div:hover {
  filter: brightness(0.9);
}
.post-card-top-row-dots-option > div {
  padding: 8px 14px;
  /* background: rgba(162, 225, 255); */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #fe0000;
}
/* .post-card-post-text{
  max-height: 27px;
  overflow: auto;
} */
.post-card-post-image {
  border-radius: 10px;
  width: 100%;
  height: 250px;
  align-self: center;
  margin-top: 1.2rem;
  object-fit: inherit;
  border-radius: 10px;
  cursor: pointer;
}
.post-card-post-image-hr {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 3vw;
  margin-top: 4px;
  height: 1px;
  background-color: rgba(128, 128, 128, 0.819);
  width: calc(100% - 6vw);
  text-align: center;
}
.post-card-populartiy {
  width: 100%;
  padding: 4px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.post-card-populartiy-likes {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #515151;
}

.post-card-populartiy-other {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2vw;
}
.post-card-populartiy-other > span {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #515151;
}
.post-card-populartiy-other > div {
  width: 4px;
  height: 4px;
  background-color: gray;
  border-radius: 100px;
}
.post-card-post-icons {
  padding: 4px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.post-card-post-icon1 {
  width: 30%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.post-card-post-icon1 {
  width: 30%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.post-card-post-icon1 > img,
.post-card-post-icon2 > img {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.post-card-add-comment {
  margin: 20px 0;
  padding: 8px 10px;
  background: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}
.post-card-add-comment > img {
  height: 26px;
  width: 26px;
  margin-right: 5px;
  border-radius: 100px;
}
.post-card-add-comment > input {
  background: #f6f6f6;
  outline: none;
  border: none;
  width: 80%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: black;
}
.post-card-add-comment-image {
  float: left;
  display: flex;
  gap: 16px;
}
.post-card-add-comment-image > img {
  height: 20px;
  width: 20px;
}
.likes-and-comments-container {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* width: 100px; */
  gap: 10px;
}
.commentsBlog {
  cursor: pointer;
}
.commentsBlog img {
  width: 20px;
}
.likeImg img {
  width: 23px;
}
.likesAndMore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #515151;
  margin-top: 8px;
  padding: 1rem 2rem;
}
.likesBlogs {
  width: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.likesAndMore p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #515151;
  margin: 0;
}
.heartLike {
  color: red;
  font-size: 32px;
  cursor: pointer;
}
.shareAndMorePost {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-bottom: 0;
  margin-top: 5px;
}
.shareAndMorePost p {
  margin: 0 0 0 5px;
}

.postCardInteractions {
  display: flex;
  align-items: center;
  margin-top: 20px;
  column-gap: 20px;
  padding: 0 10px;
}
.postCardLikes {
  display: flex;
  gap: 10px;
  width: fit-content;
  align-items: center;
}
.postCardLikes > span {
  margin-left: -20px;
}
.postCardLikes > span,
.postComments > span {
  font-size: 14px;
}

.postComments > span {
  font-size: 14px;
}
.likeIcon {
  font-size: 28px;
  color: #8b8b8b;
}

.postComments {
  display: flex;
  gap: 10px;
  height: 20px;
  width: fit-content;
  align-items: center;
}
.postComments > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.postShare {
  display: flex;
  gap: 10px;
  height: 20px;
  width: fit-content;
  align-items: center;
  margin-left: auto;
}
.postShare > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.postCommentUserProfileImg {
  height: 26px;
  width: 26px;
  margin-right: 5px;
  border-radius: 50%;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .post-card-top-row-info p:nth-child(1),
  .post-card-post-text > p {
    font-weight: 600;
    font-size: 16px;
  }
  .post-card-top-row-info p:nth-child(2) {
    font-weight: 400;
    font-size: 12px;
  }
  .post-card-post-text:has(.seemore){
    max-height:unset;
  } 
  .sendIcon1{
    font-size:20px !important;
  }
}
@media screen and (max-width: 720px) {
  .post-card-container {
    width: 100%;
  }
}
