.chat-box-main-chatiing
{
 width: 100%;
 height: 100%;
 overflow: scroll;
 padding-left: 64px;
 padding-right: 13px;
 
}
.chat-box-main-chatiing-chat-right
{
 margin-top: 12px;
 width: 100%;
 min-height: 70px;
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 gap: 10px;
}
.chat-box-main-chatiing-chat-right-message
{
 min-width: 50px;
min-height: 47px;
max-width: 300px;
padding: 15px;
background: #E8ECEF;
border-radius: 10px;


font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;

color: #000000;
}
.chat-box-main-chatiing-chat-right>span
{
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 11px;
line-height: 13px;
/* identical to box height */


color: #727272;
}



.chat-box-main-chatiing-chat-left
{
 margin-top: 12px;
width: 100%;
 min-height: 70px;
 display: flex;
 flex-direction: column;
 align-items: flex-end;
 gap: 10px;
}
.chat-box-main-chatiing-chat-left-message
{
 min-width: 50px;
min-height: 47px;
max-width: 311px;
padding: 15px;
background: #128C7E;
border-radius: 10px;

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;

color: #FFFFFF;
}
.chat-box-main-chatiing-chat-left>span
{
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 11px;
line-height: 13px;
/* identical to box height */


color: #727272;
}

@media screen and (max-width: 720px) {
.chat-box-main-chatiing {
 
    padding-left: 13px;
    padding-right: 13px;
}
}