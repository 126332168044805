.profile-wallet-transaction-page-view
{
 width: 100%;
 height: 100%;
 padding: 24px;
}
.profile-wallet-transaction-page-top
{
 display: flex;
 align-items: center;
 margin-bottom: 42px;
}
.profile-wallet-transaction-page-heading
{
 font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;
color: rgba(13, 13, 13, 0.89);
padding-left: 4px;
padding-right: 70px;
margin-left: 50px;
border-bottom: solid 4px #00838F;;
}

.profile-wallet-transaction-page-view-other-detail
{
 margin-top: 25px;
 margin-left: 240px;
 display: flex;
 align-items: flex-end;
 gap: 45px;
}
.profile-wallet-transaction-page-no-of-trans
{
 font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 16px;

color: #4F4F4F;
}

.profile-wallet-transaction-fiter
{
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 16px;
color: rgba(0, 95, 104, 0.7);

}
.profile-wallet-transaction-fiter>select
{
 margin-left: 17px;
 width: 115px;
height: 28px;
border: 0.5px solid #000000;
}

.profile-wallet-transaction-page-view-next-page
{
 width: 100%;
 height: 30px;

 font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;

color: rgba(79, 79, 79, 0.8);
display: flex;
gap: 8px;
align-items: center;
justify-content: flex-end;
}


.profile-wallet-transaction-page-no-transcation{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .profile-wallet-transaction-page-no-transcation>img
 {
 width: 500px;
 height: 500px;
 }

 

 @media only screen and (min-device-width: 320px) and (max-device-width: 480px){
 .profile-wallet-transaction-page-no-transcation > img {
   width: 100%;
   height: 100%;
 }
}