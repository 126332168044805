.bestseller-container
{
  display: flex;
  flex-direction: column;
  gap: 20px;
background-color: #F5F5F5;

padding-top:40px ;

}
.bestseller-heading-container
{
    display: flex;
    padding-left: 36px;
    margin-bottom: 10px;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 1vw;
}
.bestseller-vertical-fill
{
width: 13px;
height: 45px;

background: #03989E;
}
.bestseller-heading
{
  color: #03989e;
  font-family: Titillium Web,sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 95.6%;
}

.bestseller-container .bestseller-not-available{
  display: flex;
  justify-content: center;
  font-weight: 600;
  color: #03989E;
  font-size: 20px;
  margin-left: 44px;
}
@media screen and (max-width: 720px)
{
  .bestseller-vertical-fill
  {
    width: 0;
    height: 0;
  }
  .bestseller-heading-container
  {
    text-align:left;
    padding-left: 2.5vw;
    padding-bottom: 0;
  }
  .bestseller-container
  {
    padding-top: 20px;
    gap: 10px;
  }


.bestseller-heading {
font-size: 23px;
}
}