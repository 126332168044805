.putatoeServiceView-container
{
 height: 100%;
 width: 100vw;
    background-color: #F5F5F5  !important;
}
.putatoeServiceView-top-heading
{
 padding-top: 20px;
    padding-left: 2.5vw;
    font-family: 'Titillium Web', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 46px;
    letter-spacing: 0.02em;
        color: #03989E;
        padding-bottom: 1.5vw;
}
.putatoeServiceView-sec-heading-container
{
 padding-top: 76px;
     display: flex;
    padding-left: 2.5vw;
    padding-bottom: 3.5vw;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 1vw;
}
.putatoeServiceView-sec-vertical-fill
{
    width: 13px;
    height: 70px;
    background: #03989E;
}
.putatoeServiceView-sec-heading
{
 font-family: 'Titillium Web', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 95.6%;
    color: #03989E;
    padding-bottom: 5px;
}
.putatoeServiceView-sec-data
{
 /* background-color: white !important;  */
 margin-left: -60px;
}
.putatoeServiceView-container .popular-product-container .popular-product-category {
    color: #03989E;
}


.putatoeServiceView-third-heading
{
 padding-top: 60px;
    padding-left: 2.5vw;
    font-family: 'Playfair Display', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 53px;
    text-align: center;
    color: #008D94;
    padding-bottom: 58px;
}

@media only screen and (max-width: 720px){
    .putatoeServiceView-third-heading{
        font-size: 30px;
    }
}