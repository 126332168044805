.service-provider-mobile-view
{
 width: 100%;
 position: sticky;
 top:0;
}
.service-provider-mobile-main-screen-container
{
 position: relative;
 z-index: 10;
 background-color: #128B96;

}

