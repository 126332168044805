/*Promotion css*/
/*Promotion css*/
.postMain {
  width: 100%;
  height: 100vh;
  padding: 20px;
  font-family: "Poppins", sans-serif;
  background-color: rgb(244, 251, 246);
  display: flex;
  flex-direction: column;
  position: relative;
}
.postOverlay {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.808);
  z-index: 9999;
  top: 0;
  left: 0;
}
.perticulerPost {
  width: 100%;
  height: 100%;
  column-gap: 40px;
  padding: 1rem 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.post-container {
  overflow: hidden;
  overflow-y: hidden;
  overflow-y: auto;
  width: 100%;
  position: relative;
  display: flex;
  height: 90vh;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 90vh;
}
.posts_loading {
  height: 6rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem;
}
.protions-write-a-post-button-container {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 10px;
}
.backButtonPost{
  display: none;
  font-size: 23px;
  cursor: pointer;
}
.protions-write-a-post-button {
  color: white;
  background-color: #00838f;
  padding: 10px 18px;
  border: none;
  border-radius: 10px;
}
.allPromotion-container {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: fit-content;
}
/* .promotions-display-all-posts-container
{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  height: 85vh;
  overflow-y: scroll;
} */

/* @media (max-width: 768px) {
  .promotions-display-all-posts-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .promotions-display-all-posts-container {
    grid-template-columns: repeat(1, 1fr);
  }
} */
@media screen and (max-width: 720px) {
  .protions-write-a-post-button-container {
    padding-right: 5%;
    top: 65px;
  }
  .perticulerPost{
    padding: 10px;
  }
  .protions-write-a-post-button-container {
    justify-content: space-between;
  }
  .backButtonPost{
    display: block;
  }
}
