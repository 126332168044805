.add-address-modal-container-background {
  position: fixed;
  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  /* overflow: scroll; */
}
.add-address-modal-container {
  z-index: 50;
  /* position: relative; */
  position: absolute;
  top: 114px;
  right: calc((100% - 500px) / 2);
  margin: auto;
  overflow-y: auto;
  padding: 1.5rem;
  width: 500px;
  transition: all 0.6s ease;
  scroll-behavior: smooth;

  /* pointer-events: auto; */
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  height: fit-content;

  /* overflow: auto; */
  /* height: auto; */
  /* position: fixed; */
  /* left: 0px; */
  /* transition: 0.5s; */
  /* width: 0px; */
  /* padding: 0; */
  /* padding-bottom: 20px; */
}
/* .add-address-modal-container-intro-in
{
 right: 0 !important;
 transition: all 0.6s ease;
} */
.add-address-modal-cross {
  display: block;
  width: 100%;
  font-size: 25px;
  text-align: right;
  color: #00838f;
  cursor: pointer;
  font-weight: 800;
  line-height: 1.5;
}

.add-address-modal-coupon-enter {
  display: flex;
  flex-direction: column;
}

.add-address-modal-coupon-enter-input {
  padding: 8px 0px;
  display: flex;
  font-size: 12px;
  flex-direction: column;
}
.add-address-modal-coupon-enter-input > span {
  font-size: 16px;
  font-weight: 700;
  color: #00838f;
  letter-spacing: 0.2px;
  padding-bottom: 8px;
}
.add-address-modal-coupon-enter-input > input {
  height: 40px;
  width: 100%;
  font-size: 12px;
  padding: 12px;
  border: 1px solid #d4d5d9;
  background-color: transparent;
  font-weight: 300;
  border-radius: 4px;
  line-height: 14px;
}

.add-address-modal-coupon-enter-input > select {
  height: 40px;
  width: 100%;
  font-size: 12px;
  padding: 12px;
  border: 1px solid #d4d5d9;
  background-color: transparent;
  font-weight: 300;
  border-radius: 4px;
  line-height: 14px;
}

.error input,.error select {
  border: 1px solid #cc0033;
  outline: none;
}
.add-new-address-input-check {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  color: #94969f;
  font-size: 12px;
  margin-bottom: 10px;
}
.add-new-address-input-check > label {
  margin: 0;
}

.add-address-modal-map-section {
  width: 100%;
  display: flex;
  justify-content: center;
}
.add-address-modal-map-section > button {
  height: 50px;
  color: white;
  border: none;
  padding: 5px 10px;
  background-color: #00838f;
  display: flex;
  flex-direction: column;
}
.add-address-modal-map-section > button > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.add-address-modal-coupon-enter-submit {
  width: 500px;
  padding: 8px;
  margin: 0 -1.5rem;
  margin-bottom: -1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.add-address-modal-coupon-enter-submit > button {
  width: 95%;
  height: 45px;
  text-align: center;
  background: #00838f;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-weight: 700;
}

@media screen and (max-width: 720px) {
  .add-address-modal-container {
    width: 90%;
    left: 0;
    right: 0;
    z-index: 5;
    top: 180px;
  }
  .add-address-modal-container-background {
    z-index: 2;
  }
  .add-address-modal-coupon-enter-submit {
    width: 100%;
    margin: unset;
  }
  .add-address-modal-coupon-enter-submit > button {
    font-size: 11px;
  }
}
