.service-card-product-info-container
{

}
.back-container-single-page{
    margin-top: 20px;
margin-left: 20px;
}
.back-container-single-page >svg{
    font-size: 34px;
    cursor: pointer;
}
.service-card-product-info-container-top-prime .service-provider-card-details-top-product-new-page{
    padding: 0;
}
.service-card-product-info-container .service-provider-card-details-brand-name-share-container{
display: flex;
width: 60%;

justify-content: space-between;
align-items: center;
}
.service-card-product-info-container .service-provider-card-details-brand-name-share-container .share-wrapper{
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}
.service-card-product-info-container .service-provider-card-details-brand-name-share-container .share-wrapper .share-label{
    color: #00838f;
    font-weight: 600;
    font-size: 17px;
}
.service-card-product-info-container-top
{
 width: 100%;
 display: flex;
 /* align-items: center; */
 justify-content: center;
 /* flex-direction: column; */
 padding: 3vw;
 gap: 1vw;
}


.service-card-product-image-container{
    border-radius: 15px;
    padding:20px;
    border: 1px solid #ececec;
    
  
}
.service-card-product-image-container>img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    width: 400px;
    height: 270px;

}
.service-card-product-image-sec-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 2vw;
    height: 70px;
    overflow-x: scroll;
    width: 462px;
}
.service-card-product-info-container-top-carousel
{
 width: 50%;
 height:fit-content;
 display: flex;
 flex-direction: column;
 gap: 20px;
 justify-content: center;
 align-items: center;
 position: relative;
}
.service-card-product-info-container-top-carousel>img
{
    width: 400px;
    height: 300px;



vertical-align:center;
object-fit: contain;
}

.service-card-product-info-container-top-carousel-scroll
{
 position: absolute;
 width: 80%;
 height: fit-content;
 display: flex;
 align-items: center;
 justify-content: space-between;

}
.service-card-product-info-container-top-carousel-scroll-left
{
 opacity: 0.5;
 padding: 1vw 0.4vw;
}
.service-card-product-info-container-top-carousel-scroll-right
{
opacity: 0.5;
padding: 1vw 0.4vw;
}



.service-card-product-info-container-top-prime
{
 display: flex;
 flex-direction: column;
 justify-content: center;
 width: 50%;
 /* gap: 0.3vw; */
 height: 300px;
}
.service-card-product-info-container-top-prime-brand
{
font-family: 'Poppins';
font-size: 20px;
    font-weight: 700;
    color: rgb(55, 16, 93);
    text-transform: uppercase;
    /* margin-bottom: 0.1vw; */
}
.service-card-product-info-container-top-prime-product-type{
    font-family: 'Poppins';
    font-size: 20px;
        font-weight: 500;
        color: rgb(55, 16, 93);
        text-transform: uppercase;   
}
.service-card-product-info-container-top-prime-name
{
 font-family: 'Poppins';
font-size: 18px;
    font-weight: 700;
    color: rgb(31, 3, 22);
    text-transform: capitalize;
    margin-bottom: 0.2vw;
}
.service-card-product-info-container-top-prime-mrp-new
{
margin-top: 10px;
font-style: normal;
font-weight: 600;
font-size: 12px;
/* line-he/ight: 18px; */
/* identical to box height */

/* text-align: right; */
display: flex;
align-items: center;
gap: 15px;
color: rgba(0, 0, 0, 0.535);
}
.service-card-product-info-container-top-prime-price
{

font-style: normal;
font-weight: 600;
font-size: 12px;
/* line-height: 18px; */
/* text-align: right; */

color: #000000;
}
.service-card-product-info-container-top-prime-price>span
{
 color: #000000;
 font-weight: 600;
 font-size: 21px;
}
.service-card-product-info-container-top-prime-discount
{
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 12px;
/* line-height: 16px; */
/* identical to box height */

/* text-align: right; */

color: #FF6868;
}
.service-card-product-info-container-bottom-scontainer-details{
    display: flex;
width: 100%;

}
.service-card-product-info-container-top-prime-rating
{
 display: flex;
 /* gap: 1vw; */
 margin-bottom: 10px;
}
.service-card-product-info-container-top-prime-quantity
{
    width: 50%;
font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    /* line-height: 15px; */
    /* text-align: right; */
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
position: relative;
    height: fit-content;

    justify-content: flex-end;
    gap: 5px;

}
.service-card-product-info-container-top-prime-quantity::before {
    content: 'Qty:';
    position: absolute;
    z-index: 3;
    font-size: 14px;
    top: 29px;

padding-left: 6px;
    color: black;
   }
   .service-card-product-info-container-top-prime  .service-stock-color-container{
color: #00838f;
   }
.service-card-product-info-container-prime-quantity {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    /* line-height: 15px; */
    /* text-align: right; */
    color: #000000;
    display: flex;
    width: 50%;
    align-items: center;
    /* justify-content: flex-start; */
    gap: 4px;
}
.service-card-product-info-container-prime-quantity>select {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    position: relative;
    line-height: 153.69%;
    width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1.8px solid #D0DADB;
    border-radius: 10px;
    height: 22px;
    padding-top: 2px;
    display: flex;
    align-items: center;
    outline: none;
margin-left: -5px;
    padding-left: 28px;
}
.service-card-product-info-container-prime-quantity::before {
    content: 'Qty:';
    position: absolute;
    z-index: 3;
    font-size: 12px;

padding-left: 6px;
    color: black;
   }
.service-card-product-info-container-top-prime-quantity>span
{

}
.service-card-product-info-container-top-prime-quantity>select
{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 153.69%;
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #D0DADB;
    border-radius: 10px;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 30px;

}


.service-card-product-info-container-top-prime-add-to-cart
{
    width: 50%;
    margin-top: -30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 10px;
}
.service-card-product-info-container-top-prime-quantity-counter
{
 display: flex;
 align-items: center;
 gap: 10px;
}
.service-card-product-info-container-top-prime-quantity-counter>span
{
 font-family: 'Titillium Web';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 30px;
/* text-align: right; */
border-radius: 2px;
color: #00838f;
cursor: pointer;
padding: 0.5vw;
}
.service-card-product-info-container-top-prime-quantity-counter>div{
width: 30px;
height: 30px;
background:#00838f1a;
border: 0.5px solid #a5a3a3;
border-radius: 5px;

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
color: #000000;
display: flex;
align-items:center ;
justify-content: center;
}
.service-card-product-info-container-top-prime-quantity-cart
{
 /* background: white;
border: 0.25px solid #CECECE;
box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
border-radius: 4px; */
}
.service-card-product-info-container-top-prime-quantity-cart>button
{
    align-items: center;
    background: #00838f;
    border: 0.25px solid #cecece;
    border-radius: 10px;
    box-shadow: 0 3px 4px rgba(0,0,0,.1);
    color: #fff;
    display: flex;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    gap: 2px;
    height: 38px;
    justify-content: center;
    line-height: 12px;
    outline: none;
    width: 95px;
}
.service-card-product-info-container-top-prime-quantity-cart>button>img{
 width: 8px;
 height: 8px;
}
.service-card-product-info-container-top-prime-quantity-cart>button>span
{
 font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
color: #4F4F4F;
}








/* bottom */
.service-card-product-info-container-bot
{
 width: 100%;
 display: flex;
 /* align-items: center; */
 justify-content: center;
 flex-direction: column;
 padding: 3vw;
 padding-top: 0;
}
.service-card-product-info-container-bot-name
{
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 20px;
/* line-height: 24px; */
text-align: left;
color: #000000d6;
margin-left: -1vw;
margin-bottom: 1rem;
}

.service-card-product-info-container-bot-about
{

}

.service-card-product-info-container-bot-about>h1
{
font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 16px;
text-align: left;

color: #000000;
}
.service-card-product-info-container-bot-about>p
{
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 20px;

color: #00000083;
}

.service-card-product-info-container-bot-similar-products{
width: 100%;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}




/* select bar */
.service-card-product-info-container-top-prime-variety
{
 padding-top: 78px;
 position: relative;
width: 412px;
height: 126px;
}
.service-card-product-info-container-top-prime-variety>select
{
 position: relative;
 display: flex;
flex-direction: column;
align-items: flex-start;
padding: 12px 16px;
gap: 10px;

width: 412px;
height: 48px;

background: #D8FFEC;
z-index: 9;
border: 1px solid #BDBDBD;
border-radius: 4px;
}
.service-card-product-info-container-top-prime-variety>select>option
{
 position: relative;
background: #FFFFFF;
height: 54px;
}
.service-card-product-info-container-top-prime-variety>select>option>div
{
 position: relative;
width: 412px;
height: 54px;
z-index: 20;
background: #881e1e !important;
}
.service-card-product-info-container-top-prime-variety-line
{
width: 100%;
height: 2px;
/* Gray 4 */

border: 1px solid #BDBDBD;
}
.service-card-product-info-container-top-prime-variety-options
{
    display: flex; 
    align-items: center;
    justify-content: center;
    gap: 1vw;
}

.service-card-product-info-container-top-prime-variety-quantity
{
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 10px;
line-height: 12px;

color: #000000;
}
.service-card-product-info-container-top-prime-variety-price
{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
text-align: right;

color: #000000;
}
.service-card-product-info-container-top-prime-variety-price>span
{
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 18px;
color: #07B963 !important;
}
.service-card-product-info-container-top-prime-variety-off
{
font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 10px;
line-height: 12px;
color: #DE3847;
}
@media screen and (max-width: 720px) {
    .service-card-product-info-container{
        width: 100%;

min-height: 100vh;
padding: 20px;

    }
    .service-card-product-info-container .service-provider-card-details-brand-name-share-container{
        width: 100%;
    }
    .service-card-product-info-container-prime-quantity
    {
        width: 70%
    }
    .service-card-product-info-container-top {
        flex-direction: column;
        margin-top: 30px;
    }
    .service-card-product-info-container-top-carousel,.service-card-product-image-sec-container  {
        width: 100%;

    }
    .back-container-single-page{
        margin-top: -10px;

margin-bottom: 52px;
    }
    .service-card-product-info-container .service-card-product-info-container-bot-similar-products  .similar-product-container-new{
height: fit-content;
    }
    .service-card-product-info-container-top-carousel:not(:has(.service-card-product-image-sec-container)) {
height: 200px;


    }
    .service-card-product-image-container {
        width: 100%;
    }
    .service-card-product-image-container >img,.service-card-product-info-container-top-carousel>img {
        width: 100%;
        height: 100%;
    }
    .service-card-product-info-container-top-prime {
        width: 100%;
        margin-top: 20px;
    }
    .imagecorrect{
        max-height: 150%;
    }
    .imagecorrect>img{
        height: unset !important;
        max-height:100%;
    }
    .justformooobille{
        align-items: flex-end !important;
    }
}