.login-box-sidebar-logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
}
.login-box-sidebar-logo > img {
  width: 100px;
  height: 100px;
}
.login-box-sidebar-welcome {
  width: 100%;
  margin-top: 14px;
  text-align: center;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
}
.login-box-sidebar-welcome > span {
  font-weight: 700;
}

.login-box-sidebar-text {
  margin-top: 17px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  text-align: center;

  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  padding: 0 2.63vw;
}
.login-box-sidebar-option {
  width: 100%;
  padding: 0 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1vw;
  transition: all 1s;
  margin-top: 24px;
}
.login-box-sidebar-option > span {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.05em;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
}
.login-box-sidebar-option > div {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  letter-spacing: 0.05em;
  color: #000000;
  border-bottom: 2px solid #228fa6;
  padding-bottom: 4px;
}

.login-box-sidebar-input-box {
  margin-top: 21px;
  /* margin-bottom: 6px; */
  padding: 0 40px;
  display: flex;
  flex-direction: column;
}
.compulsory {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content:center; */
}
.compulsory .star {
  margin-top: 5px;
}
.login-box-sidebar-input-box > span {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.6);
}
.login-box-sidebar-input-box > input {
  background: #f2f2f2;
  border: 0.2px solid #228fa6;
  box-shadow: 0px 0px 10px rgba(34, 143, 166, 0.15);
  border-radius: 3px;
  height: 40px;
  padding-left: 3px;
  margin-bottom: 20px;
}
.login-box-sidebar-input-box > div {
  background: #f2f2f2;
  border: 0.2px solid #228fa6;
  box-shadow: 0px 0px 10px rgba(34, 143, 166, 0.15);
  border-radius: 3px;
  height: 40px;
  padding-left: 3px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.login-box-sidebar-input-box > div > input {
  height: 100%;
  flex-grow: 1;
  background: #f2f2f2;
  border: none;
  outline: none;
}
.login-box-sidebar-input-box > div > img {
  cursor: pointer;
  height: 19px;
  width: 22px;
}

.login-box-sidebar-button-login {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
.login-box-sidebar-button-login > button {
  width: 140px;
  height: 48px;
  border: none;
  background: #228fa6;
  box-shadow: 0px 15px 30px rgba(34, 143, 166, 0.5);
  border-radius: 3px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}
.login-box-sidebar-button-login > button:hover {
  background: #2290a6b6;
  box-shadow: 0px 10px 20px rgba(34, 144, 166, 0.363);
}
.login-box-sidebar-description-terms {
  margin-top: 68px;
  margin-bottom: 30px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 0 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
.login-box-sidebar-description-terms > a {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  text-decoration: underline;
}

.login-box-sidebar-forgot-password {
  /* margin-top: 29px; */
  cursor: pointer;
  width: 100%;
  padding: 0 40px;
  margin-bottom: 18px;
  text-align: right;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  text-decoration-line: underline;

  color: #228fa6;
}

.login-box-sidebar-hr {
  background-color: #d0d0d0;
  height: 1px;
  margin: 44px 19px 0 40px;
  z-index: 9;
}
.login-box-sidebar-hr-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0125em;

  /* Gray / 300 */

  color: #d0d0d0;
  width: 100%;
  text-align: center;
  margin-top: -11px;
  z-index: 10;

  padding-left: 30px;
}
.login-box-sidebar-hr-text > span {
  padding: 10px 15px;
  background-color: white;
}
.login-box-sidebar-google-button {
  /* background-color: #D0D0D0; */
  padding: 0 19px 0 40px;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 10px;
}
.login-box-sidebar-google-button > button {
  width: 100%;
  height: 45px;
  background-color: white;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.0125em;
  color: #505050;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
}
.login-box-sidebar-google-button > button > img {
  width: 16px;
  height: 16px;
  margin-right: 16px;
}

/* new account */
.login-box-sidebar-input-box-new-account {
  margin-top: 21px;
  margin-bottom: 6px;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
}
.login-box-sidebar-input-box-new-account > span {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.6);
}
.login-box-sidebar-input-box-new-account > input {
  background: #f2f2f2;
  border: 0.2px solid #228fa6;
  box-shadow: 0px 0px 10px rgba(34, 143, 166, 0.15);
  border-radius: 3px;
  height: 40px;
  padding-left: 3px;
  margin-bottom: 20px;
}
.login-box-sidebar-button-login-new-account {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.login-box-sidebar-button-login-new-account > button {
  width: 100%;
  margin: 0 30px;
  height: 48px;
  border: none;
  background: #228fa6;
  box-shadow: 0px 15px 30px rgba(34, 143, 166, 0.5);
  border-radius: 100px;

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

.login-box-sidebar-button-login-new-account > button:hover {
  width: 100%;
  margin: 0 30px;
  height: 48px;
  border: none;
  background: #2290a6b6;
  box-shadow: 0px 10px 20px rgba(34, 143, 166, 0.5);
  border-radius: 100px;

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}
.login-sidebar-container2,
.location-sidebar-container {
  top: 0;
  left: 0;
  position: fixed;
  height: 100vh;
  /* display: block; */
  width: 399px;
  z-index: 100;
  transition: all 0.6s ease;
  overflow-y: scroll;

  background: #ffffff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
}
.location-sidebar-container .location {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
}

.location-sidebar-container .location > input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  line-height: 2;
  color: #495057;
  outline: none;

  margin-top: 20px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.location-sidebar-container .location-button {
  width: 100%;
  height: 48px;
  border: none;
  background: #228fa6;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  box-shadow: 0px 15px 30px rgba(34, 143, 166, 0.5);
  border-radius: 3px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
}
.login-sidebar-container-none,
.location-sidebar-container-none {
  display: none;
}

.location-sidebar-container .location_predictions .predictions-mains {
  overflow: hidden;
  cursor: pointer;
  border-bottom: 2.7px dashed black;
}
.location_predictions .predictions-mains .predictions-mains-subtext {
  padding: 18px 20px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.location_predictions
  .predictions-mains
  .predictions-mains-subtext
  .icon-location {
  text-align: left;
  font-size: 18px;

  padding-top: 0;
  padding-left: 1px;
  color: black;
}
.close-login-sidebar {
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 2px;

  display: block;
  width: 100%;
  font-size: 25px;
  text-align: right;
  color: #00838f;
  padding-bottom: 1rem;
}
.close-login-sidebar span {
  cursor: pointer;
}
.input-login-sidebar {
  margin-bottom: 1rem;
  width: 100%;
}
.input-sidebar {
  width: 100%;
  background-color: #fff;
  padding: 12px 14px;
  display: block;
  width: 100%;
  border: none;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  font-size: 12px;
  letter-spacing: 0.5px;
}
.input-sidebar:focus {
  outline: none;
}
.login-otp-button-sidebar {
  border: none;
  width: 100%;
  padding: 10px 20px;
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  background-color: #00838f;
  text-decoration: none;
}
.form-separator-sidebar {
  height: 12px;
  margin: 20px 0 32px;
  text-align: center;
  border-bottom: 1px solid #e4e5e7;
}
.form-separator-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  padding: 0 8px;
  color: #62646a;
}
.login-password-sidebar {
  border: none;
  width: 100%;
  padding: 10px 20px;
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  background-color: #00838f;
  text-decoration: none;
}
.no-account-login-sidebar {
  width: 100%;
  text-align: center;
  font-size: 12px;
}
.create-account-login-sidebar {
  cursor: pointer;
  text-decoration: none;
}

.login-box-back-button {
  position: relative;
  top: 20px;
  left: 20px;
  width: fit-content;
}
.login-box-back-button > .back-icon {
  font-size: 24px;
  cursor: pointer;
}
.back-icon:active {
  transform: scale(0.9);
  transition: transform 0s ease;
}
.otptimer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: end;
  justify-content: center;
  margin-top: 20px;
  visibility: hidden;
}
.otptimermainsection {
  display: flex;
  flex-direction: row;
  font-family: "Outfit";
  font-weight: 800;
  font-size: 14px;
  color: grey;
}
.otptimerremain {
  font-family: "Outfit";
  font-size: 17px;
  font-weight: 700;
}
.login-submit-button {
  margin-top: 42px !important;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .login-sidebar-container2 {
    width: 350px;
    padding: 10px;
  }
  .login-box-sidebar-logo > img {
    height: 80px;
  }

  .login-box-sidebar-button-login {
    flex-direction: column;
  }
  .login-box-sidebar-button-login > button {
    width: 80%;
  }
}
