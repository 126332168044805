.profile-section-container {
  width: 100%;
  height: 981px;
  background: #ffffff;
  border-radius: 20px;
}
.profile-section-container-0-image {
  width: 800px;
  height: 708.74px;
  margin: 46px 121px 126px 226px;
}
.dthimageprofilesection{
  margin: 10px 121px 20px 226px !important;
}
.dthimageprofilecontainer{
  height: auto !important;
}
.profile-section-container2 {
  height: 950px;
  background: #ffffff;
  position: relative;
  border-radius: 20px;
}
.profile-section-container2:has(.myblog-blog-container){
  overflow: scroll !important;
  padding-top:1px;
}
.myblog-blog-container:has(.MyBlogContainer1){
  height: unset;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .profile-section-container2 {
    height: fit-content;
    overflow-y: auto;
    scroll-behavior: smooth;
    margin-bottom: 50px;
    /* margin-top: -20px; */
  }
}
