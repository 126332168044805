.login-sidebar-forgot-heading {
  width: 100%;
  /* padding: 0 40px; */
  /* display: flex; */
  /* flex-direction: column; */
  font-family: "Outfit";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 33px;
  color: #000000;
  text-align: center;
}

.login-sidebar-forgot-text {
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  padding: 0 40px;
  text-align: center;
  color: #5b5858;
}

.login-sidebar-forgot-inputs {
  padding: 0 40px;
}
.login-sidebar-forgot-inputs > input {
  width: 100%;
  height: 55px;
  border: none;
  background: #f6f6f6;
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding-left: 14px;
}
.login-sidebar-forgot-inputs > input:focus {
  border: none;
  outline: none;
}
.login-sidebar-forgot-inputs > input::placeholder {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  /* padding-left: 14px; */
  color: #7e7d7d;
}

.login-sidebar-forgot-continue-button {
  margin-top: 15px;
  width: 100%;
  padding: 0 40px;
  margin-bottom: 33px;
  margin-top: 100px;
}
.login-sidebar-forgot-continue-button > button {
  background: #228fa6;
  mix-blend-mode: normal;
  border-radius: 12px;
  width: 100%;
  height: 55px;
  border: none;

  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  color: #ffffff;
}

/* otp verification */

.login-sidebar-forgot-image {
  width: 100%;
  padding: 0 40px;
  /* margin-bottom: 15px; */
}
.login-sidebar-forgot-image > img {
  width: 100%;
  height: 320px;
  object-fit: cover;
  
}
.login-sidebar-forgot-inputs-otp {
  width: 100%;
  padding: 0 61px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login-sidebar-forgot-inputs-otp > input {
  background: #f6f6f6;
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  border: none;
  width: 58px;
  height: 58px;
  text-align: center;
}
.login-sidebar-forgot-inputs-otp > input:focus {
  border: none;
  outline: none;
}

.login-sidebar-forgot-time {
  margin-top: 52px;
  margin-bottom: 25px;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  text-align: center;
  color: #464646;
}
.login-sidebar-forgot-resend {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #5a5a5a;
}
.login-sidebar-forgot-resend > span {
  color: #383737;
  font-weight: 600;
  cursor: pointer;
}

/* success */
.login-sidebar-forgot-success {
  width: 100%;
  text-align: center;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;

  color: #000000;
  margin-bottom: 23px;
}
.login-sidebar-forgot-success-text {
  margin-bottom: 157px;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  text-align: center;

  color: #000000;
}
.login-sidebar-forgot-success-continue-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 300px;
}
.login-sidebar-forgot-success-continue-button > button {
  width: 222px;
  height: 63px;
  border: none;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  color: #017189;

  background: rgba(66, 133, 244, 0.15);
  border-radius: 20px;
}
.login-sidebar-forgot-success-continue-button > button > img {
  width: 18px;
  height: 12px;
}

/* reset */

.login-sidebar-forgot-inputs-reset {
  width: 100%;
  padding: 0 35px;
}
.login-sidebar-forgot-reset-input-1 {
  width: 100%;
  display: flex;
  height: 55px;
  align-items: center;
}

.login-sidebar-forgot-inputs-reset > fieldset {
  display: block;
  margin-left: 2px;
  margin-right: 2px;
  padding-top: 0.35em;
  padding-bottom: 0.625em;
  padding-left: 0.75em;
  padding-right: 0.75em;
  border: 2px groove;
  border-radius: 100px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}

.login-sidebar-forgot-inputs-reset > fieldset > legend {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #333333;
  margin-left: 14px;
  padding: 0 4px;
  margin-bottom: 0;
  width: fit-content;
}
.login-sidebar-forgot-inputs-reset > fieldset > input {
  width: 100%;
  border: none;
  height: 33px;
  margin-left: 14px;
}
.login-sidebar-forgot-inputs-reset > fieldset > input:focus {
  border: none;
  outline: none;
}
.login-sidebar-forgot-inputs-reset > fieldset > img {
  cursor: pointer;
  height: 19px;
  width: 22px;
}
.login-sidebar-forgot-inputs-reset > fieldset > input::placeholder {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #333333;
  opacity: 0.7;
}
