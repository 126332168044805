.news-page-container
{
 width: 100%;
 height: 100%;
 /* min-height: calc( 100vh - 85px ); */
}
.news-page-top-search-container
{
 width: 100%;
 height: 40px;
 margin: 36px 0px ;
 display: flex;
 align-items: center;
 justify-content: center;
}
.categorysection{
  display:flex;
  flex-direction: row;
  align-items:center;
  justify-content:center;
  gap:40px;
  flex-wrap:wrap;
  margin-bottom:20px;
}
.categorysubsection{
  width:auto;
  padding:8px;
  background-color:rgb(241, 241, 241);
  color:black;
  font-weight:600;
  border-radius:10px;
  cursor: pointer;
}
.categorysubsectionactive{
  background-color:#00838f;
  color:white;
}
.news-page-search-container
{
width: 520px;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d4d5d9;
    border-radius: 4px;
  }
  .news-page-search-container>input
  {
    height: 40px;
    width: 100%;
    font-size: 12px;
    padding: 12px;
    background-color: transparent;
    border: none;
    border-right: none;
    font-weight: 300;
    line-height: 14px;
    outline: none;
}
.news-page-search-image
{
 height: 100%;
 width: 40px;
 display: flex;
 align-items: center;
 justify-content: center;
 cursor: pointer;
 /* border: 1px solid #d4d5d9; */
 border-left: none;
 border-radius: 0px 4px 4px 0;
}
.news-page-search-image>img
{
height: 16px;
width: 16px;
}


.news-page-new-article-container
{
 width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-around;
    row-gap: 15px;
}

















/* css only for news selector  */



.all-news-page-selector-cointainer
{
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  width: 80px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items:flex-end;
}
.all-news-page-selector-cointainer2
{
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items:flex-end;
}
.all-news-page-selector-cointainer-image
{  
  width: 100%;
  height: 80px;
  /* background-color: #105644; */
  border-radius: 100px;
  padding: 10px;
  z-index: 10000;
  display: flex;
  justify-content: flex-end;
}
/* .all-news-page-selector-cointainer-image:hover
{
  width: 500px;
} */

.all-news-page-selector-cointainer-image:hover ~ .all-news-page-selector-none
{
  
    width: 300px;
    height: 40vh;
    overflow-y: auto;
  /* position: relative; */
    position: fixed;
    bottom: 100px;
    right: 30px;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0px 15px 20px rgb(0 0 0 / 10%);
    z-index: 100;
    transition: all 0.3s ease-out;
    transform: scaleY(1);
}


.all-news-page-selector-cointainer-image>img
{
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.all-news-page-selector-none
{
  /* display: none; */
  visibility: hidden;
  color: #000000;
  transition: all 0.3s ease-out;
    transform: scaleY(0);
       width: 300px;
    height: 0vh;
    z-index: -100000;
}
.all-news-page-selector
{
      width: 300px;
    height: 49vh;
    overflow-y: auto;
  /* position: relative; */
    position: fixed;
    bottom: 100px;
    right: 30px;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0px 15px 20px rgb(0 0 0 / 10%);
    z-index: 1000;
    transition: all 0.3s ease-out;
    transform: scaleY(1);
    
}
.all-news-page-selector-heading
{
  position: sticky;
  top: 0;
  height: 8vh;
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 131, 143, 1) 45%, rgba(0, 212, 255, 1) 100%);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 15px;
    color: white;
    font-weight: bold;
    font-size: 12px;
}
.all-news-page-selector-heading >div{
  display: none;
}
.all-news-page-selector-selectors
{
  padding: 20px 45px;
}
.all-news-page-selector-tab
{
    background: #f1f1f1;
    color: #00838f;
    padding: 6px 8px;
    font-size: 12px;
    border-radius: 6px;
    font-weight: bold;
    margin-top: 8px;
    width: 100%;
    cursor: pointer;
}
.all-news-page-selector-tab-selected
{
    background-color: #00838f!important;
    color: #fff!important;
    padding: 6px 8px;
    font-size: 12px;
    border-radius: 6px;
    font-weight: bold;
    margin-top: 8px;
    width: 100%;
    cursor: pointer;
}
.all-news-page-tag{
  border: 2px solid #00838f;
  background-color: white;
  border-radius: 16px;
  width: fit-content;
  padding: 20px;
  margin: auto;
  margin-top: 100px;
}

/* background-color: white;
  height: 50vh;
  width: 300px;
  overflow-y: auto; */

  @media screen and (max-width: 720px) {
    .news-page-search-container{
      width:320px;
    }
    .news-page-new-article-container{
      grid-template-columns: 1fr;
    }

    .all-news-page-selector-heading
     >div{
    display: flex;
    width: 30px;
    height: 30px;
      }
      .all-news-page-selector-heading
{
        display: flex;
        align-items: center;
        justify-content: space-between;
      
    }
  }