.my-concession-main-page-type-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.my-concession-main-page-type-buttons > button {
  border: none;
  width: 272px;
  height: 52px;
  background: #00838f;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  letter-spacing: -0.2px;
  color: #ffffff;
}

.my-concession-main-page-view {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.add-concession-form {
  display: flex;
  flex-wrap: wrap;
}

.col-md-6 {
  width: 50%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-12 {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.addressformlabel {
  margin-bottom: 5px;
}

.text-danger {
  color: red;
  font-size: 14px;
  margin-left: 1px;
}

.formcontrol {
  width: 100%;
  padding: 0.375rem 0.75rem;

  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.tooltipmsg {
  display: block;
  margin-top: 0.5rem;
  font-size: 0.875rem;
}

.text-center {
  text-align: center;
}

.mt-4 {
  margin-top: 1.5rem;
}

.addressformlabel {
  font-size: 16px;
  font-weight: 700;
  color: #00838f;
  letter-spacing: 0.2px;
  padding-bottom: 8px;
}

#confession {
  height: 200px;
  resize: none;
}

.my-confession-main-page-no-confession-given {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.my-confession-main-page-no-confession-given > img {
  width: 500px;
  height: 500px;
}

.my-confession-main-page-confession-text {
  color: #00838f;
  font-weight: 600;
  font-size: 20px;
}
.loadingContainer{
  position: absolute;
  background-color: #3d3d3d99;
  width: 100%;
  z-index: 10;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 150px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .my-feedback-main-page-data{
    height: fit-content;
  }
  .confessionsectionss{
    height: 500px;
    overflow: auto;
  }
  .confession-mobile-view-heading{
width: 100%;
  }
  .add-concession-form {
    flex-direction: column;
    gap: 10px;
  }
  .add-concession-form >div{
    width: 100%;
  }
  .confessionsectionss::-webkit-scrollbar {
    width: 8px !important;
    display: flex;
    height: 7px;
    margin-bottom: 30px;
}
.confessionsectionss::-webkit-scrollbar-track {
  border-radius: 6px !important;
  background-color: #f1f1f1;
}
.confessionsectionss::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 6px !important;
}
.confessionsectionss .my-feedback-profile-feedback-card-main{
  width:90%;
}
  .my-concession-main-page-view{
    width: 100%;
    height: fit-content;
    padding: 10px;
    flex-direction: column;
    row-gap: 20px;
    overflow: hidden;
  }
  .my-feedback-profile-feedback-card-row1{
    position: relative;
    padding: 10px 0px;
    flex-direction: column;
    row-gap: 20px;
  }
  .my-feedback-profile-feedback-card-image{
    width: unset;
  }
  .my-feedback-profile-feedback-card-col2{
    width: 100%;
    padding: 20px;
  }
  .my-feedback-profile-feedback-card-col3{
    position: absolute;
    top: 20px;
    right: 10px; 
  }
  .my-feedback-profile-feedback-card-col3 > div{
    background-color: black;
  }
  .my-feedback-profile-feedback-card-col3-thredots{
    top: 30px;
    right: 10px;
  }
  .profile-wallet-main-page-heading > span{
    font-size: 23px;
  }
  .my-confession-main-page-no-confession-given > img {
    width: 100%;
    height: 100%;
  }
}
