.manage-myproducts-profile-serivce-main-page-main-box {
  display: grid;
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  overflow: auto;
  max-height: 630px;
  padding: 10px 70px;
}
.manage-myproducts-profile-serivce-main-page-main-box .no-products-message{
  width: 100%;
  display: flex;
  justify-content: center;
  grid-column: span 2;
  font-size: 17px;
  font-weight: bold;
}
.manage-services-profile-service-main-page .profile-wallet-main-page-heading-left{
  justify-content: flex-start;
}
.manage-myproducts-profile-serivce-main-page-main-box-card {
  width: 100%;
 
  height: fit-content;
  background: #ffffff;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
  padding: 15px 20px;
  border: 2px solid rgb(227,227,227);
}

.manage-myproducts-profile-serivce-main-page-main-box-card-amount {
  width: 100%;
  height: calc(100% / 4);
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.522);
  display: flex;
  align-items: center;
}
.manage-myproducts-profile-serivce-main-page-main-box-card-amount > span {
  color: #00838f;
  font-size: 18px;
  font-weight: 600;
}

.manage-myproducts-profile-serivce-main-page-main-box-card-name {
  width: 100%;
  height: calc(100% / 4);
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.524);
  display: flex;
  align-items: center;
}
.manage-myproducts-profile-serivce-main-page-main-box-card-name > span {
  color: black;
  font-size: 18px;
  font-weight: 600;
}
.manage-myproducts-profile-serivce-main-page-main-box-card-desc {
  width: 100%;
  height: calc(100% / 4);
  font-size: 16px;
  flex-wrap: wrap;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.524);
  display: flex;
  align-items: center;
}
.manage-myproducts-profile-serivce-main-page-main-box-card-desc > span {
  color: black;
  font-size: 16px;
  font-weight: 600;
}
.manage-myproducts-profile-serivce-main-page-main-box-card-icon {
  width: 50%;
  /* height: calc(100% / 4); */
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.524);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  align-items: flex-end;
}
.manage-myproducts-profile-serivce-main-page-main-box-card-icon > img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.manage-services-profile-service-main-page {
  width: 100%;
  height: 100%;
  padding: 20px;
}
.manage-myproducts-profile-service-main-page-inputs {
  margin-top: 30px;
  padding: 0 25%;
}
.manage-myproducts-profile-service-main-page-inputs-1 {
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
}
.manage-myproducts-profile-service-main-page-inputs-1 > span {
  font-size: 16px;
  font-weight: 700;
  color: #00838f;
  letter-spacing: 0.2px;
  padding-bottom: 8px;
}
.manage-myproducts-profile-service-main-page-inputs-1 > input {
  height: 40px;
  width: 100%;
  font-size: 12px;
  padding: 12px;
  border: 1px solid #d4d5d9;
  background-color: transparent;
  font-weight: 300;
  border-radius: 4px;
  line-height: 14px;
}

.manage-myproducts-profile-service-main-page-inputs-submit {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.manage-myproducts-profile-service-main-page-inputs-submit > button {
  border: none;
  width: 272px;
  height: 52px;
  background: #00838f;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  letter-spacing: -0.2px;
  color: #ffffff;
}
.manage-myproducts-profile-details{
  display: flex;
  height: fit-content;
  flex-direction: column;
width: fit-content;
  justify-content: space-around;
}
.manage-myproducts-buttons{
  border: 2px solid rgb(227, 227, 227);
  width: 100px;
  border-radius: 8px;
  padding: 4px 10px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}
.edit-button{
  background-color: #00838f;
  color: white;
  border: none;
}
.manage-myproducts-profile-serivce-main-page-main-box::-webkit-scrollbar {
  width: 5px !important;
  display: flex;
  height: 7px;
  margin-bottom: 30px;
}
.manage-myproducts-profile-serivce-main-page-main-box::-webkit-scrollbar-thumb {
background-color: gray;
border-radius: 6px !important;
}
.manage-myproducts-profile-serivce-main-page-main-box::-webkit-scrollbar-track {
  border-radius:6px !important;
background-color:#f1f1f1;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .manage-myproducts-profile-serivce-main-page-main-box {
    grid-template-columns: 1fr;
    padding: 10px;
    height: fit-content;
  }
  .manage-myproducts-profile-serivce-main-page-main-box-card-icon {
    gap: 10px;
    justify-content: flex-end;
    width: 20%;
  }
  .manage-myproducts-profile-serivce-main-page-main-box-card-name {
    flex-wrap: wrap;
  }
  .manage-services-profile-service-main-page {
    padding: unset;
  }
  .manage-myproducts-profile-serivce-main-page-main-box-card-desc {
    width: 75%;
  }

  .manage-myproducts-profile-service-main-page-inputs{
    padding: 0 20px;
  }
}
