.allservice-mobile-last-container
{

}
.allservice-mobile-last-heading
{
 padding-left: 17px;
 padding-bottom: 32px;
font-family: 'Lato';
font-style: normal;
font-weight: 900;
font-size: 24px;
line-height: 20px;
/* identical to box height, or 83% */

letter-spacing: -0.2px;

color: #00838F;
}
.allservice-mobile-last
{
 display: grid;
    width: 100%;
    align-content: center;
    justify-content: center;
    grid-template-columns: 6fr 6fr ;
    grid-column-gap: 9px;
    grid-row-gap: 9px;
    justify-items: center;
    align-items: center;
    padding: 4px;

}

.allservicetab-container-mobile
{
    width: 100%;
    height: 140px;
    position: relative;
}
.allservicetab-image-container-mobile
{
    width: 100%;
}
.allservicetab-image-container-mobile>img
{
    width: 100%;
    height: 140px;
}
.allservicetab-text-mobile
{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    background: linear-gradient(202.81deg, rgba(105, 100, 100, 0) -3.5%, rgba(60, 57, 57, 0.4) 30.22%, rgba(18, 17, 17, 0.45) 61.64%, rgba(0, 0, 0, 0.71) 85.2%);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 5px;
}
.allservicetab-heading-mobile
{
    overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
font-family: 'Lato';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 95.6%;
color: #FFFFFF;
}