.cart-main-page-mobile-item
{
       margin: 10px auto;
    padding: 3vw 2vw;
    background-color: #FFFFFF;
    width: calc(100% - 4vw );
    min-height: 220px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cart-main-page-mobile-item-cross
{
 font-weight: 800 ;
 font-size: 20px;
 color: #00838f;
 cursor: pointer;
 position: absolute;
    top: 4px;
    right: 38px;

}
.cart-main-page-mobile-item-col1
{
height: 100%;
width: 40%;
display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.cart-main-page-mobile-item-col1>article
{
 width: 100%;
 display: flex;
 justify-content: flex-end;
}
.cart-main-page-mobile-item-col1>article>span
{
 padding: 1vw 2vw;
 background-color: rgba(0, 131, 143, 0.88);
 border-radius: 18px;
 /* width: 100%; */
 display: flex;
 align-items: center;
 justify-content: space-between;
 gap: 10px;

 font-family: 'Lato';
  letter-spacing: 1.2px;
 font-style: normal;
    font-weight: 600;
    font-size: 17px;
    /* line-height: 15px; */
    color: #FFFFFF;
    margin-bottom: 10px;
}
.cart-main-page-mobile-item-col1>article>span>img
{
 height: 20px;
}

.cart-main-page-mobile-item-col1>div
{
 height: 130px;
 width: 100%;
}
.cart-main-page-mobile-item-col1>div>img{
height: 100%;
width: 100%;
}
.cart-main-page-mobile-item-col1>section
{
 margin-top: 10px;
 border: 1px solid black;
 border-radius: 7px;
 padding: 1.5vw 2vw;

/* font-family: 'Lato'; */
  letter-spacing: 1.2px;
 font-style: normal;
    font-weight: 400;
    font-size: 17px;
    /* line-height: 15px; */
    color: black;

}







/* column 2 */



.cart-main-page-mobile-item-col2
{
 width: 60%;
 /* padding-left: 1.5rem; */
}
.cart-main-page-mobile-item-col2-brand
{
     font-weight: 400;
    font-size: 14px;
    height: 100%;
    width: 60%;
    color: rgba(0, 0, 0, 0.573);
}

.cart-main-page-mobile-item-col2-product_name
{
 white-space: nowrap;
     overflow: hidden;
    text-overflow: ellipsis;
        color: black;
        font-size: 21px;
        font-weight: 400;
}

.cart-main-page-mobile-item-col2-seller
{
 color: black;
        font-size: 17px;
        font-weight: 400;
}
.cart-main-page-mobile-item-col2-product_quantity
{
 width: 100%;
 color: black;
        font-size: 17px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
}

.cart-main-page-mobile-item-col2-short_description
{
 font-size: 19px;
 color: rgba(0, 0, 0, 0.719);
}
.cart-main-page-mobile-item-col2-short_description>span
{
 color: red;
 font-weight: 500;
}

.cart-main-page-mobile-item-col2-cross
{
 display: flex;
 align-items: center;
 justify-content: flex-start;
 gap: 10px;
 font-size: 20px;
 color: red;
 font-weight: 600;
}
.cart-main-page-mobile-item-col2-cross>img
{
    height: 30px;

}

.cart-main-page-mobile-item-col2-rates
{
 display: flex;
 gap: 6px;
 align-items: center;
 justify-content: flex-start;
}
.cart-main-page-mobile-item-col2-rates>div
{
font-size: 13px;
    font-weight: 700;
        line-height: 1.5;
    color: #212529;
    text-align: left;
}
.cart-main-page-mobile-item-col2-rates>span
{
font-size: 13px;
    font-weight: 700;
        line-height: 1.5;
    color: #212529;
    text-align: left;
    text-decoration: line-through;
    color: #94969f;
}
.cart-main-page-mobile-item-col2-rates-off>p
{
font-size: 15px;
    font-weight: 700;
        line-height: 1.5;
    text-align: left;

    color:rgba(0, 131, 143, 0.88);
}


.cart-main-page-mobile-item-col2-counter
{
 display:flex ;
 justify-content: flex-start;
 align-items: center;
}
.cart-main-page-mobile-item-col2-minus
{
 width: 28px;
 height: 28px;
    border-radius: 3px;
    border: 1px solid #00838f;
    background-color: #f4f4f5;
    color: #333;
    padding: 0;
    text-align: center;
    font-size: 0.9em;
    margin-right: 2.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.cart-main-page-mobile-item-col2-count
{
 width: 28px;
 height: 28px;
    border-radius: 3px;
    border: 1px solid #00838f;
    background-color: #f4f4f5;
    color: #333;
    padding: 0;
    text-align: center;
    font-size: 0.9em;
    margin-right: 2.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.cart-main-page-mobile-item-col2-plus
{
 width: 28px;
 height: 28px;
    border-radius: 3px;
    border: 1px solid #00838f;
    background-color: #f4f4f5;
    color: #333;
    padding: 0;
    text-align: center;
    font-size: 0.9em;
    margin-right: 2.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 900;
}