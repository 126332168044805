.my-wallet-profile-page-redem-background
{
  position: fixed;
 top:0;
 right: 0;
 width: 100vw;
 height: 100vh;
 z-index: 1000;
 display: flex;
 align-items: center;
 justify-content: center;
 background-color: rgba(0, 0, 0, 0.267);
}
.my-wallet-profile-page-redem-modal
{
width: 646px;
height: 433px;
z-index: 2000;
background: #FFFCFC;
padding: 50px;
/* display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between; */
position: relative;
}

.my-wallet-profile-page-redem-modal>p
{
 font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 21px;
text-align: center;
color: #626262;
}
.my-wallet-profile-page-redem-modal>div
{
 height: calc(100% -  33px);
 display: flex;
 align-items: center;
 justify-content: flex-end;
 gap: 30px;
 z-index: 4000;
 position: relative;
}
.my-wallet-profile-page-redem-modal>div>input
{
 width: 308px;
height: 40.87px;
background: #FFFFFF;
border: 0.4px solid #474646;
border-radius: 30px;
padding: 21px;
z-index: 4001;
position: relative;
}
.my-wallet-profile-page-redem-modal>div>button
{
 width: 85px;
height: 35px;
background: #1B1B1B;
border: 0.4px solid rgba(255, 255, 255, 0.4);
border-radius: 30px;

font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 18px;

color: #FFFFFF;
}
.my-wallet-profile-page-redem-modal-image
{
 position: absolute;
 z-index: 3000;
}
.my-wallet-profile-page-redem-modal-image>img
{
 position: absolute;
top: 12px;
 right: 40px;
 z-index: 3000;
}
.my-wallet-profile-page-redem-modal-image>img:nth-child(2)
{

}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .my-wallet-profile-page-redem-modal-image{
    display: none;
  }
  .my-wallet-profile-page-redem-modal{
    width: 80%;
    height: fit-content;
    padding: 20px;
  }
  .my-wallet-profile-page-redem-modal > p{
    font-size: 20px;
    margin: 20px 0px;
  }
  .my-wallet-profile-page-redem-modal > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
  }
  .my-wallet-profile-page-redem-modal > div > input {
    width: 80%;
    font-size: 11px;
  }

  .my-wallet-profile-page-redem-modal > div > button{
    width: 160px;
    height: 40px;
  }

}