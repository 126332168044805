.my-service-profile-all-offline-customer-lower-box-image
{
 width: 40px;
 height: 40px;
 border-radius: 100px;
 margin: 0 15px;
}
.my-service-profile-all-offline-customer-lower-box-box-bottom-section{
width: 88%;
display: flex;
justify-content: space-between;
}
.my-service-profile-all-offline-customer-lower-box-image>img
{
 width: 40px;
 height: 40px;
 border-radius: 100px;
}
.my-service-profile-all-offline-customer-lower-box-user
{
 font-size: 15px;
 font-weight: 600;
 color: black;
 width: calc((100% - 70px)/4);
}

.my-service-profile-all-offline-customer-lower-box-phone
{
 font-size: 15px;
 font-weight: 600;
 color: rgba(0, 0, 0, 0.675);
 width: calc((100% - 70px)/4);
}

.my-service-profile-all-offline-customer-lower-box-button
{
 font-size: 15px;
 font-weight: 600;
 color: black;
 justify-content: space-between;
width: 50%;
 height: 100%;
 display: flex;
 align-items: center;
}

.my-service-profile-all-offline-customer-lower-box-button>button
{
 width: 30%;
 padding: 0.2vw 0.3vw;
 outline: none;
 border: none;
 box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);;
 background-color: rgba(16, 156, 226, 0.221);
}

.my-service-profile-all-offline-customer-lower-box-price
{
 font-size: 13px;
 font-weight: 600;
 color: white;

 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}
.my-service-profile-all-offline-customer-lower-box-price>div
{
 width: 60%;
 height: 90%;
 padding: 0.2vw 0.3vw;
 border-radius: 10px;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 background-color: rgba(5, 153, 5, 0.864);
}
.my-service-profile-all-offline-customer-lower-box-price>section
{
 width: 60%;
 height: 90%;
 padding: 0.2vw 0.3vw;
 border-radius: 10px;
display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 background-color: rgb(241, 40, 9);
}
.my-service-profile-all-offline-customer-lower-box-price>article
{

 height: 90%;
 padding: 0.2vw 0.3vw;
 border-radius: 10px;
 display: flex;
gap: 10px;
width: auto;
 justify-content: center;
 align-items: center;
 background-color: rgb(25, 153, 203);
 /* background-color: rgb(26, 221, 8); */
}

.demo{
position: absolute;
background-color: rgba(0, 0, 0, 0.9);
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
} 
.innerContainer{
    background-color: white;
    opacity: 1;
    color: black;
    width: 100%;
    height: 20vh;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .my-service-profile-all-offline-customer-lower-box-box-bottom-section{
        flex-direction: column;
        gap: 10px;
        width: 80%;
        justify-content: space-between;
    }
    .my-service-profile-all-offline-customer-lower-box-button,.my-service-profile-all-offline-customer-lower-box-price {
        width: 100%;
        gap: 30px;
    }
    .my-service-profile-all-offline-customer-lower-box-user{
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .my-service-profile-all-offline-customer-lower-box-price{
        align-items: flex-start;
    }
    .my-service-profile-all-offline-customer-lower-box-price>article{
        padding: 5px;
    }
    .mob-offline{
        height: auto;
        justify-content: space-between;
    }
}