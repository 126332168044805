.profile-section-container-add-service-provider {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
}
.add-service-provider-profile-left {
  width: 29.79%;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.37);
}
.add-service-provider-profile-right {
  height: 100%;
  width: calc(100% - 29.79%);
}

.add-service-provider-profile-left-1-active {
  margin-top: 92px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* padding: 25px 115px 25px 0px; */
  /* gap: 10px; */
  width: 100%;
  height: 69px;
}
.add-service-provider-profile-left-1-active > div {
  visibility: visible;
  height: 100%;
  width: 4px;
  background-color: #1d8b97;
}
.add-service-provider-profile-left-1-active > span {
  padding: 25px 115px 25px 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 19px;
  color: #015259;
}

.add-service-provider-profile-left-2 {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* padding: 25px 115px 25px 0px; */
  /* gap: 10px; */
  width: 100%;
  height: 69px;
  /* background: #E9E9EA; */
}
.add-service-provider-profile-left-2 > div {
  visibility: hidden;
  transition: all 0.3s;
}
.add-service-provider-profile-left-2 > span {
  padding: 25px 115px 25px 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 19px;
  color: #015259;
}
.add-service-provider-profile-left-2:hover > div {
  visibility: visible;
  height: 100%;
  width: 4px;
  background-color: #1d8b97;
}

.add-service-provider-profile-left-2:hover {
  background-color: #e9e9ea;
}

.add-service-provider-profile-left-1 {
  margin-top: 92px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* padding: 25px 115px 25px 0px; */
  /* gap: 10px; */
  width: 100%;
  height: 69px;
  /* background: #E9E9EA; */
}

.add-service-provider-profile-left-1 > div {
  visibility: hidden;
  transition: all 0.3s;
}
.add-service-provider-profile-left-1 > span {
  padding: 25px 115px 25px 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 19px;
  color: #015259;
}

.add-service-provider-profile-left-1:hover > div {
  visibility: visible;
  height: 100%;
  width: 4px;
  background-color: #1d8b97;
}
.add-service-provider-profile-left-1:hover {
  background-color: #e9e9ea;
}

.add-service-provider-profile-left-2-active {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* padding: 25px 115px 25px 0px; */
  /* gap: 10px; */
  width: 100%;
  height: 69px;
  /* background: #E9E9EA; */
}
.add-service-provider-profile-left-2-active > div {
  visibility: visible;
  height: 100%;
  width: 4px;
  background-color: #1d8b97;
}
.add-service-provider-profile-left-2-active > span {
  padding: 25px 115px 25px 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 19px;
  color: #015259;
}

.add-service-provider-profile-right-inputs > input::-webkit-outer-spin-button,
.add-service-provider-profile-right-inputs > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.add-service-provider-profile-right-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 153.69%;
  color: #000000;
  opacity: 0.5;
  margin-left: 47px;
  padding-top: 40px;
  text-align: justify;
  width: 350px;
}

.add-service-provider-profile-right .add-service-provider-profile-right-inputs,.firstSecion .add-service-provider-profile-right-inputs {
  display: flex;
  flex-direction: column;
  width: 402px;
  margin-left: 47px;
  margin-top: 40px;
  position: relative;
}
.add-service-provider-profile-right-inputs .availability-message{
  position: absolute;
  right: 2px;
  color: #00838f;
margin-top: -2px;
}
.add-service-provider-profile-right-inputs >label{
  display: inline-block;
    padding: 10px 20px;
    width: fit-content;
    border-radius: 10px;
    background-color: #00838f;
    color: #ffffff;
    border: none;
    cursor: pointer;
}

.add-service-provider-profile-right-inputs > div {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #00838f;
}
.add-service-provider-profile-right-inputs > input,
.add-service-provider-profile-right-inputs > select,
.add-service-provider-profile-right-inputs > textarea {
  border: 1px solid #d0dadb;
  border-radius: 10px;
  height: 30px;
  margin-bottom: -26px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.add-service-provider-profile-right-inputs > textarea {
  height: 60px;
}
.add-service-provider-profile-right-inputs > select {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 153.69%;
  width:30%;
  /* or 18px */

  color: #0000006b;
}
.add-service-provider-profile-right-inputs > input::placeholder,
.add-service-provider-profile-right-inputs > select::placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 153.69%;
  /* or 18px */

  color: #000000;

  opacity: 0.5;
}
.add-service-provider-profile-right-inputs > select > option {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 153.69%;
  /* or 18px */

  color: #0000006b;

  opacity: 0.5;
}

.add-service-provider-profile-right-image-upload {
  width: 402px;
  margin-top: 50px;
  display: flex;
  gap: 35px;
  align-items: center;
  justify-content: flex-start;
}
.add-service-provider-profile-right-image-upload > span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #4e4e4e;
}

.add-service-provider-profile-right-submit {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 20px;
}
.add-service-provider-profile-right-submit > button {
  width: 160px;
  height: 45px;
  background: #00838f;
  border-radius: 10px;
  border: none;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

/* second page */

.add-service-provider-profile-right-inputs-2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 35px;
}
.add-service-provider-profile-right-inputs-2 > input {
  width: 50.23%;
  border: 1px solid #d0dadb;
  border-radius: 10px;
  height: 35px;
  padding-left: 10px;
}
.add-service-provider-profile-right-inputs-2 > input::placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 153.69%;
  /* identical to box height, or 28px */

  color: #000000;

  opacity: 0.5;
}

.add-service-provider-profile-right-search-result {
  margin-top: 46px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1vw;
}
.add-service-provider-profile-right-search-result12{
  justify-content: unset;
  overflow:auto;
  max-height:80%;
  padding-bottom: 10px;
  width:98%;
}
.add-service-provider-profile-right-search-result12::-webkit-scrollbar{
  display: flex;
  margin-bottom: 30px;
  width: 5px;
}
.add-service-provider-profile-right-search-result12::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 3px;
}
.add-service-provider-profile-right-search-result12::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.add-service-provider-profile-right-search-result-box {
  width: 700px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 78px;
}

.add-service-provider-profile-right-search-result-box-image {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.add-service-provider-profile-right-search-result-box-image > img {
  width: 48px;
  height: 48px;
  background-color: #000000;
  border-radius: 100px;
}

.add-service-provider-profile-right-search-result-box-detail {
  display: flex;
  flex-direction: column;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: start;
}
.add-service-provider-profile-right-search-result-box-detail > img {
  width: 48px;
  height: 48px;
  background-color: #000000;
  border-radius: 100px;
}

.add-service-provider-profile-right-search-result-box-detail > span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  color: #4e4e4e;
}
.add-service-provider-profile-right-search-result-box-detail > div {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 153.69%;
  /* or 18px */

  /* text-align: center; */
  color: #525252;
}

.add-service-provider-profile-right-search-result-box-phone {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  width: 10vw;
  line-height: 153.69%;
  /* or 18px */

  text-align: center;
  color: #4e4e4e;
}

.add-service-provider-profile-right-search-result-box-gen-detail {
  display: flex;
  flex-direction: column;
  gap: 13px;
  width: 150px;
  align-items: flex-end;
  justify-content: center;
}
.add-service-provider-profile-right-search-result-box-gen-detail > span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;

  color: #4e4e4e;
  display: flex;
  align-items: stretch;
  gap: 8px;
}
.add-service-provider-profile-right-search-result-box-gen-detail > span > img {
  margin-top: -1px;
}
.add-service-provider-profile-right-search-result-box-gen-detail > div {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;

  color: #4e4e4e;
}
.add-service-provider-profile-right
  .add-service-provider-profile-right-edit-form {
  padding: 10px;
  margin-top: 30px;
}
.add-service-provider-profile-right
  .add-service-provider-profile-right-edit-form
  > span {
  padding: 25px 40px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 19px;
  color: #015259;
}
.add-service-provider-profile-right-edit-form .row-button {
  display: flex;
  gap: 10px;
  flex-direction: row;
}
.imageHolder {
  position: relative;
  height: 200px;
  width: 400px;
  overflow: hidden;
}
.imageHolder > img {
  width: 80%;
  height: 80%;
  object-fit: contain;
}
.crossXIcon {
  color: #00838f;
  font-size: xx-large;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
}
.imageInputform {
  height: 100%;
  width: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}
.imageInputform label {
  display: inline-block;
  padding: 10px 20px;
  background-color: #00838f;
  color: #ffffff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.imageInputform input[type="file"] {
  display: none;
}

.imageInputform label:hover {
  background-color: #006c76;
}
.add-service-providerservices > select{
  width:100% !important;
  margin-bottom:-26px !important;
}
.add-service-provider-profile-right1{
  display:flex;
  flex-direction:column;
  align-items:center;
  margin-top:50px;
}
.add-service-provider-profile-right1 > .add-service-provider-profile-right-heading{
  margin-left:0px;
  text-align: center;
}
.add-service-provider-profile-right-search-result12 .add-service-provider-profile-right-search-result-box{
  width:90%;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .add-service-provider-profile-right1 > .add-service-provider-profile-right-heading{
    text-align: justify;
    margin-bottom: -20px;
  }
  .add-service-provider-profile-right-inputs1{
    margin-bottom: -26px !important;
    margin-left:unset !important;
    padding-left:2px !important;
    width:100% !important;
  }
  .add-service-providerservices{
    margin-left:unset !important;
    padding-left:2px !important;
    width:100% !important;
  }
  .add-service-provider-profile-right-inputs1 > input, .add-service-providerservices > select{
    width:100% !important;
  }
  .profile-section-container-add-service-provider {
    flex-direction: column;
  }
  .add-service-provider-profile-left {
    width: 100%;
    height: fit-content;
    display: flex;
    padding: 0 10px;
  }
  .add-service-provider-profile-right-inputs:has(.my-job-post-service-profile-page-image){
    margin-bottom:30px !important; 
  }
  .add-service-provider-profile-left-1-active,
  .add-service-provider-profile-left-1,
  .add-service-provider-profile-left-2,
  .add-service-provider-profile-left-2-active {
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
  .add-service-provider-profile-left-2-active,
  .add-service-provider-profile-left-1-active {
    border-bottom: 4px solid #00838f;
  }
  .add-service-provider-profile-left-2-active > div,
  .add-service-provider-profile-left-1-active > div,
  .add-service-provider-profile-left-1:hover > div,
  .add-service-provider-profile-left-2:hover > div {
    display: none;
  }
  .add-service-provider-profile-left-1-active > span,
  .add-service-provider-profile-left-1 > span,
  .add-service-provider-profile-left-2-active > span,
  .add-service-provider-profile-left-2 > span {
    padding: unset;
    font-size: 13px;
  }
  .add-service-provider-profile-right-inputs-2 {
    padding: 0 10px;
    margin-top: unset;
  }
  .add-service-provider-profile-right-inputs-mob{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

  }
  .add-service-provider-profile-right {
    margin-top: 20px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 0 20px;
  }
  .add-service-provider-profile-right-heading {
    width: 80%;
    padding-top: 0px;
  }
  .add-service-provider-profile-right-inputs-2 > input {
    width: 100%;
  }
  .add-service-provider-profile-right-heading,
  .add-service-provider-profile-right-inputs {
    margin-left: unset;
    width: 100%;
  }

  .add-service-provider-profile-right-image-upload,
  .imageHolder,
  .add-service-provider-profile-right-submit {
    margin-top: 10px;
    width: 100%;
  }

  .add-service-provider-profile-right-search-result-box {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-around;
    height: auto;
  }
  .add-service-provider-profile-right-search-result{
    margin-top: 10px;
  }

  .add-service-provider-profile-right-search-result-box > div {
    width: 50%;
  }
  .add-service-provider-profile-right-search-result-box
    .add-service-provider-profile-right-search-result-box-image {
    width: 70px;
  }
  .add-service-provider-profile-right-search-result-box
    .chat-box-person-icons-col-3 {
    flex: 0;
  }
  .add-service-provider-profile-right-search-result-box
    .add-service-provider-profile-right-search-result-box-gen-detail {
    padding-left: 55px;
    width: 80%;
    flex-direction: row;
  }
  .add-service-provider-profile-right-inputs > div {
    margin-top: 10px;
  }
  .add-service-provider-profile-right-search-result12{
    max-height:700px;
  }
}
