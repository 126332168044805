.rating-Mobile-Modal-background
{
 position: fixed;
 width: 100vw;
 height: 100vh;
 z-index: 100;
  background-color: black;
 opacity: 0.5;
}
.rating-Mobile-Modal-container
{
 position: fixed;
 z-index: 200;
 width: 365.09px;
height: 315px;
background-color: white;
opacity: 1;
top: 50%;
left: 50%;
margin-top: -157px; /* Negative half of height. */
margin-left: -182.5px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-around;
border-radius: 40px;
}

.rating-Mobile-Modal-heart-container
{
 width: 90%;
}

.rating-Mobile-Modal-heart-image-container
{
width: 60px;
height: 55px;
border-radius: 100px;
background: #252D37;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
display: flex;
align-items: center;
justify-content: center;
}

.rating-Mobile-Modal-heart-image-container>img
{
width: 28px;
height: 28px;
}


.rating-Mobile-Modal-ask-container
{
 width: 90%;
 text-align:left;
 font-family: 'Overpass Mono';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 30px;

color: #000000;
}






.rating-Mobile-Modal-rating-container
{
 width: 90%;
 display: flex;
 justify-content: space-between;
 align-items: center;
}
.rating-Mobile-Modal-rating-rate
{
 width: 50.24px;
height: 45px;
display: flex;
justify-content: center;
align-items: center;
background: #252D37;
border-radius: 100px;
font-family: 'Overpass Mono';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 25px;
text-align: center;

color: #FFFFFF;
cursor: pointer;
}

.rating-Mobile-Modal-rating-rate:hover{
 background-color: #E68226;
}


.rating-Mobile-Modal-submit-container
{
 width: 90%;
 display: flex;
 justify-content: center;
 align-self: center;
}
.rating-Mobile-Modal-submit-container>button
{
 outline: none;
 border: none;
 width: 90%;
 padding: 3vw;
 background: #00838F;
border-radius: 50px;
font-family: 'Overpass Mono';
font-style: normal;
font-weight: 600;
font-size: 25px;
line-height: 32px;
display: flex;
align-items: center;
text-align: center;
color: #FFFFFF;
display: flex;
justify-content: center;
}