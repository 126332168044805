.popular-products-per-category .confirmation-modal-serviceprovider {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  .popular-products-per-category 
    .confirmation-modal-serviceprovider
    .confirmation-modal-serviceprovider-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    max-width: 400px;
    max-height: 240px;
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .popular-products-per-category
    .confirmation-modal-serviceprovider
    .confirmation-modal-serviceprovider-content
    .confirmation-modal-serviceprovider-buttons {
    display: flex;
    column-gap: 10px;
  }
  .popular-products-per-category
    .confirmation-modal-serviceprovider
    .confirmation-modal-serviceprovider-content
    .confirmation-modal-serviceprovider-buttons
    > button {
    width: 100px;
    height: 40px;
    background: #00838f;
    border-radius: 10px;
    border: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
  .sserviccecard{
    min-height:96%;
  }