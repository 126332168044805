
.create-post-holder{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-post-container {
  padding: 2rem;
  width: 50%;
  height: 515px;
  background-color: white;
  opacity: 1;
  overflow-y: scroll;
  position: relative;
}
.create-post-modal-top-row-cross {
  position: absolute;
  top: 0;
  right: 0;
}
.create-post-image-container {
  border: 3px solid #00838f;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.create-post-clear-image-cross {
  position: relative;
}
.create-post-camera-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 30px;
}
.create-post-camera-container > img {
  width: 50px;
  height: 50px;
  margin-bottom: 7.5px;
  margin-top: 25px;
}
.create-post-camera-container > div {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  color: #00838f;
  margin-bottom: 24px;
}
.create-post-camera-container > span {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  text-align: center;

  color: #344054;
}

.create-post-camera-container > span > label > input {
  display: none;
}
.create-post-camera-container > span > label {
  color: white;
  background-color: #00838f;
  padding: 10px 18px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  justify-content: flex-end;
}

.create-post-text-box {
  margin-top: 20px;
}

.public-DraftEditor-content {
  background-color: red;
  height: 15rem;
  width: 60vw;
  padding: 2rem;
  overflow: auto;
}
.create-post-text-options {
  width: 100%;
  height: fit-content;
  display: flex;
  border: 0.5px solid rgba(125, 127, 127, 0.5);
  border-bottom: 0;
}
.create-post-text-options-caption {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #828282;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 0.5px solid rgba(125, 127, 127, 0.5);
  flex-grow: 2;
  padding: 5px;
}
.create-post-text-options-style > select {
  border: none;
  outline: none;
  background-color: transparent;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 150%;
  color: #344054;
  appearance: none;
  padding-right: 20px;
  -webkit-appearance: none; /* safari and chrome */
  -moz-appearance: none; /* Mozilla */
  -ms-appearance: none;
  background-position: calc(100%) center !important;
  background: url("../images/dropdownarrow.svg") no-repeat;
}
.create-post-text-options-style-biu {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-grow: 1;
}
.create-post-text-options-style-biu > button,
.create-post-text-options-style-list > button {
  min-width: 10px;
  width: 35px;
  height: 35px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  background: white;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  margin: 5px;
  transition: all 0.5s ease;
}

.create-post-text-options-style-list {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-grow: 1;
}

.create-post-container-publish {
  padding-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.create-post-container-publish > button {
  color: white;
  background-color: #00838f;
  padding: 10px 18px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  justify-content: flex-end;
}

.create-post-container-publish-2 {
  padding-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.create-post-container-publish-2 > button {
  color: white;
  background-color: #00838f;
  padding: 10px 18px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
}
.create-post-container-publish-2-cancel {
  background: #ffffff !important;
  border: 1px solid #027e8b !important;
  border-radius: 10px !important;
  color: #004d55 !important;
}

.post-card-modal-top-row-image {
  position: relative;
  max-width: 60%;
  max-height: 80%;
}
.post-card-modal-top-row-image > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.postUploadImg {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 32px;
  padding: 83px 20px;
  position: relative;
  margin: 50px 0;
  border: 1px solid #ccc;
}
.postUploadImg h1,
.postUploadImg p {
  margin: 0;
}
.cancelcross {
  position: absolute;
  top: 25px;
  right: 35px;
  cursor: pointer;
}
.postUploadImg h1 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  color: #344054;
}
.postUploadImg p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #344054;
  margin-top: 20px;
}
.imgContainer {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  width: 100%;
  height: 200px;
  text-align: center;
  color: #344054;
}

.imgContainer label > input {
  display: none;
}
.imgContainer label {
  cursor: pointer;
  border: 2px solid black;
  padding: 1.2rem;
  border-radius: 0.8rem;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: -4px 10px #004d55;
  text-align: center;
}
.imgContainer label:hover {
  transform: translate(-3px, 1px);
  box-shadow: -2px 6px #004d55;
}
.uploadedImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.create-post-holder .error {
  color: #cc0033;
  display: inline-block;
  width: 100%;
  font-size: 14px;
  line-height: 15px;
  margin: 5px 0 0;
}
.create-post-holder .error {
  color: #cc0033;
  display: inline-block;
  width: 100%;
  font-size: 14px;
  line-height: 15px;
  margin: 5px 0 0;
}
@media screen and (max-width: 720px) {
  .create-post-container {
    width: 90%;
    /* transform: translate(-50%, -42%); */
  }
  .postOverlay{
    height: 100vh;
  }
  .create-post-holder{
    height: 100vh;
  }
  .create-post-camera-container > span > label {
    padding: 5px 9px;
  }
  .create-post-text-options-caption,.create-post-text-options-style-biu,.create-post-text-options-style-list{
    width:100%;
  }
.create-post-text-options{
  flex-direction:column;
}
}
