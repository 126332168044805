.putatoeServiceView-third-data-container
{
   display: grid;
    margin: auto;
    width: 100%;
    align-content: center;
    justify-content: center;
    grid-template-columns: 6fr 6fr 6fr;
    /* grid-column-gap: 0.987vw; */
    grid-row-gap: 2vw;
    justify-items: center;
    align-items: center;
    text-decoration: none;
    margin-bottom: 20px;
}


.PutatoeServiceOptionCards-container-desktop
{
width: 437px;
height: 250px;
    background: #FFFFFF;
box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3vw;
    cursor: pointer;
    transition: 0.3s transform cubic-bezier(0.4, 0, 1, 1), 0.3s box-shadow, 0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
}
.PutatoeServiceOptionCards-container-desktop:hover
{
  transform: scale(1.02);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06) !important;
}
.PutatoeServiceOptionCards-image-container
{
  width: 50%;
  height: 100%;
}
.PutatoeServiceOptionCards-image
{
  width: 100%;
  height: 100%;
}
.PutatoeServiceOptionCards-desktop-text
{
  width: 50%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.PutatoeServiceOptionCards-heading-list
{

}
.PutatoeServiceOptionCards-heading-list>a{
  /* padding-left: 15px; */
  display: flex;
  flex-direction: column;
}
.PutatoeServiceOptionCards-heading-desktop
{
font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 900;
font-size: 20px;
/* line-height: 24px; */
color: #000000;
}
.PutatoeServiceOptionCards-list
{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 11px;
/* line-height: 20px; */
/* identical to box height */


color: #000000;
}




 @media only screen and (max-width: 2442px)
 {

 }
 @media only screen and (max-width: 1300px)
 {
  .allservice-last
{
    grid-template-columns: 6fr 6fr 6fr;
}
  .PutatoeServiceOptionCards-container-desktop
  {
    width: 29vw;
    height: 16.5vw;
  }
 } 
 /* @media only screen and (max-width: 949px)
 {
  .allservice-last
{
    grid-template-columns: 6fr 6fr;
}
 }  */
 @media only screen and (max-width: 680px)
 {
  .allservice-last
{
    grid-template-columns: 6fr 6fr;
}
  .PutatoeServiceOptionCards-container-desktop 
  {
    width: 40vw;
    height: 30vw;
}

.putatoeServiceView-third-data-container{
  grid-template-columns: 6fr;

    grid-row-gap: 10vw;
}

 } 
