.mobile-header
{
 position: relative;
 width: 100%;
 display: flex;
 flex-direction: column;
  background-color: rgba(0, 131, 143, 0.88);
}

.mobile-header-top
{
 width: 100%;
 display: flex;
 justify-content: space-between;
  height: 60px;
  align-items: center;
 /* background-color: rgba(0, 131, 143, 0.88); */
 
}
.mobile-profile-holder
{
 /* width: 30%; */
  padding-right: 10px;
   padding-left: 10px;
 display: flex;
 justify-content: center;
    align-items: center;
}
.mobile-profile-icons
{
 width: 35px;
height: 35px;
border-radius: 100px;
/* background: #7B919C; */
}

.mobile-profile-holder-location
{
  border: 2px solid white;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2.3px 5px;
  color: white;
  font-weight: 600;
  height: 35px;
  flex-grow: 1;
}
.mobile-profile-holder-location>span
{
  
}


.mobile-utility-icon
{
 padding: 0 10px;
 /* width: 70%; */
 display: flex;
 justify-content: flex-end;
    align-items: center;
    gap: 4.8vw;
}


.mobile-header-bottom
{
 display: flex;
  width: 100%;
 display: flex;
 justify-content: space-around;
  height: 60px;
 /* background-color: rgba(0, 131, 143, 0.88); */
 align-items: center;
}
.mobile-search-bar
{
 display: flex;
 width: 78.6vw;
height: 34px;
position: relative;
background: #FFFFFF;
box-shadow: 2px 6px 6px rgba(0, 0, 0, 0.25);
border-radius: 12px;
justify-content: flex-end;
}
.mobile-search-icon 
{
 width: 20%;
 height: 100%;
background: #FFFFFF;
z-index: 20;
display: flex;
border-radius: 12px;
justify-content: center;
align-items: center;
}
.mobile-search-icon-image
{
width:18.21px;
height:18.21px;
z-index: 20;
}

.mobile-search-bar-input
{
 outline: none;
 border: none;
  display: flex;
 width: 80%;
height: 100%;
position: relative;
background: #FFFFFF;
/* box-shadow: 2px 6px 6px rgba(0, 0, 0, 0.25); */
border-radius: 12px;
z-index: 10;
}


.mobile-mic-icon
{
 width:35px;
height:35px;
}






/* popular products */
.popular-container-mobile
{

}
.popular-product-container-mobile
{
 width: 100%;
}
.popular-product-category-mobile
{
 width: 70%;
 padding-left: 5.06vw;
font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 20px;
/* identical to box height, or 125% */

letter-spacing: -0.2px;

color: #000000;
margin-bottom: 4.55vw;
}
.popular-products-per-category-mobile
{
 display: flex;
 gap: 3.77vw;
 overflow: auto;
 margin-bottom: 10.667vw;
 overflow-y: hidden;
 padding-bottom: 1px;
}
/* card */

.popularproductcard-container-mobile
{
 width: 30.933vw;
height: 38.4vw;
background: #FFFFFF;
border-radius: 20px;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}
.popularproduct-image-container-mobile
{
width: 30.933vw;
height: 29.876vw;
border-radius: 20px 20px 0px 0px;
display: flex;
justify-content: center;
align-items: center;
}
.popularproduct-image-mobile
{
width: 26.933vw;
height: 25.876vw;
border-radius: 20px 20px 20px 20px;
}
.popularproduct-details-mobile
{
 width: 100%;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 15px;
color: #000000;
display: flex;
align-items: center;
overflow: hidden;
}
.popularproduct-name-mobile
{
 width: 100%;
text-align: center;
}



/* here we have some changes for react toast to work in mobile screeen  */



@media only screen and (max-width: 480px) {
  .Toastify__toast-container{
    margin: auto;
    width: 60%!important;
    transform : translate(30%,-100%) !important;
    text-align: center;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    /* transform: scale(0.5,1)!important; */
  }
}














/* bestsellers   or popular service providers */
.bestseller-heading-container-mobile
{
 padding-left: 4.533vw;
 margin-top: 4.533vw;
 width: 100%;
 height: 6.933vw;
 color:  #00838F;
 display: flex;
 margin-bottom: 13.33vw;
 gap: 1vw;
}
.bestseller-vertical-fill-mobile
{
 height: 100%;
 width: 3px;
 background-color: #00838F;
}
.bestseller-heading-mobile
{
 margin-top: 0.5vw;
 font-family: 'Titillium Web';
font-style: normal;
font-weight: 700;
font-size: 5.867vw;
line-height: 5.6vw;
/* identical to box height, or 91% */

letter-spacing: -0.2px;

color: #03989E;
}




.service-container-mobile
{
 width: 100%;
 height: 42vw;
  margin-bottom: 8.8vw;
}
.service-name-container-mobile
{
 width: 100%;
 padding-left: 5vw ;
 text-align: justify;
 padding-bottom: 6.133vw;
}
.service-name-container-mobile > span{
font-family: 'Lato';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 20px;
/* identical to box height, or 111% */

letter-spacing: -0.2px;

color: #00838F;
}
.service-provider-mobile
{
 display: flex;
 overflow: auto;
 gap: 2.9vw;
 margin-left: 1vw;
 margin-right: 1vw;
}
/* service cards */

.service-card-container-mobile
{
 position: relative;
 width: 24vw;
height: 26.67vw;
border: 1px solid #A7C9CA;
border-radius: 10px;

}
.service-card-image-mobile
{
 position: relative;
 width: 24vw;
height: 26.67vw;
 border: 1px solid #A7C9CA;
border-radius: 10px;
}

.service-card-name-container-mobile
{
 padding: 1vw;
 position: absolute;
width: 24vw;
height: 9vw;
left: 0;
bottom: 0;
overflow: hidden;
display: flex;
align-items: center;
justify-content: center;
text-align: center;

background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.32) 65.1%, rgba(0, 0, 0, 0.48) 84.9%, rgba(0, 0, 0, 0.65) 100%);
border-radius: 10px;
}
.service-card-name-mobile
{
 font-family: 'Lato';
font-style: normal;
font-weight: 600;
font-size: 2.31vw;
line-height: 95.6%;
/* or 10px */


color: #FFFFFF;
}





























































/* blog section mobile css */
.mobileBlogMainPageNav{
  width: 100%;
  height: 66px;
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  align-items: center;
  background-color: white;
  padding:  0 15px;
}
.mobileBlogMainPageNavc1{
  width: 19px;
  height: 16px;
  margin: 0 auto;
  position: relative;
}
.mobileBlogMainPageNavc2 input{
  width: 100%;
  padding: 4px;
}

.writeYourBlogMobile{
  width: 100%;
  display: grid;
  height: 90px;
background-color: #fff;
  grid-template-columns:80px 1fr;
  column-gap: 8px;
  align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
}
.editimagemobileblog{
  width: 47px;
  height: 47px;
  background: #BCCBFF;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.writeyourblogtitle{
  display: flex;
justify-content:start;
align-items: center;
}
.writeyourblogtitle p{
  margin: 0;
  margin-left: 10px;
}

.blogcaregorymobile{
  width: 100%;
  height: 75px;
  overflow-x: auto;
  background-color: #fff;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
}
.blogcaregorymobile ul{
  display: flex;
  margin-left: 30px;
  overflow-x: auto;
}
.blogcaregorymobile ul li{
  padding: 5px 10px;
  list-style: none;
 
}
.blogcaregorymobile ul li:nth-child(1){
  border-bottom: 1px solid black;

}
.allblogsmobile{
  width: 100%;
  background-color:white;
  padding: 10px 8px;
}
.allblogsmobilenav{
  width: 100%;
  height: 80px;
  align-items: center;
  display: grid;
  grid-template-columns: 70px 1fr;
  position: relative;
  background-color: #fff;
}
.userprofilemobilec{
  width: 50px;
  height: 50px;
}
.userprofilemobilec img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.nameAndMore{
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
}
.nameAndDate{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.nameAndDate p{
  margin: 0;
}
.blogtitlemobile{
  background-color: white;
}
.blogtitlemobile h4 , .blogtitlemobile p{
  padding-right:30px;
  padding-left: 30px;
}
.blogtitlemobile h4{
  font-family: 'Ubuntu';
font-style: normal;
font-weight: 700;
font-size: 15px;
letter-spacing: 1px;
color: #000000;
}
.blogimagemobile{
  width: 100%;

}
.blogimagemobile img{
  width: 100%;
}
.likeAndShareMobile{
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
}
.cmtshareMobile{
  width: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cmtshareMobile p{
  font-family: 'Open Sans';
font-style: normal;
font-weight: 300;
font-size: 11px;
color: #515151;
}
.loveAndShareMobile{
  width: 90%;
  margin: 10px auto;
 display: flex;
 justify-content: space-between;
 align-items: center;
 background-color: #fff;
}
.loveAndShareMobile{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #515151;
}
.loveAndComment{
  width: 200px;
  display: flex;
  justify-content:start;
  align-items: center;
}
.moreOptionMobileBox{
  width: 100px;
  background: #E5E5E5;
border-radius: 5px;
padding-bottom: 10px;
position: absolute;
top: 58px;
right: 51px;
z-index: 200;

}
.moreOptionMobileBox ul li{
  list-style: none;
  padding: 5px 0px 5px 10px;
}

.mobileSideBar{
  width: 345px;
  /* background-color:blue; */
  background-color: #fff;
  position: fixed;
  top: 0;
  min-height: 100vh;
  z-index: 200;
}
.mobileSideBarNav{
  width: 100%;
  height: 100px;
  display: grid;
  grid-template-columns: 70px 1fr;
  /* background-color:red; */
  align-items: center;
  padding: 0 10px;
}
.sidebarImgMobileBlog{
  width: 50px;
  height: 50px;
}
.sidebarImgMobileBlog img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.sidebarBlogUsernameAndFollower{
  display: flex;
  /* justify-content: end; */
  flex-direction: column;
  margin-top: 35px;
}
.sidebarBlogUsernameAndFollower p{
  margin: 0;
}
.sidebarUserFollower{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.sidebarUsername p{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
}
.sidebarUserFollower p{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #828282;
}
.cancleIconSidebar{
  position: relative;
  top: -20px;
}

.sidebarNavMobile ul li{
  font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 25px;
padding: 10px 25px;
color: #343434;
cursor: pointer;
}

.myblogMobileDiv{
  width: 100%;


}
.myblogmobileDummyDiv{
  padding: 20px 10px;

  position: relative;
}
.profileMobileMyBlogCircle{
  width: 100px;
  height: 100px;
  padding: 10px;
  border-radius: 50%;
  background-color: #fff;
  z-index: 500;
}
.profileMobileMyBlogCircle img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.profileMobileMyBlogCircle{
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50% , -50%);
}
.profileMobileBlogBackDiv{
  position: relative;
  top: 20px;
  padding-top: 80px;
  background: #FFFFFF;
box-shadow: 0px 10px 30px rgba(123, 87, 182, 0.18);
border-radius: 33px;
padding-bottom: 20px;
}
.profileMobileBlogBackDiv p{
  margin: 0;
}
.profileMobileBackDivUsername p{
  font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 23px;
color: #000000;
}
.profileMobilrBackDivAbout p{
  font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 11px;
line-height: 126.19%;
text-align: center;
color: #000000;
}
.profileMobileMyBlogNav{
  width: 85%;
  background: #F8F9FC;
box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
border-radius: 21px;
display: flex;
justify-content: space-between;
align-items: center;
padding:9px 14px;
margin: 0 auto;
}
.followerMyBlogProfileMobile{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items:center;
}
.followerMyBlogProfileMobile h1 , .followerMyBlogProfileMobile h1 , .followerMyBlogProfileMobile h1{
  font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 109.19%;
text-align: center;
color: #000000;
margin: 0;
}
.followerMyBlogProfileMobile p{
  font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 14px;
display: flex;
align-items: center;
text-align: right;
color: #828282;
margin: 0;
}
.plusIconMYBlog{
  position: fixed;
  right: 30px;
  bottom: 20px;
  z-index: 200;
}
.plusIconMYBlog img{
  width: 100%;
}






/*  */
.writeYourBlogMainPageMobile p{
  margin: 0;
}
.writeYourBlogMainPageMobile{
  width: 100%;
  background-color: #fff;
  padding: 20px;
}
.blogTitleMobile{
  width: 100%;
  padding: 20px 0;
}
.blogTitleMobile p , .blogDESCMobile p , .categoryMobilrBlogCreate p , .createBlogBody p{
  font-family: 'Mulish';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 150%;
color: #344054;
}
.cameraIconMobileBlog p{
  font-family: 'Urbanist';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 140%;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.2px;
color: #00838F;
}
.blogTitleMobile input , .blogDESCMobile input , .categoryMobilrBlogCreate select{
  width: 100%;
  height: 52px;
  padding: 0 10px;
  background: #FFFFFF;
  outline: none;
border: 1px solid #D0D5DD;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 999px;
margin-top: 8px;
}
.uploadedImgMobile{
  width: 90px;
  height: 70px;
  position: relative;
  /* background-color:red; */
}
.uploadedImgMobile img{
 width: 100%;
}

.uploadBlogIMgMobile{
  width: 100%;
  height: 163px;
  background: #FAFAFA;
border-radius: 32px;
border: 3px solid #00838F;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
margin-top: 20px;
}
.oroptionMobilr{
  width: 100%;
  height: 10px;
   display:grid;
   grid-template-columns: 1fr 50px 1fr;
   margin-top: 20px;
   align-items: center;
}
.sideHand{
  width: 50px;
  height: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:grey;
}
.openCameraAndTaePhoto{
  width: 100%;
  height: 58px;
  width: 100%;
  padding: 0 60px;
  background: #E0FCFF;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 999px;
margin-top: 30px;
display: flex;
justify-content:space-around;
align-items: center;
border: 1px solid grey;
}
.categoryMobilrBlogCreatec{
  border: 1px solid grey;
  border-radius: 100px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 0;
}
.categoryMobilrBlogCreate{
  border: none;
  margin-top: 20px;
}
.categoryMobilrBlogCreatec p{
  color: grey;
}
.allCatogariesDivMobile{
  width: 100%;
  height: 300px;
  overflow-y: auto;
  background: #FFFFFF;
box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05);
border-radius: 12px;
padding: 15px;
}
.allCatogariesDivMobile ul li{
  padding: 8px 0;
  list-style: none;
}
.createBlogBody{
  margin-top: 20px;
}
.createBlogBody textarea{
  width: 100%;
  background: #FFFFFF;
border: 1px solid #D0D5DD;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 12px;
padding: 10px;
outline: none;
margin-top: 10px;
}

.uploadBlogBtnMobile{
  padding: 0 30px;
  margin-top: 20px;
}
.uploadBlogBtnMobile p{
 background: #0096FB;
border-radius: 100px;
padding: 15px 0;
color: white;
}






/*  */

.singleBlogMobile{
  position: relative;
  background-color: #fff;
}
.singleBlogMobileImg{
  width: 100%;
  position: sticky;
  top: 0;
  /* height: 500px; */
}
.singleBlogMobileImg img{
  width: 100%;
  /* height: 100%; */
}
.singleBlogContentMobileMain{
  min-height: 500px;
  overflow-y: auto;
  border-radius: 40px 40px 0px 0px;
position: relative;
top: -50px;
background: #FFFFFF;
}
.singleBlogContentMobile{
  border-radius: 40px 40px 0px 0px;
background: #FFFFFF;
}
.singleblogtitleMobile h1{
  font-family: 'Ubuntu';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 21px;
letter-spacing: 1px;
color: #040404;
}
  










.mobileChat2MainDiv{
width: 100%;
height: 100vh;
}
.mobileChat2Nav{
  width: 100%;
  /* height: 123px; */
  background: #00838F;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mobileChat2NavFirstDiv{
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  padding-left: 15px;
}
.mobilechat2mainnavfirstcc{
  width: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobilechat2mainnavfirstc h1{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 25px;
line-height: 30px;
text-align: center;
letter-spacing: -0.333333px;
color: #FFFFFF;
}
.mobilechat2mainnavfirstccicons{
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobileChat2NavSecondDiv{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobileChat2NavFirstDivchatoptions{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:3px 10px 0;
  padding-left: 20px;
}
.mobileChat2NavFirstDivchatoptions p{
  margin: 0;
  padding: 5px 13px;
  border-bottom: 4px solid #FFFFFF;
  color: white;
}

.mobilechat2chatMember{
  height: 80px;
  display: grid;
  grid-template-columns: 100px 1fr;
  column-gap: 10px;
  align-items: center;

}
.mobilechat2chatmemberProfile{
  margin: 0 auto;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.mobilechat2chatmemberProfile img{
  border-radius: 50%;
}
.mobilechat2chatmemberchatinfo{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mobilechat2chatmemberchatinfofirst {
  display: flex;
  justify-content: space-between;
  padding: 2px 15px 2px 5px;
}
.mobilechat2chatmemberchatinfo p{
  margin: 0;
}
.mobilechat2chatmemberchatinfosecond p{
  padding: 2px 15px 2px 5px; 
}


.chatmobile2openchatmain{
  width: 100%;
}
.chatmobile2OpenchatNav{
  width: 100%;
  display: grid;
  grid-template-columns: 35px 1fr 90px;
  align-items: center;
  background: #00838F;
box-shadow: 0px 0.33px 0px #A6A6AA;
padding: 5px 12px 5px 3px;
}
.chatmobile2chatopenmemberprofile{
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.chatmobile2chatopenmemberprofile img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.chatmobile2chatopenmemberimfo{
  display: flex;
 justify-content: start;
  align-items: center;
}
.chatmobile2chatopenmembername{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
}
.chatmobile2chatopenmembername h2{
font-family: 'Lato';
font-style: normal;
font-weight: 900;
font-size: 16px;
line-height: 19px;
letter-spacing: -0.3px;
color: #FFFFFF;
}
.chatmobile2chatopenmembername h2 , .chatmobile2chatopenmembername p{
  margin: 0;
  color: white;
}


.chatmobile2chatopenmychat{
 max-width: 60%;
background: #DCF7C5;
border-radius: 8px;
display: table;
padding: 5px;
border-radius: 8px;
position: relative;
padding: 5px 10px;
}
.chatmobile2openchatyourchat{
 max-width: 60%;
  float: right;
  background: #FAFAFA;
  display:block;
  padding: 5px;
  border-radius: 8px;
  position: relative;
  padding: 5px 10px;
}
.chatmobile2openchatyourchat p , .chatmobile2chatopenmychat p{
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* identical to box height */

letter-spacing: -0.3px;

color: #000000;
  margin: 0;
}
.chatmobile2openchatyourtimimg , .chatmobile2mychattiming{
 width: 100%;
 display: flex;
 justify-content: end;
}
.chatmobile2openchatyourtimimg p, .chatmobile2mychattiming p{
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 11px;
line-height: 16px;
text-align: right;
letter-spacing: 0.5px;
color: rgba(0, 0, 0, 0.25);
}
.chatmobile2openchatchats{
  width: 100%;
  padding: 10px;
  max-width: 500px;
  background-color:red;
}