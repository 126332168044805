.chat-page-container {
  height: max(100vh, 860px);
  width: 100vw;
  /* display: flex;
 align-items: flex-start;
 justify-content: center; */
  padding-top: 68px;
}
.chat-page-inner-container {
  margin: 0 auto;
  width: 1193px;
  height: 614px;
  background: #dbedef;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
}

/* modal */
.chat-page-add-friend-back {
  position: fixed;
  width: 100%;
  height: calc(100% - 85px);
  top: 85px;
  background-color: rgba(0, 0, 0, 0.25);
  opacity: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.chat-page-add-friend {
  margin-top: 30px;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 400px;
  pointer-events: auto;
  background-color: #fff;
  /* background-clip: padding-box; */
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  padding: 2vw 4vw;
}

.chat-page-add-friend-cross {
  display: block;
  width: 100%;
  font-size: 25px;
  text-align: right;
  color: #00838f;
  cursor: pointer;
  font-weight: 800;
  line-height: 1.5;
}
.chat-page-add-friend > input {
  margin-top: 8px;
  height: 40px;
  width: 100%;
  font-size: 12px;
  padding: 12px;
  border: 1px solid #d4d5d9;
  background-color: transparent;
  font-weight: 300;
  border-radius: 4px;
  line-height: 14px;
  outline: none;
}
.chat-page-add-friend > span {
  font-size: 18px;
  font-weight: 700;
  color: #00838f;
  letter-spacing: 0.2px;
  padding-bottom: 8px;
}

.chat-page-add-friend-button {
  align-items: center;
  background: #00838f;
  border: none;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  color: #fff;
  display: flex;

  font-style: normal;
  margin-top: 20px;
  font-weight: 600;
  height: 42px;
  padding: 10px 20px;
  line-height: 17px;
  width: fit-content;
}
.chat-page-add-friend-button-div {
  display: flex;
  justify-content: center;
}

.login-to-continue-chat-page {
  height: calc(100% - 85px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-to-continue-photo {
  width: 400px;
}
.login-to-continue-photo > img {
  width: 100%;
}
.login-to-continue-chat-page > span {
  font-size: 28px;
  color: #00838f;
  font-family: "Georgia";
  font-weight: 700;
  text-align: center !important;
  margin-bottom: 3rem !important;
  margin-top: 1.5rem !important;
}

@media screen and (max-width: 720px) {
  .chat-page-inner-container {
    flex-direction: column;
    width: 90%;
    height: auto;
  }
  .chat-page-add-friend-back {
    z-index: 3;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .chat-page-container {
    min-height: 100vh;
  }
  .chat-page-inner-container {
    height: 80%;
    border-radius: 0px;
    margin-bottom: 100px;
    position: relative;
  }
  .chat-page-add-friend-back {
height: 100vh;
  }
  .chat-page-add-friend {
    margin-top: 100px;
    width: 300px;
  }
}
