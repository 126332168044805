.order-confiramtion-cart-page-container-mobile-top
{
 width: 100vw;
 height: 100vh;
 background-color: white;
}

.order-confiramtion-cart-page-container-mobile
{

 height: calc(50% - 30px);
 background-color: rgba(0, 131, 143);
 border-radius: 0 0 62px 62px;
}

.order-confiramtion-cart-page-container-mobile>div
{
 padding: 3vw;
 width: 100%;
 height: 75%;
 display: flex;
 align-items: center;
 justify-content: center;
}
.order-confiramtion-cart-page-container-mobile>div>span
{
 background-color: white;
 padding: 7vw;
 border-radius:100px ;
 display: flex;
 align-items: center;
 justify-content: center;
}
.order-confiramtion-cart-page-container-mobile>div>span>img
{
 width: 130px;
}


.order-confiramtion-cart-page-container-mobile>section
{
 width: 100%;
 text-align: center;
     overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    /* line-height: 95.6%; */
    color: #FFFFFF;
    margin-bottom: 2vw;
}

.order-confiramtion-cart-page-container-mobile>article
{
  width: 100%;
 text-align: center;
     overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #ffffff9f;
}


.order-confiramtion-cart-page-container-mobile-row2
{
 width: 100%;
height: calc(50% - 30px);
display: flex;
align-items: center;
justify-content: center;
}
.order-confiramtion-cart-page-container-mobile-row2>button
{
 background-color: rgba(0, 131, 143);
  font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    padding: 3vw 6vw;
    border: none;
    border-radius: 12px;
    /* line-height: 95.6%; */
    color: #FFFFFF;
}