.blogsofAll {
  width: 400px;
  max-height: 560px;
  height: 530px;
  overflow: hidden;
  background: #ffffff;
  border: 2px solid rgb(197, 197, 197);
  border-radius: 28px;
  position: relative;
  margin-bottom: 2rem;
  background-color: white;
  padding: 15px 30px;
}
.blogsofAllNav {
  width: 100%;
  height: 71px;
  display: grid;
  grid-template-columns: 70px 1fr 20px;
  align-items: center;
}
.blogsofAllProfile {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.blogsofAllProfile img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin: 0 auto;
}
.blogsofAllUsername {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.blogsofAllUsername p:nth-child(1),
.blogTitle > p {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 160%;
  color: #00838f;
  margin: 0;
}
.blogTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blogsofAllUsername p:nth-child(2),
.blogDescription > p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: #515151;
  margin: 0;
}
.post-card-top-row-dots-option-no {
  display: none;
}
.post-card-top-row-dots-option {
  width: 90px;
  background: #f4f1f1;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 75px;
  right: 40px;
  cursor: pointer;
}
.post-card-top-row-dots-option > span {
  padding: 8px 14px;
  background: #f4f1f1;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
}
.post-card-top-row-dots-option > .delete {
  padding: 8px 14px;
  background: #f4f1f1;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #fe0000;
}
.moreIcon {
  height: 1.2rem;
}

/* .blogTitle p {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}
.blogDetailContainer > p {
  display: flex;
  flex-direction: column;
  max-height: 380px;
  height: fit-content;
  padding: 4px;
  overflow: hidden;
} */

.blogImage {
  border-radius: 28px;
  width: 100%;
  height: 250px;
  align-self: center;
  object-fit: inherit;
}
.commentsBlog {
  cursor: pointer;
}
.commentsBlog img {
  width: 20px;
}
.blogCardInteractions {
  display: flex;
  align-items: center;
  margin-top: 20px;
  column-gap: 20px;
  padding: 0 10px;
}

.blogCardLikes {
  display: flex;
  gap: 10px;
  width: fit-content;
  align-items: center;
}
.blogCardLikes > span {
  margin-left: -20px;
}
.blogCardLikes > span,
.BlogComments > span {
  font-size: 14px;
}
.likeIcon {
  color: #00838f;
  font-size: 28px;
}

.BlogComments {
  display: flex;
  gap: 10px;
  height: 20px;
  width: fit-content;
  align-items: center;
}
.blogIcon,.shareIcon{
  cursor: pointer;
  color: #00838f;
  font-size: 22px;
}

.BlogShare {
  display: flex;
  gap: 10px;
  height: 20px;
  width: fit-content;
  align-items: center;
  margin-left: auto;
}
.BlogShare > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.no-user-icon {
  color: #00838f;
  font-size: 15px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .blogsofAllUsername p:nth-child(1),
  .blogTitle > p {
    font-weight: 600;
    font-size: 16px;
  }
  .blogsofAllUsername p:nth-child(2),
  .blogDescription > p {
    font-weight: 400;
    font-size: 12px;
  }
  .blogsofAll {
    width: 100%;
    cursor: pointer;
  }
  .post-card-top-row-dots-option {
    top: 68px;
    right: 42px;
  }
  .likesBlogs > span {
    margin-left: 0px;
  }
  .seemore{
    font-size:13px;
    color: #747474;
    font-weight:800;
    font-family: "Roboto";
    font-style: normal;
  }
  .seemorediv{
    display:flex;
    flex-direction: row;
  }
  .blogsofAll1{
    max-height: unset; 
    height: unset;
    border-radius: 20px;
  }
  .sendIcon1{
    color:black;
    font-size:25px;
    padding-right: 5px;
  }
}
