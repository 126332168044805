.jobs-main-page-container
{
    display: flex;
    gap: 10px;
    padding: 30px 20px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
}
.jobs-main-overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
.job-card-container
{
    height: 290px;
    width: 300px;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #027d8b18;
    box-shadow: 2px 2px 2px rgba(128, 128, 128, 0.475);
}
.job-modal-container{
    position: relative;
    background-color: white;
    height: fit-content;
    max-height: 500px;
    width: 600px;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    box-shadow: 2px 2px 2px rgba(128, 128, 128, 0.475);
}
.job-card-top-row-container
{
    display: flex;
    align-items: center;
}
.job-card-container .job-card-skills > p{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 260px;
    white-space: nowrap;
}
.job-card-top-row-image,.job-card-top-row-image img
{
    width: 70px;
    height: 70px;
    border-radius: 50%;
}
.job-card-top-row-image
{
    margin-right: 15px;
}
.job-card-top-row-details
{
    margin-top: -10px;
}
.job-card-top-row-details-job-role
{
    margin-top: 7px;
    color: #027e8b;
    font-weight: 600;
}
.job-modal-second-row{
    padding: 4px 30px;
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.modal-heading{
    font-size: 20px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: unset;
}
.job-card-description
{
    min-height: auto;
    max-height: 300px;
    white-space: nowrap;

}
.job-card-description >p{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}
.job-modal-description
{
    height: fit-content;
    max-height: 100px;
    overflow: auto;
    overflow-y: scroll;
}
.job-card-apply-button
{
    width: 100%;
    background-color: #027e8b;
    color: white;
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
    border: none;
    outline: none;
    margin-top: 5px;
}
.crossIcon{
    position: absolute;
    top: 20px;
    right: 20px;
}