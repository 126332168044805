.chat-box-person-icons
{
 padding: 20px 20px 10px 10px;
 width: 100%;
 display: flex;
 justify-content: space-between;
 align-items: center;
 /* gap: 10px; */
 height: 70px;
}
.chat-box-person-icons-col-1{
  cursor: pointer;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.chat-box-person-icons-username{
  max-width: 200px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  align-items: start;
}
.chat-box-person-icons-username > span{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  
  color: #8A8A8A;
}

.chat-box-person-icons-image
{
   width: 50px;
 height: 50px;
 border-radius: 100px;
 background-color: black;
}
.chat-box-person-icons-image>img{
  width: 50px;
 height: 50px;
 border-radius: 100px;
}
.chat-box-person-icons-col2
{
  flex: 1;
  border: 2px solid;
  max-width: 200px;
 display: flex;
 flex-direction: column;
 gap: 4px;
 cursor: pointer;
}

.chat-box-person-card-delete-col3{
  width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2px;
    position: relative;
}
.chat-box-person-card-delete-col3>div {
  cursor: pointer;
  background-color: black;
  width: 4px;
  
  height: 4px;
  background: #C4C4C4;
  border-radius: 100px;
}
.chat-box-person-card-delete-col3-thredots {
  background: #fff;
  box-shadow: 2px 1px 9px rgba(0,0,0,.25);
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -10px;
  width: 90px;
}
.chat-box-person-card-delete-col3-thredots >span{
  cursor: pointer;
}
.chat-box-person-icons-username
{
 font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
/* identical to box height */


color: #000000;

}
